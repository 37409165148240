import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'
import XlsxUploader from 'components/Common/Uploader/XlsxUploader'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddAssignMaterialToBinModal from './modals/add-assign-material'

import {
    getAssignMaterialToBinList,
    deleteAssignMaterialToBin,
    downloadMaterialToBinTemplate,
    uploadMaterialToBinTemplate,
} from 'api/master/assignMaterialToBIN'
import { DownloadIc } from 'assets'

import { assignMaterialColumns } from './columns'

function AssignMaterialToBin() {
    const [showAddAssignMaterialToBinModal, setAddAssignMaterialToBinModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [selectedRows, setSelectedRows] = useState([])

    const { doRefresh } = useQueryRefresh()

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = selectedRows.map((row) => ({
                    materialId: row.materialId,
                    binId: row.binId,
                }))

                const res = await deleteAssignMaterialToBin(reqBody)

                if (!res.success) return
                Modal.success({
                    title: 'Success',
                    content: 'Delete success!',
                    onOk: (close) => {
                        doRefresh()
                        close()
                    },
                })
            },
        })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete Assign" onClick={handleBulkDelete} />
    )

    const handleDownload = async () => {
        await downloadMaterialToBinTemplate()
    }

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddAssignMaterialToBinModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
            <Button
                onClick={handleDownload}
                className="outLinedBlue flex justify-center items-center w-48 h-45 rounded-10 mx-1"
                size="middle "
            >
                <DownloadIc />
                <span className="ml-2">Download Template</span>
            </Button>
            <XlsxUploader callApi={uploadMaterialToBinTemplate} />
        </Row>
    )

    return (
        <>
            <RichTable
                rowKey="binId"
                columns={assignMaterialColumns(onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getAssignMaterialToBinList}
                type="rich"
            />
            <AddAssignMaterialToBinModal
                close={() => setAddAssignMaterialToBinModal(false)}
                visible={showAddAssignMaterialToBinModal}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default AssignMaterialToBin
