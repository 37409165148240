import React, { useState, useEffect } from 'react'
import { Select, Spin } from 'antd'

import { getUomByMaterial } from 'api/master/materialConversion'
import { removeDuplicateObjectInArray } from 'utils/array'

const SelectUomByMaterial = ({
    materialId,
    onChange = () => {},
    placeholder = 'Select',
    ...props
}) => {
    const [loading, setLoading] = useState(false)
    const [optList, setOptList] = useState([])

    useEffect(() => {
        if (!materialId) return

        const fetchUomOptions = async () => {
            setLoading(true)
            const res = await getUomByMaterial({ filter: materialId })
            const opt = res.result.data.map((item) => ({
                label: `${item.uom}`,
                value: item.uom,
            }))
            setOptList(removeDuplicateObjectInArray(opt, 'value'))
            setLoading(false)
        }

        fetchUomOptions()
    }, [materialId])

    return (
        <Select
            showSearch
            loading={loading}
            onChange={onChange}
            placeholder={placeholder}
            className="rounded-10 h-50"
            style={{ width: '100%', borderRadius: '5 !important' }}
            size="large"
            dropdownRender={(menu) => (
                <>
                    {!loading && menu}
                    {loading && (
                        <div className="text-center p-10">
                            <Spin />
                        </div>
                    )}
                </>
            )}
            options={optList}
            {...props}
        />
    )
}

export default SelectUomByMaterial
