import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon, PasswordIcon, UserIcon } from 'assets'
import EllipsisText from 'components/Common/EllipsisText'

export const columns = (onCLickEditIcon, onCLickDeleteIcon, onClickChangePasswordIcon, onClickResetIcon) => [
    {
        title: 'Actions',
        width: 200,
        render: (_, record) => (
            <div
                style={{
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    minWidth: 70,
                }}
            >
                <Row gutter={8}>
                    <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                        <DeleteIcon />
                    </Col>
                    <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                        <EditIcon />
                    </Col>
                    <Col className={'cursor-pointer'} onClick={() => onClickChangePasswordIcon(record)}>
                        <PasswordIcon />
                    </Col>
                    <Col className={'cursor-pointer'} onClick={() => onClickResetIcon(record)}>
                        <UserIcon />
                    </Col>
                </Row>
            </div>
        ),
    },
    {
        title: 'User ID',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: 'Description',
        dataIndex: 'position',
        key: 'position',
    },
    {
        title: 'Role Menu',
        dataIndex: 'roleId',
        key: 'roleId',
    },
    // {
    //     title: 'Group DC Location',
    //     dataIndex: 'groupDcLocationId',
    //     key: 'groupDcLocationId',
    // },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Sloc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Gate',
        dataIndex: 'gateId',
        key: 'gateId',
    },
    {
        title: 'Docking',
        dataIndex: 'dockingId',
        key: 'dockingId',
    },
    {
        title: 'Staging In',
        dataIndex: 'stagingIn',
        key: 'stagingIn',
        render: (text) => (
            <EllipsisText>
                {text?.split(',')?.map((i) => (
                    <p className="min-w-fit my-0 py-0">{i}</p>
                ))}
            </EllipsisText>
        ),
        width: 250,
    },
    {
        title: 'Staging Out',
        dataIndex: 'stagingOut',
        key: 'stagingOut',
        render: (text) => (
            <EllipsisText>
                {text?.split(',')?.map((i) => (
                    <p className="min-w-fit my-0 py-0">{i}</p>
                ))}
            </EllipsisText>
        ),
        width: 250,
    },
    {
        title: 'Create Date',
        dataIndex: 'createDate',
        key: 'createDate',
    },
    {
        title: 'Create By',
        dataIndex: 'createBy',
        key: 'createBy',
    },
]
