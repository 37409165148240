export const HTTP_FAILED = 'FAILED'
export const HTTP_ERROR = 'ERROR'
export const HTTP_LOGIN_ERROR = 'Username or password may be incorrect, please login again'
export const HTTP_SESSION_EXPIRED = 'Your session has expired, please login again'
export const HTTP_UNAUTHORIZED_ACTION = 'Unauthorized Action!'
export const HTTP_INTERNAL_SERVER_ERROR = 'Internal server error'
export const HTTP_UNPROCESSED_REQUEST = 'Request can not be processed'
export const HTTP_UNKNOWN_ERROR = 'Unknown Error'
export const HTTP_NETWORK_ERROR = 'Network Error'
export const HTTP_NOT_FOUND = 'API Not Found'
export const HTTP_METHOD_NOT_ALLOWED = 'Method Not Allowed'
export const HTTP_NETWORK_ISSUE_ERROR =
    'This could be server network issue or dropped internet connection'

const ERROR_STRING = {
    HTTP_FAILED,
    HTTP_ERROR,
    HTTP_LOGIN_ERROR,
    HTTP_SESSION_EXPIRED,
    HTTP_UNAUTHORIZED_ACTION,
    HTTP_INTERNAL_SERVER_ERROR,
    HTTP_UNPROCESSED_REQUEST,
    HTTP_UNKNOWN_ERROR,
    HTTP_NETWORK_ERROR,
    HTTP_NETWORK_ISSUE_ERROR,
    HTTP_NOT_FOUND,
    HTTP_METHOD_NOT_ALLOWED,
}

export default ERROR_STRING
