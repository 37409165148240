import { useState } from 'react'
import { LOCAL_STORAGE_KEYS } from 'config/localStorage'

const { DC_LOCATION_FOR_DASHBOARD } = LOCAL_STORAGE_KEYS

const dcLocationFromLocalStorage = JSON.parse(
    localStorage.getItem(DC_LOCATION_FOR_DASHBOARD) || '{}'
)

function useDashboardState() {
    const [dcLocation, setDcLocation] = useState(dcLocationFromLocalStorage)
    const onChangeDcLocation = (val) => {
        setDcLocation(val)
        localStorage.setItem(DC_LOCATION_FOR_DASHBOARD, JSON.stringify(val))
    }

    return { dcLocation, onChangeDcLocation }
}

export default useDashboardState
