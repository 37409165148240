// import { Row, Col } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = () => [
    {
        title: 'System ID',
        dataIndex: 'systemId',
        key: 'systemId',
    },
    {
        title: 'Route',
        dataIndex: 'route',
        key: 'route',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Shipping Type ',
        dataIndex: 'shippingType',
        key: 'shippingType',
    },
    {
        title: 'Transit Time ',
        dataIndex: 'transitTime',
        key: 'transitTime',
    },
    {
        title: 'Trans Lead Time ',
        dataIndex: 'transLeadTime',
        key: 'transLeadTime',
    },
    {
        title: 'Departure Point',
        dataIndex: 'departurePoint',
        key: 'departurePoint',
    },
    {
        title: 'Destination Point',
        dataIndex: 'destinationPoint',
        key: 'destinationPoint',
    },
]
