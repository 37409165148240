import React from 'react'

function ButtonsGroupGrid({ buttonWidth, children, align = 'right', style, className }) {
    const direction = {
        left: 'ltr',
        right: 'rtl',
    }
    return (
        <div
            className={className}
            style={{
                display: 'grid',
                gridTemplateColumns: `repeat(auto-fit, ${buttonWidth || '157'}px)`,
                gap: 20,
                justifyContent: 'start',
                direction: direction[align] || 'rtl',
                ...style,
            }}
        >
            {children}
        </div>
    )
}

export default ButtonsGroupGrid
