import React, { useState } from 'react'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import useQueryRefresh from 'customHooks/useQueryRefresh'
import { deleteSpv } from 'api/transaction/plannerDoExecution'

const RemoveSpvModal = ({ payload, ...props }) => {
    const [loading, setLoading] = useState(false)
    const { doRefresh } = useQueryRefresh()

    const handleRemoveSPV = async () => {
        setLoading(true)
        try {
            await deleteSpv({ transactionId: payload.transactionId })
            setLoading(false)
            doRefresh()
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    return (
        <ConfirmationModal
            loading={loading}
            visible={props.visible}
            close={props.close}
            title="Confirm Remove SPV"
            content="Are you sure want to remove SPV ?"
            successTitleMsg="Success"
            successContentMsg="SPV Removed"
            // errorTitleMsg="SomethingWentWrong"
            errorContentMsg=""
            onOk={handleRemoveSPV}
        />
    )
}

export default RemoveSpvModal
