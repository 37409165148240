import { Row, Col } from 'antd'
import { indonesianNumber } from 'utils/formatter'

export const columns = [
    {
        title: 'No Booking',
        dataIndex: 'bookingNo',
        key: 'bookingNo',
    },
    // {
    //     title: 'Plant',
    //     dataIndex: 'plantId',
    //     key: 'plantId',
    // },
    {
        title: 'Material No',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Req Qty',
        dataIndex: 'stock',
        key: 'stock',
        render: (text) => {
            if (+text < 0) return <span className="text-nabati-red">{indonesianNumber(text)}</span>
            return <span>{indonesianNumber(text)}</span>
        },
    },
    {
        title: 'Group DC',
        dataIndex: 'dcLocationId',
        key: 'dcLocationId',
    },
    {
        title: (
            <div className="text-center">
                Factory Plant
                <Row>
                    <Col span={12}>Plant</Col>
                    <Col span={12}>QTY</Col>
                </Row>
            </div>
        ),
        dataIndex: '',
        key: '',
        render: (_, record) => {
            const list = record?.items?.filter((item) => item.tipe === 'factory') || []

            return (
                <>
                    {list.map((item) => (
                        <div className="text-center">
                            <Row>
                                <Col span={12}>{item.plant}</Col>
                                <Col span={12}>{indonesianNumber(item.qty)}</Col>
                            </Row>
                        </div>
                    ))}
                </>
            )
        },
    },
    {
        title: (
            <div className="text-center">
                In Transit
                <Row>
                    <Col span={12}>Plant</Col>
                    <Col span={12}>QTY</Col>
                </Row>
            </div>
        ),
        dataIndex: '',
        key: '',
        render: (_, record) => {
            const list = record?.items?.filter((item) => item.tipe === 'intransit') || []

            return (
                <>
                    {list.map((item) => (
                        <div className="text-center">
                            <Row>
                                <Col span={12}>{item.plant}</Col>
                                <Col span={12}>{indonesianNumber(item.qty)}</Col>
                            </Row>
                        </div>
                    ))}
                </>
            )
        },
    },
]
