import React from 'react'
import { Layout, Row, Col, Dropdown, Button } from 'antd'
import { MenuOutlined, CaretDownOutlined, CloseOutlined } from '@ant-design/icons'

import SimpleContainer from 'components/layouts/simple'
import { UserIcon } from 'assets'
import { auth } from 'auth'

import { useMenu } from './useMenuHooks'
const { Header } = Layout

function UserMenuOverlay() {
    return (
        <SimpleContainer className="bg-white">
            <Button type="primary" className="rounded-5" onClick={auth.signOut}>
                Logout
            </Button>
        </SimpleContainer>
    )
}

function HeaderDashboard({ toggleSideMenu }) {
    const { currentMenu } = useMenu()
    const user = auth.getUserInfo()

    return (
        <>
            <Header className="sticky site-layout-background pl-4 top-0 z-10">
                <Row align="middle">
                    <Col span={4}>
                        {React.createElement(CloseOutlined ? MenuOutlined : MenuOutlined, {
                            className: 'trigger ml-3',
                            onClick: toggleSideMenu,
                        })}
                    </Col>
                    <Col span={16}>
                        <p className="text-center font-bold text-lg">Warehouse Management System</p>
                    </Col>
                    <Col span={4}>
                        <div className="flex justify-center items-center">
                            <div className="w-9">
                                <UserIcon fill="#666666" />
                            </div>
                            <Dropdown overlay={<UserMenuOverlay />}>
                                <div className="ml-2 flex justify-center items-center">
                                    <p>{user?.name || 'User'}</p>
                                    <p className="-mt-2 ml-7">
                                        <CaretDownOutlined className="text-gray-600" />
                                    </p>
                                </div>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </Header>
            <div className="text-2xl font-bold px-4 py-3">{currentMenu?.title}</div>
        </>
    )
}

export default HeaderDashboard
