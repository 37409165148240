import { Input } from 'antd'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import { useState } from 'react'

import useQueryRefresh from 'customHooks/useQueryRefresh'
import { assignBookingId } from 'api/transaction/plannerDoExecution'

const AssignBookingId = ({ close, startLoadingApi = async () => {}, payload, ...props }) => {
    const [loading, setLoading] = useState(false)
    const [bookingIdInput, setBookingId] = useState('')
    const [confirmationModal, setConfirmationModal] = useState(false)

    const { doRefresh } = useQueryRefresh()

    const openConfirmationModal = () => {
        setConfirmationModal(true)
    }

    const handleAssignBookingId = async () => {
        setLoading(true)
        try {
            const res = await assignBookingId({
                transactionId: payload?.transactionId,
                doNumber: payload?.documentNumber,
                bookingId: bookingIdInput,
            })
            setLoading(false)
            if (res.success === false) return { ...res, success: false }
            setBookingId('')
            close()
            doRefresh()
            setConfirmationModal(false)
            return { ...res, success: true }
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    return (
        <>
            <SimpleModal
                title="Assign Booking ID"
                okText="OK"
                width={432}
                close={() => {
                    setBookingId('')
                    close()
                }}
                onOk={openConfirmationModal}
                {...props}
            >
                <Input
                    className="rounded-10 h-50 w-full"
                    placeholder="Input Booking ID"
                    size="large"
                    value={bookingIdInput}
                    onChange={(e) => setBookingId(e.target.value)}
                    loading={loading}
                />
            </SimpleModal>
            <ConfirmationModal
                visible={confirmationModal}
                close={() => setConfirmationModal(false)}
                title="Confirm Booking ID"
                content={`Are you sure to Booking ID ${payload?.transactionId}?`}
                successTitleMsg="Success Booking ID"
                successContentMsg={(res) => res?.message || ''}
                onOk={handleAssignBookingId}
            />
        </>
    )
}

export default AssignBookingId
