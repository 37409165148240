import React, { useState, useEffect, useContext } from 'react'
import { Table, Input, Button, Row, Col, Modal, Switch } from 'antd'
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'

import SimpleModal from 'components/Common/Modals/SimpleModal'
import SimpleContainer from 'components/layouts/simple'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import CreatePidModal from '../create-pid'
import SetPicAndDateModal from '../set-pic-and-date'

// Custom Hooks
import useQueryRefresh from 'customHooks/useQueryRefresh'
import { RichTableContext } from 'components/Common/RichTable'

// API
import {
    stockOpnameCompareSapList,
    submitToSapCompareStock,
    finishCompareStock,
    downloadCompareStock,
    submitStockOpname,
} from 'api/transaction/result-cycle-count-opname'

import { columns } from './columns'
// import { downloadAsCSV } from 'utils/downloader'
import { addSorterToColumn } from 'utils/misc'
import { groupByKey } from 'utils/array'

import {
    getReCountReqBody,
    getSubmitToSapReqBody,
    addTransactionId,
    addDocnum,
    addRowKey,
} from './helpers'

function CompareWmsVsSap({ close, payload, visible }) {
    const [data, setData] = useState([])
    const [selectedData, setSelectedData] = useState([])
    const [showOnlySelectedData, setShowOnlySelectedData] = useState(false)
    const [filteredInputValue, setFilteredInputValue] = useState(null)

    // Last Update state to trigger useEffect after submit API
    const [lastUpdate, setLastUpdate] = useState('')

    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [showCreatePidModal, setCreatePidModal] = useState(false)
    const [picDateModal, setPicDateModal] = useState(false)

    const { resetRowSelections: resetParentPageRowSelections } = useContext(RichTableContext)

    // Custom Hooks
    const { doRefresh } = useQueryRefresh()
    const reloadData = () => setLastUpdate(new Date())

    const filterData = (data_) => {
        const dataToFilter = showOnlySelectedData ? selectedData : data_
        const text = filteredInputValue?.toLowerCase()
        if (!text) return dataToFilter

        return dataToFilter.filter((item) => {
            if (item.plantId.toLowerCase().includes(text)) return true
            if (item.materialId.toLowerCase().includes(text)) return true
            if (item.description.toLowerCase().includes(text)) return true
            if (item.batch.toLowerCase().includes(text)) return true
            if (item.slocId.toLowerCase().includes(text)) return true
            return false
        })
    }

    const rowSelection = () => ({
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedData(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
        selectedRowKeys,
        getCheckboxProps: (record) => ({
            disabled: !record.statusSubmit === 1,
        }),
    })

    const resetRowSelections = () => {
        setSelectedData([])
        setSelectedRowKeys([])
    }

    const handleDownload = async () => {
        try {
            setLoading(true)
            await downloadCompareStock({ transactionId: payload.transactionId })
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const onClickSubmit = () => {
        if (selectedData.length === 0)
            return Modal.error({ title: 'Please select at least one data' })

        // Check if user select all data with the same Plant Material and sloc
        // We can check plant material and sloc in docnum key since docnum === transactionId + plant + material + sloc
        let error = ''
        const groupedDataByDocnum = groupByKey(data, 'docnum')
        const groupedSelectedDataByDocnum = groupByKey(selectedData, 'docnum')
        Object.entries(groupedSelectedDataByDocnum).forEach(([key, val]) => {
            if (groupedDataByDocnum[key].length !== val.length)
                error = 'Please select all data with the same Plant, Material, and Sloc'
        })

        if (error) return Modal.error({ title: error })
        setCreatePidModal(true)
    }

    const handleSubmitToSap = async (pidValues) => {
        const reqBodyArr = getSubmitToSapReqBody(selectedData, pidValues, payload)

        console.log('reqBodyArr handleSubmitToSap', reqBodyArr)
        // return

        setLoading(true)
        const allRes = await Promise.all(reqBodyArr.map(submitToSapCompareStock))
        setLoading(false)

        Modal.confirm({
            title: 'Result',
            onOk: () => {
                resetRowSelections()
                resetParentPageRowSelections()
                reloadData()
            },
            content: (
                <>
                    {allRes.map((res) => (
                        <div className="my-2">
                            <p>Document Number: {String(res?.responseSap?.documentNumber)}</p>
                            <p>Message: {String(res?.responseSap?.message)}</p>
                        </div>
                    ))}
                </>
            ),
        })
    }

    const handleReCount = async (picAndDate) => {
        const reqBodyArr = getReCountReqBody(selectedData, picAndDate)

        setLoading(true)
        const res = await submitStockOpname(reqBodyArr)
        setLoading(false)

        if (!res?.success) {
            return Modal.error({
                title: 'Error',
                content: <p>{res?.message ? res?.message : 'Something went wrong'}</p>,
            })
        }

        Modal.success({
            title: 'Success Recount',
            onOk: () => {
                resetRowSelections()
                resetParentPageRowSelections()
                reloadData()
            },
            content: <p>Docnum: {res?.docnum || ''}</p>,
        })
    }

    const handleFinish = async () => {
        const reqBody = { transactionId: payload.transactionId }
        const res = await finishCompareStock(reqBody)
        if (!res?.success) return
        Modal.success({
            title: `Finished`,
            onOk: () => {
                resetRowSelections()
                resetParentPageRowSelections()
                close()
                doRefresh()
            },
        })
    }

    useEffect(() => {
        if (!visible) return
        if (!payload?.transactionId) return
        const fetchData = async () => {
            try {
                setLoading(true)
                const res = await stockOpnameCompareSapList({
                    transactionId: payload?.transactionId,
                })
                setData(
                    (res?.result?.data || [])
                        .map(addTransactionId(payload))
                        .map(addDocnum)
                        .map(addRowKey)
                )
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.error(error)
            }
        }
        fetchData()
    }, [payload, payload?.transactionId, visible, lastUpdate])

    return (
        <>
            <SimpleModal
                visible={visible}
                title="Compare Stock WMS vs SAP"
                width={1055}
                close={() => {
                    setData([])
                    resetRowSelections()
                    doRefresh()
                    close()
                }}
                withCrossIcon
                footer={false}
            >
                <SimpleContainer>
                    <Row gutter={24}>
                        <Col span={16}>
                            <Input
                                className="rounded-10 h-50"
                                placeholder="Search Material, Batch, Sloc, Plant..."
                                suffix={<SearchOutlined />}
                                onChange={(e) => setFilteredInputValue(e.target.value)}
                                value={filteredInputValue}
                                size="large"
                                allowClear
                            />
                        </Col>
                        <Col span={8} className="flex items-center">
                            <Button
                                loading={loading}
                                icon={<DownloadOutlined />}
                                onClick={handleDownload}
                                className="outLinedBlue w-48 h-45 rounded-10 flex justify-center items-center"
                            >
                                Download
                            </Button>
                        </Col>
                    </Row>
                </SimpleContainer>
                <SimpleContainer>
                    <div className="flex items-center h-8">
                        Show only selected data?
                        <Switch
                            className={
                                showOnlySelectedData ? 'bg-nabati-pink mx-2' : 'bg-slate-300 mx-2'
                            }
                            onClick={() => setShowOnlySelectedData(!showOnlySelectedData)}
                            checkedChildren="YES"
                            unCheckedChildren="NO"
                            checked={showOnlySelectedData}
                        />
                        <p>{selectedRowKeys.length} data selected</p>
                    </div>
                    <Table
                        rowKey="rowKey"
                        loading={loading}
                        dataSource={filterData(data)}
                        columns={addSorterToColumn(columns())}
                        rowClassName={(_, index) =>
                            `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                        }
                        pagination={false}
                        rowSelection={rowSelection()}
                        scroll={{ x: 1000 }}
                    />
                </SimpleContainer>

                <ButtonsGroupGrid align="right" className="mb-3 mt-10">
                    <Button
                        loading={loading}
                        onClick={onClickSubmit}
                        type="primary"
                        className="w-157 h-45 rounded-10 mx-2"
                    >
                        Submit to SAP
                    </Button>
                    <Button
                        loading={loading}
                        onClick={() => {
                            if (selectedData.length === 0)
                                return Modal.error({ title: 'Please select at least one data' })
                            setPicDateModal(true)
                        }}
                        className="outLinedPink w-157 h-45 rounded-10 mx-2"
                    >
                        Re-Count
                    </Button>
                    <Button
                        loading={loading}
                        onClick={() =>
                            Modal.confirm({
                                title: 'Finish confirmation',
                                content: 'Are you sure want to finish transaction?',
                                okText: 'Finish',
                                onOk: handleFinish,
                            })
                        }
                        className="outLinedGrey w-157 h-45 rounded-10 mx-2"
                    >
                        Finish
                    </Button>
                </ButtonsGroupGrid>
                <CreatePidModal
                    close={() => setCreatePidModal(false)}
                    visible={showCreatePidModal}
                    onSubmit={handleSubmitToSap}
                />

                {/* PIC and Date for recount */}
                <SetPicAndDateModal
                    visible={picDateModal}
                    close={() => setPicDateModal(false)}
                    onChange={() => {}}
                    onOk={handleReCount}
                />
            </SimpleModal>
        </>
    )
}

export default CompareWmsVsSap
