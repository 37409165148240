import React from 'react'
import { Table } from 'antd'
import './style.css'

function SimpleTable({ onChange, ...props }) {
    const getClassName = (_, index) => (index % 2 === 0 ? '' : 'bg-nabati-lightGray')
    const getFooter = (data) => {
        const from = props?.pagination?.total
            ? (props.pagination.current - 1) * props.pagination.pageSize + 1
            : 0
        const to = data.length > 0 ? from + data.length - 1 : 0
        return `Showing ${from} to ${to} of ${props?.pagination?.total || 0} entries`
    }

    return (
        <Table
            {...props}
            dataSource={props.data}
            onChange={onChange}
            rowClassName={getClassName}
            footer={getFooter}
        />
    )
}

export default SimpleTable
