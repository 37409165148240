import { CheckCircleFilled } from '@ant-design/icons'

export const columns = () => [
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Plant Description',
        dataIndex: 'plantDescription',
        key: 'plantDescription',
    },
    {
        title: 'No Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Sloc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Stock Status',
        dataIndex: 'stockStatus',
        key: 'stockStatus',
    },
    {
        title: 'Qty Count',
        dataIndex: 'qtyWms', // ??? Tanya Pak Roel lagi
        key: 'qtyWms', // ??? Tanya Pak Roel lagi
    },
    {
        title: 'Qty Others',
        dataIndex: 'qtyOthers', // ??? Tanya Pak Roel lagi
        key: 'qtyOthers', // ??? Tanya Pak Roel lagi
    },
    {
        title: 'Qty SAP',
        dataIndex: 'qtySap',
        key: 'qtySap',
    },
    {
        title: 'Different',
        dataIndex: 'qtyDifferent',
        key: 'qtyDifferent',
    },
    {
        title: 'Status',
        dataIndex: 'submitSap',
        key: 'submitSap',
        align: 'center',
        render: (text) => {
            const isAlreadySubmitted = +text === 1
            if (isAlreadySubmitted) return <CheckCircleFilled className="text-green-500 text-lg" />
            return <CheckCircleFilled className="text-gray-200 text-lg" />
        },
    },

    // {
    //     title: 'Over Qty',
    //     dataIndex: 'overQuantity',
    //     key: 'overQuantity',
    //     render: (text, record) => {
    //         const isOverQuantity = +text > 0
    //         if (isOverQuantity) return <p className="text-red-600">{text}</p>
    //         return <p>{text}</p>
    //     },
    // },
]
