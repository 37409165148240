import React, { useState } from 'react'
import { Form, Modal, Divider, Button, Input, Select } from 'antd'

import useQueryRefresh from 'customHooks/useQueryRefresh'

function AddEditMenuId({
    close,
    visible,
    payload: initialValues = {},
    successMsg = 'success',
    title = '',
    callApi,
    additionalPayload = {},
}) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        const payload = {
            ...initialValues,
            // menuId: values.menuId,
            menuType: values.menuType,
            description: values.description,
            path: values.path,
            icon: values.icon,
            ...additionalPayload,
        }

        try {
            const res = await callApi(payload)
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                {/* <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="menuId"
                    label="Menu ID"
                    initialValue={initialValues?.menuId}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Input
                        disabled={initialValues?.menuId}
                        placeholder="Menu ID"
                        className="rounded-5"
                        size="large"
                    />
                </Form.Item> */}
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="menuType"
                    label="Application"
                    initialValue={initialValues?.menuType}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Select placeholder="Application" className="rounded-5" size="large">
                        <Select.Option value="mobile">Mobile</Select.Option>
                        <Select.Option value="web">Web</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="description"
                    label="Description"
                    initialValue={initialValues?.description}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Input placeholder="Description" className="rounded-5" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="path"
                    label="Path"
                    initialValue={initialValues?.path}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Input placeholder="Path" className="rounded-5" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="icon"
                    label="Icon"
                    initialValue={initialValues?.icon}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Input placeholder="Icon" className="rounded-5" size="large" />
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={() => {
                        setLoading(false)
                        close()
                    }}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddEditMenuId
