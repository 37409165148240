import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, InfoYellowIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const photoOutboundColumns = (
    onCLickDisplayIcon
) => [
    {
        title: 'Actions',
        render: (_, record) => {
            return (
                <div
                    style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        minWidth: 100,
                    }}
                >
                    <Row gutter={8} justify="start" align="middle">
                        <Col
                            span={8}
                            className={'cursor-pointer'}
                            onClick={() => onCLickDisplayIcon(record)}
                        >
                            <EyeIcon />
                        </Col>
                    </Row>
                </div>
            )
        },
    },
    {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Ref Doc No',
        dataIndex: 'refDocNo',
        key: 'refDocNo',
    },
    {
        title: 'Forwarder Name',
        dataIndex: 'forwarderName',
        key: 'forwarderName',
    },
    {
        title: 'Do Number',
        dataIndex: 'doNumber',
        key: 'doNumber',
    },
    {
        title: 'Delivery Date',
        dataIndex: 'deliveryDate',
        key: 'deliveryDate',
    },
    {
        title: 'Customer Id',
        dataIndex: 'customerId',
        key: 'customerId',
    },
    {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName',
    },
    {
        title: 'Status',
        dataIndex: 'description',
        key: 'description',
        render: (text) => {
            const color = {
                Uncreate: 'red',
                'Sign Pallet': 'yellow',
                'Ready To Load': 'green',
            }

            return <Tag color={color[text]}>{text}</Tag>
        },
    },
]
