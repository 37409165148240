import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, InfoYellowIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const reportReachtruckColumns = (
) => [
    {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    
    {
        title: 'Create Date',
        dataIndex: 'createDate',
        key: 'createDate',
    },
    {
        title: 'User Id',
        dataIndex: 'userId',
        key: 'userId',
    },
    {
        title: 'Mover Transaction',
        dataIndex: 'moverTransactionId',
        key: 'moverTransactionId',
    },
    {
        title: 'Pallet id',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Pallet Code',
        dataIndex: 'palletCode',
        key: 'palletCode',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Bin Id',
        dataIndex: 'binId',
        key: 'binId',
    },
    {
        title: 'To Bin Id',
        dataIndex: 'toBinId',
        key: 'toBinId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'Uom',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Stock Status',
        dataIndex: 'stockStatus',
        key: 'stockStatus',
    },
    {
        title: 'Sloc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Sector',
        dataIndex: 'sectorId',
        key: 'sectorId',
    },
    {
        title: 'Gate',
        dataIndex: 'gateId',
        key: 'gateId',
    },
    {
        title: 'Stanggin In',
        dataIndex: 'staggingIn',
        key: 'staggingIn',
    },
    {
        title: 'Stanggin Out',
        dataIndex: 'staggingOut',
        key: 'staggingOut',
    },
    {
        title : 'Docking Id',
        dataIndex : 'dockingId',
        key : 'dockingId',
    },
    {
        title : 'Sled',
        dataIndex : 'sled',
        key : 'sled',
    },
    {
        title : 'Manufacture Date',
        dataIndex : 'manufactureDate',
        key : 'manufactureDate',
    },
    {
        title : 'Reference Doc',
        dataIndex : 'referenceDoc',
        key : 'referenceDoc',
    },
    {
        title : 'Status Scan Pallet',
        dataIndex : 'statusScanPallet',
        key : 'statusScanPallet',
    },
]
