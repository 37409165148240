import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddDcLocationPlantModal from './modals/add-dc-location-plant'
import UpdateDcLocationPlantModal from './modals/update-dc-location-plant'

import { getDcLocationPlantList, deleteDcLocationPlant } from 'api/master/dcLocationPlant'

import { dcLocationPlantColumns } from './columns'

function DcLocationPlant() {
    const [showUpdateDcLocationPlantModal, setUpdateDcLocationPlantModal] = useState(false)
    const [showAddDcLocationPlantModal, setAddDcLocationPlantModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [selectedRows, setSelectedRows] = useState([])

    const { doRefresh } = useQueryRefresh()

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateDcLocationPlantModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = selectedRows.map((row) => ({
                    dcLocationId: row.dcLocationId,
                    plantId: row.plantId,
                    factoryPlant: row.factoryPlant,
                }))

                const res = await deleteDcLocationPlant(reqBody)

                if (!res.success) return
                doRefresh()
            },
        })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete All" onClick={handleBulkDelete} />
    )

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddDcLocationPlantModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                columns={dcLocationPlantColumns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getDcLocationPlantList}
                type="rich"
            />
            <AddDcLocationPlantModal
                close={() => setAddDcLocationPlantModal(false)}
                visible={showAddDcLocationPlantModal}
            />
            <UpdateDcLocationPlantModal
                close={() => setUpdateDcLocationPlantModal(false)}
                visible={showUpdateDcLocationPlantModal}
                payload={modalFormPayload}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default DcLocationPlant
