import React from 'react'
import SelectDebounceFetch from './index'
import { getCharacteristicSortList } from 'api/master/characteristicSort'

function SelectCharacteristicSort({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Characteristic Sort"
            fetchOptions={async (searchTerm) => {
                const res = await getCharacteristicSortList({ filter: searchTerm, total: 10 })
                const list = res?.result?.data.map((characteristic) => ({
                    value: characteristic.id,
                    label: characteristic.id + '-' + characteristic.characteristicName,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectCharacteristicSort
