import React, {useEffect, useState} from 'react'
import { Col, Image, Row,Radio, Switch, Space, List, Typography, Checkbox, Button } from 'antd'
import { saveAs } from 'file-saver'
import { Close, CheckCircleIcon } from 'assets'
import {
    getListPalletReportOutbound, downloadListReport
} from 'api/report/photoOutbound'
import { palletOutboundColumns } from './columns'
import RichTable from 'components/Common/RichTable'
import { DownloadOutlined } from '@ant-design/icons'


function LabeledItem({ title, content, className = 'my-4' }) {
    return (
        <div className={className}>
            <p className="font-bold text-sm text-nabati-grey">{title}</p>
        </div>
    )
}

export  function ImageMapingTab (dataItem){
	const downloadImage = (url,name) => {
        saveAs(url, name) // Put your image url here.
    }
	var indents = [];
	dataItem?.imageData?.map((item,key) =>
	indents.push(<Col span='6' style={{textAlign:'center'}} key={key}><Image src={item.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.replace('http://','https://'),dataItem?.outboundId+'-checkin-'+key+'.jpg')
	}} >Download</a></Col>)
	)
    return (
        <Row>
			{indents}
		</Row>

	)
}

export  function ImageCheckingArmadaTab (dataItem){
	const downloadImage = (url,name) => {
        saveAs(url, name) // Put your image url here.
    }
	var detailData = dataItem.imageData?.detail
	var notes = detailData[0].notes
	var indents = [];
	var items = [];
	dataItem?.imageData?.image?.map((item,key) =>
	indents.push(<Col span='6' style={{textAlign:'center'}} key={key}><Image src={item.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.replace('http://','https://'),dataItem?.outboundId+'-checkin-'+key+'.jpg')
	}} >Download</a></Col>)
	)

	detailData[1].item?.map((data,keys) =>
		items.push(
			<Col  style={{textAlign:'left',justifyContent:'space-between'}} key={keys}>
				<Col span="3">
				<LabeledItem>{data.description}</LabeledItem>
				</Col>
				<Col span="3">
				<Radio className='primary' style={{float:'right'}} disabled={(data.checkStatus == "X") ? false :true}  defaultChecked={(data.checkStatus == "X") ? true :false}>	
					{data.description}
				</Radio>
				</Col>
			</Col>
		)
	)
    return (
		<>
		
        <Row span="36" style={{width:'fit-content',marginBottom:20}}>
			<Col span="36">
			<List
				header={''}
				footer={''}
				column={'xl'}
				bordered
				dataSource={detailData[1].item}
				renderItem={(item) => (
				<List.Item >
					<Col span="24">
					{item.description}
					</Col>
					<Col span="8">
					{(item.checkStatus == "X") ? <CheckCircleIcon /> : <Close style={{height:24,width:24, marginLeft:-2}} />}
					</Col>
				</List.Item>
				)}
			/>
			</Col>
			
		</Row>
		<Row style={{marginBottom:20}}>
			<Col span="36">
				<label><b>Note</b></label><br/>
				{detailData[0].notes}
			</Col>
		</Row>
		<Row>
			{indents}
		</Row>
		</>

	)
}

export function ImageMapingTabLoadingProcess (dataItem) {
	var noKontainerData = [];
	var tertutupFullData = [];
	var tertutupSetengahData = [];
	var tirData = [];
	var tutupBelakangData = [];
	var tutupDepanData = [];
	const downloadImage = (url,name) => {
        saveAs(url, name) // Put your image url here.
    }
	dataItem?.imageData?.noKontainer?.map((item,key) =>
	noKontainerData.push(<Col span='6'  style={{textAlign:'center'}} key={key}><Image preview={true} src={item.image.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.image.replace('http://','https://'),dataItem?.outboundId+'-loadingprocessnokontainer-'+key+'.jpg')
	}} >Download</a></Col>)
	)
	dataItem?.imageData?.tertutupFull?.map((item,key) =>
	tertutupFullData.push(<Col span='6'  style={{textAlign:'center'}} key={key}><Image preview={true} src={item.image.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.image.replace('http://','https://'),dataItem?.outboundId+'-loadingprocessfullclose-'+key+'.jpg')
	}} >Download</a></Col>)
	)
	dataItem?.imageData?.tertutupSetengah?.map((item,key) =>
	tertutupSetengahData.push(<Col span='6'  style={{textAlign:'center'}} key={key}><Image preview={true} src={item.image.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.image.replace('http://','https://'),dataItem?.outboundId+'-loadingprocesshalfclose-'+key+'.jpg')
	}} >Download</a></Col>)
	)
	dataItem?.imageData?.tir?.map((item,key) =>
	tirData.push(<Col span='6' style={{textAlign:'center'}} key={key}><Image preview={true} src={item.image.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.image.replace('http://','https://'),dataItem?.outboundId+'-loadingprocesstier-'+key+'.jpg')
	}} >Download</a></Col>)
	)
	dataItem?.imageData?.tutupBelakang?.map((item,key) =>
	tutupBelakangData.push(<Col span='6'  style={{textAlign:'center'}} key={key}><Image preview={true} src={item.image.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.image.replace('http://','https://'),dataItem?.outboundId+'-loadingprocessbackclose-'+key+'.jpg')
	}} >Download</a></Col>)
	)
	dataItem?.imageData?.tutupDepan?.map((item,key) =>
	tutupDepanData.push(<Col span='6' style={{textAlign:'center'}}  key={key}><Image preview={true} src={item.image.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.image.replace('http://','https://'),dataItem?.outboundId+'-loadingprocessfrontclose-'+key+'.jpg')
	}} >Download</a></Col>)
	)
    return (
		<>
		<Row>
			<Col span='24'>
				<LabeledItem
						title="Tutup Depan "
					/>
				<Row>{tutupDepanData}</Row>
			</Col>
			<Col span='24'>
				<LabeledItem
						title="Tutup Belakang "
					/>
				<Row>{tutupBelakangData}</Row>
			</Col>
			<Col span='24'>
				<LabeledItem
						title="No Kontainer "
					/>
				<Row>{noKontainerData}</Row>
			</Col>
			<Col span='24'>
				<LabeledItem
						title="Tertutup Setengah "
					/>
				<Row>{tertutupSetengahData}</Row>
			</Col>
			<Col span='24'>
				<LabeledItem
						title="Tertutup Full "
					/>
				<Row>{tertutupFullData}</Row>
			</Col>
			<Col span='24'>
				<LabeledItem
						title="TIR "
					/>
				<Row>{tirData}</Row>
			</Col>
		</Row>
		</>
	)
}

export  function ImageMapingTabParkingCrew (dataItem){
	var sealData = [];
	var driverData = [];
	const downloadImage = (url,name) => {
        saveAs(url, name) // Put your image url here.
    }
	dataItem?.imageData?.seal?.map((item,key) =>
	sealData.push(<Col span='6' style={{textAlign:'center'}} key={key}><Image src={item.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.replace('http://','https://'),dataItem?.outboundId+'-parkingcrewseal-'+key+'.jpg')
	}} >Download</a></Col>)
	)
	dataItem?.imageData?.driver?.map((item,key) =>
	driverData.push(<Col span='6' style={{textAlign:'center'}} key={key}><Image src={item.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.replace('http://','https://'),dataItem?.outboundId+'-parkingcrewdriver-'+key+'.jpg')
	}} >Download</a></Col>)
	)
    return (
        <Row>
			<Col span='24'>
				<LabeledItem
						title="Seal "
					/>
				<Row>{sealData}</Row>
			</Col>
			<Col span='24'>
				<LabeledItem
						title="Driver "
					/>
				<Row>{driverData}</Row>
			</Col>
		</Row>
	)
}

export  function ImageMapingTabCheckout (dataItem){
	var checkoutData = [];
	var sealData = [];
	const downloadImage = (url,name) => {
        saveAs(url, name) // Put your image url here.
    }
	dataItem?.imageData?.checkout?.map((item,key) =>
	checkoutData.push(<Col span='6' style={{textAlign:'center'}} key={key}><Image src={item && item.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.replace('http://','https://'),dataItem?.outboundId+'-checkout-'+key+'.jpg')
	}} >Download</a></Col>)
	)
	dataItem?.imageData?.seal?.map((item,key) =>
	sealData.push(<Col span='6' style={{textAlign:'center', marginRight:20}} key={key}><Image src={item && item.replace('http://','https://')} /><br/><a href="#" onClick={() => {
		downloadImage(item.replace('http://','https://'),dataItem?.outboundId+'-checkoutseal-'+key+'.jpg')
	}} >Download</a></Col>)
	)
    return (
        <Row>
			<Col span='24'>
				<LabeledItem
						title="Checkout "
					/>
				<Row>{checkoutData}</Row>
			</Col>
			<Col span='24'>
				<LabeledItem
						title="Seal "
					/>
				<Row>{sealData}</Row>
			</Col>
		</Row>
	)
}

// export  function DetailTabPallet2 (dataItem){
// 	console.log(dataItem.outboundId)
// 	const [loading, setLoading] = useState(false)
// 	const [dataPallet, setDataPallet] = useState([])
// 	// const [state, dispatch] = useReducer(stateReducer, initialState)
// 	useEffect(() => {
//         if (!dataItem.outboundId) return

//         const callApi = async () => {
//             setLoading(true)
//             const data = await getListPalletReportOutbound({ id: dataItem.outboundId })
//             setDataPallet(data.data)
//         }
//         callApi()
//         setLoading(false)
//     }, [dataItem])
// 	return (
//         <Row>
// 			<Col span='24'>
// 			<RichTable
//                 columns={columns(onCLickEditIcon, onCLickDeleteIcon)}
//                 showButtonsArea={false}
//                 showSearch={true}
//                 withRowSelection={true}
//                 ButtonsAreaComponent={buttons}
//                 RowSelectionComponent={rowSelectionButtons}
//                 onChangeSelectedRows={onChangeSelectedRows}
//                 getListApi={getMenuList}
//                 type="rich"
//             />
// 			<SimpleTable
// 				loading={loading}
// 				columns={palletOutboundColumns(dataItem.outboundId)}
// 				dataSource={dataPallet || []}
// 				footer={null}
// 			/>
		
	
// 			</Col>
// 		</Row>
// 	)
// }

export  function DetailTabPallet (dataItem){
	console.log(dataItem.outboundId)
	const [loading, setLoading] = useState(false)
	const [loadingBtn, setLoadingBtn] = useState(false)
	const [dataPallet, setDataPallet] = useState([])
	// const [state, dispatch] = useReducer(stateReducer, initialState)
	useEffect(() => {
        if (!dataItem.outboundId) return

        const callApi = async () => {
            setLoading(true)
            const data = await getListPalletReportOutbound({ id: dataItem.outboundId })
            setDataPallet(data)
        }
        callApi()
        setLoading(false)
    }, [dataItem])
	console.log("dataItem")
	console.log(dataPallet)
	const handleDownload = async () => {
        try {
            setLoadingBtn(true)
            await downloadListReport({ id: dataItem.outboundId, limit:10000, total:10000 })
            setLoadingBtn(false)
        } catch (error) {
            console.error(error)
            // setLoadingBtn(false)
        }
    }
	const buttons = () => {
		return (
			<Button
			icon={<DownloadOutlined />}
			loading={loadingBtn}
			onClick={handleDownload}
			className="outLinedBlue w-48 h-45 rounded-10 flex justify-center items-center"
		>
			Download
		</Button>
		)
	}
	return (
        <Row>
			<Col span='24'>
	
			<RichTable
                columns={palletOutboundColumns(dataItem.outboundId)}
                showButtonsArea={true}
                showSearch={true}
                // withRowSelection={true}
                ButtonsAreaComponent={buttons}
                // RowSelectionComponent={rowSelectionButtons}
                // onChangeSelectedRows={onChangeSelectedRows}
                getListApi={dataPallet}
				byId={true}
                type="rich"
            />
			{/* <SimpleTable
				loading={loading}
				columns={palletOutboundColumns(dataItem.outboundId)}
				dataSource={dataPallet || []}
				footer={null}
			/>
		 */}
	
			</Col>
		</Row>
	)
}