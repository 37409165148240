import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddEditModal from './modals/add-edit-role-menu'

import {
    getRoleMenuList,
    deleteRoleMenu,
    createNewRoleMenu,
    updateRoleMenu,
} from 'api/maintain-user/role-menu'

import { columns } from './columns'
import { auth } from 'auth'
const user = auth.getUserInfo()

function RoleMenu() {
    const [showUpdateUserModal, setUpdateUserModal] = useState(false)
    const [showAddUserModal, setAddUserModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [selectedRows, setSelectedRows] = useState([])

    const { doRefresh } = useQueryRefresh()

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateUserModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = selectedRows.map((row) => ({
                    menuId: row.menuId,
                    roleId: row.roleId,
                }))

                const res = await deleteRoleMenu(reqBody)

                // ASK BE TO CHANGE STATUS TO SUCCESS!!
                if (!res.status) return
                // ASK BE TO CHANGE STATUS TO SUCCESS!!
                doRefresh()
            },
        })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete All" onClick={handleBulkDelete} />
    )

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddUserModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle"
                // disabled={true}
            >
                Add
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                columns={columns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                ButtonsAreaComponent={buttons}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getRoleMenuList}
                type="rich"
            />
            <AddEditModal
                title="Add Role"
                close={() => setAddUserModal(false)}
                visible={showAddUserModal}
                payload={{}}
                successMsg="Success add Role"
                callApi={createNewRoleMenu}
            />
            <AddEditModal
                title="Edit Role"
                close={() => setUpdateUserModal(false)}
                visible={showUpdateUserModal}
                payload={modalFormPayload}
                successMsg="Success edit Role"
                callApi={updateRoleMenu}
                additionalPayload={{ changeBy: user.name }}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default RoleMenu
