import { Input, Select } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

export const formItems = (initialValues) => [
    {
        name: 'dcLocationId',
        label: 'DC Location',
        initialValue: { value: initialValues?.dcLocationId || null },
        style: { gridColumnStart: 1, gridColumnEnd: 3 },
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.DcLocation disabled={initialValues?.dcLocationId} />,
    },
    {
        name: 'gateId',
        label: 'Gate ID',
        initialValue: initialValues?.gateId || null,
        style: { gridColumnStart: 1, gridColumnEnd: 3 },
        rules: [
            {
                required: true,
            },
            {
                max: 15,
                message: 'The max character length is 15',
            },
        ],
        children: (
            <Input
                disabled={initialValues?.gateId}
                placeholder="Gate ID"
                className="rounded-5"
                size="large"
            />
        ),
    },
    {
        name: 'building',
        label: 'Building',
        initialValue: initialValues?.building || null,
        style: { gridColumnStart: 1, gridColumnEnd: 3 },
        rules: [
            {
                required: true,
            },
            {
                max: 50,
                message: 'The max character length is 50',
            },
        ],
        children: (
            <Input
                // disabled={initialValues?.building}
                placeholder="Building"
                className="rounded-5"
                size="large"
            />
        ),
    },
    {
        name: 'from',
        label: 'From',
        initialValue: initialValues?.from || null,
        rules: [
            {
                required: true,
            },
            {
                max: 20,
                message: 'The max character length is 20',
            },
        ],
        children: <Input placeholder="From" className="rounded-5" size="large" />,
    },
    {
        name: 'to',
        label: 'To',
        initialValue: initialValues?.to || null,
        rules: [
            {
                required: true,
            },
            {
                max: 20,
                message: 'The max character length is 20',
            },
        ],
        children: <Input placeholder="To" className="rounded-5" size="large" />,
    },
    {
        name: 'fromPlant',
        label: 'From Plant',
        initialValue: { value: initialValues?.fromPlant || null },
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.Plant placeholder="From Plant" />,
    },
    {
        name: 'toPlant',
        label: 'To Plant',
        initialValue: { value: initialValues?.toPlant || null },
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.Plant placeholder="To Plant" />,
    },
    {
        name: 'active',
        label: 'Active',
        initialValue: initialValues?.active || null,
        style: { gridColumnStart: 1, gridColumnEnd: 3 },
        rules: [
            {
                required: true,
            },
        ],
        children: (
            <Select placeholder="Active" className="rounded-5" size="large">
                <Select.Option value="Y">Yes</Select.Option>
                <Select.Option value="N">No</Select.Option>
            </Select>
        ),
    },
]
