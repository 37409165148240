import React, { useReducer, useEffect, useContext } from 'react'
import { Table, Input, Button, Modal } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import useQueryRefresh from 'customHooks/useQueryRefresh'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import { RichTableContext } from 'components/Common/RichTable'

import { generatePallet, assignPallet } from 'api/transaction/plannerDoExecution'
import { columns } from './columns'

const initialState = {
    data: [],
    loading: false,
    transactionId: '',
    error: '',
    openQuantity: 0,
    params: {
        reqQty: 0,
        plant: '',
        material: '',
    },
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'HYDRATE_PAYLOAD_TO_STATE':
            return {
                ...state,
                transactionId: action.payload?.transactionId,
                openQuantity: action.payload?.openQty,
                requestQty: action.payload?.qty,
                params: {
                    reqQty: action.payload?.openQty,
                    plant: action.payload?.plantStock,
                    material: action.payload?.material,
                },
                error: '',
            }
        case 'SET_PARAMS':
            return { ...state, params: action.params }
        case 'SET_TRANSACTION_ID':
            return { ...state, transactionId: action.transactionId }
        case 'SET_DATA':
            return { ...state, data: [...action.data], loading: false }
        case 'SET_OPEN_QTY':
            return { ...state, openQuantity: action.openQuantity }
        case 'SET_ERROR':
            return { ...state, error: action.error, loading: false }
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'RESET':
            return initialState
        default:
            return state
    }
}

const AssignPallet = ({ close, visible, payload, ...props }) => {
    const [state, dispatch] = useReducer(stateReducer, initialState)
    const { doRefresh } = useQueryRefresh()
    const { resetRowSelections } = useContext(RichTableContext)

    const handleGeneratePallet = async () => {
        dispatch({ type: 'SET_LOADING', loading: true })
        const reqBody = {
            reqQty: +state.params.reqQty,
            plant: state.params.plant,
            material: state.params.material,
        }

        try {
            const { data } = await generatePallet(reqBody)
            if (!data) dispatch({ type: 'SET_ERROR', error: 'Pallets Not Available' })
            if (data) {
                dispatch({ type: 'SET_DATA', data })
                dispatch({
                    type: 'SET_OPEN_QTY',
                    openQuantity:
                        +state.params.reqQty - data.reduce((acc, cur) => acc + cur.qty, 0),
                })
            }
        } catch (error) {
            dispatch({ type: 'SET_LOADING', loading: false })
        }
    }

    const handleCreateAssignPallet = async () => {
        dispatch({ type: 'SET_LOADING', loading: true })

        const reqBody = {
            transactionId: state.transactionId,
            pallets: state.data.map((item) => ({
                // ...item,
                doNumber: payload.documentNumber,
                doItem: +payload.documentItem,
                palletId: item.palletId,
                palletCode: item.palletCode,
                companyId: payload.company,
                plantId: item.plant,
                slocId: item.slocId,
                sectorId: item.sectorId,
                gateId: item.gateId,
                binId: item.binId,
                staggingIn: item.staggingIn,
                dockingId: item.dockingId,
                materialId: item.material,
                description: item.description,
                batch: item.batch,
                refDocNo: payload.bookingNumber,
                qty: item.qty,
                overQty: item.overQty,
                uom: item.uom,
                manufactureDate: item.manufactureDate,
                sled: item.sled,
            })),
        }

        try {
            const res = await assignPallet(reqBody)
            if (!res.success) {
                return dispatch({ type: 'SET_LOADING', loading: false })
            }
            Modal.success({ title: 'Success Assign Pallet', content: res.message })
            resetRowSelections()
            dispatch({ type: 'RESET' })
            close()
            doRefresh()
        } catch (error) {
            dispatch({ type: 'SET_LOADING', loading: false })
            console.error(error)
        }
    }

    useEffect(() => {
        if (!visible) return
        dispatch({ type: 'HYDRATE_PAYLOAD_TO_STATE', payload })
    }, [payload, visible])

    return (
        <SimpleModal
            title="Assign Pallet"
            width={636}
            destroyOnClose={true}
            footer={null}
            visible={visible}
            {...props}
        >
            {state.error && (
                <div className="flex items-center text-lg text-white bg-nabati-red w-full text-center rounded-10 p-5 my-3">
                    <InfoCircleOutlined className="text-3xl justify-self-start mr-4" />
                    {state.error}
                </div>
            )}
            <p className="mb-2 text-base text-nabati-grey">Request Quantity</p>
            <Input
                value={+state.requestQty}
                className="rounded-10 h-50 w-1/2"
                placeholder="Request Quantity"
                suffix={<p className="text-xs">DUS</p>}
                size="large"
            />
            <p className="mt-2 mb-2 text-base text-nabati-grey">Open Quantity</p>
            <div className="flex mb-4">
                <Input
                    value={state.openQuantity}
                    className="rounded-10 h-50 w-1/2"
                    placeholder="Request Quantity"
                    suffix={<p className="text-xs">DUS</p>}
                    size="large"
                />
                <Button
                    className="flatBlue ml-3 h-50 w-160 rounded-10"
                    onClick={handleGeneratePallet}
                    loading={state.loading}
                >
                    Generate
                </Button>
            </div>
            {state?.data?.length > 0 && (
                <Table
                    dataSource={state.data}
                    columns={columns}
                    rowClassName={(_, index) =>
                        `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                    }
                    pagination={false}
                />
            )}

            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={() => {
                        dispatch({ type: 'RESET' })
                        resetRowSelections()
                        close()
                    }}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleCreateAssignPallet}
                    disabled={state.data.length <= 0}
                    type="primary"
                    loading={state.loading}
                >
                    Create
                </Button>
            </div>
        </SimpleModal>
    )
}

export default AssignPallet
