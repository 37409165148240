import React from 'react'
import SimpleModal from 'components/Common/Modals/SimpleModal'

function PlannerInfo({ payload, handleUpdateInfo, ...props }) {
    return (
        <SimpleModal
            width={432}
            header={false}
            okText="Update"
            onOk={() => {
                handleUpdateInfo()
                props.close()
            }}
            {...props}
        >
            <div className="text-2xl font-bold mb-1">Information</div>
            <div className="text-base">{payload?.information}</div>
        </SimpleModal>
    )
}

export default PlannerInfo
