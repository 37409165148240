import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { DatePicker, Select } from 'antd'
import useSearchParams from 'customHooks/useSearchParams'

import { TIME_PRESET, DATE_FORMAT, TIME_RANGE_PRESET } from 'config/constant/timeAndDate'

const { TODAY, THIS_WEEK, THIS_MONTH, THIS_YEAR } = TIME_PRESET
const { RangePicker } = DatePicker
const { Option } = Select

// initialPreset props: 'Today', 'This Week', 'This Month', 'This Year'
function DatePickerQueryInput({ containerClassName, initialPreset = THIS_MONTH, style }) {
    const [inputPreset, setInputPreset] = useState(initialPreset)
    const { currentQuery, setSearchParams } = useSearchParams()
    const onInputPresetChange = (dateRange) => {
        setInputPreset(dateRange)
        setSearchParams({
            ...currentQuery,
            from: TIME_RANGE_PRESET[dateRange][0]?.format(DATE_FORMAT) || '',
            to: TIME_RANGE_PRESET[dateRange][1]?.format(DATE_FORMAT) || '',
        })
    }
    const handleDatePickerChange = (dateSet) => {
        setSearchParams({
            ...currentQuery,
            from: dateSet[0]?.format(DATE_FORMAT) || '',
            to: dateSet[1]?.format(DATE_FORMAT) || '',
        })

        setInputPreset(null)
    }

    useEffect(() => {
        const isDateRangeIsToday =
            moment(currentQuery.from, DATE_FORMAT).isSame(TIME_RANGE_PRESET[TODAY][0], 'day') &&
            moment(currentQuery.to, DATE_FORMAT).isSame(TIME_RANGE_PRESET[TODAY][1], 'day')

        const isDateRangeIsThisWeek =
            moment(currentQuery.from, DATE_FORMAT).isSame(TIME_RANGE_PRESET[THIS_WEEK][0], 'day') &&
            moment(currentQuery.to, DATE_FORMAT).isSame(TIME_RANGE_PRESET[THIS_WEEK][1], 'day')

        const isDateRangeIsThisMonth =
            moment(currentQuery.from, DATE_FORMAT).isSame(
                TIME_RANGE_PRESET[THIS_MONTH][0],
                'day'
            ) &&
            moment(currentQuery.to, DATE_FORMAT).isSame(TIME_RANGE_PRESET[THIS_MONTH][1], 'day')

        const isDateRangeIsThisYear =
            moment(currentQuery.from, DATE_FORMAT).isSame(TIME_RANGE_PRESET[THIS_YEAR][0], 'day') &&
            moment(currentQuery.to, DATE_FORMAT).isSame(TIME_RANGE_PRESET[THIS_YEAR][1], 'day')

        if (isDateRangeIsToday) return setInputPreset(TODAY)
        if (isDateRangeIsThisWeek) return setInputPreset(THIS_WEEK)
        if (isDateRangeIsThisMonth) return setInputPreset(THIS_MONTH)
        if (isDateRangeIsThisYear) return setInputPreset(THIS_YEAR)
        setInputPreset(undefined)
    }, [currentQuery])

    useEffect(() => {
        const hasDateQuery = !!currentQuery.from && !!currentQuery.to
        if (hasDateQuery) return

        setSearchParams({
            ...currentQuery,
            from: moment().startOf('month').format(DATE_FORMAT), // TODAY
            to: moment().endOf('month').format(DATE_FORMAT) // TODAY
        })
    }, [currentQuery, setSearchParams])

    return (
        <div
            className={`relative flex items-center ${containerClassName}`}
            style={{
                minWidth: 520,
                ...style,
            }}
        >
            <p className="w-10">Date</p>
            <Select
                showArrow={true}
                showSearch={false}
                className={`customizedLeftRounded ml-2`}
                style={{ width: 150 }}
                size="large"
                onChange={onInputPresetChange}
                placeholder="Select time"
                value={inputPreset}
            >
                {Object.keys(TIME_RANGE_PRESET).map((presetDate) => (
                    <Option value={presetDate} key={presetDate}>
                        {presetDate}
                    </Option>
                ))}
            </Select>

            <RangePicker
                size="large"
                className="customized rounded-r-lg rounded-l-none absolute"
                style={{ left: 198 }}
                onChange={handleDatePickerChange}
                value={[
                    moment(currentQuery.from, DATE_FORMAT),
                    moment(currentQuery.to, DATE_FORMAT),
                ]}
                defaultValue={[moment(), moment()]}
                format={DATE_FORMAT}
            />
        </div>
    )
}

export default DatePickerQueryInput
