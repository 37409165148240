import React from 'react'
import { Modal, Divider, Button } from 'antd'
import { CrossIc } from 'assets'

const SimpleModal = ({
    title = 'Title Required',
    children,
    close,
    onOk,
    onOk2,
    loading = false,
    okText = 'Save',
    okText2 = 'Save',
    cancelText = 'Cancel',
    closable = false,
    footer = true,
    header = true,
    maskClosable = false,
    destroyOnClose = true,
    withCrossIcon = false,
    ...props
}) => {
    return (
        <Modal
            footer={null}
            closable={closable}
            onCancel={close}
            maskClosable={maskClosable}
            destroyOnClose={destroyOnClose}
            {...props}
        >
            {header && (
                <>
                    <div className="flex justify-between">
                        <p className="text-lg">{title}</p>
                        {withCrossIcon && <CrossIc className="cursor-pointer" onClick={close} />}
                    </div>
                    <Divider className="border-black my-4" />
                </>
            )}
            {children}
            {footer ? (
                typeof footer === 'boolean' ? (
                    <div className="flex justify-end items-center">
                        <Button
                            className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                            style={{ width: 197, height: 45 }}
                            onClick={close}
                            type="ghost"
                        >
                            {cancelText}
                        </Button>
                        {onOk2 && ( <Button
                            className="rounded-10 mx-1 mt-5 "
                            style={{ width: 197, height: 45 }}
                            onClick={onOk2}
                            type="default"
                            loading={loading}
                        >
                            {okText2} 
                        </Button>)}
                        <Button
                            className="rounded-10 mx-1 mt-5 "
                            style={{ width: 197, height: 45 }}
                            onClick={onOk}
                            type="primary"
                            loading={loading}
                        >
                            {okText}
                        </Button>
                    </div>
                ) : (
                    { footer }
                )
            ) : (
                ''
            )}
        </Modal>
    )
}

export default SimpleModal
