import React, { useState } from 'react'
import { Form, Modal, Divider, Button, Input } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

import useQueryRefresh from 'customHooks/useQueryRefresh'
import SelectUomByMaterial from 'components/Common/SelectFetchByMaterial/selectUomByMaterial'
import SelectPlantByCompany from 'components/Common/SelectFetchByApi/selectPlantByCompany'

import SelectSlocByPlant from 'components/Common/SelectFetchByPlant/selectSlocByPlant'

// import ModalForm from 'components/Common/Modals/ModalForm'
// import { formItems } from '../formItems'

function AddItemModal({ close, visible, addItem, isInPlantTransactionType, ...props }) {
    const [loading, setLoading] = useState(false)
    const [plantState, setPlant] = useState('')
    const [materialState, setMaterial] = useState('')
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const handleAddItem = (values) => {
        const item = {
            material: values?.material.value,
            materialDescription: values?.material.label.split(' - ')[1],
            qty: values?.qty,
            poUnit: values?.poUnit,
            plant: values?.plant.value,
            plantDescription: values?.plant.label.split(' - ')[1],
            slocId: values?.slocId?.value || '',
            slocDescription: values?.slocId?.label.split(' - ')[1],
            route: values?.route.value,
            routeDescription: values?.route.label.split(' - ')[1],
        }

        const { success } = addItem(item)
        return { success }
    }

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        let payload = { ...values }

        const res = handleAddItem(payload)
        if (res.success || res.status) {
            Modal.success({
                title: `New Item was created`,
            })
            close()
            doRefresh()
        }
        return setLoading(false)
    }

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg font-bold">Add Item</p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="material"
                    label="No Material"
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDebounceFetch.Material
                        onChange={(val) => {
                            setMaterial(val.value)
                        }}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%' }}
                    name="qty"
                    label="Qty"
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Input type="number" placeholder="Qty" className="rounded-5" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%' }}
                    name="poUnit"
                    label="UOM / PO Unit"
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectUomByMaterial materialId={materialState} className="rounded-5" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="plant"
                    label="Plant"
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectPlantByCompany
                        labelInValue
                        onChange={(val) => setPlant(val.value)}
                        className="rounded-5"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="slocId"
                    label="Sloc"
                    shouldUpdate={true}
                    rules={[{ required: isInPlantTransactionType ? true : false }]}
                >
                    <SelectSlocByPlant plant={plantState} className="rounded-5" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="route"
                    label="Route"
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDebounceFetch.Route />
                </Form.Item>
            </Form>

            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={close}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Add Item
                </Button>
            </div>
        </Modal>
    )
}

export default AddItemModal
