import React, { useState } from 'react'
import { Button, Row } from 'antd'

import RichTable from 'components/Common/RichTable'
import { plantsColumns } from './columns'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddPlantModal from './modals/add-plant'
import UpdatePlantModal from './modals/update-plant'

import { getPlantList } from 'api/master/plant'

function Plant() {
    const [showUpdatePlantModal, setUpdatePlantModal] = useState(false)
    const [showAddPlantModal, setAddPlantModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdatePlantModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddPlantModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                // rowKey="plantId"
                columns={plantsColumns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                getListApi={getPlantList}
            />
            <AddPlantModal close={() => setAddPlantModal(false)} visible={showAddPlantModal} />
            <UpdatePlantModal
                close={() => setUpdatePlantModal(false)}
                visible={showUpdatePlantModal}
                payload={modalFormPayload}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default Plant
