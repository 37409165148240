import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getInboundSummary = async (params) => {
    const response = await call(METHODS.POST, '/dashboard', params)
    return response.data
}

export const getCheckerDurationProductivity = async (params) => {
    const response = await call(METHODS.POST, '/dashboard/inboundduration', params)
    return response.data
}

export const getCheckerDurationProductivityGroup = async (params) => {
    const response = await call(METHODS.POST, '/dashboard/inboundduration-group', params)
    return response.data
}

export const getProductionDus = async (params) => {
    const response = await call(METHODS.POST, '/dashboard/productiondus', params)
    return response.data
}

export const getProductionPallet = async (params) => {
    const response = await call(METHODS.POST, '/dashboard/productionpallet', params)
    return response.data
}

export const getProduction = async (params) => {
    const response = await call(METHODS.POST, '/dashboard/production', params)
    return response.data
}

export const getNonProduction = async (params) => {
    const response = await call(METHODS.POST, '/dashboard/nonproduction', params)
    return response.data
}
