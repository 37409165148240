import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import AppRoutes from 'routes'
import './App.less'

const App = () => {
    return (
        <BrowserRouter basename="/">
            <AppRoutes />
        </BrowserRouter>
    )
}

export default App
