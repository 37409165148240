import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'
import { API_URL } from 'config/env'

export const getProdTargetList = async (params) => {
    const response = await call(METHODS.GET, '/dashboard/production_target', params)
    return response.data
}

export const createNewProdTarget = async (data) => {
    const url = `/dashboard/production_target/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updateProdTarget = async (data) => {
    const url = `/dashboard/production_target/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deleteProdTarget = async (data) => {
    const url = `/dashboard/production_target/delete`
    const response = await call(METHODS.DELETE, url, data)
    return response.data
}

export const downloadProdTargetTemplate = async (data) => {
    window.open(API_URL + '/dashboard/production_target/download')
}

export const uploadProdTargetTemplate = async (formData) => {
    const options = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }
    const response = await call(
        METHODS.POST,
        `/dashboard/production_target/import`,
        formData,
        options
    )
    return response.data
}
