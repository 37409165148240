import React, { useContext, useState, useEffect, useMemo } from 'react'
import BarChart from 'components/Common/Chart/BarChart'

import { getCheckerDurationProductivityGroup } from 'api/dashboard/inbound'

// Context
import { CardDatePickerContext } from 'components/Common/CardDatePicker'
import { DashboardInboundContext } from '../../index'

import { options } from './options'
import ConfirmWithPasswordModal from 'components/Common/Modals/ConfirmWithPasswordModal'

function ChartCheckerInboundGroup() {
    const [loading, setLoading] = useState(false)
    const [checkerDataLabel, setCheckerDataLabel] = useState([])
    const [checkerDataValue, setCheckerDataValue] = useState([])

    const { dateRange } = useContext(CardDatePickerContext)
    const { dcLocation } = useContext(DashboardInboundContext)
    const datasets = useMemo(() => Object.values(checkerDataValue || {}), [checkerDataValue])

    const labels = useMemo(() => Object.values(checkerDataLabel || {}), [checkerDataLabel])
    
    useEffect(() => {
        if (!dcLocation) return
        const { startDate, endDate } = dateRange
        const reqBody = {
            startDate,
            endDate,
            dcLocationId: [dcLocation?.value],
        }
        const fetchData = async () => {
            setLoading(true)
            try {
                const res = await getCheckerDurationProductivityGroup (reqBody)
    
                setCheckerDataLabel(res?.data?.checkerInboundDuration.label || {})
                setCheckerDataValue(res?.data?.checkerInboundDuration.value || {})
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }

        fetchData()
    }, [dateRange, dcLocation])

    return (
        <BarChart loading={loading} options={options()} datasets={datasets} labels={[...labels]} />
    )
}

export default ChartCheckerInboundGroup
