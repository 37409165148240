import React, { useContext, useEffect, useState } from 'react'
import { Button, Table, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import SimpleCard from 'components/layouts/simple'
import { auth } from 'auth'
import { Link } from 'react-router-dom'


function InventoryVsDoArea({ columns, data, dataBooking}) {
    return (
        <SimpleCard className="mt-5">
            <p className="text-xl font-bold mt-1 mb-4">Inventory vs DO</p>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: 18,
                }}
            >
             
                    <div
                        className="flex flex-col justify-between p-1 rounded-lg"
                        style={{
                            border: '0.5px solid #BBBBBB',
                        }}
                    >
                        <div className="flex justify-between items-center m-1 text-base font-bold">
                            <p className="mr-2 text-sm">Deficit Stock</p>
                            <Tooltip
                                title="tooltip"
                                color="#F4FBFC"
                                overlayInnerStyle={{
                                    color: 'black',
                                    fontWeight: 'bold',
                                }}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                        <div>
                            <Table
                                className="customizedFontSize"
                                showHeader={true}
                                columns={columns}
                                dataSource={data}
                                size="small"
                                // scroll={{ x: true, y: 200 }}
                                pagination={false}
                                rowClassName={(_, index) =>
                                    index % 2 === 0 ? '' : 'bg-nabati-lightGray'
                                }
                            />
                        </div>
                        <div className="m-1 mt-3">
                            <Link to="/transaction/planner-set-priority">
                                <Button type="link"  className="w-full h-8 rounded-lg text-nabati-blue border-nabati-blue">
                                    More Info
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div
                        className="flex flex-col justify-between p-1 rounded-lg"
                        style={{
                            border: '0.5px solid #BBBBBB',
                        }}
                    >
                        <div className="flex justify-between items-center m-1 text-base font-bold">
                            <p className="mr-2 text-sm">Deficit Stock - Check In</p>
                            <Tooltip
                                title="tooltip"
                                color="#F4FBFC"
                                overlayInnerStyle={{
                                    color: 'black',
                                    fontWeight: 'bold',
                                }}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                        <div>
                            <Table
                                className="customizedFontSize"
                                showHeader={true}
                                columns={columns}
                                dataSource={dataBooking}
                                size="small"
                                // scroll={{ x: true, y: 200 }}
                                pagination={false}
                                rowClassName={(_, index) =>
                                    index % 2 === 0 ? '' : 'bg-nabati-lightGray'
                                }
                            />
                        </div>
                        <div className="m-1 mt-3">
                            <Link to="/transaction/planner-set-priority">
                                <Button type="link"  className="w-full h-8 rounded-lg text-nabati-blue border-nabati-blue">
                                    More Info
                                </Button>
                            </Link>
                        </div>
                    </div>
            </div>
        </SimpleCard>
    )
}

export default InventoryVsDoArea
