import React from 'react'

import RichTable from 'components/Common/RichTable'
import { columns } from './columns'

import { getTransportPlantList } from 'api/master/transportPlant'

function TransportPlant() {
    return <RichTable columns={columns()} showSearch={true} getListApi={getTransportPlantList} />
}

export default TransportPlant
