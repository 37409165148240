import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, InfoYellowIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const reportPickerDcColumns = (
) => [
    {
        title: 'Pallet id',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Pallet Code',
        dataIndex: 'palletCode',
        key: 'palletCode',
    },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Sloc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Sector',
        dataIndex: 'sectorId',
        key: 'sectorId',
    },
    {
        title: 'Gate',
        dataIndex: 'gateId',
        key: 'gateId',
    },
    {
        title: 'Bin Id',
        dataIndex: 'binId',
        key: 'binId',
    },
    {
        title: 'Stanggin In',
        dataIndex: 'staggingIn',
        key: 'staggingIn',
    },
    {
        title: 'Priority Id',
        dataIndex: 'priorityId',
        key: 'priorityId',
    },
    {
        title: 'Docking Id',
        dataIndex: 'dockingId',
        key: 'dockingId',
    },
    {
        title: 'Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'Uom',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Stock Status',
        dataIndex: 'stockStatus',
        key: 'stockStatus',
    },
    {
        title: 'Reachtruck Status',
        dataIndex: 'reachtruckStatus',
        key: 'reachtruckStatus',
    },
    {
        title: 'Update Date',
        dataIndex: 'updateDate',
        key: 'updateDate',
    },
    {
        title: 'Update User',
        dataIndex: 'updateUser',
        key: 'updateUser',
    },
     {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
    },
]
