import React, { useReducer } from 'react'
import { Button } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import ConfirmationSubmitModal from 'components/Common/Modals/ConfirmationModal'

import AssignSPVModal from '../planner-do-execution/modals/assign-spv'
import CreatePostToDoStoModal from './modals/create-post-to-do-sto'
import ConfirmationDeletePoSto from './modals/confirmation-delete'

import { columns } from './columns'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import {
    getPlannerStockTransferList,
    startLoadingStockTransfer,
    createPoStoSapFromDraft,
} from 'api/transaction/plannerStockTransfer'

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ROWS':
            return { ...state, selectedRows: [...action.selectedRows] }
        case 'TOGGLE_ASSIGN_SPV':
            return { ...state, assignSpvModal: !state.assignSpvModal }
        case 'TOGGLE_CREATE_PO_STO':
            return { ...state, createPostToDoModal: !state.createPostToDoModal }
        case 'TOGGLE_DELETE_PO_STO':
            return { ...state, deletePoStoModal: !state.deletePoStoModal }
        case 'TOGGLE_SUBMIT_PO_STO':
            return { ...state, submitPoStoModal: !state.submitPoStoModal }
        case 'SET_MODAL_PAYLOAD':
            return { ...state, modalFormPayload: action.modalFormPayload }
        default:
            return state
    }
}

const initialState = {
    selectedRows: [],
    createPostToDoModal: false,
    assignSpvModal: false,
    deletePoStoModal: false,
    submitPoStoModal: false,
    modalFormPayload: null,
}

const PlannerStockTransfer = () => {
    const [state, dispatch] = useReducer(stateReducer, initialState)
    const { doRefresh } = useQueryRefresh()

    const onChangeSelectedRows = (rowKeys, rows) => {
        dispatch({ type: 'SET_SELECTED_ROWS', selectedRows: rows })
    }

    const onCLickCreatePOSTODO = () => {
        dispatch({ type: 'TOGGLE_CREATE_PO_STO' })
        dispatch({ type: 'SET_MODAL_PAYLOAD', modalFormPayload: { createMode: true } })
    }

    const onCLickStartLoading = () => {
        dispatch({ type: 'TOGGLE_ASSIGN_SPV' })
    }

    const onClickSubmit = () => {
        dispatch({ type: 'TOGGLE_SUBMIT_PO_STO' })
    }

    const handleSubmit = async () => {
        const reqBody = {
            transactionId: state.selectedRows[0].transactionId,
        }
        try {
            const res = await createPoStoSapFromDraft(reqBody)
            doRefresh()
            // if (!res.success) return res
            return res
        } catch (error) {
            console.error(error)
            return { success: false }
        }
    }

    const onClickEditIcon = (row) => {
        dispatch({ type: 'TOGGLE_CREATE_PO_STO' })
        dispatch({ type: 'SET_MODAL_PAYLOAD', modalFormPayload: row })
    }

    const onClickDisplayIcon = (row) => {
        dispatch({ type: 'TOGGLE_CREATE_PO_STO' })
        dispatch({ type: 'SET_MODAL_PAYLOAD', modalFormPayload: { ...row, displayMode: true } })
    }

    const buttons = () => (
        <ButtonsGroupGrid>
            <Button
                onClick={onCLickCreatePOSTODO}
                type="primary"
                className="w-157 h-45 rounded-10 mx-2"
            >
                {'Create PO STO>DO'}
            </Button>
            <Button
                onClick={onCLickStartLoading}
                className="flatBlue w-157 h-45 rounded-10 mx-2"
                disabled={state.selectedRows.length !== 1}
            >
                Start Loading
            </Button>
            <Button
                onClick={onClickSubmit}
                className="flatPink w-157 h-45 rounded-10 mx-2"
                disabled={
                    state.selectedRows.length !== 1 ||
                    (state.selectedRows[0].poNo && state.selectedRows[0].doNo)
                }
            >
                Submit
            </Button>
        </ButtonsGroupGrid>
    )

    return (
        <>
            <RichTable
                columns={columns(onClickEditIcon, onClickDisplayIcon)}
                showButtonsArea={true}
                showSearch={true}
                withRowSelection={true}
                withSearchAndFilterArea={true}
                ButtonsAreaComponent={buttons}
                getListApi={getPlannerStockTransferList}
                onChangeSelectedRows={onChangeSelectedRows}
                withDatePicker={true}
                scroll={{ x: 1300 }}
            />
            <CreatePostToDoStoModal
                payload={state.modalFormPayload}
                visible={state.createPostToDoModal}
                close={() => {
                    dispatch({ type: 'TOGGLE_CREATE_PO_STO' })
                    dispatch({ type: 'SET_MODAL_PAYLOAD', modalFormPayload: null })
                }}
            />
            <AssignSPVModal
                visible={state.assignSpvModal}
                close={() => dispatch({ type: 'TOGGLE_ASSIGN_SPV' })}
                transactionId={state.selectedRows[0]?.transactionId || ''}
                startLoadingApi={startLoadingStockTransfer}
            />
            <ConfirmationDeletePoSto
                payload={state.modalFormPayload}
                visible={state.deletePoStoModal}
                close={() => dispatch({ type: 'TOGGLE_DELETE_PO_STO' })}
            />
            <ConfirmationSubmitModal
                visible={state.submitPoStoModal}
                title="Are you sure Submit PO STO?"
                content={`Transaction ID: ${state.selectedRows[0]?.transactionId}`}
                successTitleMsg="Submit Success!"
                close={() => dispatch({ type: 'TOGGLE_SUBMIT_PO_STO' })}
                onOk={handleSubmit}
                centered={false}
                okText="Submit"
            />
        </>
    )
}

export default PlannerStockTransfer
