import { call, call_sloc_plant } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getPlantSlocList = async (params) => {
    const response = await call(METHODS.GET, '/master/plantsloc', params)
    return response.data
}

export const createNewPlantSloc = async (data) => {
    const url = `/master/plantsloc/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updatePlantSloc = async (data) => {
    const url = `/master/plantsloc/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deletePlantSloc = async (data) => {
    const url = `/master/plantsloc/delete`
    const response = await call(METHODS.DELETE, url, data)
    return response.data
}

export const searchPlantSloc = async (id) => {
    const url = `/master/plantsloc/search`
    const response = await call(METHODS.POST, url)
    return response.data
}

export const getPlantSlocByPlant = async (params) => {
    const modifiedParams = {
        sortBy: 'plant_id',
        ...params,
    }

    const response = await call(METHODS.GET, '/master/plantsloc', modifiedParams)
    return response.data
}

export const getSlocByPlant = async (params) => {
    const GREAT_NUMBER = 999
    const modifiedParams = {
        sortBy: 'plant_id',
        filterBy: 'plant_id',
        total: GREAT_NUMBER,
        ...params,
    }

    const response = await call(METHODS.GET, '/master/plantsloc', modifiedParams)
    return response.data
}

export const getSlocByCompanies = async (params) => {
    const response = await call(METHODS.POST, '/master/plantsloc/bycompany', params)
    return response.data
}

export const getSlocIdByPlant = async (params) => {
    const response = await call(METHODS.POST, '/master/plantsloc/byplant', params)
    return response.data
}

export const getSlocByPlantWithoutTotal = async (params) => {
    const modifiedParams = {
        sortBy: 'plant_id',
        filterBy: 'plant_id',
        ...params,
    }

    const response = await call_sloc_plant(METHODS.GET, '/master/plantsloc', modifiedParams)
    return response.data
}
