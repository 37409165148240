import { PUBLIC_URL } from 'config/env'
import MSG from 'config/constant/http-error'

import { toCamelCase } from 'utils/formatter'
import { route } from 'utils/misc'
import { clearLocalStorage } from 'utils/localStorage'

import notify from 'utils/notify'

const loginApiSubUrl = 'login'

export const responseInterceptor = (response) => {
    if (response?.data?.success === false || response?.data?.status === false) {
        // Flag  skipErrorInterceptor is to prevent notification error
        const skipErrorInterceptor = response?.config?.skipResponseInterceptor || false
        if (!skipErrorInterceptor) {
            notify.error(response?.data?.message || response?.data?.messages, MSG.HTTP_FAILED)
        }
    }

    if (response?.data?.code === 401) {
        notify.error(MSG.HTTP_SESSION_EXPIRED)
        window.setTimeout(() => {
            clearLocalStorage()
            window.location.href = `${PUBLIC_URL}/login?isExpired=true`
        }, 1500)
    }

    return {
        ...response,
        ...(response.data && { data: toCamelCase(response.data) }),
    }
}

export const errorInterceptor = (err) => {
    const { response } = err
    if (response) {
        // Flag  skipErrorInterceptor is to prevent notification error
        const skipErrorInterceptor = response?.config?.skipErrorInterceptor || false
        if (skipErrorInterceptor) return

        const { url = '' } = response.config
        if (response.status === 401 && url.includes(loginApiSubUrl)) {
            if (response.data) {
                notify.error(response.data.message)
            }else{
                notify.error(MSG.HTTP_LOGIN_ERROR)
            }
            clearLocalStorage()
        } else if (response.status === 401) {
            notify.error(MSG.HTTP_SESSION_EXPIRED)
            window.setTimeout(() => {
                clearLocalStorage()
                window.location.href = `${PUBLIC_URL}/login?isExpired=true`
            }, 1500)
        } else if (response.status === 403) {
            notify.error(MSG.HTTP_UNAUTHORIZED_ACTION)
        } else if (response.status === 404) {
            notify.error(MSG.HTTP_NOT_FOUND)
        } else if (response.status === 405) {
            notify.error(MSG.HTTP_METHOD_NOT_ALLOWED)
        } else if (response.status === 422) {
            let description = response.data
                ? response.data.message || response.data.error
                : MSG.HTTP_UNPROCESSED_REQUEST
            description = description.split('_').join(' ')
            notify.error(description)
        } else if (response.status === 500) {
            notify.error(
                response.data.messages || response.data.error || MSG.HTTP_INTERNAL_SERVER_ERROR
            )
        } else if (response.status === 503) {
            const description = response.data ? response.data.message || response.data.error : null
            description && notify.error(description)
            route.push('/maintenance')
        }
    } else {
        notify.error(MSG.HTTP_NETWORK_ISSUE_ERROR, MSG.HTTP_NETWORK_ERROR)
    }
    return Promise.reject(err)
}
