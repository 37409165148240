import { Input, Select } from 'antd'

export const formItems = (initialValues) => [
    {
        name: 'dcLocationId',
        label: 'Dc Location ID',
        initialValue: initialValues?.dcLocationId || null,
        rules: [
            {
                required: true,
            },
            {
                max: 10,
                message: 'The max character length is 10',
            },
        ],
        children: (
            <Input
                disabled={initialValues?.dcLocationId}
                placeholder="Dc Location ID"
                className="rounded-5"
                size="large"
            />
        ),
    },
    {
        name: 'name',
        label: 'Description',
        initialValue: initialValues?.name || null,
        rules: [
            {
                required: true,
            },
            {
                max: 50,
                message: 'The max character length is 50',
            },
        ],
        children: <Input placeholder="Description" className="rounded-5" size="large" />,
    },
    {
        name: 'active',
        label: 'Active',
        initialValue: initialValues?.active || null,
        rules: [
            {
                required: true,
            },
        ],
        children: (
            <Select placeholder="Active" className="rounded-5" size="large">
                <Select.Option value="Y">Yes</Select.Option>
                <Select.Option value="N">No</Select.Option>
            </Select>
        ),
    },
]
