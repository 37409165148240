import { Navigate, Outlet } from 'react-router-dom'
import MenuId from 'components/pages/maintain-user/menu-id'
import RoleMenu from 'components/pages/maintain-user/role-menu'
import UserId from 'components/pages/maintain-user/user-id'

export const maintainUserRoutes = {
    path: '/maintain-user',
    element: <Outlet />,
    children: [
        { index: false, element: <Navigate to="menu-id" /> },
        { path: 'menu-id', element: <MenuId /> },
        { path: 'role-menu', element: <RoleMenu /> },
        { path: 'user-id', element: <UserId /> },
        { path: '*', element: <Navigate to="menu-id" /> },
    ],
}
