import { compareData } from 'utils/array'

export const addSorterToColumn = (columns) => {
    // input: {
    //           title: 'Plant',
    //           dataIndex: 'plantId',
    //           key: 'plantId',
    //        }

    // output: {
    //           title: 'Plant',
    //           dataIndex: 'plantId',
    //           key: 'plantId',
    //           sorter: (a, b) => compareData(a.plantId, b.plantId),
    //        }

    return columns.map((column) => {
        if (column.title === 'Actions') return column
        if (!column.dataIndex) return column

        return {
            ...column,
            sorter: (a, b) => compareData(a[column.dataIndex], b[column.dataIndex]),
        }
    })
}
