import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

export const formItems = (initialValues) => [
    {
        name: 'binId',
        label: 'BIN',
        initialValue: initialValues?.binId || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.Bin />,
    },
    {
        name: 'materialId',
        label: 'Material',
        initialValue: { value: initialValues?.materialId || null },
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.Material />,
    },
]
