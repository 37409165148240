import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const companyColumns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, { companyId, description }) => (
            <Row gutter={8}>
                <Col
                    className={'cursor-pointer'}
                    onClick={() => onCLickDeleteIcon({ companyId, description })}
                >
                    <DeleteIcon />
                </Col>
                <Col
                    className={'cursor-pointer'}
                    onClick={() => onCLickEditIcon({ companyId, description })}
                >
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
