import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'

import { options } from '../options'

export const getPlannerDashboardCountTransaction = async (params) => {
    const response = await call(METHODS.GET, '/dashboard-count-transaction', params, options)
    return response.data
}

export const getPlannerDashboardInventory = async (params) => {
    const response = await call(METHODS.GET, '/dashboard-inventory', params, options)
    return response.data
}
//
export const plannerDefisitStock = async (params) => {
    const response = await call(METHODS.POST, '/planner-priority-deficit-stock', params, options)
    return response.data
}

export const plannerDefisitStockBooking = async (params) => {
    const response = await call(
        METHODS.POST,
        '/planner-priority-deficit-stock-booking',
        params,
        options
    )
    return response.data
}
