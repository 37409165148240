import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const dockingColumns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'DC Location',
        dataIndex: 'dcLocationId',
        key: 'dcLocationId',
    },
    {
        title: 'Building',
        dataIndex: 'building',
        key: 'building',
    },
    {
        title: 'Docking ID',
        dataIndex: 'dockingId',
        key: 'dockingId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Active',
        dataIndex: 'noActive',
        key: 'noActive',
        render: (text) => {
            if (text === 'Y') return 'N'
            if (text === 'N') return 'Y'
            return '-'
        },
    },
    {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
    },
]
