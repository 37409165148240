import React from 'react'
import SelectDebounceFetch from './index'
import { getChangeBinStatus } from 'api/master/bin'

function SelectChangeBinStatus({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Change Bin Status"
            fetchOptions={async (searchTerm) => {
                const res = await getChangeBinStatus({ filter: searchTerm, total: 10 })
                console.log("res")
                console.log(res)
                const list = res?.data.map((bin) => ({
                    label: bin.description,
                    value: bin.status,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectChangeBinStatus
