import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, EditIconDisabled } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";
import moment from 'moment'
export const columns = (onClickEditIcon, onClickDisplayIcon, onClickDeleteIcon) => [
    {
        title: 'Actions',
        render: (_, record) => {
            const isAlreadySubmitted = record.poNo && record.doNo
            return (
                <div
                    style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        minWidth: 70,
                    }}
                >
                    <Row gutter={8} justify="start" align="middle">
                        <Col
                            span={12}
                            className={isAlreadySubmitted ? 'cursor-not-allowed' : 'cursor-pointer'}
                            onClick={() => {
                                if (isAlreadySubmitted) return
                                onClickEditIcon(record)
                            }}
                        >
                            {isAlreadySubmitted ? <EditIconDisabled /> : <EditIcon />}
                        </Col>
                        <Col
                            span={12}
                            className={'cursor-pointer'}
                            onClick={() => onClickDisplayIcon(record)}
                        >
                            <EyeIcon />
                        </Col>
                        {/* <Col
                            span={8}
                            className={'cursor-pointer'}
                            onClick={() => onClickDeleteIcon(record)}
                        >
                            <DeleteIcon />
                        </Col> */}
                    </Row>
                </div>
            )
        },
    },
    {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionType',
        render: (text, record) => <div style={{ minWidth: 50 }}>{text}</div>,
    },
    {
        title: 'Date',
        dataIndex: 'docDate',
        key: 'docDate',
        render: (text, record) => {
            const date = moment(text).format('DD-MM-YYYY')
            return <p style={{ minWidth: 80 }}>{date}</p>
        },
    },
    {
        title: 'Type',
        dataIndex: 'transactionType',
        key: 'transactionType',
        render: (text, record) => <div style={{ minWidth: 50 }}>{text}</div>,
    },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
        render: (text, record) => <div style={{ minWidth: 50 }}>{text}</div>,
    },
    {
        title: 'Plant Sender',
        dataIndex: 'plantSender',
        key: 'plantSender',
        render: (text, record) => <div style={{ minWidth: 90 }}>{text}</div>,
    },
    {
        title: 'Sloc Sender',
        dataIndex: 'slocSender',
        key: 'slocSender',
        render: (text, record) => <div style={{ minWidth: 90 }}>{text}</div>,
    },
    {
        title: 'Plant Receiver',
        dataIndex: 'plantReceiver',
        key: 'plantReceiver',
        render: (text, record) => <div style={{ minWidth: 100 }}>{text}</div>,
    },
    {
        title: 'Sloc Receiver',
        dataIndex: 'slocReceiver',
        key: 'slocReceiver',
        render: (text, record) => <div style={{ minWidth: 90 }}>{text}</div>,
    },
    {
        title: 'PO STO No',
        dataIndex: 'poNo',
        key: 'poNo',
        render: (text, record) => <div style={{ minWidth: 90 }}>{text}</div>,
    },
    {
        title: 'Item',
        dataIndex: 'poItem',
        key: 'poItem',
        align: 'center',
        render: (text, record) => <div style={{ minWidth: 50 }}>{text}</div>,
    },
    {
        title: 'DO STO No',
        dataIndex: 'doNo',
        key: 'doNo',
        render: (text, record) => <div style={{ minWidth: 90 }}>{text}</div>,
    },
    {
        title: 'Item',
        dataIndex: 'doItem',
        key: 'doItem',
        align: 'center',
        render: (text, record) => <div style={{ minWidth: 50 }}>{text}</div>,
    },
    {
        title: 'Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text, record) => <div style={{ minWidth: 120 }}>{text}</div>,
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Status',
        dataIndex: 'statusDescription',
        key: 'statusDescription',
        render: (text) => {
            // posible status =
            //     00	Uncreate
            //     01	No Sign Pallet
            //     02	Sign Pallet
            //     03	Ready To Load
            //     04	Assign Team
            //     05	Loading Process
            //     06	Cancel Foreman
            //     07	Approve
            //     08	Back To Planner
            //     09	Assign Loader / Picker
            //     10	Finish
            //     11	Loading Start
            //     12	Loading End
            //     13	Not Approve
            //     14	Hold
            //     15	Reached
            //     16	Unreached
            //     17	Start Loading
            //     18	Reject Armada
            //     19	Shipping Completion

            const color = {
                Uncreate: 'red',
                'Sign Pallet': 'yellow',
                'Ready To Load': 'green',
                draft: 'grey',
            }

            return <Tag color={color[text]}>{text}</Tag>
        },
    },
]
