import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, InfoYellowIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const plannerDoExecutionColumns = (
    onCLickEditIcon,
    onCLickDisplayIcon,
    onClickInfoIcon = () => {}
) => [
    {
        title: 'Actions',
        render: (_, record) => {
            return (
                <div
                    style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        minWidth: 100,
                    }}
                >
                    <Row gutter={8} justify="start" align="middle">
                        <Col
                            span={8}
                            className={'cursor-pointer'}
                            onClick={() => onCLickEditIcon(record)}
                        >
                            <EditIcon />
                        </Col>
                        <Col
                            span={8}
                            className={'cursor-pointer'}
                            onClick={() => onCLickDisplayIcon(record)}
                        >
                            <EyeIcon />
                        </Col>
                        {record.transactionId && record.information && (
                            <Col
                                span={8}
                                className={'cursor-pointer'}
                                onClick={() => onClickInfoIcon(record)}
                            >
                                <InfoYellowIcon />
                            </Col>
                        )}
                    </Row>
                </div>
            )
        },
    },
    {
        title: 'Outbond ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Check In',
        dataIndex: 'checkin',
        key: 'checkin',
    },
    {
        title: 'Date',
        dataIndex: 'refDate',
        key: 'refDate',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
    },
    {
        title: 'Customer Description',
        dataIndex: 'customerName',
        key: 'customerName',
    },
    {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
    },
    {
        title: 'Plant',
        dataIndex: 'plant',
        key: 'plant',
    },
    {
        title: 'Company Do Header',
        dataIndex: 'companyDoHeader',
        key: 'companyDoHeader',
    },
    {
        title: 'Booking No',
        dataIndex: 'bookingNumber',
        key: 'bookingNumber',
    },
    {
        title: 'Do Number',
        dataIndex: 'documentNumber',
        key: 'documentNumber',
    },
    {
        title: 'Doc Item',
        dataIndex: 'documentItem',
        key: 'documentItem',
    },
    {
        title: 'Material',
        dataIndex: 'material',
        key: 'material',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    
    {
        title: 'Open Qty',
        dataIndex: 'openQty',
        key: 'openQty',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Status',
        dataIndex: 'statusDescription',
        key: 'statusDescription',
        render: (text) => {
            const color = {
                Uncreate: 'red',
                'Sign Pallet': 'yellow',
                'Ready To Load': 'green',
            }

            return <Tag color={color[text]}>{text}</Tag>
        },
    },
]
