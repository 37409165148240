import React from 'react'
import SelectDebounceFetch from './index'
import { getCompanyList } from 'api/master/company'

function SelectCompany({ withoutLabel = false, ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Company"
            fetchOptions={async (searchTerm) => {
                const res = await getCompanyList({ filter: searchTerm, total: 10 })
                const list = res?.result?.data.map((company) => ({
                    label: withoutLabel
                        ? company.companyId
                        : `${company.companyId} - ${company.description}`,
                    value: company.companyId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectCompany
