import React from 'react'
import SelectDebounceFetch from './index'
import { searchRoute } from 'api/master/route'

function SelectRoute({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Route"
            fetchOptions={async (searchTerm) => {
                const res = await searchRoute(searchTerm)
                const list = res?.result?.map((plant) => ({
                    label: `${plant.route} - ${plant.description}`,
                    value: plant.route,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectRoute
