import React, { useContext, useEffect, useCallback, useState } from 'react'
import { Button, Modal } from 'antd'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import DatePickerQueryInput from 'components/Common/DatePickerQueryInput'
import RichTable, { TABLE_TYPE } from 'components/Common/RichTable'
import useQueryRefresh from 'customHooks/useQueryRefresh'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import CreateScrapModal from '../list-outstanding-material/modals/create-scrap'
import { groupingPayload } from '../list-outstanding-material/helper'
import { SimpleTabsContext } from 'components/Common/SimpleTabs'
import { getScrapList, deleteScrap, postScrapToSap } from 'api/transaction/plannerFisikWms'

import { columns } from './columns'

function ListScrap() {
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showSubmitModal, setShowSubmitModal] = useState(false)
    const [createScrapModal, setCreateScrapModal] = useState(false)
    const [rowPayload, setRowPayload] = useState(null)
    const [selectedRows, setSelectedRows] = useState([])
    const { setButtonsHeader } = useContext(SimpleTabsContext)
    const { doRefresh } = useQueryRefresh()

    const onCLickDeleteIcon = (row) => {
        setRowPayload(row)
        setShowDeleteModal(true)
    }

    const onCLickEditIcon = (row) => {
        setRowPayload(row)
        // do something to edit here
        setCreateScrapModal(true)
    }

    const onChangeSelectedRows = (rowKeys, rows) => {
        setSelectedRows(rows)
    }

    const handleDelete = async () => {
        const reqBody = { transactionId: rowPayload.transactionId }
        try {
            const res = await deleteScrap(reqBody)
            if (!res.success) return res
            doRefresh()
            setRowPayload(null)
            return res
        } catch (error) {
            console.error(error)
            setRowPayload(null)
            return { success: false }
        }
    }

    const handleSubmit = async () => {
        const reqBody = { transactionId: selectedRows[0].transactionId }
        try {
            const res = await postScrapToSap(reqBody)
            if (!res.success) {
                const error = res?.logs || []
                Modal.error({
                    title: 'Oops',
                    content: error.reduce(
                        (curr, err) => (
                            <>
                                {curr}
                                <br />
                                <span>{`Type: ${err.type} - ${err.message}`}</span>
                            </>
                        ),
                        ''
                    ),
                    zIndex: 1002,
                    width: 500,
                })
                setShowSubmitModal(false)
                return res
            }
            doRefresh()
            return res
        } catch (error) {
            console.error(error)
            return { success: false }
        }
    }

    const HeaderButtons = useCallback(
        () => (
            <ButtonsGroupGrid>
                <Button
                    disabled={selectedRows.length !== 1}
                    onClick={() => setShowSubmitModal(true)}
                    type="primary"
                    className="w-157 h-45 rounded-10 mx-2"
                >
                    Submit
                </Button>
            </ButtonsGroupGrid>
        ),
        [selectedRows.length]
    )

    useEffect(() => {
        setButtonsHeader(HeaderButtons)
    }, [HeaderButtons, setButtonsHeader])

    return (
        <>
            <div className="flex mt-3">
                <DatePickerQueryInput containerClassName="ml-auto" />
            </div>
            <RichTable
                columns={columns(onCLickDeleteIcon, onCLickEditIcon)}
                withRowSelection={true}
                withDatePicker={false}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getScrapList}
                type={TABLE_TYPE.BASIC}
                scroll={{ x: 1300 }}
            >
                <CreateScrapModal
                    isOnEditMode={true}
                    visible={createScrapModal}
                    close={() => setCreateScrapModal(false)}
                    payload={rowPayload?.transactionId ? groupingPayload([rowPayload]) : null}
                />
                <ConfirmationModal
                    visible={showDeleteModal}
                    title="Are you sure you want to delete?"
                    content={`This will permanently delete. Action can not be undone.`}
                    successTitleMsg="Delete success!"
                    onOk={handleDelete}
                    centered={false}
                    okText="Delete"
                    close={() => setShowDeleteModal(false)}
                />
                <ConfirmationModal
                    visible={showSubmitModal}
                    title="Are you sure you want to Submit?"
                    content={`Transaction ID: ${selectedRows[0]?.transactionId}`}
                    successTitleMsg="Submit success!"
                    successContentMsg={(res) => `Document Number: ${res.documentNumber}`}
                    onOk={handleSubmit}
                    centered={false}
                    okText="Submit"
                    close={() => setShowSubmitModal(false)}
                />
            </RichTable>
        </>
    )
}

export default ListScrap
