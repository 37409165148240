import React from 'react'
import RichTable from 'components/Common/RichTable'
import { getCountryList } from 'api/master/country'
import { columns } from './columns'

const Country = () => {
    return (
        <>
            <RichTable
                columns={columns()}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                getListApi={getCountryList}
            />
        </>
    )
}

export default Country
