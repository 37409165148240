import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'
import { options } from '../options'
import { utils, writeFile, write } from 'xlsx'

export const getListReportOutbound = async (params) => {
    const response = await call(METHODS.GET, '/report-photo-outbound', params)
    return response.data
}

export const getDetailReport = async ({ transactionId }) => {
    const response = await call(METHODS.POST, '/report-photo-outbound/detail', {
        transactionId,
    })
    return response.data
}

export const getListPalletReportOutbound = async (params) => {
    const response = await call(METHODS.GET, '/planner-pallet-assign', params, options)
    const dataRaw = response.data
    return response.data
}

export const downloadListReport = async (params) => {
    const response = await call(METHODS.GET, '/planner-pallet-assign', params, options)
    const dataResponse = response.data
    console.log("dataResponse")
    console.log(dataResponse)
    let ws = utils.json_to_sheet(dataResponse.data)
    let wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'sheet')
    write(wb, { bookType: 'xlsx', type: 'buffer' }) // generate a nodejs buffer
    write(wb, { bookType: 'xlsx', type: 'binary' }) // generate a binary string in web browser
    return writeFile(wb, 'pallet-assign-list-'+params.id+'.xlsx')
}
