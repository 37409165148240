import React, { useState, useEffect } from 'react'
import { Form, Modal, Divider, Button, Input } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

import useQueryRefresh from 'customHooks/useQueryRefresh'
import SelectUomByMaterial from 'components/Common/SelectFetchByMaterial/selectUomByMaterial'
import SelectSlocByPlant from 'components/Common/SelectFetchByPlant/selectSlocByPlant'

function EditItemModal({ close, visible, payload, assignEditedItem, ...props }) {
    const [loading, setLoading] = useState(false)
    const [plantState, setPlant] = useState('')
    const [materialState, setMaterial] = useState('')
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const handleEditItem = (values) => {
        const editedItem = {
            ...payload,
            material: values.material.value,
            materialDescription: values.material.label.split(' - ')[1],
            qty: values.qty,
            poUnit: values.poUnit,
            plant: values.plant.value,
            plantDescription: values.plant.label.split(' - ')[1],
            slocId: values.slocId.value,
            slocDescription: values.slocId.label.split(' - ')[1],
            route: values.route.value,
            routeDescription: values.route.label.split(' - ')[1],
        }
        const { success } = assignEditedItem(editedItem)
        return { success }
    }

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        let payload = { ...values }

        const res = handleEditItem(payload)
        if (res.success || res.status) {
            Modal.success({
                title: `Item edited`,
            })
            close()
            doRefresh()
        }
        return setLoading(false)
    }

    useEffect(() => {
        setMaterial(payload?.material)
        setPlant(payload?.plant)
    }, [payload?.material, payload?.plant])

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>Edit Item</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="material"
                    label="No Material"
                    initialValue={{
                        label: `${payload?.material} ${
                            payload?.materialDescription ? `- ${payload?.materialDescription}` : ''
                        }`,
                        value: payload?.material,
                    }}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <SelectDebounceFetch.Material
                        onChange={(val) => {
                            setMaterial(val.value)
                        }}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%' }}
                    name="qty"
                    label="Qty"
                    shouldUpdate={true}
                    initialValue={payload?.qty}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type="number" placeholder="Qty" className="rounded-5" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%' }}
                    name="poUnit"
                    label="UOM / PO Unit"
                    shouldUpdate={true}
                    initialValue={payload?.poUnit}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <SelectUomByMaterial materialId={materialState} className="rounded-5" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="plant"
                    label="Plant"
                    initialValue={{
                        label: `${payload?.plant} ${
                            payload?.plantDescription ? `- ${payload?.plantDescription}` : ''
                        }`,
                        value: payload?.plant,
                    }}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <SelectDebounceFetch.Plant
                        onChange={(val) => {
                            setPlant(val.value)
                        }}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="slocId"
                    label="Sloc"
                    initialValue={{
                        label: `${payload?.slocId} ${
                            payload?.slocDescription ? `- ${payload?.slocDescription}` : ''
                        }`,
                        value: payload?.slocId,
                    }}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <SelectSlocByPlant plant={plantState} className="rounded-5" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="route"
                    label="Route"
                    initialValue={{
                        label: `${payload?.route} ${
                            payload?.routeDescription ? `- ${payload?.routeDescription}` : ''
                        }`,
                        value: payload?.route,
                    }}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <SelectDebounceFetch.Route
                        onChange={(val) => {
                            setPlant(val.value)
                        }}
                    />
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={close}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Edit Item
                </Button>
            </div>
            {/* <ModalForm
                title="Edit Item"
                formItems={() => formItems(initialValues, false)}
                submitWithApi={false}
                callApi={handleEditItem}
                successMsg={(values) => `New Item ${values.id} was created`}
                close={close}
                visible={visible}
                preserveLabelInValue={true}
                gridColumn={2}
                {...props}
            /> */}
        </Modal>
    )
}

export default EditItemModal
