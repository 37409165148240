import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { updateCheckingArmada } from 'api/master/checkingArmada'
import { auth } from 'auth'

function UpdateCheckingArmadaModal({ payload, close, visible }) {
    const user = auth.getUserInfo()

    const modifiedPayload = {
        description: payload.description,
        id: payload.id,
        changeBy: user?.userName || 'BCO_MJK2', // delete me! ["BCO_MJK2"]
    }
    return (
        <ModalForm
            title="Edit Checking Armada"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={updateCheckingArmada}
            successMsg={(values) => `Checking Armada was updated`}
            close={close}
            visible={visible}
        />
    )
}

export default UpdateCheckingArmadaModal
