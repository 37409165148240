import { Navigate, Outlet } from 'react-router-dom'
import InboundDashboard from 'components/pages/dashboard/inbound'
import OutboundDashboard from 'components/pages/dashboard/outbound'
import WhInventoryDashboard from 'components/pages/dashboard/wh-inventory'

export const dashboardRoutes = {
    path: '/dashboard',
    element: <Outlet />,
    children: [
        { index: false, element: <Navigate to="inbound" /> },
        { path: 'inbound', element: <InboundDashboard /> },
        { path: 'outbound', element: <OutboundDashboard /> },
        { path: 'wh-inventory', element: <WhInventoryDashboard /> },
        { path: '*', element: <Navigate to="inbound" /> },
    ],
}
