import React, { useContext, useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import BarChart from 'components/Common/Chart/BarChart'

import { getDashboardCheckinFinish } from 'api/dashboard/outbound'
import { DARK_PINK_1, PRIMARY_BLUE } from 'config/colors'

// Context
import { CardDatePickerContext } from 'components/Common/CardDatePicker'
import { DashboardOutboundContext } from '../../index'

import { options } from './options'

function ChartCheckinVsFinish() {
    const [loading, setLoading] = useState(false)
    const [checkInData, setCheckinData] = useState([])
    const [finishData, setFinishData] = useState([])

    const { dateRange } = useContext(CardDatePickerContext)
    const { dcLocation } = useContext(DashboardOutboundContext)

    // Data and Labels
    const labels = useMemo(() => checkInData.map((dt) => dt.label), [checkInData])
    const datasets = [
        {
            label: 'Checkin',
            data: checkInData.map((dt) => dt.value),
            backgroundColor: DARK_PINK_1,
            stack: 'Stack 0',
        },
        {
            label: 'Finish',
            data: finishData.map((dt) => dt.value),
            backgroundColor: PRIMARY_BLUE,
            stack: 'Stack 0',
        },
    ]

    useEffect(() => {
        if (!dcLocation) return
        const { startDate, endDate } = dateRange
        console.log('startDate', startDate)
        console.log('endDate', endDate)

        const reqBody = {
            from: moment(startDate).format('DD-MM-YYYY'),
            to: moment(endDate).format('DD-MM-YYYY'),
            dcLocationId: [dcLocation?.value],
        }
        const fetchData = async () => {
            setLoading(true)
            try {
                const { data } = await getDashboardCheckinFinish(reqBody)

                setCheckinData(
                    data.map((i) => ({
                        label: i.date,
                        value: i.checkIn,
                    }))
                )
                setFinishData(
                    data.map((i) => ({
                        label: i.date,
                        value: i.finish,
                    }))
                )
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }

        fetchData()
    }, [dateRange, dcLocation])

    return (
        <BarChart loading={loading} options={options()} datasets={datasets} labels={[...labels]} />
    )
}

export default ChartCheckinVsFinish
