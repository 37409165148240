import React, { useState } from 'react'
import { Modal } from 'antd'

import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import RichTable from 'components/Common/RichTable'
import AddEditModal from './modals/add-edit-background'

import { columns } from './columns'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import {
    getBackgroundMonitoring,
    deleteBackgroundMonitoring,
    reloadManualSap,
} from 'api/utility/background-monitoring'

import { updateBackgroundMonitoring } from 'api/utility/background-monitoring'

const BackgroundMonitoring = () => {
    const [selectedRows, setSelectedRows] = useState([])
    const { doRefresh } = useQueryRefresh()
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [showUpdateUserModal, setUpdateUserModal] = useState(false)

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = selectedRows.map((row) => row.id)
                const res = await deleteBackgroundMonitoring(reqBody)
                if (!res.success) return
                Modal.success({ title: 'Deleted!' })
                doRefresh()
                close()
            },
        })
    }

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateUserModal(true)
    }

    const handleReload = (row) => {
        Modal.confirm({
            title: 'Are you sure?',
            content: `Reload manual ${row.source}?`,
            okText: 'Reload',
            onOk: async (close) => {
                const reqBody = { id: row.id }
                const res = await reloadManualSap(reqBody)
                if (!res.success) return
                Modal.success({ title: `Reload manual ${row.source} success` })
                doRefresh()
            },
        })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete" onClick={handleBulkDelete} />
    )
    return (
        <>
            <RichTable
                columns={columns(handleReload, onCLickEditIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getBackgroundMonitoring}
            />
            <AddEditModal
                title={`Edit Duration & Unit`}
                close={() => setUpdateUserModal(false)}
                visible={showUpdateUserModal}
                payload={modalFormPayload}
                successMsg={`Success edit duration & unit`}
                callApi={updateBackgroundMonitoring}
                // additionalPayload={{ changeBy: user.name }}
            />
        </>
    )
}

export default BackgroundMonitoring
