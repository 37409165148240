import React, { useState } from 'react'
import { Button, Row } from 'antd'

import RichTable from 'components/Common/RichTable'
import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddEditModal from './modals/add-edit-plant-sloc'

import { getPlantSlocList, createNewPlantSloc, updatePlantSloc } from 'api/master/plantSloc'

import { plantsColumns } from './columns'

function PlantSloc() {
    const [showUpdatePlantSlocModal, setUpdatePlantSlocModal] = useState(false)
    const [showAddPlantSlocModal, setAddPlantSlocModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdatePlantSlocModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddPlantSlocModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                columns={plantsColumns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                getListApi={getPlantSlocList}
            />
            <AddEditModal
                title="Add Plant Sloc"
                close={() => setAddPlantSlocModal(false)}
                visible={showAddPlantSlocModal}
                successMsg="Success add Plant Sloc"
                callApi={createNewPlantSloc}
            />
            <AddEditModal
                title="Edit Plant Sloc"
                close={() => setUpdatePlantSlocModal(false)}
                visible={showUpdatePlantSlocModal}
                payload={modalFormPayload}
                successMsg={(values) => 'Success edit Plant Sloc'}
                callApi={updatePlantSloc}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default PlantSloc
