import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { updatePalletAssignmentSort } from 'api/master/palletAssignmentSort'
import { auth } from 'auth'

function UpdatePalletAssignmentSortModal({ payload, close, visible }) {
    const user = auth.getUserInfo()

    const modifiedPayload = {
        dcLocationId: payload.dcLocationId,
        sortId: payload.sortId,
        name: payload.name,
        characteristic: payload.characteristic,
        // characteristicName: payload.characteristicName,
        changeBy: user?.userName || '',
    }
    return (
        <ModalForm
            title="Edit Strategy-Pallet Assignment Sort"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={updatePalletAssignmentSort}
            successMsg={(values) => `Pallet Assignment Sort ${values.sortId} was updated`}
            close={close}
            visible={visible}
        />
    )
}

export default UpdatePalletAssignmentSortModal
