import React, { useReducer, useEffect, useState } from 'react'
import { Table, Button, Tag } from 'antd'
// import SearchQueryInput from 'components/Common/SearchQueryInput'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import AddDoItemModal from '../add-do-item'
import UpdateQtyOutboundModal from '../change-qty-outbound'
import useQueryRefresh from 'customHooks/useQueryRefresh'
import useSearchParams from 'customHooks/useSearchParams'

import { getPlannerDoExecution, putDoExecution } from 'api/transaction/plannerDoExecution'
import { auth } from 'auth'
import { removeDuplicateObjectInArray } from 'utils/array'

import notify from 'utils/notify'

const FLAG = {
    NEW: 'I',
    DELETED: 'D',
}

const columns = (bookingNumber) => {
    const DELETED_CLASS = 'line-through text-nabati-red'
    return [
        // {
        //     title: 'Booking ID',
        //     render: (text, rec) => (
        //         <p className={`${rec.flag === FLAG.DELETED ? DELETED_CLASS : ''}`}>
        //             {bookingNumber}
        //         </p>
        //     ),
        // },

        {
            title: 'Booking ID',
            dataIndex: 'refDocNo',
            key: 'refDocNo',
            render: (text, rec) => (
                <p className={`${rec.flag === FLAG.DELETED ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
        {
            title: 'DO Number',
            dataIndex: 'doNumber',
            key: 'doNumber',
            render: (text, rec) => (
                <p className={`${rec.flag === FLAG.DELETED ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
        {
            title: 'DO Item',
            dataIndex: 'doItem',
            key: 'doItem',
            render: (text, rec) => (
                <p className={`${rec.flag === FLAG.DELETED ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
        {
            title: 'Open Qty',
            dataIndex: 'openQty',
            key: 'openQty',
            render: (text, rec) => (
                <p className={`${rec.flag === FLAG.DELETED ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
        {
            title: 'Customer',
            dataIndex: 'customerId',
            key: 'customerId',
            render: (text, rec) => (
                <p className={`${rec.flag === FLAG.DELETED ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
        {
            title: 'Customer Description',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (text, rec) => (
                <p className={`${rec.flag === FLAG.DELETED ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
        {
            title: 'Company',
            dataIndex: 'companyId',
            key: 'companyId',
           
        },
        {
            title: 'Status',
            dataIndex: 'flag',
            key: 'flag',
            render: (text, rec) => {
                const getColor = (flag) => {
                    if (flag === FLAG.DELETED) return 'red'
                    if (flag === FLAG.NEW) return 'green'
                    return ''
                }

                const getText = (flag) => {
                    if (flag === FLAG.DELETED) return 'Deleted'
                    if (flag === FLAG.NEW) return 'New'
                    return ''
                }

                if (!text) return <></>
                return (
                    <Tag className="rounded-5" color={getColor(text)}>
                        {getText(text)}
                    </Tag>
                )
            },
        },
    ]
}

const initialState = {
    confirmationModal: false,
    confirmationDeleteModal: false,
    addDoItemModal: false,
    updateQtyModal: false,
    selectedRowKeys: [],
    loading: false,
    data: [],
    isSuccessUpdated: false,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return {
                ...state,
                data: action.data,
                loading: false,
                selectedRowKeys: state.selectedRowKeys.filter((id) => {
                    return state.data.findIndex((item) => item.id !== id)
                }),
            }
        case 'ADD_DATA':
            return {
                ...state,
                data: removeDuplicateObjectInArray([...action.data, ...state.data], 'id'),
            }
        case 'TOGGLE_CONFIRMATION_MODAL':
            return { ...state, confirmationModal: !state.confirmationModal }
        case 'TOGGLE_CONFIRMATION_DELETE':
            return {
                ...state,
                confirmationDeleteModal: !state.confirmationDeleteModal,
            }
        case 'TOGGLE_ADD_DO_ITEM_MODAL':
            return { ...state, addDoItemModal: !state.addDoItemModal }
        case 'TOGGLE_UPDATE_QTY_MODAL':
            return { ...state, updateQtyModal: !state.updateQtyModal }
        case 'SET_SELECTED_ROW_KEYS':
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys,
            }
        case 'SET_IS_SUCCESS_UPDATED':
            return { ...state, isSuccessUpdated: action.isSuccessUpdated, loading: false }
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'RESET_STATE':
            return {
                ...state,
                selectedRowKeys: [],
                loading: false,
                data: [],
            }
        default:
            return state
    }
}

function ChangeOutBondId({ payload, close, visible, ...props }) {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [dataTemp, setDataTemp] = useState([])
    const { doRefresh } = useQueryRefresh()
    const { currentQuery } = useSearchParams()
    const user = auth.getUserInfo()

    const onClickDelete = () => {
        dispatch({ type: 'TOGGLE_CONFIRMATION_DELETE' })
    }

    const handleChange = async () => {
        dispatch({ type: 'SET_LOADING', loading: true })

        // HARDCODED 1 NEXT LINE!!
        const ID_IN_OUT = state.data[state.data.length - 1]?.idInOut

        const params = {
            transactionId: payload?.transactionId,
            items: state.data
                .map((dt, idx) => {
                    return {
                        ...dt,
                        idInOut: dt.idInOut === dt.doNumber ? ID_IN_OUT : dt.idInOut,
                    }
                })
                .filter((item) => item.flag !== ''),
        }

        try {
            const res = await putDoExecution(params)
            dispatch({ type: 'SET_IS_SUCCESS_UPDATED', isSuccessUpdated: res.success })
            dispatch({ type: 'RESET_STATE' })
            close()
            doRefresh()
        } catch (error) {
            console.error(error)
        }

        // if (res.success) close()
    }

    const handleDelete = async () => {
        const currentData = [...state.data]
        state.selectedRowKeys.forEach((id) => {
            const idx = currentData.findIndex((item) => item?.id === id)
            const isNew = currentData[idx].flag === FLAG.NEW

            if (isNew) currentData[idx] = null
            if (!isNew) currentData[idx].flag = FLAG.DELETED
        })
        dispatch({ type: 'SET_DATA', data: currentData.filter((item) => !!item) })
        dispatch({ type: 'SET_SELECTED_ROW_KEYS', selectedRowKeys: [] })
    }

    const insertData = (incomingData = []) => {
        const newData = incomingData.map((item) => ({
            id: item.id,
            idInOut: item.idInOut,
            refDocNo: item.bookingNo,
            doNumber: item.doNumber,
            doItem: item.doItem,
            dcLocationId: user.dcLocationId,
            companyId: payload.company,
            plantId: item.plantId,
            qty: item.qty,
            openQty: item.openQty,
            customerId: item.customerId,
            customerName: item.customerName,
            flag: 'I',
            companyDoHeader: item.companyId,
        }))
        dispatch({ type: 'ADD_DATA', data: newData })
    }

    const updateData = (incomingData = []) => {
        const newData = incomingData.map((item) => ({
            id: item.id,
            idInOut: item.idInOut,
            refDocNo: item.bookingNo,
            doNumber: item.doNumber,
            doItem: item.doItem,
            dcLocationId: user.dcLocationId,
            companyId: payload.company,
            plantId: item.plantId,
            qty: item.qty,
            openQty: item.openQty,
            customerId: item.customerId,
            customerName: item.customerName,
            flag: 'U',
            companyDoHeader: item.companyId,
        }))
        dispatch({ type: 'UPDATE_DATA', data: newData })
    }

    const rowSelection = () => ({
        onChange: (selectedRowKeys, selectedRows) => {
            console.log("selectedRows ========================")
           
      
            console.log("dataTemp",dataTemp)
            console.log(selectedRows)
            setDataTemp(selectedRows)
            dispatch({ type: 'SET_SELECTED_ROW_KEYS', selectedRowKeys: selectedRowKeys ,  selectedRows: selectedRows})
        },
        selectedRows: state.selectedRowKeys,
        // type: "radio",
    })
    console.log("dataTemp")
    console.log(dataTemp)
    useEffect(() => {
        if (!payload?.transactionId) return
        if (!visible) return
        dispatch({ type: 'SET_LOADING', loading: true })
        const fetchData = async () => {
            const res = await getPlannerDoExecution({ id: payload?.transactionId })
            if (!res.data) return dispatch({ type: 'SET_LOADING', loading: false })

            const data =
                res?.data?.map((item) => ({
                    id: item.id,
                    idInOut: item.idInOut,
                    refDocNo: item.refDocNo,
                    doNumber: item.doNumber,
                    doItem: item.doItem,
                    dcLocationId: user.dcLocationId,
                    companyId: payload.company,
                    plantId: item.plantId,
                    qty: item.qty,
                    openQty: item.openQty,
                    customerId: item.customerId,
                    customerName: item.customerName,
                    companyDoHeader: item.companyId,
                    flag: '',
                })) || []
            dispatch({ type: 'SET_DATA', data })
        }

        fetchData()
    }, [payload, visible, currentQuery.rrc, user.dcLocationId])

    return (
        <>
            <SimpleModal
                title="Change Outbond ID"
                okText="Change"
                width={800}
                onOk={() => {
                    if (state.data.length === 0) return notify.error('No Data', 'FAILED', 3)
                    dispatch({ type: 'TOGGLE_CONFIRMATION_MODAL' })
                }}
                close={() => {
                    close()
                    dispatch({ type: 'RESET_STATE' })
                }}
                visible={visible}
                {...props}
            >
                <div className="flex items-center h-8">
                    {<p className="mr-5 font-bold">Selected: {state.selectedRowKeys.length}</p>}
                    <Button
                        onClick={() => dispatch({ type: 'TOGGLE_ADD_DO_ITEM_MODAL' })}
                        //   icon={<DeleteOutlined />}
                        className="flex items-center justify-center rounded-10 text-nabati-red border-nabati-red"
                    >
                        Add DO/Item
                    </Button>
                    {state?.selectedRowKeys?.length > 0 && (
                        <>
                            <Button
                                onClick={onClickDelete}
                                icon={<DeleteOutlined />}
                                className="flex items-center justify-center rounded-10 text-nabati-red border-nabati-red ml-2"
                            >
                                Delete
                            </Button>
                            <Button
                                disabled={(state?.selectedRowKeys?.length > 1) ? true : false}
                                onClick={() => dispatch({ type: 'TOGGLE_UPDATE_QTY_MODAL' })}
                                icon={<EditOutlined />}
                                className="flex items-center justify-center rounded-10 text-nabati-red border-nabati-red ml-2"
                            >
                               Change Outstanding OB
                            </Button>
                        </>
                    )}
                </div>
                <Table
                    loading={state.loading}
                    dataSource={state?.data}
                    columns={columns(payload?.bookingNumber)}
                    rowClassName={(_, index) =>
                        `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                    }
                    pagination={false}
                    rowSelection={rowSelection()}
                    rowKey="id"
                />
            </SimpleModal>
            <ConfirmationModal
                loading={state.loading}
                visible={state.confirmationModal}
                close={() => dispatch({ type: 'TOGGLE_CONFIRMATION_MODAL' })}
                onOk={handleChange}
                title="Confirm Change Outbond ID"
                // content="Outbond ID 1200002 will be updated?"
                successTitleMsg="Success Updated"
                // successContentMsg="Outbond ID 1000000"
            />
            <ConfirmationModal
                visible={state.confirmationDeleteModal}
                close={() => dispatch({ type: 'TOGGLE_CONFIRMATION_DELETE' })}
                onOk={handleDelete}
                title="Confirm Delete"
                content="Are you sure want to delete items?"
                successTitleMsg="items deleted"
                // successContentMsg="Outbond ID 1000000"
            />
            <AddDoItemModal
                visible={state.addDoItemModal}
                close={() => dispatch({ type: 'TOGGLE_ADD_DO_ITEM_MODAL' })}
                payload={payload}
                insertData={insertData}
                currentData={state.data}
            />
             <UpdateQtyOutboundModal
                visible={state.updateQtyModal}
                close={() => dispatch({ type: 'TOGGLE_UPDATE_QTY_MODAL' })}
                payload={payload}
                insertData={updateData}
                currentData={state}
                successTitleMsg="Success Updated"
            />
        </>
    )
}

export default ChangeOutBondId
