import React from 'react'
import { Modal, Divider, Progress, message, Spin } from 'antd'
import { UploadCloudIc, CrossIc } from 'assets'

function DropFileModal({
    visible,
    close,
    multiple,
    handleFiles,
    title = 'Upload Files',
    progressPercentage = 0,
    loading = false,
}) {
    const preventDefault = (ev) => {
        ev.preventDefault()
        ev.stopPropagation()
    }

    const validateFiles = (files) => {
        if (!multiple && files.length > 1) {
            message.error('Please select only one file')
            return false
        }
        return true
    }

    const onDropFiles = (e) => {
        e.preventDefault()
        e.stopPropagation()

        let files = e.dataTransfer.files
        const isValid = validateFiles(files)
        if (!isValid) return
        handleFiles(files)
    }

    // if (!visible) return <></>
    return (
        <Modal
            title={false}
            visible={visible}
            onCancel={close}
            footer={false}
            width={576}
            closeIcon={
                <div className="cursor-pointer mt-5 ml-2">
                    <CrossIc />
                </div>
            }
            destroyOnClose
            forceRender
        >
            <p
                className="text-lg"
                style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: 18,
                    color: '#000000',
                }}
            >
                {title}
            </p>
            <Divider className="border-nabati-lightGray my-4" />
            <div
                className="custom-input-container"
                onDragOver={preventDefault}
                onDragEnter={preventDefault}
                onDragLeave={preventDefault}
                onDrop={onDropFiles}
            >
                <UploadCloudIc />
                <p>
                    Drag & Drop Files Here <br />
                    Or
                </p>

                {loading && <Spin className="mt-5 mb-3" tip="Uploading..." />}
                {visible && // bug fix upload same file
                    progressPercentage === 0 && (
                        <input
                            type="file"
                            multiple={multiple}
                            onChange={async (e) => {
                                const files = e.target.files
                                const isValid = validateFiles(files)
                                if (!isValid) return
                                handleFiles(files)
                            }}
                            size="large"
                            className="custom-file-input rounded-10 h-50"
                            style={{ width: '100%' }}
                        />
                    )}
                {progressPercentage > 0 && (
                    <div className="w-4/5">
                        <Progress
                            strokeColor={{ from: '#EE028C', to: '#EE028C' }}
                            percent={+progressPercentage}
                            status="active"
                        />
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default DropFileModal
