import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'

export const getMaterialConversionList = async (params) => {
    const response = await call(METHODS.GET, '/master/materialconversion', params)
    return response.data
}

export const getUomByMaterial = async (params) => {
    const modifiedParams = {
        filterBy: 'material_id',
        ...params,
    }
    const response = await call(METHODS.GET, '/master/materialconversion', modifiedParams)
    return response.data
}
