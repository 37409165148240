import React, { useState } from 'react'
import moment from 'moment'
import { Form, Modal, Divider, Button, Input, DatePicker } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
import useQueryRefresh from 'customHooks/useQueryRefresh'

const DATE_FORMAT = 'YYYY-MM-DD'

function AddEditFormModal({
    close,
    visible,
    payload: initialValues = {},
    successMsg = 'success',
    title = '',
    callApi,
    additionalPayload = {},
}) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        const payload = {
            dcLocation: values.dcLocation.value,
            date: values.date.format(DATE_FORMAT),
            productionTarget: values.productionTarget,
        }

        const modifiedPayload = {
            ...payload,
            ...additionalPayload,
        }

        try {
            const res = await callApi(modifiedPayload)
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="dcLocation"
                    label="DC Location"
                    initialValue={{ value: initialValues?.dcLocation || null }}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDebounceFetch.DcLocation disabled={initialValues?.dcLocation} />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="date"
                    label="Date"
                    initialValue={moment(initialValues?.date)}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <DatePicker
                        disabled={initialValues?.date}
                        className="rounded-5 w-full"
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="productionTarget"
                    label="Production Target"
                    initialValue={initialValues?.productionTarget || null}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder="Input production target"
                        className="rounded-5"
                        size="large"
                    />
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={close}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddEditFormModal
