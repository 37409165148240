export const options = () => ({
    responsive: true,
    elements: {
        line: {
            tension: 0.4, // disables bezier curves
            fill: false,
            // backgroundColor: [PRIMARY_BLUE, PRIMARY_PINK, YELLOW_1],
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'week',
            },
        },
    },
    plugins: {
        legend: {
            display: true,
            position: 'top',
            boxHeight: 100,
            labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                font: { size: 14, weight: 600 },
                padding: 10,
            },
        },
        title: {
            display: true,
            text: 'Inbound from Non Production (Transaction Count)',
            font: { size: 16, weight: 700 },
            padding: 0,
        },
    },
})
