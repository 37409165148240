import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'
import { API_URL } from 'config/env'

export const getBinList = async (params) => {
    const response = await call(METHODS.GET, '/master/bin', params)
    return response.data
}

export const createNewBin = async (data) => {
    const response = await call(METHODS.POST, `/master/bin/store`, data)
    return response.data
}

export const updateBin = async (data) => {
    const response = await call(METHODS.POST, `/master/bin/update`, data)
    return response.data
}

export const deleteBin = async (data) => {
    const response = await call(METHODS.DELETE, `/master/bin/delete`, data)
    return response.data
}

export const downloadBinTemplate = async () => {
    window.open(API_URL + '/master/bin/download')
}

export const uploadBinTemplate = async (formData) => {
    const options = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }
    const response = await call(METHODS.POST, `/master/bin/import`, formData, options)
    return response.data
}

export const generateQrBin = async (data) => {
    const response = await call(METHODS.POST, `/master/bin/qr`, data)
    return response.data
}

export const getBinByPlant = async ({ plantId }) => {
    const response = await call(METHODS.POST, `/master/bin/getbyplant`, { plantId })
    return response.data
}

export const getBeam = async ({ plantId, companyId, binNo }) => {
    const response = await call(METHODS.POST, `/master/bin/getbeam`, {
        plantId,
        companyId,
        binNo,
    })
    return response.data
}

export const getBinId = async ({ plantId, companyId, binNo, beam }) => {
    const response = await call(METHODS.POST, `/master/bin/getbinid`, {
        plantId,
        companyId,
        binNo,
        beam,
    })
    return response.data
}

export const getStagingInOut = async ({ dcLocationId }) => {
    const response = await call(METHODS.POST, `/master/bin/getstaginginout`, { dcLocationId })
    return response.data
}

export const getChangeBinStatus = async (params) => {
    const response = await call(METHODS.GET, '/master/bin/changebinstatus', params)
    return response.data
}
