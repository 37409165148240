import { Input } from 'antd'

export const formItems = (initialValues) => [
    {
        name: 'id',
        label: 'ID',
        initialValue: initialValues?.id || null,
        rules: [
            {
                required: true,
            },
            {
                max: 3,
                message: 'The max character length is 3',
            },
        ],
        children: (
            <Input
                disabled={initialValues?.id}
                placeholder="ID"
                className="rounded-5"
                size="large"
            />
        ),
    },
    {
        name: 'description',
        label: 'Description',
        initialValue: initialValues?.description || null,
        rules: [
            {
                required: true,
            },
            {
                max: 25,
                message: 'The max character length is 25',
            },
        ],
        children: <Input placeholder="Description" className="rounded-5" size="large" />,
    },
]
