import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { updateMoveReason } from 'api/master/moveReason'
import { auth } from 'auth'

function UpdateMoveReasonModal({ payload, close, visible }) {
    const user = auth.getUserInfo()

    const modifiedPayload = {
        id: payload.id,
        companyId: payload.companyId,
        description: payload.description,
        modifiedBy: user?.userName || '',
    }
    return (
        <ModalForm
            title="Edit Move Reason"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={updateMoveReason}
            successMsg={(values) => `Move Reason ${values.id} was updated`}
            close={close}
            visible={visible}
        />
    )
}

export default UpdateMoveReasonModal
