import React from 'react'
import SelectDebounceFetch from './index'
import { getReasonMovementList } from 'api/master/reasonMovement'

function SelectReasonMovement({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Reason Movement"
            fetchOptions={async (searchTerm) => {
                const res = await getReasonMovementList({ filter: searchTerm, total: 10 })
                const list = res?.result?.data.map((reason) => ({
                    label: `${reason.reasonId} - ${reason.description}`,
                    value: reason.reasonId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectReasonMovement
