const generatePalletFromExistingItems = (items) => {
    const pallets = []

    items.forEach((item) => {
        item.pallets.forEach((pall) => {
            const newPallet = {
                // companyId: "KSNI",
                palletId: pall.palletId,
                palletCode: pall.palletCode,
                plantId: item.plant,
                plantDescription: item.plantDescription,
                slocId: item.stgeLoc,
                sectorId: pall.sectorId,
                gateId: pall.sectorId,
                staggingIn: pall.sectorId,
                dockingId: pall.sectorId,
                materialId: item.material,
                description: item.description,
                qty: pall.qty,
                uom: pall.uom,
                batch: item.batch,
                stockType: pall.stockStatus,
                binId: pall.binId,
                toBinId: item.toBinId,
                sled: pall.sled,
                manufactureDate: pall.manufactureDate,
            }

            pallets.push(newPallet)
        })
    })

    return pallets
}

const getPossibleGroup = (pallets) => {
    const possibleGroup = pallets.map((pallet) => ({
        plantId: pallet.plantId,
        materialId: pallet.materialId,
        description: pallet.description,
        slocId: pallet.slocId,
        batch: pallet.batch,
    }))

    const uniquePossibleGroup = []
    possibleGroup.forEach((item) => {
        const i = uniquePossibleGroup.findIndex(
            (x) =>
                x.plantId === item.plantId &&
                x.materialId === item.materialId &&
                x.slocId === item.slocId &&
                x.batch === item.batch
        )
        if (i <= -1) {
            uniquePossibleGroup.push(item)
        }
    })
    return uniquePossibleGroup.map((pallet, idx) => ({
        ...pallet,
        groupIndex: idx,
    }))
}

export const generateItemsByPallets = (newIncomingPallets = [], existingItems = []) => {
    const oldPallets = generatePalletFromExistingItems(existingItems)
    const pallets = [...oldPallets, ...newIncomingPallets]
    const allGroup = getPossibleGroup(pallets)
    const items = allGroup.map((group) => {
        const possiblePallets = []
        pallets.forEach((x) => {
            if (
                x.plantId === group.plantId &&
                x.materialId === group.materialId &&
                x.slocId === group.slocId &&
                x.batch === group.batch
            )
                possiblePallets.push({ ...x, groupIndex: group.groupIndex })
        })

        return possiblePallets
    })

    return items.map((item) => ({
        item: (+allGroup[item[0].groupIndex].groupIndex + 1) * 10,
        material: allGroup[item[0].groupIndex].materialId,
        description: allGroup[item[0].groupIndex].description,
        plant: allGroup[item[0].groupIndex].plantId,
        stgeLoc: allGroup[item[0].groupIndex].slocId,
        batch: allGroup[item[0].groupIndex].batch,
        entryQnt: item.reduce((prev, cur) => prev + cur.qty, 0),
        entryUom: item[0].uom,
        plantDescription: item[0].plantDescription,
        slocDescription:
            allGroup[item[0].groupIndex]?.slocDescription ||
            allGroup[item[0].groupIndex]?.stgeLocDescription ||
            '-',
        toBinId: item[0].plantDescription.toBinId,
        groupIndex: item[0].groupIndex,
        pallets: item.map((i) => ({
            palletId: i.palletId,
            palletCode: i.palletCode,
            binId: i.binId,
            qty: i.qty,
            uom: i.uom,
            sled: i.sled,
            manufactureDate: i.manufactureDate,
            stockStatus: i.stockType,
            slocId: i.slocId,
            sectorId: i.sectorId,
            gateId: i.gateId,
            dockingId: i.dockingId,
            staggingIn: i.staggingIn,
        })),
    }))
}
