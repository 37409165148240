import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getMaterialList = async (params) => {
    const response = await call(METHODS.GET, '/master/material', params)
    return response.data
}

export const getMaterialConversion = async (params) => {
    const response = await call(METHODS.POST, '/master/materialconversion/list', params)
    return response.data
}

export const getMaterialPlantSloc = async (params) => {
    const response = await call(METHODS.POST, '/master/materialplantsloc/list', params)
    return response.data
}

export const createNewMaterial = async (data) => {
    // const url = `/master/material/store`;
    // const response = await call(METHODS.POST, url, data);
    // return response.data;
}

export const updateMaterial = async (data) => {
    // const url = `/master/material/${data.companyId}/update`;
    // const response = await call(METHODS.PUT, url, data);
    // return response.data;
}

export const deleteMaterial = async (id) => {
    // const url = `/master/material/${id}/delete`;
    // const response = await call(METHODS.DELETE, url);
    // return response.data;
}

export const bulkDeleteMaterial = async () => {
    // const fakeRes = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve("");
    //   }, 500);
    // });
    // const response = await fakeRes;
    // return response;
}
