export const options = () => ({
    responsive: true,
    // elements: {
    //     bar: {
    //         backgroundColor: [DARK_PINK_1, PRIMARY_BLUE],
    //     },
    // },
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
    plugins: {
        legend: {
            display: true,
            position: 'top',
            boxHeight: 100,
            labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                font: { size: 14, weight: 400 },
                padding: 10,
            },
        },
        title: {
            display: true,
            text: 'Checkin VS Finish',
            font: { size: 16, weight: 700 },
            padding: 0,
            color: '#000000',
        },
        datalabels: {
            display: false,
            // color: '#000000',
            // anchor: 'end',
            // offset: -20,
            // align: 'start',
        },
    },
})
