import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { updateAssignPlant } from 'api/master/assignPlantToCompany'

function UpdateAssignPlantModal({ payload, close, visible }) {
    const modifiedPayload = {
        plantId: payload.plantId,
        companyId: payload.companyId,
    }

    return (
        <ModalForm
            title="Edit Company"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={updateAssignPlant}
            successMsg={(values) => `Company was updated`}
            close={close}
            visible={visible}
        />
    )
}

export default UpdateAssignPlantModal
