import React from 'react'
import { Tooltip } from 'antd'
import SimpleCard from 'components/layouts/simple'

function GridCardArea({ cards }) {
    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(270px, 1fr))',
                gap: 20,
            }}
        >
            {cards.map((card, index) => (
                <SimpleCard
                    key={index}
                    style={{
                        height: 132,
                        margin: 0,
                    }}
                >
                    <div className="flex justify-center items-center text-base font-bold">
                        <p className="mr-2">{card.name}</p>
                        <Tooltip
                            title="tooltip"
                            color="#F4FBFC"
                            overlayInnerStyle={{ color: 'black', fontWeight: 'bold' }}
                        >
                            {card.icon}
                        </Tooltip>
                    </div>
                    <div
                        className="text-nabati-blue font-bold mt-4 text-center"
                        style={{
                            fontSize: 34,
                        }}
                    >
                        {card.count}
                    </div>
                </SimpleCard>
            ))}
        </div>
    )
}

export default GridCardArea
