import { Input, Select } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

export const formItems = (initialValues) => [
    {
        name: 'id',
        label: 'Pallet ID',
        initialValue: initialValues?.id || null,
        rules: [
            {
                required: true,
            },
            {
                max: 10,
                message: 'The max character length is 10',
            },
        ],
        children: (
            <Input
                disabled={initialValues?.id}
                placeholder="Pallet ID"
                className="rounded-5"
                size="large"
            />
        ),
    },
    {
        name: 'companyId',
        label: 'Company',
        placeholder: 'Company',
        initialValue: { value: initialValues?.companyId || null },
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.Company />,
    },
    {
        name: 'plantId',
        label: 'Plant',
        placeholder: 'Plant',
        initialValue: { value: initialValues?.plantId || null },
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.Plant />,
    },
    {
        name: 'code',
        label: 'Code',
        placeholder: 'Code',
        initialValue: initialValues?.code || null,
        rules: [
            {
                required: true,
            },
            {
                max: 6,
                message: 'The max character length is 6',
            },
        ],
        children: (
            <Input
                disabled={initialValues?.code}
                placeholder="Code"
                className="rounded-5"
                size="large"
            />
        ),
    },
    {
        name: 'color',
        label: 'Color',
        placeholder: 'Color',
        initialValue: initialValues?.color || null,
        rules: [
            {
                required: true,
            },
            {
                max: 15,
                message: 'The max character length is 15',
            },
        ],
        children: <Input placeholder="Color" className="rounded-5" size="large" />,
    },
    {
        name: 'isAvailable',
        label: 'Is Available',
        placeholder: 'Is Available',
        initialValue: initialValues?.isAvailable || 'Y',
        rules: [
            {
                required: true,
            },
        ],
        children: (
            <Select placeholder="Is Available" className="rounded-5" size="large">
                <Select.Option value="Y">Yes</Select.Option>
                <Select.Option value="N">No</Select.Option>
            </Select>
        ),
    },
    {
        name: 'isActive',
        label: 'Is Active',
        placeholder: 'Is Active',
        initialValue: initialValues?.isActive || 'Y',
        rules: [
            {
                required: true,
            },
        ],
        children: (
            <Select placeholder="Is Active" className="rounded-5" size="large">
                <Select.Option value="Y">Yes</Select.Option>
                <Select.Option value="N">No</Select.Option>
            </Select>
        ),
    },
    {
        name: 'isMulti',
        label: 'Is Multi',
        placeholder: 'Is Multi',
        initialValue: initialValues?.isMulti || 'N',
        rules: [
            {
                required: true,
            },
        ],
        children: (
            <Select placeholder="Is Multi" className="rounded-5" size="large">
                <Select.Option value="Y">Yes</Select.Option>
                <Select.Option value="N">No</Select.Option>
            </Select>
        ),
    },
    {
        name: 'isHold',
        label: 'Is Hold',
        placeholder: 'Is Hold',
        initialValue: initialValues?.isHold || 'N',
        rules: [
            {
                required: true,
            },
        ],
        children: (
            <Select placeholder="Is Hold" className="rounded-5" size="large">
                <Select.Option value="Y">Yes</Select.Option>
                <Select.Option value="N">No</Select.Option>
            </Select>
        ),
    },
    {
        name: 'isFreeze',
        label: 'Is Freeze',
        placeholder: 'Is Freeze',
        initialValue: initialValues?.isFreeze || 'N',
        rules: [
            {
                required: true,
            },
        ],
        children: (
            <Select placeholder="Is Freeze" className="rounded-5" size="large">
                <Select.Option value="Y">Yes</Select.Option>
                <Select.Option value="N">No</Select.Option>
            </Select>
        ),
    },
    // {
    //     name: 'status',
    //     label: 'status',
    //     placeholder: 'status',
    //     initialValue: initialValues?.status || null,
    //     rules: [
    //         {
    //             required: true,
    //         },
    //     ],
    //     children: (
    //         <Select placeholder="status" className="rounded-5" size="large">
    //             <Select.Option value="0">0</Select.Option>
    //             <Select.Option value="1">1</Select.Option>
    //             <Select.Option value="2">2</Select.Option>
    //             <Select.Option value="3">4</Select.Option>
    //         </Select>
    //     ),
    // },
]
