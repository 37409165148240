import { Row, Col } from 'antd'
import { EditIcon, ReloadIc } from 'assets'
import { capitalize } from 'utils/formatter'

// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = (onClickReload, onCLickEditIcon) => [
    {
        title: 'Actions',
        width: 50,
        render: (_, record) => (
            <Row gutter={8}>
                {/* <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col> */}
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'Function',
        dataIndex: 'source',
        key: 'source',
    },
    {
        title: 'Duration',
        dataIndex: 'time',
        key: 'time',
    },
    {
        title: 'Unit',
        dataIndex: 'duration',
        key: 'duration',
        render: (text) => <>{capitalize(text)}</>,
    },
    {
        title: 'Status',
        dataIndex: 'isAuto',
        key: 'isAuto',
        render: (text) => (
            <>
                {text === 'N' ? (
                    <span style={{ color: 'red' }}>Non-Auto</span>
                ) : (
                    <span style={{ color: '#1EAF56' }}>Auto</span>
                )}
            </>
        ),
    },
    {
        title: 'Last Update',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
    },
    {
        title: 'Action',
        dataIndex: '-',
        key: '-',
        render: (_, rec) => {
            return (
                <div
                    className="flex justify-content items-center cursor-pointer text-nabati-blue"
                    onClick={() => onClickReload(rec)}
                >
                    <ReloadIc />
                    <div className="ml-2">Reload Manual</div>
                </div>
            )
        },
    },
]
