import React, { useState } from 'react'
import { message } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'
// import SelectGlByPlant from 'components/Common/SelectFetchByPlant/selectGlByPlant'
// import SelectCostCenterByPlant from 'components/Common/SelectFetchByPlant/selectCostCenterByPlant'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

function AddRouteModal({ visible, payload, doEditItem, close, ...props }) {
    const [route, setRoute] = useState('')

    const handleOk = () => {
        if (!route) return message.error('Please Select Route')

        const editedItem = { ...payload, route }
        doEditItem(editedItem)
        message.success('Route Added')
        close()
    }

    return (
        <>
            <SimpleModal
                title="Add Route"
                visible={visible}
                okText="OK"
                width={636}
                onOk={handleOk}
                close={close}
                {...props}
            >
                <p className="mb-2 text-base text-nabati-grey">Route</p>
                <SelectDebounceFetch.Route
                    onChange={({ value }) => setRoute(value)}
                    className="rounded-10 h-50"
                    style={{ width: '100%', borderRadius: '5 !important' }}
                    size="large"
                />
            </SimpleModal>
        </>
    )
}

export default AddRouteModal
