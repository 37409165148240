import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { auth } from 'auth'

import { findCurrentMenu } from 'utils/misc'
import { PARENT_MENU_DICTIONARY } from 'config/menu'
import { onlyUnique, sortByKey } from 'utils/array'

export const useMenu = () => {
    const [authorizedMenu, setMenu] = useState([])
    const [currentMenu, setCurrentMenu] = useState('')
    const { pathname } = useLocation()

    useEffect(() => {
        const userRoles = auth.getUserRoles()
        const allPossibleSubmenu = userRoles
            .filter((r) => r.path)
            .map((r) => ({
                menuId: r.menuId,
                icon: r.icon === '&bull;' ? '•' : r.icon,
                path: r.path,
                title: r.description.split(' / ')[1],
                parent: r.description.split(' / ')[0],
            }))

        const allPossibleMenu = allPossibleSubmenu
            .map((menu) => menu.parent)
            .filter(onlyUnique)
            .map((parentMenu) => ({
                title: parentMenu,
                submenu: allPossibleSubmenu.filter((sub) => parentMenu === sub.parent),
            }))
            .filter((parentMenu) => parentMenu.submenu.length > 0)
            .map((item) => ({
                ...item,
                ...PARENT_MENU_DICTIONARY[item.title],
            }))
            .sort(sortByKey('menuOrder'))

        // console.log('allPossibleParentMenu', allPossibleMenu)
        setMenu(allPossibleMenu)
    }, [])

    useEffect(() => {
        setCurrentMenu(findCurrentMenu(pathname, authorizedMenu))
    }, [pathname, authorizedMenu])

    return { authorizedMenu, currentMenu }
}
