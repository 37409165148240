import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getOrderList = async (params) => {
    const response = await call(METHODS.GET, '/master/order', params)
    return response.data
}

export const searchCostCenterList = async (params) => {
    // params = {
    //     key: 'C11210003',
    // }
    const response = await call(METHODS.GET, '/master/gl/search', params)
    return response.data
}

export const getOrderOptionByPlant = async (params) => {
    const response = await call(METHODS.POST, '/master/order/getbyplant', params)
    return response.data
}
