import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { updateDcLocationPlant } from 'api/master/dcLocationPlant'
import { auth } from 'auth'

function UpdateDcLocationPlantModal({ payload, close, visible }) {
    const user = auth.getUserInfo()
    const modifiedPayload = {
        dcLocationId: payload.dcLocationId,
        plantId: payload.plantId,
        factoryPlant: payload.factoryPlant,
        changeBy: user.userName,
    }
    return (
        <ModalForm
            title="Edit Dc Location Id"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={updateDcLocationPlant}
            successMsg={(values) => `Dc Location Id ${values.dcLocationId} was updated`}
            close={close}
            visible={visible}
        />
    )
}

export default UpdateDcLocationPlantModal
