import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'

export const binColumns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        render: (_, record) => {
            return (
                <div
                    style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        minWidth: 70,
                    }}
                >
                    <Row gutter={8} justify="start" align="middle">
                        <Col
                            span={12}
                            className={'cursor-pointer'}
                            onClick={() => onCLickDeleteIcon(record)}
                        >
                            <DeleteIcon />
                        </Col>
                        <Col
                            span={12}
                            className={'cursor-pointer'}
                            onClick={() => onCLickEditIcon(record)}
                        >
                            <EditIcon />
                        </Col>
                    </Row>
                </div>
            )
        },
    },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Sloc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Building',
        dataIndex: 'building',
        key: 'building',
    },
    {
        title: 'Zone ',
        dataIndex: 'zone',
        key: 'zone',
    },
    {
        title: 'Sector',
        dataIndex: 'sector',
        key: 'sector',
    },
    {
        title: 'BIN',
        dataIndex: 'binNo',
        key: 'binNo',
    },
    {
        title: 'BEAM',
        dataIndex: 'beam',
        key: 'beam',
    },
    {
        title: 'Sub Beam',
        dataIndex: 'subBeam',
        key: 'subBeam',
    },
    {
        title: 'Level Ranking',
        dataIndex: 'level',
        key: 'level',
    },
    {
        title: 'Group Ranking',
        dataIndex: 'groupPriority',
        key: 'groupPriority',
    },
    {
        title: 'Stagging In',
        dataIndex: 'stagingIn',
        key: 'stagingIn',
    },
    {
        title: 'Stagging Out',
        dataIndex: 'stagingOut',
        key: 'stagingOut',
    },
    {
        title: 'Group Priority',
        dataIndex: 'groupPriority',
        key: 'groupPriority',
    },
    {
        title: 'BIN ID',
        dataIndex: 'binId',
        key: 'binId',
        render: (text) => <p className="w-20">{text}</p>,
    },
    {
        title: 'Capacity Pallet',
        dataIndex: 'capacity',
        key: 'capacity',
    },
    {
        title: 'Is Available',
        dataIndex: 'isAvailable',
        key: 'isAvailable',
    },
    {
        title: 'Is Active',
        dataIndex: 'isActive',
        key: 'isActive',
    },
]
