// import { Row, Col } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = () => [
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Order ID',
        dataIndex: 'orderId',
        key: 'orderId',
    },
    {
        title: 'Cost Center ID',
        dataIndex: 'costCenterId',
        key: 'costCenterId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
