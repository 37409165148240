import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { updateCompany } from 'api/master/company'

function UpdateCompanyModal({ payload, close, visible }) {
    return (
        <ModalForm
            title="Edit Company"
            formItems={formItems}
            initialValues={payload}
            callApi={updateCompany}
            successMsg={(values) => `Company ${values.companyId} was updated`}
            close={close}
            visible={visible}
        />
    )
}

export default UpdateCompanyModal
