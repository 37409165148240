import React from 'react'

import { getStagingInOut } from 'api/master/bin'
import AsyncSelectWithDependency from 'components/Common/AsyncSelectWithDependency'

function SelectStagingOutByDcLocations({
    loading,
    dcLocations = [],
    onChange,
    placeholder = 'Select Stagging Out',
    labelInValue = true,
    ...props
}) {
    const loadOptions = (res) => {
        if (!res.data.stagingOut) return []
        return (
            res?.data?.stagingOut?.split(',').map((stagingIn) => ({
                label: stagingIn,
                value: stagingIn,
            })) || []
        )
    }
    return (
        <AsyncSelectWithDependency
            labelInValue={true}
            placeholder={placeholder}
            callApi={(val) => {
                return getStagingInOut({ dcLocationId: val.join(',') })
            }}
            dependencyState={dcLocations}
            loading={loading}
            onChange={onChange}
            loadOptions={loadOptions}
            id="stagging-out"
            {...props}
        />
    )
}

export default SelectStagingOutByDcLocations
