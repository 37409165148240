import React, { useState } from 'react'
import { Button, Modal } from 'antd'

import RichTable, { TABLE_TYPE } from 'components/Common/RichTable'
import DatePickerQueryInput from 'components/Common/DatePickerQueryInput'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import EditItemModal from './modals/edit-item'
import CreateChangeStatusModal from './modals/create-change-status'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import { onlyUnique } from 'utils/array'

import { columns } from './columns'

import {
    getMassChangeStatusInventory,
    deleteChangeStatusToSap,
    changeStatusToSap,
} from 'api/transaction/reportInventory'

const initModalState = { visible: false, payload: null }
const MassChangeStatus = () => {
    const [editModal, setEditModal] = useState(initModalState)
    const [createChangeStatusModal, setCreateChangeStatus] = useState(initModalState)
    const [confirmationDeleteModal, setConfirmationDeleteModal] = useState(initModalState)
    const [confirmationSubmitModal, setConfirmationSubmitModal] = useState(initModalState)
    const [selectedRows, setSelectedRows] = useState([])
    const { doRefresh } = useQueryRefresh()

    const onCLickEditIcon = (row) => setCreateChangeStatus({ visible: true, payload: row })

    const onChangeSelectedRows = (rowKeys, rows) => setSelectedRows(rows)

    const onCLicDisplayIcon = (row) => {
        setCreateChangeStatus({ visible: true, payload: { ...row, isOnDisplayMode: true } })
    }

    const onCLickDeleteIcon = (row) => setConfirmationDeleteModal({ visible: true, payload: row })

    const onClickCreate = () => setCreateChangeStatus({ visible: true, payload: null })

    const onClickSubmit = () => setConfirmationSubmitModal({ visible: true, payload: null })

    const handleDelete = async () => {
        const idToDelete = confirmationDeleteModal.payload.transactionId
        const res = await deleteChangeStatusToSap({ transactionId: idToDelete })
        if (!res.success) {
            return res
        }
        setConfirmationDeleteModal({ visible: false, payload: null })
        doRefresh()
        return res
    }

    const generateErrorMessage = (error) => {
        const errors = error.errors || []
        return errors.reduce(
            (curr, err) => (
                <>
                    {curr}
                    <br />
                    <span>{`Type: ${err.type} - ${err.message}`}</span>
                </>
            ),
            <b>{`Transaction ID: ${error.transactionId}`}</b>
        )
    }

    const handleSubmit = async () => {
        const uniqueIds = selectedRows.map((row) => row.transactionId).filter(onlyUnique)
        try {
            const response = await Promise.all(
                uniqueIds.map(async (transactionId) => await changeStatusToSap({ transactionId }))
            )
            const failedPromises = response
                .map((res, idx) => ({
                    ...res,
                    transactionId: uniqueIds[idx],
                }))
                .filter((res) => !res.success)

            if (failedPromises.length === 0) {
                Modal.success({
                    title: 'Success Submit',
                    content: selectedRows
                        .map((row) => row.transactionId)
                        .filter(onlyUnique)
                        .reduce(
                            (curr, row) => (
                                <>
                                    {curr}
                                    <br />
                                    <b>{`• ${row}`}</b>
                                </>
                            ),
                            'Transaction IDs'
                        ),
                    zIndex: 1002,
                    width: 500,
                })
            }

            if (failedPromises.length > 0) {
                Modal.error({
                    title: 'Oops',
                    content: failedPromises
                        .map((error) => generateErrorMessage(error))
                        .reduce((curr, node) => (
                            <div className="m-1">
                                {curr} <br /> {node}
                            </div>
                        )),
                    zIndex: 1002,
                    width: 500,
                })
                setConfirmationSubmitModal({ visible: false, payload: null })
            }
            setConfirmationSubmitModal({ visible: false, payload: null })
            doRefresh()
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div>
            <div className="flex mb-3">
                <Button onClick={onClickCreate} className="outLinedPink w-157 h-45 rounded-10 mx-2">
                    Create
                </Button>
                <Button
                    onClick={onClickSubmit}
                    type="primary"
                    className="w-157 h-45 rounded-10 mx-2"
                >
                    Submit
                </Button>
                <DatePickerQueryInput containerClassName="ml-auto" />
            </div>
            <RichTable
                columns={columns(onCLickEditIcon, onCLickDeleteIcon, onCLicDisplayIcon)}
                showButtonsArea={false}
                withRowSelection={true}
                withSearchAndFilterArea={false}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getMassChangeStatusInventory}
                // type={TABLE_TYPE.BASIC}
            />
            <EditItemModal
                visible={editModal.visible}
                close={() => setEditModal({ visible: false, payload: null })}
            />
            <CreateChangeStatusModal
                visible={createChangeStatusModal.visible}
                payload={createChangeStatusModal.payload}
                close={() => setCreateChangeStatus({ visible: false, payload: null })}
            />
            <ConfirmationModal
                title="Delete item?"
                visible={confirmationDeleteModal.visible}
                close={() => setConfirmationDeleteModal(!confirmationDeleteModal.visible)}
                onOk={handleDelete}
                content={`Transaction ID: ${confirmationDeleteModal?.payload?.transactionId}`}
                successTitleMsg="Saved"
                successContentMsg={(res) => res?.message || ''}
            />
            <ConfirmationModal
                title="Submit Change Status?"
                visible={confirmationSubmitModal.visible}
                close={() => setConfirmationSubmitModal(!confirmationSubmitModal.visible)}
                onOk={handleSubmit}
                content={selectedRows
                    .map((row) => row.transactionId)
                    .filter(onlyUnique)
                    .reduce(
                        (curr, row) => (
                            <>
                                {curr}
                                <br />
                                <b>{`• ${row}`}</b>
                            </>
                        ),
                        'Submit these transaction ID?'
                    )}
                successTitleMsg="Saved"
                successContentMsg={(res) => res?.message || ''}
                isConfirmationOnly
            />
        </div>
    )
}

export default MassChangeStatus
