import React from 'react'
import SelectDebounceFetch from './index'
import { getMaterialList } from 'api/master/material'

function SelectMaterial({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Material"
            fetchOptions={async (searchTerm) => {
                const res = await getMaterialList({ filter: searchTerm })
                const list = res?.result?.data?.map((material) => ({
                    label: `${material.materialId} - ${material.description}`,
                    value: material.materialId,
                }))
                return list || []
                // return [{ label: '300330', value: '300330' }]
            }}
            {...props}
        />
    )
}

export default SelectMaterial
