import React from 'react'

const DEFAULT_NUMBER_COLOR = '#444444'
function StyledNumber({ data = [] }) {
    return (
        <div className="flex item-center justify-center w-full">
            {data.map((item, ind) => (
                <div className="mx-5" key={ind}>
                    <p
                        className="font-bold text-2.125 text-center"
                        style={{ color: item.color || DEFAULT_NUMBER_COLOR }}
                    >
                        {item.number || 0}
                    </p>
                    <p className="text-xs font-semibold text-center">{item.text || ''}</p>
                </div>
            ))}
        </div>
    )
}

export default StyledNumber
