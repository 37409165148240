import React, { useState, useEffect } from 'react'
import { Modal, Divider, Button } from 'antd'
import SelectPrintColumnButton from './SelectPrintColumnButton'
import PrintConfirmationModal from 'components/Common/Modals/PrintConfirmationModal'

import { generateQrBin } from 'api/master/bin'
import QrCodePrint from './qr-code-print'
import { refactorQrCodes } from './helpers'
import QrPageBody from './QrPageBody'

function QrCodeList({ visible, close, binIds = [] }) {
    const [loading, setLoading] = useState(false)
    const [printModal, setPrintModal] = useState(false)
    const [groupedQrCodes, setGroupedQrCodes] = useState([])

    const [columnPerPage, setColumnPerPage] = useState({ value: '', label: 'Print' })

    useEffect(() => {
        if (!visible) return
        const fetchData = async () => {
            setLoading(true)
            const res = await generateQrBin(binIds || [])
            const resWithBeam = res.map((item) => ({ ...item, beam: item.binId.split('.')[1] }))
            const refactor = refactorQrCodes(resWithBeam)
            setGroupedQrCodes(refactor)

            setLoading(false)
        }

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [binIds.length, visible])

    return (
        <Modal
            id="area"
            visible={visible}
            destroyOnClose
            width={1200}
            footer={null}
            closable={false}
        >
            <p className="text-lg">
                <b>Show QR Code (BIN)</b>
            </p>
            <Divider className="border-black my-4" />
            <QrPageBody
                loading={loading}
                groupedQrCodes={groupedQrCodes}
                columnPerPage={columnPerPage.value || 3}
            />

            {/* DELETE ME */}
            {/* <PrintRuler />
            <QrCodePrint groupedQrCodes={groupedQrCodes} />
            <PrintRuler /> */}
            {/* DELETE ME */}

            <div className="flex justify-end">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={() => {
                        setGroupedQrCodes([])
                        close()
                    }}
                    type="ghost"
                >
                    Close
                </Button>
                {/* <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={() => setPrintModal(true)}
                    type="primary"
                    loading={loading}
                >
                    {loading ? 'Wait...' : 'Print'}
                </Button> */}
                <SelectPrintColumnButton
                    value={columnPerPage}
                    loading={loading}
                    onChange={(e) => {
                        setColumnPerPage(e)
                        setPrintModal(true)
                    }}
                />
            </div>
            <PrintConfirmationModal
                visible={printModal}
                close={() => {
                    // setColumnPerPage({ value: '', label: 'Print' })
                    setPrintModal(false)
                }}
                confirmationText={`Print ${columnPerPage.value} columns per page?`}
                componentToPrint={
                    <QrCodePrint
                        groupedQrCodes={groupedQrCodes}
                        columnPerPage={columnPerPage.value}
                    />
                }
            />
        </Modal>
    )
}

export default QrCodeList
