import React from 'react'
import RichTable, { TABLE_TYPE } from 'components/Common/RichTable'
import { columns } from './columns'

import { getPalletBooking } from 'api/transaction/reportInventory'

function PalletBooking() {
    return (
        <div>
            <RichTable
                columns={columns()}
                showButtonsArea={false}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                withDatePicker={false}
                getListApi={getPalletBooking}
                // type={TABLE_TYPE.BASIC}
            />
        </div>
    )
}

export default PalletBooking
