import React, { useState, useEffect, useMemo } from 'react'
import { Form, Modal, Divider, Button, Input } from 'antd'

import DivSkeletonLoading from 'components/Common/SkeletonLoading/divSkeletonLoading'
import useQueryRefresh from 'customHooks/useQueryRefresh'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
import { getMenuByRoleId } from 'api/maintain-user/role-menu'

function AddEditRoleMenu({
    close,
    visible,
    payload: initialValues = {},
    successMsg = 'success',
    title = '',
    callApi,
    additionalPayload = {},
}) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const [initialMenuIds, setInitialMenuIds] = useState([])
    const roleId = useMemo(() => initialValues.roleId || null, [initialValues.roleId])

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        const payload = {
            ...initialValues,
            menuId: values.menuId.map((m) => m.value),
            roleId: values.roleId || '',
            description: values.description,
            ...additionalPayload,
        }

        try {
            const res = await callApi(payload)
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        const isEditMode = !!roleId
        if (!isEditMode) return
        if (!visible) return
        const fetchData = async () => {
            setLoading(true)
            const res = await getMenuByRoleId({ roleId: roleId })
            if (!res.success) return setLoading(false)
            setInitialMenuIds(() => [
                ...res?.result?.map((p) => ({
                    value: p.menuId,
                    label: `${p.menuId} ${p.menuDescription}`,
                })),
            ])
            setLoading(false)
        }

        fetchData()
    }, [roleId, visible])

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            {loading && <DivSkeletonLoading tip="Loading..." />}
            {!loading && (
                <Form
                    requiredMark={false}
                    layout="vertical"
                    form={form}
                    preserve={false}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                        gap: 10,
                    }}
                >
                    {!!initialValues?.roleId && (
                        <Form.Item
                            className="my-1"
                            style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                            name="roleId"
                            label="Role ID"
                            initialValue={initialValues?.roleId}
                            shouldUpdate={true}
                            rules={[{ required: true }]}
                        >
                            <Input
                                placeholder="Role ID"
                                className="rounded-5"
                                size="large"
                                disabled={initialValues?.roleId}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        className="my-1"
                        style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="menuId"
                        label="Menu ID"
                        initialValue={initialMenuIds || []}
                        shouldUpdate={true}
                        rules={[{ required: true }]}
                    >
                        <SelectDebounceFetch.MenuId mode="multiple" />
                    </Form.Item>
                    <Form.Item
                        className="my-1"
                        style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="description"
                        label="Role Description"
                        initialValue={initialValues?.description}
                        shouldUpdate={true}
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Role Description" className="rounded-5" size="large" />
                    </Form.Item>
                </Form>
            )}
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={() => {
                        setLoading(false)
                        close()
                    }}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddEditRoleMenu
