import React, { useCallback, useReducer, useEffect } from 'react'
import moment from 'moment'
import { Button, Row, Col, Modal, Input, DatePicker } from 'antd'

import { auth } from 'auth'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import ImageUploader from 'components/Common/Uploader/ImageUploader'
// import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
// import SelectGlByPlant from 'components/Common/SelectFetchByPlant/selectGlByPlant'
// import SelectCostCenterByPlant from 'components/Common/SelectFetchByPlant/selectCostCenterByPlant'
// import SelectOrderByPlant from 'components/Common/SelectFetchByPlant/selectOrderByPlant'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import DataTable from './data-table'
import { createScrapDraft, getScrapDetail } from 'api/transaction/plannerFisikWms'

const getBodyRequest = (state) => {
    const user = auth.getUserInfo()
    return {
        source: '1',
        transactionId: state.formData.transactionId,
        docnum: state.formData.transactionId,
        plant: state.formData.plant, // dari input atas ?
        pstngDate: state.formData.reservationDate, // apa beda pstg_date dengan doc_date?
        docDate: state.formData.docDate, // apa beda pstg_date dengan doc_date?
        refDocNo: state.formData.refDocNo, // material slip
        headerTxt: state.formData.headerTxt, // header text
        deliveryNote: state.formData.deliveryNote,
        billOfLanding: state.formData.billOfLanding,
        prUname: user?.username || user?.userName || '', // ???
        referenceId: state.dataTable.map((el) => el.referenceId).join(','),
        items: state.dataTable.map((el, idx) => {
            return {
                referenceId: el.referenceId,
                item: (+idx + 1) * 10, // ??? bisa sama dengan index saja kah?
                batch: el.batch,
                material: el.materialId,
                description: el.description,
                plant: el.plant,
                glAccount: el.gl,
                costcenter: el.costCenter,
                entryUom: el.uom,
                entryQnt: el.qty,
                stgeLoc: el.slocId,
                pallets: el.pallets,
            }
        }),
        images: state.formData.images.map((img, idx) => ({
            ...img,
            imageId: idx + 1,
            data: img.data.split(',')[1],
            name: img.name,
        })),
    }
}

const initState = {
    loading: false,
    formData: {
        transactionId: '',
        plant: '',
        reservationDate: moment().format('YYYY-MM-DD'), //pstngDate
        docDate: moment().format('YYYY-MM-DD'),
        refDocNo: '', // ???
        headerTxt: '',
        deliveryNote: '',
        billOfLanding: '',
        images: [],
        source: '1', // ???
    },
    dataTable: [],
    initialDataTable: [],
    confirmationSaveModal: false,
    confirmationDeleteModal: false,
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'SET_FORM_DATA':
            return { ...state, formData: { ...state.formData, ...action.payload } }
        case 'SET_DATA_TABLE':
            return { ...state, dataTable: action.dataTable }
        case 'SET_INITIAL_DATA_TABLE':
            return { ...state, initialDataTable: action.initialDataTable }
        case 'TOGGLE_CONFIRM_SAVE':
            return { ...state, confirmationSaveModal: action.toggle }
        case 'TOGGLE_CONFIRM_DELETE':
            return { ...state, confirmationDeleteModal: action.toggle }
        case 'RESET':
            return initState
        default:
            return state
    }
}

function CreateScrap({ close, payload, isOnEditMode, visible, ...props }) {
    const [state, dispatch] = useReducer(stateReducer, initState)
    const { doRefresh } = useQueryRefresh()

    const setDataTable = useCallback((dataTable) => {
        dispatch({ type: 'SET_DATA_TABLE', dataTable })
    }, [])

    const validateBeforeSave = () => {
        const error = []
        state.dataTable.forEach((dt) => {
            if (!dt.gl || !dt.costCenter)
                error.push(`${dt.materialId}: Please add GL and Cost Center`)
        })
        if (error.length > 0) return error
        return error
    }

    const onClickSave = () => {
        const error = validateBeforeSave()
        if (error.length > 0)
            return Modal.error({
                title: 'Oops',
                width: 500,
                content: error.reduce(
                    (acc, cur) => (
                        <>
                            {acc}
                            <p>{cur}</p>
                        </>
                    ),
                    <></>
                ),
            })
        dispatch({ type: 'TOGGLE_CONFIRM_SAVE', toggle: true })
    }

    const handleSave = async () => {
        const reqBody = getBodyRequest(state)

        dispatch({ type: 'SET_LOADING', loading: true })
        try {
            const res = await createScrapDraft(reqBody)
            if (!res.success) return res
            dispatch({ type: 'SET_LOADING', loading: false })
            doRefresh()
            close()
            return res
        } catch (error) {
            console.error(error)
            dispatch({ type: 'SET_LOADING', loading: false })
            return { success: false }
        }
    }

    useEffect(() => {
        if (!visible) return
        if (isOnEditMode) return

        dispatch({ type: 'RESET' })
        dispatch({
            type: 'SET_FORM_DATA',
            payload: {
                transactionId: payload?.transactionId,
                company: payload?.companyId,
                plant: payload?.plantId,
            },
        })

        dispatch({
            type: 'SET_INITIAL_DATA_TABLE',
            initialDataTable: payload?.items?.map((item) => ({
                ...item,
                key: parseInt(Math.random() * 1000000000),
                materialId: item?.materialId || '-',
                description: item?.description || '-',
                batch: item?.batch || '-',
                qty: item?.qty || '-',
                uom: item?.uom || '-',
                plant: item?.plant || '-',
                slocId: item?.slocId || '-',
            })),
        })
    }, [payload, isOnEditMode, visible])

    useEffect(() => {
        if (!visible) return
        if (!isOnEditMode) return

        const fetchScrapDetail = async () => {
            const res = await getScrapDetail({ id: payload.transactionId })
            const data = res?.data || {}

            dispatch({
                type: 'SET_FORM_DATA',
                payload: {
                    transactionId: data?.transactionId,
                    company: data?.company,
                    plant: data?.plant,
                    headerTxt: data?.headerTxt,
                    deliveryNote: data?.deliveryNote,
                    billOfLanding: data?.billOfLading,
                },
            })

            dispatch({
                type: 'SET_INITIAL_DATA_TABLE',
                initialDataTable: data?.items?.map((item) => ({
                    ...item,
                    key: parseInt(Math.random() * 1000000000),
                    materialId: item?.material || '-',
                    description: item?.description || '-',
                    batch: item?.batch || '-',
                    qty: item?.entryQnt || '-',
                    uom: item?.entryUom || '-',
                    plant: item?.plant || '-',
                    slocId: item?.slocId || '-',
                    gl: item?.glAccount || '-',
                    costCenter: item?.costcenter || '-',
                })),
            })
        }

        dispatch({ type: 'RESET' })
        fetchScrapDetail()
    }, [payload, isOnEditMode, visible])

    return (
        <>
            <SimpleModal
                title={isOnEditMode ? 'Edit Scrap' : 'Create Scrap'}
                width={1190}
                footer={null}
                visible={visible}
                {...props}
            >
                <Row gutter={16}>
                    <Col span={16}>
                        <Row gutter={[16, 16]} className="mb-5">
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Posting Date</p>
                                <DatePicker
                                    size="large"
                                    className="rounded-10 h-50"
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD"
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { reservationDate: e.format('YYYYMMDD') },
                                        })
                                    }}
                                    defaultValue={moment(state?.formData?.reservationDate)}
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Document Date</p>
                                <DatePicker
                                    size="large"
                                    className="rounded-10 h-50"
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD"
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { docDate: e.format('YYYYMMDD') },
                                        })
                                    }}
                                    defaultValue={moment(state?.formData?.docDate)}
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Delivery Note</p>
                                <Input
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { deliveryNote: e.target.value },
                                        })
                                    }}
                                    size="large"
                                    className="rounded-10 h-50"
                                    style={{ width: '100%' }}
                                    value={state.formData?.deliveryNote}
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Bill of Landing</p>
                                <Input
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { billOfLanding: e.target.value },
                                        })
                                    }}
                                    size="large"
                                    className="rounded-10 h-50"
                                    style={{ width: '100%' }}
                                    value={state.formData?.billOfLanding}
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Header Text</p>
                                <Input
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { headerTxt: e.target.value },
                                        })
                                    }}
                                    size="large"
                                    className="rounded-10 h-50"
                                    style={{ width: '100%' }}
                                    value={state.formData?.headerTxt}
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">BA Attachment</p>
                                <ImageUploader
                                    multiple
                                    initialValues={state.formData.images}
                                    onChange={(data) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: {
                                                images: data.map((img, ind) => ({
                                                    imageId: ind + 1,
                                                    name: img.name,
                                                    data: img.data,
                                                })),
                                            },
                                        })
                                    }}
                                    onDeleteImage={() => {}}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <DataTable
                    isOnEditMode={isOnEditMode}
                    setDataTable={setDataTable}
                    initialDataTable={state.initialDataTable}
                    loading={state.loading}
                />
                <Row justify="end" className="mt-5">
                    <Button onClick={close} className="outLinedPink w-157 h-45 rounded-10 mx-2">
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={onClickSave}
                        className="w-157 h-45 rounded-10 mx-2"
                    >
                        Save
                    </Button>
                </Row>
            </SimpleModal>
            <ConfirmationModal
                title="Confirm Submit?"
                content={`Are you sure to create this?`}
                successTitleMsg="Success!"
                successContentMsg={(res) => `Document Number: ${res?.documentNumber}`}
                onOk={handleSave}
                centered={false}
                okText="Save"
                visible={state.confirmationSaveModal}
                close={() => dispatch({ type: 'TOGGLE_CONFIRM_SAVE', toggle: false })}
            />
        </>
    )
}

export default CreateScrap
