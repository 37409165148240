import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'
import { options } from '../options'

export const getPalletInventory = async (params) => {
    const response = await call(METHODS.GET, '/report-inventory', params, options)
    return response.data
}

export const getPalletBooking = async (params) => {
    const response = await call(METHODS.GET, '/report-inventory-booking', params, options)
    return response.data
}

export const findInventoryById = async (params) => {
    const response = await call(
        METHODS.GET,
        '/report-inventory-find-change-status',
        params,
        options
    )
    return response.data
}

export const findPallet = async (params) => {
    const response = await call(METHODS.GET, '/report-inventory-find-pallet', params, options)
    return response.data
}

export const getMassChangeStatusInventory = async (params) => {
    const response = await call(METHODS.GET, '/report-inventory-change-status', params, options)
    return response.data
}

export const changeStatusInventory = async (params) => {
    const response = await call(METHODS.POST, '/report-inventory-change-status', params, options)
    return response.data
}

export const changeStatusToSap = async (params) => {
    const response = await call(
        METHODS.POST,
        '/report-inventory-post-change-status',
        params,
        options
    )
    return response.data
}

export const deleteChangeStatusToSap = async (params) => {
    const response = await call(METHODS.DELETE, '/report-inventory-change-status', params, options)
    return response.data
}

export const getMassChangeTypeOptions = async (params = {}) => {
    const response = await call(METHODS.GET, '/wh-change-type', params, options)
    return response.data
}
