import { PRIMARY_BLUE, YELLOW_1, GREEN_1, DARK_BLUE_2, PRIMARY_PINK } from 'config/colors'

// TO DO: make generate colors function by Array Color
const COLOR_PATTERN = [PRIMARY_BLUE, DARK_BLUE_2]

export const options = () => ({
    responsive: true,
    elements: {
        bar: {
            backgroundColor: COLOR_PATTERN,
        },
    },
    scales: {
        y: {
            min: 0,
            max: 1000,
            ticks: {
                stepSize: 100,
            },
        },
    },
    plugins: {
        legend: {
            display: true,
            position: 'top',
            boxHeight: 100,
            labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                font: { size: 14, weight: 400 },
                padding: 10,
            },
        },
        title: {
            display: true,
            text: 'Productivity Outbound',
            font: { size: 16, weight: 700 },
            padding: 0,
        },
        datalabels: {
            display: false,
            // color: '#444444',
            // anchor: 'end',
            // offset: -20,
            // align: 'start',
        },
    },
})
