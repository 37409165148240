import React from 'react'
import SelectDebounceFetch from './index'
import { getPlantSlocByPlant } from 'api/master/plantSloc'

function SelectSlocByPlant({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Sloc"
            fetchOptions={async (searchTerm) => {
                const res = await getPlantSlocByPlant({ filter: searchTerm, limit: 10 })
                const list = res?.result?.data.map((sloc) => ({
                    label: `${sloc.slocId} - ${sloc.description}`,
                    value: sloc.slocId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectSlocByPlant
