export const generatePalletFromPayload = (payload) => {
    const items = [payload]
    const pallets = []

    items.forEach((item) => {
        item.pallets.forEach((pallet) => {
            const newPallet = {
                // companyId: "KSNI",
                palletId: pallet.palletId,
                palletCode: pallet.palletCode,
                plantId: item.plant,
                plantDescription: item.plantDescription,
                slocId: item.stgeLoc,
                sectorId: pallet.sectorId,
                gateId: pallet.sectorId,
                staggingIn: pallet.sectorId,
                dockingId: pallet.sectorId,
                materialId: item.material,
                description: item.description,
                qty: pallet.qty,
                uom: pallet.uom,
                batch: item.batch,
                stockType: pallet.stockStatus,
                binId: pallet.binId,
                toBinId: item.toBinId,
                sled: pallet.sled,
                manufactureDate: pallet.manufactureDate,
                ...pallet,
            }

            pallets.push(newPallet)
        })
    })

    return pallets
}
