import React, { useEffect, useState } from 'react'
import { Form, Modal, Divider, Button, Select, DatePicker, Input } from 'antd'
import moment from 'moment';
import useQueryRefresh from 'customHooks/useQueryRefresh'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
import dayjs from 'dayjs';
import 'dayjs/locale/id';

function AddEditAllowBackDate({
    close,
    visible,
    payload: initialValues = {},
    successMsg = 'success',
    title = '',
    callApi,
    additionalPayload = {},
}) {
    const [loading, setLoading] = useState(false)
    const [closingMonth, setClosingMonth] = useState('')
    const [allowBackDate, setAllowBackDate] = useState('')
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()
    console.log(moment(initialValues?.closingMonth).format('YYYY-MM-DD'))
    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        const payload = {
            dcLocation: values.dcLocation.value,
            closingMonth: values.closingMonth,
            allowBackDate: values.allowBackDate,
            ...additionalPayload,
        }


        try {
            const res = await callApi(payload)
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
                setClosingMonth('')
                setAllowBackDate('')
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }
    useEffect(() => {
        setClosingMonth('')
        setAllowBackDate('')
    }, [])
    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="dcLocation"
                    label="Company"
                    initialValue={{ value: initialValues?.dcLocation || null }}
                    shouldUpdate={true}
    
                >
                    <SelectDebounceFetch.DcLocation
                        disabled={initialValues?.dcLocation}
                        // placeholder="Company"
                        // className="rounded-5"
                        // size="large"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="closingMonth"
                    label="Closing Month"
                    getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                    getValueProps={(i) => ({value:  moment((closingMonth.length > 0) ? closingMonth : initialValues?.closingMonth)})}
                    // shouldUpdate={true}
            
                >
                    <DatePicker
                        size="large"
                        className="rounded-10 h-50"
                        style={{ width: '100%' }}
                        format="YYYY-MM-DD"
                        onChange={(_, dateString) => setClosingMonth(dateString)} 
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="allowBackDate"
                    label="Allow Back Date"
                    getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                    getValueProps={(i) => ({value:  moment((allowBackDate.length > 0) ? allowBackDate : initialValues?.allowBackDate)})}
                    // shouldUpdate={true}
            
                >
                    <DatePicker
                        size="large"
                        className="rounded-10 h-50"
                        style={{ width: '100%' }}
                        format="YYYY-MM-DD"
                        onChange={(_, dateString) => setAllowBackDate(dateString)} 
                    />
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={() => {
                        setLoading(false)
                        close()
                    }}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddEditAllowBackDate
