import { Navigate, Outlet } from 'react-router-dom'
import IpSystem from 'components/pages/utility/ip-system'
import CompanySystem from 'components/pages/utility/company-system'
import BackgroundMonitoring from 'components/pages/utility/background-monitoring'
import AllowBackDate from 'components/pages/utility/allow-back-date'
import AllowTransferDc from 'components/pages/utility/allowtransferdc'

export const utilityRoutes = {
    path: '/utility',
    element: <Outlet />,
    children: [
        { index: true, element: <Navigate to="ip-system" /> },
        { path: 'ip-system', element: <IpSystem /> },
        { path: 'company-&-system', element: <CompanySystem /> },
        { path: 'allow-back-date', element: <AllowBackDate /> },
        { path: 'allow-transfer-dc', element: <AllowTransferDc /> },
        {
            path: 'background-monitoring',
            element: <BackgroundMonitoring />,
        },
        { path: '*', element: <Navigate to="/404" /> },
    ],
}
