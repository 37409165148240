import React, { useState } from 'react'
// import Tooltip from 'components/Common/Tooltip'
import { Button, Drawer, Tag } from 'antd'
// import { InfoGreyIcon } from 'assets'

function FloatingRowSelectionsInfo({ selectedRows = [], resetRowSelections, rowKey }) {
    const [showDrawer, setShowDrawer] = useState(false)
    const totalRowSelected = selectedRows.length || 0

    if (totalRowSelected === 0) return <></>
    return (
        <>
            <div
                className="flex justify-center items-center
            sticky bottom-3 left-1/2 -translate-x-1/2
            bg-white w-max px-5 border  h-45 shadow-2xl rounded-full z-10"
                id="floatingRowSelectionInfo"
                style={{ borderColor: '#27c0cd40' }}
            >
                <span className="font-bold text-nabati-blue mr-2">{totalRowSelected}</span>
                <span className="font-bold mr-2">{`Row${
                    totalRowSelected > 1 ? `'s` : ''
                } Selected`}</span>
                {/* <Tooltip
                    autoAdjustOverflow={false}
                    placement="top"
                    overlayClassName=""
                    zIndex={100}
                    getPopupContainer={() => document.getElementById('floatingRowSelectionInfo')}
                    title={() => (
                        <div className="relative flex flex-wrap">
                            {selectedRows.map((row) => (
                                <div className="p-2" key={row[rowKey]}>
                                    {row[rowKey]}
                                </div>
                            ))}
                        </div>
                    )}
                >
                    <InfoGreyIcon />
                </Tooltip> */}
                <Button className="ml-3" type="link" onClick={resetRowSelections}>
                    <span className="font-bold text-nabati-blue">CLEAR</span>
                </Button>

                <Button type="link" onClick={() => setShowDrawer(true)}>
                    <span className="font-bold text-nabati-blue">SHOW SELECTION</span>
                </Button>
            </div>
            <Drawer
                zIndex={100000}
                title="All Selection Data"
                placement="bottom"
                onClose={() => setShowDrawer(false)}
                visible={showDrawer}
            >
                {selectedRows.map((el, ind) => (
                    <Tag key={ind} className="m-2">
                        {el[rowKey]}
                    </Tag>
                ))}
            </Drawer>
        </>
    )
}

export default FloatingRowSelectionsInfo
