import React, { useState, useEffect } from 'react'
import { Form, Modal, Divider, Button, Input } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

import useQueryRefresh from 'customHooks/useQueryRefresh'
import SelectSlocByPlant from 'components/Common/SelectFetchByPlant/selectSlocByPlant'

function AddEditFormModal({
    close,
    visible,
    payload: initialValues,
    successMsg = 'success',
    title = '',
    callApi,
    additionalPayload,
}) {
    const [loading, setLoading] = useState(false)
    const [plantState, setPlant] = useState('')
    const [form] = Form.useForm()
    const { submit, setFieldsValue } = form
    const { doRefresh } = useQueryRefresh()

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        const payload = {
            companyId: values.companyId.value || initialValues.companyId,
            plantId: values.plantId.value || initialValues.plantId,
            slocId: values.slocId.value || initialValues.slocId,
            building: values.building || initialValues.building,
            sortNo: values.sortNo || initialValues.sortNo,
            priorityId: values.priorityId || initialValues.priorityId,
            ...additionalPayload,
        }

        try {
            const res = await callApi({ ...payload })
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        setPlant(initialValues?.plant)
    }, [initialValues?.material, initialValues?.plant])

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="companyId"
                    label="Company ID"
                    initialValue={{ value: initialValues?.companyId || null }}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDebounceFetch.Company disabled={initialValues?.companyId} />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="plantId"
                    label="Plant"
                    initialValue={{ value: initialValues?.plantId || null }}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDebounceFetch.Plant
                        disabled={initialValues?.plantId}
                        onChange={(e) => {
                            setPlant(e?.value || '')
                            setFieldsValue({ slocId: null })
                        }}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="slocId"
                    label="Sloc ID"
                    initialValue={{ value: initialValues?.slocId || null }}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectSlocByPlant
                        plant={plantState}
                        // disabled={!plantState}
                        className="rounded-5"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="building"
                    label="Building"
                    initialValue={initialValues?.building || null}
                    shouldUpdate={true}
                    rules={[
                        { required: true },
                        {
                            max: 4,
                            message: 'The max character length is 4',
                        },
                    ]}
                >
                    <Input
                        placeholder="Building"
                        className="rounded-5"
                        size="large"
                        disabled={initialValues?.building}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="sortNo"
                    label="Group Priority"
                    initialValue={initialValues?.sortNo || null}
                    shouldUpdate={true}
                    rules={[
                        { required: true },
                        {
                            max: 3,
                            message: 'The max character length is 3',
                        },
                    ]}
                >
                    <Input
                        placeholder="Group Priority"
                        className="rounded-5"
                        size="large"
                        disabled={initialValues?.sortNo}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="priorityId"
                    label="Priority ID"
                    initialValue={initialValues?.sortNo || null}
                    shouldUpdate={true}
                    rules={[
                        { required: true },
                        {
                            max: 20,
                            message: 'The max character length is 20',
                        },
                    ]}
                >
                    <Input placeholder="Priority ID" className="rounded-5" size="large" />
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={close}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddEditFormModal
