import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getUserList = async (params) => {
    const response = await call(METHODS.GET, '/master/user', params)
    return response.data
}

export const getRoleListOptions = async (params) => {
    const response = await call(METHODS.GET, '/master/rolemenu/list', params)
    return response.data
}

export const getUserType = async (params) => {
    const response = await call(METHODS.GET, '/master/user/type', params)
    return response.data
}

export const createNewUser = async (data) => {
    const url = `/master/user/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updatePassword = async (data) => {
    const url = `/master/user/reset-password`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updateUser = async (data) => {
    const url = `/master/user/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deleteUser = async (params) => {
    const url = `/master/user/delete`
    const response = await call(METHODS.DELETE, url, params)
    return response.data
}

export const resetUser = async (params) => {
    const url = `/master/user/reset-user`
    const response = await call(METHODS.POST, url, params)
    console.log(response)
    return response.data
}