import { camelCase, snakeCase } from 'lodash'

export function camelCaseToTitleCase(str) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
        return str.toUpperCase()
    })
}

export const capitalize = (string = '') => string.charAt(0).toUpperCase() + string.slice(1)

export function toTitleCase(str) {
    return String(str)
        .replaceAll('_', ' ')
        .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
}

export const twoStringsToNewLine = (str1, str2) =>
    `${str1}
${str2}`

export function toSnakeCase(obj) {
    if (typeof obj !== 'object' || typeof obj === 'undefined' || obj === null || obj === '') {
        return obj
    }
    if (Array.isArray(obj)) {
        const arr = obj
        return arr.map((item) => toSnakeCase(item))
    }
    const newObj = {}
    Object.keys(obj).forEach((key) => {
        const newKey = snakeCase(key)
        const newVal = obj[key] ? toSnakeCase(obj[key]) : obj[key]
        newObj[newKey] = newVal
    })
    return newObj
}

export function toCamelCase(obj) {
    if (typeof obj !== 'object' || typeof obj === 'undefined' || obj === null || obj === '') {
        return obj
    }
    if (Array.isArray(obj)) {
        const arr = obj
        return arr.map((item) => toCamelCase(item))
    }
    const newObj = {}
    Object.keys(obj).forEach((key) => {
        const newKey = camelCase(key)
        const newVal = toCamelCase(obj[key])
        newObj[newKey] = newVal
    })
    return newObj
}

export const indonesianNumber = (num) => {
    num = +num || 0
    const indonesianFormatter = Intl.NumberFormat('id-ID')
    return indonesianFormatter.format(num)
}
