import React, { useState, useEffect } from 'react'
import { Form, Modal, Divider, Button, Input, Select } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
import useQueryRefresh from 'customHooks/useQueryRefresh'
import SelectMaterial from 'components/Common/SelectDebounceFetch/select-material'

function AddEditFormModal({
    close,
    visible,
    payload: initialValues,
    successMsg = 'success',
    title = '',
    callApi,
    additionalPayload,
}) {
    const [loading, setLoading] = useState(false)
    const [dcState, setDcLocation] = useState('')
    const [materialState, setMaterial] = useState('')
    const [descriptionState, setDescription] = useState('')
    const [form] = Form.useForm()
    const { submit, setFieldsValue } = form
    const { doRefresh } = useQueryRefresh()

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(false)
        submit()
        let payload = {}
        if (initialValues) {
            console.log('sasas')
            payload = {
                id:initialValues.id,
                dcLocation: values.dcLocation.value || initialValues.dcLocation,
                plantId: values.plantId.value || initialValues.plantId,
                materialId: materialState || initialValues.materialId,
                description: descriptionState || initialValues.description,
                batch: values.batch || initialValues.batch,
                isActive: values.isActive || initialValues.isActive,
                ...additionalPayload,
            }
        }else{
            payload = {
                dcLocation: values.dcLocation.value || initialValues.dcLocation,
                plantId: values.plantId.value || initialValues.plantId,
                materialId: materialState || initialValues.materialId,
                description: descriptionState || initialValues.description,
                batch: values.batch || initialValues.batch,
                isActive: values.isActive || initialValues.isActive
            }
        }
        console.log(payload)
        try {
            const res = await callApi({ ...payload })
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        setDcLocation(initialValues?.dcLocation)
    }, [initialValues?.plant])

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="dcLocation"
                    label="Company ID"
                    initialValue={{ value: initialValues?.dcLocation || null }}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDebounceFetch.DcLocation disabled={initialValues?.dcLocation} 
                         onChange={(e) => {
                            setDcLocation(e?.value || '')
                         }}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="plantId"
                    label="Plant"
                    initialValue={{ value: initialValues?.plantId || null }}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDebounceFetch.PlantByDc
                       plant={dcState}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="materialId"
                    label="material ID"
                    initialValue={{ value: initialValues?.materialId || null }}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectMaterial
                        // disabled={!plantState}
                        onChange={(e) => {
                            var str = e?.label;
                            var arr = [];
                            arr = str.split('-');
                            setMaterial(e?.value || '')
                            setDescription(arr[1].trimStart() || '')
                         }}
                        className="rounded-5"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="batch"
                    label="Batch"
                    initialValue={initialValues?.batch || null}
                    shouldUpdate={true}
                    rules={[
                        { required: true },
                        {
                            max: 10,
                            message: 'The max character length is 4',
                        },
                    ]}
                >
                    <Input
                        placeholder="Batch"
                        className="rounded-5"
                        size="large"
                        // disabled={initialValues?.batch}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="isActive"
                    label="Active"
                    initialValue={initialValues?.isActive || 'N'}
                    shouldUpdate={true}
                    readOnly="true"
                    rules={[
                        {
                            required: true,
                            
                        },
                    ]}
                >
                    <Select
                        placeholder="Is Active"
                        className="rounded-5"
                        size="large"
                        readOnly={true}
                    > 
                        {/* Y = No    N = Yes   => the origin data from backend is noActive. We need to reverse 'yes' and 'no' to make it clear from user's perspective  */}
                        <Select.Option value="N">No</Select.Option>
                        <Select.Option value="Y">Yes</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={close}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddEditFormModal
