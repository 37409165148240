import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewCompany } from 'api/master/company'

function AddCompanyModal({ close, visible }) {
    return (
        <ModalForm
            title="Add Company"
            formItems={formItems}
            callApi={createNewCompany}
            successMsg={(values) => `New Company ${values.companyId} was created`}
            close={close}
            visible={visible}
        />
    )
}

export default AddCompanyModal
