import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'DC Location',
        dataIndex: 'dcLocation',
        key: 'dcLocation',
        width: 200,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Production Target',
        dataIndex: 'productionTarget',
        key: 'productionTarget',
    },
]
