import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddCheckingArmadaModal from './modals/add-checking-armada'
import UpdateCheckingArmadaModal from './modals/update-checking-armada'

import { getCheckingArmadaList, deleteCheckingArmada } from 'api/master/checkingArmada'
import { checkingArmadaColumns } from './columns'

function CheckingArmada() {
    const [showUpdateCheckingArmadaModal, setUpdateCheckingArmadaModal] = useState(false)
    const [showAddCheckingArmadaModal, setAddCheckingArmadaModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [selectedRows, setSelectedRows] = useState([])

    const { doRefresh } = useQueryRefresh()

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateCheckingArmadaModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = selectedRows.map((row) => row.id)

                const res = await deleteCheckingArmada(reqBody)

                if (!res.success) return
                doRefresh()
            },
        })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete All" onClick={handleBulkDelete} />
    )

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddCheckingArmadaModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                // rowKey="id"
                columns={checkingArmadaColumns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getCheckingArmadaList}
                // withDatePicker={true}
                type="rich"
            />
            <AddCheckingArmadaModal
                close={() => setAddCheckingArmadaModal(false)}
                visible={showAddCheckingArmadaModal}
            />
            <UpdateCheckingArmadaModal
                close={() => setUpdateCheckingArmadaModal(false)}
                visible={showUpdateCheckingArmadaModal}
                payload={modalFormPayload}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default CheckingArmada
