import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'
import { options } from '../options'

export const getPlannerStockTransferList = async (params) => {
    const response = await call(METHODS.GET, '/planner-stock-transfer', params, options)
    return response.data
}

export const createPoStoDraft = async (params) => {
    const response = await call(METHODS.POST, '/planner-po-sto', params, options)
    return response.data
}

export const getPoStoDraft = async (params) => {
    const response = await call(METHODS.GET, '/planner-po-sto', params, options)
    return response.data
}

export const createPoStoSap = async (params) => {
    const response = await call(METHODS.POST, '/planner-po-sto-sap', params, options)
    return response.data
}

export const createPoStoSapFromDraft = async (params) => {
    const response = await call(METHODS.POST, '/planner-po-sto-draft-sap', params, options)
    return response.data
}

export const startLoadingStockTransfer = async (params) => {
    const response = await call(METHODS.POST, '/planner-st-start-loading', params, options)
    return response.data
}

export const deletePoStoSap = async (params) => {
    const response = await call(METHODS.DELETE, '/planner-po-sto', params, options)
    return response.data
}

export const searchMaterial = async (params) => {
    const response = await call(METHODS.GET, '/material', params, options)
    return response.data
}
