import React, { useEffect, useReducer } from 'react'
import { Table, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import SimpleModal from 'components/Common/Modals/SimpleModal'
// import SearchQueryInput from 'components/Common/SearchQueryInput'

import { getOutstandingItemDo } from 'api/transaction/plannerDoExecution'
// import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

import { filterArrayByArray } from 'utils/array'

const columns = [
    {
        title: 'Customer',
        dataIndex: 'customerId',
        key: 'customerId',
    },
    {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName',
    },
    {
        title: 'DO',
        dataIndex: 'doNumber',
        key: 'doNumber',
    },
    {
        title: 'Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Item',
        dataIndex: 'doItem',
        key: 'doItem',
        width: 75,
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
        width: 75,
    },
    {
        title: 'UoM',
        dataIndex: 'uom',
        key: 'uom',
        width: 75,
    },
    {
        title: 'Company Id',
        dataIndex: 'companyId',
        key: 'companyId',
        width: 75,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text, record) => {
    //     const color = {
    //       deleted: "red",
    //       new: "green",
    //     };
    //     if (!text) return <></>;
    //     return (
    //       <Tag className="rounded-5" color={color[text]}>
    //         {text}
    //       </Tag>
    //     );
    //   },
    // },
]

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ROWS':
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys,
                selectedRows: action.selectedRows,
            }
        case 'SET_DATA':
            return {
                ...state,
                data: action.data,
                loading: false,
                selectedRowKeys: [],
                selectedRows: [],
            }
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'RESET':
            return {
                ...state,
                data: [],
                selectedRowKeys: [],
                selectedRows: [],
            }
        default:
            return state
    }
}

const initialState = {
    selectedRowKeys: [],
    selectedRows: [],
    data: [],
    loading: false,
}

const AddDoItem = ({ payload, close, insertData, currentData, ...props }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const handleAddDoItem = async () => {
        insertData(state.selectedRows)
        dispatch({ type: 'RESET' })
        close()
    }

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase()

        const filteredData = state.data.map((el) => {
            const isIncludeInSearch =
                el.customerId.toLowerCase().includes(searchTerm) ||
                el.customerName.toLowerCase().includes(searchTerm) ||
                el.doNumber.toLowerCase().includes(searchTerm) ||
                el.bookingNo.toLowerCase().includes(searchTerm) ||
                el.materialId.toLowerCase().includes(searchTerm)

            return { ...el, hidden: !isIncludeInSearch }
        })

        dispatch({ type: 'SET_DATA', data: filteredData })
    }

    const rowSelection = () => ({
        preserveSelectedRowKeys: false,
        onChange: (selectedRowKeys, selectedRows) => {
            dispatch({ type: 'SET_SELECTED_ROWS', selectedRowKeys, selectedRows })
        },
        selectedRowKeys: state.selectedRowKeys,
    })

    useEffect(() => {
        if (!props.visible) return

        dispatch({ type: 'SET_LOADING', loading: true })
        const fetchData = async () => {
            const res = await getOutstandingItemDo()
            const data = filterArrayByArray(res?.data || [], currentData || [], 'id')
            dispatch({ type: 'SET_DATA', data: data.map((el) => ({ ...el, hidden: false })) })
        }
        fetchData()
    }, [props?.payload, props.visible, currentData])

    return (
        <SimpleModal
            loading={state.loading}
            title="Add Do/Item"
            okText="Add"
            cancelText="Close"
            onOk={handleAddDoItem}
            close={() => {
                close()
                dispatch({ type: 'RESET' })
            }}
            width={900}
            zIndex={2000}
            {...props}
        >
            <Input
                className="rounded-10 mt-2 mb-3"
                onChange={handleSearch}
                size="large"
                placeholder="Search"
                suffix={<SearchOutlined className="text-nabati-blue" />}
            />
            <div className="flex items-center h-8">
                {<p className="mr-5 font-bold">Selected: {state?.selectedRowKeys?.length}</p>}
            </div>
            <Table
                loading={state.loading}
                dataSource={state.data.filter((el) => !el.hidden) || []}
                columns={columns}
                rowClassName={(_, index) =>
                    `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                }
                pagination={false}
                rowSelection={rowSelection()}
                scroll={{ y: 400 }}
                rowKey="id"
            />
        </SimpleModal>
    )
}

export default AddDoItem
