import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getRouteList = async (params) => {
    const response = await call(METHODS.GET, '/master/route', params)
    return response.data
}

export const searchRoute = async (searchTerm) => {
    const url = `/master/route/search`
    const response = await call(METHODS.POST, url, { route: searchTerm })
    return response.data
}

export const bulkDeleteRoute = async () => {
    // const fakeRes = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve("");
    //   }, 500);
    // });
    // const response = await fakeRes;
    // return response;
}
