import { CaretDownOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { useReducer } from 'react'

import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import RichTable from 'components/Common/RichTable'
import SimpleContainer from 'components/layouts/simple'

import AddBookingIdModal from './modals/add-booking-id'
import AddInformation from './modals/add-information'
import AssignBookingIdkModal from './modals/assign-booking-id'
import AssignPalletModal from './modals/assign-pallet'
import AssignSPV from './modals/assign-spv'
import ChangeOutbondId from './modals/change-outbond-id'
import CreateOutbondIdModal from './modals/create-outbond-id'
import DisplayBooking from './modals/display-booking'
import GiWoTruckModal from './modals/gi-wo-truck'
import MassAssignPalletModal from './modals/mass-assign-pallet'
import PlannerChange from './modals/planner-change'
import PlannerDisplay from './modals/planner-display'
import PlannerInfo from './modals/planner-info'
import RemoveSPV from './modals/remove-spv'
import UnlockDoNumberModal from './modals/unlock-do-number'
import LockDoNumberModal from './modals/lock-do-number'
import LockUnlockDoNumberModal from './modals/lock-unlock-do-number'
import GetSingleDoNumberModal from './modals/get-single-do'
import CancelLoadingModal from './modals/cancel-loading'

import { plannerDoExecutionColumns } from './columns'
// import useQueryRefresh from "customHooks/useQueryRefresh";

import {
    getPlannerDoExecutionList,
    startLoadingDoExecution,
} from 'api/transaction/plannerDoExecution'

import {
    SET_SELECTED_ROWS,
    TOGGLE_ADD_INFORMATION,
    TOGGLE_ADD_OUTBOND_ID,
    TOGGLE_ASSIGN_PALLET,
    TOGGLE_ASSIGN_SPV,
    TOGGLE_CHANGE_OUTBOND_ID,
    TOGGLE_CREATE_OUTBOND_ID,
    TOGGLE_UNLOCK_DO_NUMBER,
    TOGGLE_LOCK_DO_NUMBER,
    TOGGLE_LOCK_UNLOCK_DO_NUMBER,
    TOGGLE_GET_SINGLE_DO_NUMBER,
    TOGGLE_CANCEL_LOADING,
    TOGGLE_DISPLAY_BOOKING,
    TOGGLE_PLANNER_CHANGE,
    TOGGLE_PLANNER_DISPLAY,
    TOGGLE_PLANNER_INFO,
    TOGGLE_REMOVE_SPV,
} from 'config/constant/common'

import { FILTER_BY } from 'config/constant/groupFilter'

const stateReducer = (state, action) => {
    switch (action.type) {
        case SET_SELECTED_ROWS:
            return { ...state, selectedRows: [...action.selectedRows] }
        case TOGGLE_CREATE_OUTBOND_ID:
            return { ...state, createOutbondIdModal: !state.createOutbondIdModal }
        case TOGGLE_CHANGE_OUTBOND_ID:
            return { ...state, changeOutbondIdModal: action.visible }
        case TOGGLE_ADD_OUTBOND_ID:
            return { ...state, addOutbondIdModal: !state.addOutbondIdModal }
        case TOGGLE_DISPLAY_BOOKING:
            return { ...state, displayBookingModal: !state.displayBookingModal }
        case TOGGLE_UNLOCK_DO_NUMBER:
            return { ...state, unlockDoNumberModal: !state.unlockDoNumberModal }
        case TOGGLE_LOCK_DO_NUMBER:
            return { ...state, lockDoNumberModal: !state.lockDoNumberModal }
        case TOGGLE_LOCK_UNLOCK_DO_NUMBER:
            return { ...state, lockUnlockDoNumberModal: !state.lockUnlockDoNumberModal }
        case TOGGLE_GET_SINGLE_DO_NUMBER:
            return { ...state, getSingleDoNumberModal: !state.getSingleDoNumberModal }
        case TOGGLE_CANCEL_LOADING:
            return { ...state, cancelLoadingModal: !state.cancelLoadingModal }
        case TOGGLE_ADD_INFORMATION:
            return { ...state, addInformationModal: !state.addInformationModal }
        case TOGGLE_PLANNER_INFO:
            return { ...state, plannerInfoModal: !state.plannerInfoModal }
        case TOGGLE_PLANNER_DISPLAY:
            return {
                ...state,
                plannerDisplayModal: !state.plannerDisplayModal,
                plannerDisplayPayload: action?.payload || null,
            }
        case TOGGLE_ASSIGN_PALLET:
            return { ...state, assignPalletModal: !state.assignPalletModal }
        case 'TOGGLE_MASS_ASSIGN_PALLET':
            return { ...state, massAssignPalletModal: action.visible }
        case TOGGLE_ASSIGN_SPV:
            return { ...state, assignSpvModal: !state.assignSpvModal }
        case TOGGLE_REMOVE_SPV:
            return { ...state, removeSpvModal: !state.removeSpvModal }
        case 'TOGGLE_GI_WO_TRUCK':
            return { ...state, giWoTruckModal: !state.giWoTruckModal }
        case 'TOGGLE_ASSIGN_BOOKING_ID':
            return { ...state, assignBookingIdModal: !state.assignBookingIdModal }
        case TOGGLE_PLANNER_CHANGE:
            return {
                ...state,
                plannerChangeModal: !state.plannerChangeModal,
                plannerChangePayload: action?.payload || null,
            }
        default:
            return state
    }
}

const initialState = {
    selectedRows: [],
    createOutbondIdModal: false,
    changeOutbondIdModal: false,
    addOutbondIdModal: false,
    displayBookingModal: false,
    unlockDoNumberModal: false,
    lockDoNumberModal: false,
    lockUnlockDoNumberModal: false,
    cancelLoadingModal: false,
    assignPalletModal: false,
    assignSpvModal: false,
    removeSpvModal: false,
    giWoTruckModal: false,
    assignBookingIdModal: false,
    addInformationModal: false,
    plannerInfoModal: false,
    plannerChangeModal: false,
    plannerChangePayload: null,
    plannerDisplayModal: false,
    plannerDisplayPayload: null,
    massAssignPalletModal: false,
}
const { BY_MATERIAL, BY_COMPANY, BY_PLANT, BY_BUILDING, BY_OUTBOUND_STATUS } = FILTER_BY
const PlannerDoExecution = () => {
    const [state, dispatch] = useReducer(stateReducer, initialState)

    const onCLickEditIcon = (row) => {
        dispatch({ type: TOGGLE_PLANNER_CHANGE, payload: row })
    }

    const onCLickDisplayIcon = (row) => {
        dispatch({ type: TOGGLE_PLANNER_DISPLAY, payload: row })
    }

    const onClickInfoIcon = (row) => {
        dispatch({ type: TOGGLE_PLANNER_INFO, payload: row })
        dispatch({ type: SET_SELECTED_ROWS, selectedRows: [row] })
    }

    const onChangeSelectedRows = (rowKeys, rows) => {
        dispatch({ type: SET_SELECTED_ROWS, selectedRows: rows })
    }

    const onClickGiWoTruck = () => {
        // const payload = state.selectedRows[0]
        // if (!payload?.transactionId)
        // return message.error('Create Outbound ID for this transaction first')
        dispatch({ type: 'TOGGLE_GI_WO_TRUCK' })
    }

    const buttonsArea = () => (
        <ButtonsGroupGrid>
            <Button
                onClick={() => dispatch({ type: TOGGLE_ASSIGN_PALLET })}
                type="primary"
                className="w-157 h-45 rounded-10 mx-2"
                disabled={state.selectedRows.length !== 1}
            >
                Assign Pallet
            </Button>
            <Button
                onClick={() => dispatch({ type: 'TOGGLE_MASS_ASSIGN_PALLET', visible: true })}
                className="outLinedPink w-157 h-45 rounded-10 mx-2"
                disabled={state.selectedRows.length === 0}
            >
                Mass Assign Pallet
            </Button>
            <Dropdown
                overlay={
                    <SimpleContainer>
                        <Button
                            type="text"
                            onClick={() => dispatch({ type: TOGGLE_ASSIGN_SPV })}
                            disabled={state.selectedRows.length === 0}
                        >
                            Assign SPV
                        </Button>
                        <br />
                        <Button
                            type="text"
                            onClick={() => dispatch({ type: TOGGLE_REMOVE_SPV })}
                            disabled={state.selectedRows.length === 0}
                        >
                            Remove SPV
                        </Button>
                        <br />
                        <Button
                            type="text"
                            onClick={onClickGiWoTruck}
                            disabled={state.selectedRows.length === 0}
                        >
                            GI w/o Truck
                        </Button>
                        <br />
                        <Button
                            type="text"
                            onClick={() => dispatch({ type: 'TOGGLE_ASSIGN_BOOKING_ID' })}
                            disabled={state.selectedRows.length === 0}
                        >
                            Assign Booking ID
                        </Button>
                        <br />
                        <Button
                            type="text"
                            onClick={() => dispatch({ type: 'TOGGLE_UNLOCK_DO_NUMBER' })}
                            disabled={state.selectedRows.length === 0}
                        >
                            Unlock Do Number
                        </Button>
                        <br />
                        <Button
                            type="text"
                            onClick={() => dispatch({ type: 'TOGGLE_LOCK_DO_NUMBER' })}
                            disabled={state.selectedRows.length === 0}
                        >
                            Lock Do Number
                        </Button>
                        <br />
                        <Button
                        
                            type="text"
                            onClick={() => dispatch({ type: 'TOGGLE_LOCK_UNLOCK_DO_NUMBER' })}
                            // disabled={state.selectedRows.length === 0}
                        >
                            Single Lock / Unlock Do Number (DO Finish)
                        </Button>
                        <br />
                        <Button
                            type="text"
                            onClick={() => dispatch({ type: 'TOGGLE_CANCEL_LOADING' })}
                            disabled={state.selectedRows.length === 0}
                        >
                            Cancel Loading
                        </Button>
                    </SimpleContainer>
                }
            >
                <Button
                    className="flatBlue w-157 h-45 rounded-10 mx-2"
                    disabled={state.selectedRows.length === 0}
                >
                    <div className="flex justify-center items-center hover:text-white">
                        <p className="-mt-2 ml-5">
                            <CaretDownOutlined className="" />
                        </p>
                        <p>Start Loading</p>
                    </div>
                </Button>
            </Dropdown>
            <Button
                onClick={() => dispatch({ type: TOGGLE_ADD_INFORMATION })}
                className="outLinedBlue w-157 h-45 rounded-10 mx-2"
                disabled={state.selectedRows.length === 0}
            >
                Add Information
            </Button>
            <Button
                onClick={() => dispatch({ type: TOGGLE_DISPLAY_BOOKING })}
                className="outLinedBlue w-157 h-45 rounded-10 mx-2"
                disabled={state.selectedRows.length === 0}
            >
                Display Outbound ID
            </Button>
            <Dropdown
                overlay={
                    <SimpleContainer>
                        <Button
                            type="text"
                            onClick={() => dispatch({ type: TOGGLE_CREATE_OUTBOND_ID })}
                            disabled={state.selectedRows.length === 0}
                        >
                            Create Outbond ID
                        </Button>
                        <br />
                        <Button
                            type="text"
                            onClick={() =>
                                dispatch({ type: TOGGLE_CHANGE_OUTBOND_ID, visible: true })
                            }
                            disabled={state.selectedRows.length === 0}
                        >
                            Change Outbond ID
                        </Button>
                        <br />
                        <Button
                            type="text"
                            onClick={() =>
                                dispatch({ type: TOGGLE_GET_SINGLE_DO_NUMBER, visible: true })
                            }
                        
                        >
                            Call DO Sap
                        </Button>
                    </SimpleContainer>
                }
            >
                <Button
                    onClick={() => {}}
                    className="outLinedBlue w-157 h-45 rounded-10 mx-2 hover:text-white"
                >
                    <div className="flex justify-center items-center hover:text-white">
                        <p className="-mt-2 ml-7">
                            <CaretDownOutlined className="" />
                        </p>
                        <p>Outbond ID</p>
                    </div>
                </Button>
            </Dropdown>
        </ButtonsGroupGrid>
    )

    return (
        <RichTable
            rowKey="id"
            columns={plannerDoExecutionColumns(
                onCLickEditIcon,
                onCLickDisplayIcon,
                onClickInfoIcon
            )}
            showButtonsArea={true}
            showSearch={true}
            withRowSelection={true}
            withSearchAndFilterArea={true}
            filterField={[BY_MATERIAL, BY_COMPANY, BY_PLANT, BY_BUILDING, BY_OUTBOUND_STATUS]}
            withFloatingRowSelectionInfo
            ButtonsAreaComponent={buttonsArea}
            getListApi={getPlannerDoExecutionList}
            withDatePicker={true}
            onChangeSelectedRows={onChangeSelectedRows}
            scroll={{ x: 1300 }}
        >
            <CreateOutbondIdModal
                visible={state.createOutbondIdModal}
                close={() => dispatch({ type: TOGGLE_CREATE_OUTBOND_ID })}
                payload={state.selectedRows}
            />
            <UnlockDoNumberModal
                visible={state.unlockDoNumberModal}
                close={() => dispatch({ type: TOGGLE_UNLOCK_DO_NUMBER })}
                payload={state.selectedRows}
           />
            <LockDoNumberModal
                visible={state.lockDoNumberModal}
                close={() => dispatch({ type: TOGGLE_LOCK_DO_NUMBER })}
                payload={state.selectedRows}
            />
             <LockUnlockDoNumberModal
                visible={state.lockUnlockDoNumberModal}
                close={() => dispatch({ type: TOGGLE_LOCK_UNLOCK_DO_NUMBER })}
                // payload={state.selectedRows}
            />
              <GetSingleDoNumberModal
                visible={state.getSingleDoNumberModal}
                close={() => dispatch({ type: TOGGLE_GET_SINGLE_DO_NUMBER })}
                // payload={state.selectedRows}
            />
            <CancelLoadingModal
                visible={state.cancelLoadingModal}
                close={() => dispatch({ type: TOGGLE_CANCEL_LOADING })}
                payload={state.selectedRows}
            />
            <PlannerChange
                visible={state.plannerChangeModal}
                close={() => dispatch({ type: TOGGLE_PLANNER_CHANGE })}
                payload={state.plannerChangePayload}
            />
            <AddBookingIdModal
                visible={state.addOutbondIdModal}
                close={() => dispatch({ type: TOGGLE_ADD_OUTBOND_ID })}
            />
            <AssignPalletModal
                visible={state.assignPalletModal}
                close={() => dispatch({ type: TOGGLE_ASSIGN_PALLET })}
                payload={state.selectedRows[0]}
            />
            <AssignSPV
                visible={state.assignSpvModal}
                close={() => dispatch({ type: TOGGLE_ASSIGN_SPV })}
                transactionId={state.selectedRows[0]?.transactionId || ''}
                startLoadingApi={startLoadingDoExecution}
            />
            <RemoveSPV
                visible={state.removeSpvModal}
                close={() => dispatch({ type: TOGGLE_REMOVE_SPV })}
                payload={state.selectedRows[0]}
            />
            <GiWoTruckModal
                visible={state.giWoTruckModal}
                close={() => dispatch({ type: 'TOGGLE_GI_WO_TRUCK' })}
                payload={state.selectedRows[0]}
                bulkPayload={state.selectedRows || []}
            />
            <AssignBookingIdkModal
                visible={state.assignBookingIdModal}
                close={() => dispatch({ type: 'TOGGLE_ASSIGN_BOOKING_ID' })}
                payload={state.selectedRows[0]}
            />
            <DisplayBooking
                visible={state.displayBookingModal}
                close={() => dispatch({ type: TOGGLE_DISPLAY_BOOKING })}
                payload={state.selectedRows[0]}
            />
            <AddInformation
                visible={state.addInformationModal}
                close={() => dispatch({ type: TOGGLE_ADD_INFORMATION })}
                payload={state.selectedRows[0]}
            />
            <PlannerInfo
                visible={state.plannerInfoModal}
                close={() => dispatch({ type: TOGGLE_PLANNER_INFO })}
                payload={state.selectedRows[0]}
                handleUpdateInfo={() => dispatch({ type: TOGGLE_ADD_INFORMATION })}
            />
            <PlannerDisplay
                visible={state.plannerDisplayModal}
                close={() => dispatch({ type: TOGGLE_PLANNER_DISPLAY })}
                payload={state.plannerDisplayPayload}
            />
            <ChangeOutbondId
                visible={state.changeOutbondIdModal}
                close={() => dispatch({ type: TOGGLE_CHANGE_OUTBOND_ID, visible: false })}
                payload={state.selectedRows[0]}
            />
            <MassAssignPalletModal
                visible={state.massAssignPalletModal}
                close={() => dispatch({ type: 'TOGGLE_MASS_ASSIGN_PALLET', visible: false })}
                payload={state.selectedRows}
            />
        </RichTable>
    )
}

export default PlannerDoExecution
