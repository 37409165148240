import { useState, useContext } from 'react'
import { Button, Modal, message } from 'antd'
import { CheckCircleIcon } from 'assets'
import { RichTableContext } from 'components/Common/RichTable'

const defaultSuccessTitle = (
    <div className="flex justify-center items-center text-nabati-green text-2xl">
        <CheckCircleIcon /> <span className="m-2">Success</span>
    </div>
)

export default function ConfirmationModal({
    visible,
    close,
    onOk = async () => {},
    onCancel = () => {},
    title = defaultSuccessTitle,
    content,
    successTitleMsg = 'Success',
    successContentMsg = () => '' || '',
    okText = 'OK',
    cancelText = 'Cancel',
    width = 450,
    isConfirmationOnly = false,
    ...props
}) {
    const [loading, setLoading] = useState(false)
    const richTableContext = useContext(RichTableContext)

    const handleOk = async (e) => {
        setLoading(true)
        let res = null
        try {
            res = await onOk(e)
            if (isConfirmationOnly) {
                close()
                setLoading(false)
                return
            }
            if (res?.success === false) {
                if (!res.message) message.error('Oops... Something went wrong')
                close()
                return setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            return console.error(err)
        }
        setLoading(false)
        close()

        if (!res || res.success === false) return
        Modal.success({
            title: successTitleMsg,
            content:
                typeof successContentMsg === 'function'
                    ? successContentMsg(res)
                    : successContentMsg,
        })

        // RESET ROW SELECTION RICH TABLE CONTEXT
        if (richTableContext && richTableContext.resetRowSelections)
            richTableContext.resetRowSelections()

        setLoading(false)
    }

    return (
        <Modal
            closable={false}
            visible={visible}
            onCancel={() => {
                setLoading(false)
                close()
            }}
            footer={null}
            destroyOnClose
            width={width}
            bodyStyle={{ padding: 20 }}
            zIndex={1001}
            {...props}
        >
            <div style={{ color: '#101820' }}>
                {title && <div className="text-2xl font-bold mb-1">{title}</div>}
                {content && <div>{content}</div>}
                <div
                    className="mt-5"
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                        gap: 10,
                    }}
                >
                    {!isConfirmationOnly && (
                        <Button
                            className="h-11 rounded-10 text-nabati-pink border-nabati-pink"
                            onClick={() => {
                                onCancel()
                                close()
                            }}
                            type="ghost"
                        >
                            {cancelText}
                        </Button>
                    )}
                    <Button
                        className="h-11 rounded-10"
                        onClick={handleOk}
                        type="primary"
                        loading={loading}
                    >
                        {okText}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
