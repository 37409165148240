import React from 'react'

import RichTable from 'components/Common/RichTable'
import { columns } from './columns'

import { getShippingTypeList } from 'api/master/shippingType'

function ShippingType() {
    return <RichTable columns={columns()} showSearch={true} getListApi={getShippingTypeList} />
}

export default ShippingType
