import React, { useReducer } from 'react'
import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, DeleteIcon, DeleteIconDisabled, EditIconDisabled } from 'assets'
import { DO_WO_CHECKIN_STATUS, DO_WO_CHECKIN_COLOR } from 'config/constant/do-execution-wo-checkin'

export const columns = (onCLickEditIcon, onCLickDisplayIcon, onClickDeleteIcon = () => {}) => [
    {
        title: 'Actions',
        dataIndex: 'statusDescription',
        key: 'statusDescription',
        render: (status, record) => {
            const isSubmitted = status === DO_WO_CHECKIN_STATUS.SUBMITTED
            return (
                <div
                    style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        minWidth: 100,
                    }}
                >
                    <Row gutter={8} justify="start" align="middle">
                        <Col
                            span={8}
                            className={isSubmitted ? 'cursor-not-allowed' : 'cursor-pointer'}
                            onClick={() => (isSubmitted ? null : onCLickEditIcon(record))}
                        >
                            {isSubmitted ? <EditIconDisabled /> : <EditIcon />}
                        </Col>
                        <Col
                            span={8}
                            className={'cursor-pointer'}
                            onClick={() => onCLickDisplayIcon(record)}
                        >
                            <EyeIcon />
                        </Col>
                        <Col
                            span={8}
                            className={isSubmitted ? 'cursor-not-allowed' : 'cursor-pointer'}
                            onClick={() => (isSubmitted ? null : onClickDeleteIcon(record))}
                        >
                            {isSubmitted ? <DeleteIconDisabled /> : <DeleteIcon />}
                        </Col>
                    </Row>
                </div>
            )
        },
    },
    {
        title: 'Create Date',
        dataIndex: 'createDate',
        key: 'createDate',
    },
    {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Booking ID',
        dataIndex: 'bookingId',
        key: 'bookingId',
    },
    {
        title: 'Forwarder ID',
        dataIndex: 'forwarderId',
        key: 'forwarderId',
    },
    {
        title: 'Forwarder Name',
        dataIndex: 'forwarderName',
        key: 'forwarderName',
    },
    {
        title: 'Delivery Order',
        dataIndex: 'deliveryNumber',
        key: 'deliveryNumber',
    },
    {
        title: 'Planner Delivery Date',
        dataIndex: 'plannedDlvDate',
        key: 'plannedDlvDate',
    },
    {
        title: 'Customer ID',
        dataIndex: 'customerId',
        key: 'customerId',
    },
    {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName',
    },
    {
        title: 'Status',
        dataIndex: 'statusDescription',
        key: 'statusDescription',
        render: (text) => {
            return <Tag color={DO_WO_CHECKIN_COLOR[text]}>{text}</Tag>
        },
    },
]
