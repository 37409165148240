import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getAllowBackDateList = async (params) => {
    const response = await call(METHODS.GET, '/utility/allow-back-date', params)
    return response.data
}

export const createNewAllowBackDate = async (data) => {
    const url = `/utility/allow-back-date/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updateAllowBackDate = async (data) => {
    const url = `/utility/allow-back-date/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deleteAllowBackDate = async (params) => {
    const url = `/utility/allow-back-date/delete`
    const response = await call(METHODS.DELETE, url, params)
    return response.data
}
