import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const palletIdColumns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'Pallet ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
    },
    {
        title: 'Color',
        dataIndex: 'color',
        key: 'color',
    },
    {
        title: 'Is Available',
        dataIndex: 'isAvailable',
        key: 'isAvailable',
        align: 'center',
    },
    {
        title: 'Is Active',
        dataIndex: 'isActive',
        key: 'isActive',
        align: 'center',
    },
]
