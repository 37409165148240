import { Navigate, Outlet } from 'react-router-dom'
import PlannerDashboard from 'components/pages/transaction/planner-dashboard'
import PlannerDoExecution from 'components/pages/transaction/planner-do-execution'
import PlannerStockTransfer from 'components/pages/transaction/planner-stock-transfer'
import PlannerStockGoodIssue from 'components/pages/transaction/planner-stock-good-issue'
import PlannerReportInventory from 'components/pages/transaction/planner-report-inventory'
import PlannerSetPriority from 'components/pages/transaction/planner-set-priority'
import PlannerFisikWmsLoader from 'components/pages/transaction/planner-fisik-wms-loader'
import ResultCycleCountOpname from 'components/pages/transaction/result-cycle-count-opname'

export const transactionRoutes = {
    path: '/transaction',
    element: <Outlet />,
    children: [
        { index: false, element: <Navigate to="planner-dashboard" /> },
        { path: 'planner-dashboard', element: <PlannerDashboard /> },
        { path: 'planner-do-execution', element: <PlannerDoExecution /> },
        { path: 'planner-stock-transfer', element: <PlannerStockTransfer /> },
        { path: 'planner-stock-good-issue', element: <PlannerStockGoodIssue /> },
        { path: 'planner-report-inventory', element: <PlannerReportInventory /> },
        { path: 'planner-set-priority', element: <PlannerSetPriority /> },
        { path: 'planner-fisik-wms-loader', element: <PlannerFisikWmsLoader /> },
        { path: 'result-cycle-count-opname', element: <ResultCycleCountOpname /> },
        { path: '*', element: <Navigate to="planner-dashboard" /> },
    ],
}
