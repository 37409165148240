import React, { useContext, useEffect, useCallback, useReducer } from 'react'
import { Button, Modal } from 'antd'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import RichTable, { TABLE_TYPE } from 'components/Common/RichTable'
import { columns } from './columns'
import { getOutstandingMaterial } from 'api/transaction/plannerFisikWms'
import { SimpleTabsContext } from 'components/Common/SimpleTabs'

import CreateScrapModal from './modals/create-scrap'
import CreatePoStoDoModal from './modals/create-po-sto-do'

import { isUniqueStrArray as isUnique } from 'utils/array'

import { groupingPayload } from './helper'

const initialState = {
    createScrapModal: false,
    poStoDoModal: false,
    selectedRows: [],
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ROWS':
            return { ...state, selectedRows: [...action.selectedRows] }
        case 'TOGGLE_SCRAP_MODAL':
            return { ...state, createScrapModal: !state.createScrapModal }
        case 'TOGGLE_PO_STO_DO_MODAL':
            return { ...state, poStoDoModal: !state.poStoDoModal }
        default:
            return state
    }
}

function ListOutstandingMaterial() {
    const [state, dispatch] = useReducer(stateReducer, initialState)
    const { setButtonsHeader } = useContext(SimpleTabsContext)

    const onChangeSelectedRows = (rowKeys, rows) => {
        console.log('rowKeys', rowKeys)
        console.log('rows xxx', rows)
        dispatch({ type: 'SET_SELECTED_ROWS', selectedRows: rows })
    }

    const HeaderButtons = useCallback(() => {
        const isValidPayload = () => {
            if (state.selectedRows.length === 0) {
                Modal.error({
                    title: 'Oops',
                    content: 'Please select at least one row',
                })
                return false
            }

            const isNotUniquePlant = !isUnique(state.selectedRows.map((dt) => dt.plantId))
            const isNotUniqueCompany = !isUnique(state.selectedRows.map((dt) => dt.companyId))

            if (isNotUniquePlant || isNotUniqueCompany) {
                Modal.error({
                    title: 'Oops',
                    content: 'Company and plant must be equal',
                })
                return false
            }

            return true
        }

        const onClickCreatePoStoDo = () => {
            if (!isValidPayload()) return
            dispatch({ type: 'TOGGLE_PO_STO_DO_MODAL' })
        }

        const onClickCreateScrap = () => {
            if (!isValidPayload()) return
            dispatch({ type: 'TOGGLE_SCRAP_MODAL' })
        }

        return (
            <ButtonsGroupGrid>
                <Button
                    onClick={onClickCreatePoStoDo}
                    type="primary"
                    className="w-157 h-45 rounded-10 mx-2"
                >
                    Create PO STO & DO
                </Button>
                <Button
                    onClick={onClickCreateScrap}
                    className="outLinedPink w-157 h-45 rounded-10 mx-2"
                >
                    Create Scrap
                </Button>
            </ButtonsGroupGrid>
        )
    }, [state.selectedRows])

    useEffect(() => {
        setButtonsHeader(HeaderButtons)
    }, [HeaderButtons, setButtonsHeader])

    return (
        <>
            <div>
                <RichTable
                    columns={columns()}
                    showButtonsArea={false}
                    withRowSelection={true}
                    withDatePicker={false}
                    onChangeSelectedRows={onChangeSelectedRows}
                    withSearchAndFilterArea={false}
                    getListApi={getOutstandingMaterial}
                    type={TABLE_TYPE.BASIC}
                    scroll={{ x: 1300 }}
                >
                    <CreateScrapModal
                        visible={state.createScrapModal}
                        close={() => dispatch({ type: 'TOGGLE_SCRAP_MODAL' })}
                        payload={groupingPayload(state.selectedRows)}
                    />
                    <CreatePoStoDoModal
                        visible={state.poStoDoModal}
                        close={() => dispatch({ type: 'TOGGLE_PO_STO_DO_MODAL' })}
                        payload={groupingPayload(state.selectedRows)}
                    />
                </RichTable>
            </div>
        </>
    )
}

export default ListOutstandingMaterial
