import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewCheckingArmada } from 'api/master/checkingArmada'
import { auth } from 'auth'

function AddCheckingArmadaModal({ close, visible }) {
    const user = auth.getUserInfo()
    const modifiedPayload = {
        createBy: user?.userName || 'BCO_MJK2', // delete me! ["BCO_MJK2"]
    }

    return (
        <ModalForm
            title="Add Checking Armada"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={createNewCheckingArmada}
            successMsg={(values) => `New Checking Armada was created`}
            close={close}
            visible={visible}
        />
    )
}

export default AddCheckingArmadaModal
