import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getProdShiftList = async (params) => {
    const response = await call(METHODS.GET, '/dashboard/production_shift', params)
    return response.data
}

export const createNewProdShift = async (data) => {
    const url = `/dashboard/production_shift/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updateProdShift = async (data) => {
    const url = `/dashboard/production_shift/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deleteProdShift = async (data) => {
    const url = `/dashboard/production_shift/delete`
    const response = await call(METHODS.DELETE, url, data)
    return response.data
}
