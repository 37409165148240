import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Row, Col } from 'antd'
import Card from 'components/Common/Card'
import { getOutboundSummary } from 'api/dashboard/outbound'
import { StyledNumber, TitleWithTooltip } from '../_sharedComponents'

const FORMAT_DATE = 'YYYY-MM-DD'
function SummarySection({ dcLocation }) {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const reqBody = {
                start_date: moment().format(FORMAT_DATE),
                end_date: moment().format(FORMAT_DATE),
                dcLocationId: [dcLocation?.value],
            }
            try {
                setLoading(true)
                const res = await getOutboundSummary(reqBody)
                console.log('res', res.data)
                setData(res.data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.error(error)
            }
        }
        fetchData()
    }, [dcLocation])

    console.log('data', data)

    return (
        <Row gutter={20}>
            <Col span={6}>
                <Card loading={loading} className="h-33">
                    <TitleWithTooltip tooltip="Checkin">Checkin</TitleWithTooltip>
                    <StyledNumber data={[{ number: data?.checkIn, text: '' }]} />
                </Card>
            </Col>
            <Col span={6}>
                <Card loading={loading} className="h-33">
                    <TitleWithTooltip tooltip="Planner">Planner</TitleWithTooltip>
                    <StyledNumber
                        data={[
                            { number: data?.planner?.assigned, text: 'Created', color: '#01A862' },
                            { number: data?.planner?.created, text: 'Assigned', color: '#FFB400' },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={12}>
                <Card loading={loading} className="h-33">
                    <TitleWithTooltip tooltip="Loading Process">Loading Process</TitleWithTooltip>
                    <StyledNumber
                        data={[
                            { number: data?.loadingProcess?.assignTeam, text: 'Assigned' },
                            { number: data?.loadingProcess?.loading, text: 'Loading Process' },
                            { number: data?.loadingProcess?.hold, text: 'Hold' },
                            { number: data?.loadingProcess?.shipmentStart, text: 'Moved' },
                            { number: data?.loadingProcess?.finish, text: 'Finished' },
                            { number: data?.loadingProcess?.shipmentCompletion, text: 'Check Out' },
                        ]}
                    />
                </Card>
            </Col>
        </Row>
    )
}

export default SummarySection
