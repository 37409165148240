import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { updatePlantSectorSloc } from 'api/master/plantSectorSloc'

function UpdatePlantSectorSlocModal({ payload, close, visible }) {
    const modifiedPayload = {
        plantId: payload.plantId,
        slocId: payload.slocId,
        sectorId: payload.sectorId,
        storlocId: payload.storlocId,
        description: payload.description,
    }
    return (
        <ModalForm
            title="Edit PlantSectorSloc"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={updatePlantSectorSloc}
            successMsg={(values) => `PlantSectorSloc ${values.storlocId} was updated`}
            close={close}
            visible={visible}
        />
    )
}

export default UpdatePlantSectorSlocModal
