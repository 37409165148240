import { Row, Col } from 'antd'
import { DeleteIcon } from 'assets'

export const assignMaterialColumns = (onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'BIN',
        dataIndex: 'binId',
        key: 'binId',
    },
    {
        title: 'Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
]
