import React, { useContext, useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import BarChart from 'components/Common/Chart/BarChart'

import { getDashboardDurationDays } from 'api/dashboard/outbound'

// Context
import { CardDatePickerContext } from 'components/Common/CardDatePicker'
import { DashboardOutboundContext } from '../../index'

import { options } from './options'

function ChartLoadingProcessDuration() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const { dateRange } = useContext(CardDatePickerContext)
    const { dcLocation } = useContext(DashboardOutboundContext)

    // Data and Labels
    const labels = useMemo(() => data.map((dt) => dt.label), [data])
    const datasets = [
        {
            label: 'Series 1',
            data: data.map((dt) => dt.value),
        },
    ]

    useEffect(() => {
        if (!dcLocation) return
        const { startDate, endDate } = dateRange

        const reqBody = {
            from: moment(startDate).format('DD-MM-YYYY'),
            to: moment(endDate).format('DD-MM-YYYY'),
            dcLocationId: [dcLocation?.value],
        }
        const fetchData = async () => {
            setLoading(true)
            try {
                const res = await getDashboardDurationDays(reqBody)
                const mappedData = res.data.map((i) => ({
                    label: i.date,
                    value: parseFloat(i.duration).toFixed(2),
                }))

                setData(mappedData)
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }

        fetchData()
    }, [dateRange, dcLocation])

    return (
        <BarChart loading={loading} options={options()} datasets={datasets} labels={[...labels]} />
    )
}

export default ChartLoadingProcessDuration
