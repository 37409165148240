import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'
import { API_URL_PLANNER } from 'config/env'

const options = {
    baseURL: API_URL_PLANNER,
}

export const getOutboundSummary = async (params) => {
    const response = await call(METHODS.GET, '/dashboard-outbound', params, options)
    return response.data
}

export const getDashboardDurationDays = async (params) => {
    const response = await call(METHODS.GET, '/dashboard-duration-days', params, options)
    return response.data
}

export const getProductivitySpv = async (params) => {
    const response = await call(METHODS.GET, '/dashboard-productivity-spv', params, options)
    return response.data
}

export const getDashboardCheckinFinish = async (params) => {
    const response = await call(METHODS.GET, '/dashboard-checkin-finish', params, options)
    return response.data
}

export const getDurationLoadSpv = async (params) => {
    const response = await call(METHODS.GET, '/dashboard-duration-load-spv', params, options)
    return response.data
}

export const getProductivitySpvAll = async (params) => {
    const response = await call(METHODS.GET, '/dashboard-productivity-spv-all', params, options)
    return response.data
}

export const getDurationSpv = async (params) => {
    const response = await call(METHODS.GET, '/dashboard-duration-spv', params, options)
    return response.data
}
