import React, { useEffect, useState } from 'react'
import { getGenerateDataPerPage } from 'utils/misc'

const QR_PER_PAGE = 20
function QrCodePrint({ data }) {
    const [dataPerPage, setDataPerPage] = useState([])

    useEffect(() => {
        const newData = getGenerateDataPerPage([...data], QR_PER_PAGE) // 20 QR List per page
        setDataPerPage(newData)
    }, [data])

    return (
        <div style={{ fontFamily: 'sans-serif', color: 'black' }}>
            {dataPerPage.map((QrList) => (
                <div style={{ pageBreakAfter: 'always' }}>
                    <p
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            textAlign: 'center',
                            margin: 30,
                        }}
                    >
                        PALLET LIST - QR CODE
                    </p>
                    <div
                        style={{
                            boxSizing: 'border-box',
                            display: 'grid',
                            gridTemplateColumns: `repeat(auto-fit, 42mm)`,
                            placeContent: 'start',
                        }}
                    >
                        {QrList.map((dt) => (
                            <div
                                className="cont"
                                key={dt.id}
                                style={{
                                    boxSizing: 'border-box',
                                    width: '40mm',
                                    height: '40mm',
                                    border: '2px solid black',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    margin: '1mm 0',
                                    pageBreakBefore: 'auto',
                                    background: '#fff',
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: 'center',
                                        left: '50%',
                                        position: 'absolute',
                                        transform: 'translateX(-50%)',
                                        top: 5,
                                        zIndex: 100,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '40mm',
                                            justifyContent: 'space-between',
                                            fontSize: 10,
                                        }}
                                    >
                                        <div style={{ marginLeft: 10 }}>Pallet</div>
                                        <div style={{ marginRight: 10 }}>{dt.code}</div>
                                    </div>
                                </div>
                                {/* <div style={{ width:'90%'}}> */}
                                <img
                                    alt={dt.id}
                                    src={`data:image/svg+xml;base64,${dt.qr}`}
                                    style={{
                                        boxSizing: 'border-box',
                                        maxWidth: '70%',
                                        height: 'auto',
                                        position: 'absolute',
                                        borderStyle: 'none',
                                        background: '#fff',
                                        top: 22,
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                    }}
                                />
                                {/* </div> */}
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        bottom: 2,
                                        fontSize: 12,
                                    }}
                                >
                                    {dt.id}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default QrCodePrint
