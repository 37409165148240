import React, { useReducer, useEffect } from 'react'
import { Table, Button } from 'antd'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import AddPalletModal from '../add-pallet'

import { columns } from './columns'
import { generateItemsByPallets } from './helper'

const initialState = {
    data: [],
    selectedRowKeys: [],
    selectedRows: [],
    confirmationSaveModal: false,
    addPalletModal: false,
    rowPayload: null,
    isDisplayModePallet: false,
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_CONFIRMATION_SAVE':
            return { ...state, confirmationSaveModal: !state.confirmationSaveModal }
        case 'TOGGLE_ADD_ITEM_BY_PALLET':
            return {
                ...state,
                addPalletModal: !state.addPalletModal,
                isDisplayModePallet: false,
            }
        case 'TOGGLE_EDIT_ITEM':
            return {
                ...state,
                addPalletModal: !state.addPalletModal,
                rowPayload: action.rowPayload,
            }
        case 'TOGGLE_DISPLAY_ITEM':
            return {
                ...state,
                addPalletModal: !state.addPalletModal,
                rowPayload: action.rowPayload,
                isDisplayModePallet: !state.isDisplayModePallet,
            }
        case 'RESET_ROW_PAYLOAD':
            return {
                ...state,
                rowPayload: null,
            }
        case 'SET_EDIT_ITEM':
            return {
                ...state,
                data: state.data.map((el, index) => {
                    const itemIndex = state.data.findIndex((p) => p.item === action.item.item)
                    if (index === itemIndex) return action.item
                    return el
                }),
            }
        case 'DELETE_ITEM':
            return {
                ...state,
                data: state.data.filter((el) => el.key !== action.item.key),
            }
        case 'ADD_DATA_BY_PALLET':
            const existingItems = state.data
            const newItems = generateItemsByPallets(action.pallets, existingItems)
            return { ...state, data: newItems }
        case 'ADD_INITIAL_ITEMS':
            return {
                ...state,
                data: [...action.initialItems],
            }
        case 'RESET_DATA':
            return {
                ...state,
                data: [],
            }
        case 'SET_SELECTED_ROW_KEYS':
            return {
                ...state,
                selectedRowKeys: [...action.selectedRowKeys],
                selectedRows: [...action.selectedRows],
            }
        default:
            return state
    }
}

const DataTable = ({ setDataTable, isOnDisplayMode, initialDataTable = [], loading }) => {
    const [state, dispatch] = useReducer(stateReducer, initialState)

    const rowSelection = () => ({
        onChange: (selectedRowKeys, selectedRows) => {
            dispatch({ type: 'SET_SELECTED_ROW_KEYS', selectedRowKeys, selectedRows })
        },
        selectedRowKeys: [...state.selectedRowKeys],
    })

    const clearRowSelection = () => {
        dispatch({ type: 'SET_SELECTED_ROW_KEYS', selectedRowKeys: [], selectedRows: [] })
    }

    const onClickEditItem = (row) => {
        dispatch({ type: 'TOGGLE_EDIT_ITEM', rowPayload: row })
    }

    const onClickDisplayItem = (row) => {
        dispatch({ type: 'TOGGLE_DISPLAY_ITEM', rowPayload: row })
    }

    const setItemsByPallets = (newIncomingPallets = []) => {
        dispatch({ type: 'ADD_DATA_BY_PALLET', pallets: newIncomingPallets })
        clearRowSelection()
    }

    const onClickAddItem = () => {
        dispatch({ type: 'TOGGLE_ADD_ITEM_BY_PALLET' })
        dispatch({ type: 'RESET_ROW_PAYLOAD' })
    }

    const doEditItem = (editedItem) => {
        dispatch({ type: 'SET_EDIT_ITEM', item: editedItem })
        clearRowSelection()
        return { success: true }
    }

    useEffect(() => {
        setDataTable(state.data)
    }, [setDataTable, state.data])

    useEffect(() => {
        if (initialDataTable.length === 0) return dispatch({ type: 'RESET_DATA' })
        dispatch({ type: 'ADD_INITIAL_ITEMS', initialItems: [...initialDataTable] })
    }, [initialDataTable])

    return (
        <>
            {!isOnDisplayMode && (
                <ButtonsGroupGrid align="left" className="mb-3">
                    <Button
                        onClick={onClickAddItem}
                        type="primary"
                        className="w-157 h-45 rounded-10 mx-2"
                        loading={loading}
                    >
                        Add Pallet
                    </Button>
                </ButtonsGroupGrid>
            )}
            <Table
                rowKey="item"
                loading={loading}
                dataSource={state.data}
                columns={columns(onClickEditItem, onClickDisplayItem, isOnDisplayMode)}
                rowClassName={(_, index) =>
                    `customizedPaddingTd customizedSmallFont ${
                        index % 2 === 0 ? 'bg-nabati-lightGray' : ''
                    }`
                }
                pagination={false}
                rowSelection={isOnDisplayMode ? false : rowSelection()}
            />
            <AddPalletModal
                doEditItem={doEditItem}
                setItemsByPallets={setItemsByPallets}
                payload={state.rowPayload}
                isEditMode={!!state.rowPayload}
                isDisplayModePallet={state.isDisplayModePallet}
                visible={state.addPalletModal}
                close={() => dispatch({ type: 'TOGGLE_ADD_ITEM_BY_PALLET' })}
            />
            {/* <EditPalletModal visible={false} /> */}
        </>
    )
}

export default DataTable
