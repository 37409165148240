import React from 'react'
import RichTable from 'components/Common/RichTable'
import { getVendorList } from 'api/master/vendor'

import { columns } from './columns'

const Country = () => {
    return (
        <>
            <RichTable
                columns={columns()}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                getListApi={getVendorList}
            />
        </>
    )
}

export default Country
