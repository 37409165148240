import React from 'react'
import RichTable, { TABLE_TYPE } from 'components/Common/RichTable'
import { columns } from './columns'

import { getPalletInventory } from 'api/transaction/reportInventory'

function PalletStock() {
    return (
        <div>
            <RichTable
                columns={columns()}
                showButtonsArea={false}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                withDatePicker={false}
                withHeaderTotal={true}
                getListApi={getPalletInventory}
                // type={TABLE_TYPE.BASIC}
                scroll={{ x: 1300 }}
            />
        </div>
    )
}

export default PalletStock
