import React from 'react'
import SimpleTabs from 'components/Common/SimpleTabs'

// tabs
import PalletStock from './tabs/pallet-stock'
import PalletBooking from './tabs/pallet-booking'
import MassChangeStatus from './tabs/mass-change-status'

function PlannerReportInventory() {
    return (
        <>
            <SimpleTabs withSearchAndFilterArea>
                {SimpleTabs.TabPane({
                    children: <PalletStock />,
                    tabTitle: 'Pallet Stock',
                    key: 1,
                })}
                {SimpleTabs.TabPane({
                    children: <PalletBooking />,
                    tabTitle: 'Pallet Booking',
                    key: 2,
                })}
                {SimpleTabs.TabPane({
                    children: <MassChangeStatus />,
                    tabTitle: 'Mass Change Status',
                    key: 3,
                })}
            </SimpleTabs>
        </>
    )
}

export default PlannerReportInventory
