import React from 'react'
import SelectDebounceFetch from './index'
import { getPrioritybinList } from 'api/master/priorityBin'

function SelectBin({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Priority BIN"
            fetchOptions={async (searchTerm) => {
                const res = await getPrioritybinList({ filter: searchTerm, total: 10 })
                const list = res?.result?.data.map((priorityBin) => ({
                    label: priorityBin.priorityId,
                    value: priorityBin.priorityId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectBin
