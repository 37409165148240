import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getVendorList = async (params) => {
    const response = await call(METHODS.GET, '/master/vendor', params)
    return response.data
}

export const getVendorFromSap = async (params) => {
    const response = await call(METHODS.GET, '/master/vendor/getsap', params)
    return response.data
}
