import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import { columns } from './columns'

import AddPriorityByDeficitModal from './modals/add-priority-by-deficit-stock'
import AddPriorityByDeficitCheckInModal from './modals/add-priority-by-deficit-stock-check-in'

import { getSetPriorityList, updateStatusPlannerPriority } from 'api/transaction/plannerSetPriority'

import useQueryRefresh from 'customHooks/useQueryRefresh'

const initModalState = { visible: false, payload: null }

function PlannerSetPriority() {
    const [addPriorityByDeficitStock, setAddPriorityModal] = useState(initModalState)
    const [addPriorityByDeficitCheckIn, setAddPriorityCheckInModal] = useState(initModalState)
    const [selectedRows, setSelectedRows] = useState([])

    const { doRefresh } = useQueryRefresh()

    const handleAddPriorityByDeficitStock = () => {
        setAddPriorityModal({ visible: true, payload: null })
    }

    const handleAddPriorityByDeficitStockCheckIn = () => {
        setAddPriorityCheckInModal({ visible: true, payload: null })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const onClickSubmit = () => {
        if (selectedRows[0].status.toLowerCase() === 'ok') {
            return Modal.error({
                title: 'Oops...',
                content: `${selectedRows[0].transactionId} already submitted`,
            })
        }
        const reqBody = {
            transactionId: selectedRows[0].transactionId,
            status: 'OK',
        }

        updateStatusPlannerPriority(reqBody)
        doRefresh()
    }

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={handleAddPriorityByDeficitStock}
                className="outLinedBlue w-157 h-45 rounded-10 mx-2"
            >
                By Deficit Stock
            </Button>
            <Button
                onClick={handleAddPriorityByDeficitStockCheckIn}
                className="outLinedBlue min-w-min h-45 rounded-10 mx-2"
            >
                By Deficit Stock-Check In
            </Button>
            <Button disabled onClick={() => {}} className="outLinedBlue w-157 h-45 rounded-10 mx-2">
                DO vs Kubkasi
            </Button>
            <Button
                onClick={onClickSubmit}
                type="primary"
                className="w-157 h-45 rounded-10 mx-2"
                disabled={selectedRows.length !== 1}
            >
                Submit
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                columns={columns()}
                showButtonsArea={true}
                showSearch={true}
                withRowSelection={true}
                onChangeSelectedRows={onChangeSelectedRows}
                withSearchAndFilterArea={true}
                ButtonsAreaComponent={buttons}
                getListApi={getSetPriorityList}
                withDatePicker={false}
                type="rich"
                scroll={{ x: 1300 }}
            />
            <AddPriorityByDeficitModal
                visible={addPriorityByDeficitStock.visible}
                close={() => setAddPriorityModal({ visible: false, payload: null })}
            />
            <AddPriorityByDeficitCheckInModal
                visible={addPriorityByDeficitCheckIn.visible}
                close={() => setAddPriorityCheckInModal({ visible: false, payload: null })}
            />
        </>
    )
}

export default PlannerSetPriority
