import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { updateDcLocation } from 'api/master/dcLocation'
import { auth } from 'auth'

function UpdateDcLocationModal({ payload, close, visible }) {
    const user = auth.getUserInfo()
    const modifiedPayload = {
        active: payload.active,
        dcLocationId: payload.dcLocationId,
        name: payload.name,
        changeBy: user?.userName || 'BCO_MJK2', // delete me! ["BCO_MJK2"]
    }

    return (
        <ModalForm
            title="Edit Dc Location"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={updateDcLocation}
            successMsg={(values) => `Dc Location ${values.dcLocationId} was updated`}
            close={close}
            visible={visible}
        />
    )
}

export default UpdateDcLocationModal
