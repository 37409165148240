import React, { useRef, useState, useEffect } from 'react'

import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import useSearchParams from 'customHooks/useSearchParams'

function SearchQueryInput({
    className = '',
    style = {},
    suffix = <SearchOutlined />,
    allowClear = true,
    ...props
}) {
    const { currentQuery, setSearchParams } = useSearchParams()
    const [searchInput, setSearchInput] = useState(currentQuery?.filter || '')

    const onSearchDebounce = useRef(
        debounce((newQuery) => {
            setSearchParams(newQuery)
        }, 500)
    ).current

    const onChangeSearch = async (event) => {
        const filter = event.target.value
        setSearchInput(filter)
        onSearchDebounce({ ...currentQuery, filter, page: 1 })
    }

    useEffect(() => {
        setSearchInput(currentQuery.filter)
    }, [currentQuery.filter])

    return (
        <Input
            className={`rounded-10 ${className}`}
            style={style}
            size="large"
            placeholder="Search"
            suffix={suffix}
            value={searchInput}
            onChange={onChangeSearch}
            allowClear={allowClear}
            {...props}
        />
    )
}

export default SearchQueryInput
