import { sortArrayByKey } from 'utils/array'

const groupingPalletByBatch = (palletInput) => {
    const sortedPallet = sortArrayByKey(palletInput, 'batch')
    const allBatch = sortedPallet.map((pallet) => pallet.batch)
    const uniqueBatch = [...new Set(allBatch)]
    return uniqueBatch.map((batch) => {
        const pallets = []
        sortedPallet.forEach((item) => {
            if (batch === item.batch) pallets.push(item)
        })
        return { [batch]: [...pallets] }
    })
}

const separateItemByPalletBatch = (item) => {
    const palletByBatch = groupingPalletByBatch(item.pallets)
    const newItems = palletByBatch.map((pallets) => {
        return {
            ...item,
            pallets: Object.values(pallets)[0],
        }
    })
    return newItems
}

export const regroupingItemsByPalletBatch = (items) => {
    const newItems = items.map((item) => separateItemByPalletBatch(item))
    const desiredItems = []
    newItems.forEach((item) => item.forEach((i) => desiredItems.push(i)))
    return desiredItems
}

export const revampReservationImage = ({ imageId, data, name }) => {
    const imgType = name.split('.')[name.split('.').length - 1].toLowerCase() // png or jpg
    const newData = `data:image/${imgType};base64,${data}`
    return { imageId, data: data ? newData : data, name }
}

export const revampReservationFile = ({ imageId, data, name }) => {
    const fileType = name.split('.')[name.split('.').length - 1].toLowerCase() // png or jpg or pdf
    const newData = `data:${
        fileType === 'pdf' ? 'application' : 'image'
    }/${fileType};base64,${data}`
    return { imageId, data: data ? newData : data, name }
}
