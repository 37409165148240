const getPossibleGroup = (rows) => {
    const uniquePossibleGroup = []
    rows.forEach((row) => {
        const i = uniquePossibleGroup.findIndex(
            (x) =>
                x.materialId === row.materialId && x.slocId === row.slocId && x.batch === row.batch
        )
        if (i <= -1)
            return uniquePossibleGroup.push({
                ...row,
                referenceId: [row.transcactionId],
                pallets: [row],
            })

        const newReferenceId = [...uniquePossibleGroup[i].referenceId, row.transcactionId]
        const newPallets = [...uniquePossibleGroup[i].pallets, row]

        uniquePossibleGroup[i] = {
            ...uniquePossibleGroup[i],
            qty: uniquePossibleGroup[i].qty + row.qty,
            referenceId: newReferenceId,
            pallets: newPallets,
        }
    })
    return uniquePossibleGroup.map((row, idx) => {
        return {
            ...row,
            groupIndex: idx,
        }
    })
}

export const groupingPayload = (rows) => {
    if (!rows) return
    const allGroup = getPossibleGroup(rows)
    const payload = {
        ...rows[0],
        items: allGroup.map((row) => {
            return {
                referenceId: row.referenceId.join(','),
                materialId: rows[row.groupIndex].materialId,
                description: rows[row.groupIndex].description,
                batch: rows[row.groupIndex].batch,
                qty: row.qty,
                uom: rows[row.groupIndex].uom,
                plant: rows[row.groupIndex].plantId,
                slocId: rows[row.groupIndex].slocId,
                pallets: row.pallets.map((pallet) => ({
                    palletId: pallet.palletId,
                    palletCode: pallet.palletCode,
                    qty: pallet.qty,
                    overQty: pallet.overQty || 0,
                    batch: pallet.batch,
                    referenceId: pallet.transcactionId,
                    plantId: pallet.plantId,
                })),
            }
        }),
    }

    return payload
}
