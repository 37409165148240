import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { updateHoldReason } from 'api/master/holdReason'
import { auth } from 'auth'

function UpdateHoldReasonModal({ payload, close, visible }) {
    const user = auth.getUserInfo()

    const modifiedPayload = {
        description: payload.description,
        reasonId: payload.reasonId,
        changeBy: user?.userName || 'BCO_MJK2', // delete me! ["BCO_MJK2"]
    }
    return (
        <ModalForm
            title="Edit Hold Reason"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={updateHoldReason}
            successMsg={(values) => `Hold Reason was updated`}
            close={close}
            visible={visible}
        />
    )
}

export default UpdateHoldReasonModal
