import React from 'react'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

function ConfirmationDeleteItem({ payload, bulkDeleteItems, visible, ...props }) {
    return (
        <ConfirmationModal
            visible={visible}
            title="Confirm Delete"
            content="Are you sure to delete item ?"
            successTitleMsg="Delete success!"
            onOk={() => bulkDeleteItems(payload)}
            centered={false}
            okText="Delete"
            {...props}
        />
    )
}

export default ConfirmationDeleteItem
