import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'

export const columns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'ID',
        dataIndex: 'menuId',
        key: 'menuId',
        align: 'center',
    },
    {
        title: 'Application',
        dataIndex: 'menuType',
        key: 'menuType',
        // align: 'center',
        // render: () => <>???</>,
    },
    {
        title: 'Menu',
        dataIndex: 'description',
        key: 'description',
    },
]
