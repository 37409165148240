import React, { useContext, useEffect, useCallback, useReducer } from 'react'
import { Button, Modal } from 'antd'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import RichTable, { TABLE_TYPE } from 'components/Common/RichTable'
import { columns } from './columns'
import { getOutstandingMaterialUngroup } from 'api/transaction/plannerFisikWms'
import { SimpleTabsContext } from 'components/Common/SimpleTabs'

import CreateMoveToBin from '../list-no-pallet/modals/create-move-to-bin'

import { isUniqueStrArray as isUnique } from 'utils/array'

import { groupingPayload } from './helper'

const initialState = {
    createScrapModal: false,
    poStoDoModal: false,
    selectedRows: [],
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ROWS':
            return { ...state, selectedRows: [...action.selectedRows] }
        case 'TOGGLE_SCRAP_MODAL':
            return { ...state, createScrapModal: !state.createScrapModal }
        case 'TOGGLE_MOVE_TO_BIN_MODAL':
            return { ...state, createMoveToBinModal: !state.createMoveToBinModal }
        case 'TOGGLE_PO_STO_DO_MODAL':
            return { ...state, poStoDoModal: !state.poStoDoModal }
        default:
            return state
    }
}

function ListOutstandingNoPallet() {
    const [state, dispatch] = useReducer(stateReducer, initialState)
    const { setButtonsHeader } = useContext(SimpleTabsContext)

    const onChangeSelectedRows = (rowKeys, rows) => {
        dispatch({ type: 'SET_SELECTED_ROWS', selectedRows: rows })
    }

    const HeaderButtons = useCallback(() => {
        const isValidPayload = () => {
            if (state.selectedRows.length === 0) {
                Modal.error({
                    title: 'Oops',
                    content: 'Please select at least one row',
                })
                return false
            }

            const isNotUniquePlant = !isUnique(state.selectedRows.map((dt) => dt.plantId))
            const isNotUniqueCompany = !isUnique(state.selectedRows.map((dt) => dt.companyId))

            if (isNotUniquePlant || isNotUniqueCompany) {
                Modal.error({
                    title: 'Oops',
                    content: 'Company and plant must be equal',
                })
                return false
            }

            return true
        }

        const onClickCreateMoveToBin = () => {
            if (!isValidPayload()) return
            dispatch({ type: 'TOGGLE_MOVE_TO_BIN_MODAL' })
        }

        return (
            <ButtonsGroupGrid>
                <Button
                    onClick={onClickCreateMoveToBin}
                    type="primary"
                    className="w-157 h-45 rounded-10 mx-2"
                >
                    Move To Bin
                </Button>
                
            </ButtonsGroupGrid>
        )
    }, [state.selectedRows])

    useEffect(() => {
        setButtonsHeader(HeaderButtons)
    }, [HeaderButtons, setButtonsHeader])

    return (
        <>
            <div>
                <RichTable
                    columns={columns()}
                    showButtonsArea={false}
                    withRowSelection={true}
                    withDatePicker={false}
                    onChangeSelectedRows={onChangeSelectedRows}
                    withSearchAndFilterArea={false}
                    getListApi={getOutstandingMaterialUngroup }
                    type={TABLE_TYPE.BASIC}
                    scroll={{ x: 1300 }}
                >
                    <CreateMoveToBin
                        visible={state.createMoveToBinModal}
                        close={() => dispatch({ type: 'TOGGLE_MOVE_TO_BIN_MODAL' })}
                        payload={state.selectedRows}
                    />
                   
                </RichTable>
            </div>
        </>
    )
}

export default ListOutstandingNoPallet
