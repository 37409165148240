// import { Row, Col } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Movement Type',
        dataIndex: 'movementType',
        key: 'movementType',
        width: 200,
    },
    {
        title: 'Reason Movement',
        dataIndex: 'reasonId',
        key: 'reasonId',
        width: 200,
    },
    {
        title: 'System ID',
        dataIndex: 'systemId',
        key: 'systemId',
        width: 200,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
