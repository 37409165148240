import React from 'react'
import { Row, Col } from 'antd'
import { EyeIcon, EditIcon, PalletIc } from 'assets'

export const columns = (onClickEditItem, onClickDisplayItem, isOnDisplayMode) => [
    {
        title: <p style={{ fontSize: 13 }}>Actions</p>,
        width: 150,
        render: (_, record) => (
            <Row gutter={8}>
                {!isOnDisplayMode && (
                    <Col className={'cursor-pointer'} onClick={() => onClickEditItem(record)}>
                        <EditIcon />
                    </Col>
                )}
                <Col className={'cursor-pointer'} onClick={() => onClickDisplayItem(record)}>
                    <EyeIcon />
                </Col>
                {record.pallets?.length > 0 && (
                    <Col
                        className="cursor-pointer text-nabati-red"
                        onClick={() => onClickDisplayItem(record)}
                    >
                        <PalletIc />
                    </Col>
                )}
            </Row>
        ),
    },
    {
        title: <p style={{ fontSize: 13 }}>No Material</p>,
        dataIndex: 'material',
        key: 'material',
    },
    {
        title: <p style={{ fontSize: 13 }}>Material Description</p>,
        dataIndex: 'materialDescription',
        key: 'materialDescription',
    },
    {
        title: <p style={{ fontSize: 13 }}>Qty</p>,
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: <p style={{ fontSize: 13 }}>UOM</p>,
        dataIndex: 'poUnit',
        key: 'poUnit',
    },
    {
        title: <p style={{ fontSize: 13 }}>Plant</p>,
        dataIndex: 'plant',
        key: 'plant',
    },
    {
        title: <p style={{ fontSize: 13 }}>Plant Description</p>,
        dataIndex: 'plantDescription',
        key: 'plantDescription',
    },
    {
        title: <p style={{ fontSize: 13 }}>Sloc</p>,
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: <p style={{ fontSize: 13 }}>Sloc Description</p>,
        dataIndex: 'slocDescription',
        key: 'slocDescription',
    },
    {
        title: <p style={{ fontSize: 13 }}>Route</p>,
        dataIndex: 'route',
        key: 'route',
    },
    {
        title: <p style={{ fontSize: 13 }}>Route Description</p>,
        dataIndex: 'routeDescription',
        key: 'routeDescription',
    },
]
