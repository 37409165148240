import React from 'react'
import SelectDebounceFetch from './index'
import { getGlList } from 'api/master/gl'

function SelectGl({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Gl"
            fetchOptions={async (searchTerm) => {
                const res = await getGlList({ filter: searchTerm, total: 10 })
                const list = res?.result?.data.map((gl) => ({
                    label: gl.accountId + ' - ' + gl.description,
                    value: gl.accountId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectGl
