import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddEditModal from './modals/add-edit-form'
import QrCodeListModal from './modals/qr-code-list'

import { getPrioritybinList, deletePrioritybin, createNewPrioritybin } from 'api/master/priorityBin'
import { priorityBinColumns } from './columns'
import { QrIc } from 'assets'
import { auth } from 'auth'

function Prioritybin() {
    const [showAddPrioritybinModal, setAddPrioritybinModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [showQrListModal, setShowQrListModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const { doRefresh } = useQueryRefresh()
    const user = auth.getUserInfo()

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = selectedRows.map((row) => ({
                    companyId: row.companyId,
                    plantId: row.plantId,
                    building: row.building,
                    priorityId: row.priorityId,
                    sortNo: row.sortNo,
                }))

                const res = await deletePrioritybin(reqBody)

                if (!res.success) return
                doRefresh()
            },
        })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete All" onClick={handleBulkDelete} />
    )

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddPrioritybinModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
            <Button
                disabled={selectedRows?.length === 0}
                onClick={() => setShowQrListModal(true)}
                className="outLinedBlue flex justify-center items-center w-48 h-45 rounded-10 mx-1"
                size="middle "
            >
                <QrIc />
                <span className="ml-2">View QR Code</span>
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                columns={priorityBinColumns(onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getPrioritybinList}
                type="rich"
            />
            <AddEditModal
                title="Add Priority Bin"
                close={() => setAddPrioritybinModal(false)}
                visible={showAddPrioritybinModal}
                payload={null}
                successMsg="Success add BIN"
                callApi={createNewPrioritybin}
                additionalPayload={{ createBy: user.name }}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
            <QrCodeListModal
                payload={[...selectedRows]}
                visible={showQrListModal}
                close={() => setShowQrListModal(false)}
            />
        </>
    )
}

export default Prioritybin
