import { message } from 'antd'

export function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })
}

export const checkImageValidity = (file, sizeLimit = 1) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
    }

    const isLessThanLimit = file.size / 1024 / 1024 < sizeLimit

    if (!isLessThanLimit) {
        message.error(`Image must smaller than ${sizeLimit}MB!`)
    }
    return [isJpgOrPng && isLessThanLimit]
}

export const checkPdfOrImageValidity = (file, sizeLimit = 2) => {
    const isValidPdfOrImage =
        file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isValidPdfOrImage) {
        message.error('You can only upload JPG/PNG/PDF file!')
    }
    const isLessThanLimit = file.size / 1024 / 1024 < sizeLimit

    if (!isLessThanLimit) {
        message.error(`Image must smaller than ${sizeLimit}MB!`)
    }
    return [isValidPdfOrImage && isLessThanLimit]
}

export const checkTxtValidity = (file, sizeLimit = 1) => {
    const TEXT_PLAIN = 'text/plain'
    const isTxt = file?.type === TEXT_PLAIN

    if (!isTxt) {
        message.error('You can only upload TXT file!')
    }
    const isLessThanLimit = file.size / 1024 / 1024 < sizeLimit

    if (!isLessThanLimit) {
        message.error(`Image must smaller than ${sizeLimit}MB!`)
    }
    return [isTxt && isLessThanLimit]
}

export const checkXlsxValidity = (file, sizeLimit = 1) => {
    const XLSX_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const isXlsx = file?.type === XLSX_TYPE
    if (!isXlsx) {
        message.error('You can only upload Xlsx file!')
    }
    const isLessThanLimit = file.size / 1024 / 1024 < sizeLimit
    if (!isLessThanLimit) {
        message.error(`Image must smaller than ${sizeLimit}MB!`)
    }
    return [isXlsx && isLessThanLimit]
}
