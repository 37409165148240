export * from './common'
export * from './http-error'

export const UOM_CONFIG = [
    {
        name: 'DUS',
        value: 'DUS',
    },
    {
        name: 'KG',
        value: 'KG',
    },
]

export const SET_PRIORITY_COLOR = {
    'Not Fullfil': 'yellow',
    Done: 'green',
    '': 'red',
}
