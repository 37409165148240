import React, { useState, useEffect } from 'react'
import { Form, Modal, Divider, Button, DatePicker, Select, Spin } from 'antd'
// import useQueryRefresh from 'customHooks/useQueryRefresh'
import { getSpvGroupDc, getSpvUsers } from 'api/transaction/plannerDoExecution'
import { getForemanList } from 'api/transaction/result-cycle-count-opname'
import { removeDuplicateObjectInArray } from 'utils/array'

function SetPicAndDate({ close, visible, initialValues, onOk }) {
    const [loading, setLoading] = useState(false)
    const [spvGroupDc, setSpvGroupDc] = useState([])
    const [spvList, setSpvList] = useState([])
    const [foremanList, setForemanList] = useState([])

    const [form] = Form.useForm()
    const { submit } = form

    // const { doRefresh } = useQueryRefresh()

    const fetchForemanList = async () => {
        const dcLocation = form.getFieldValue('groupDc')
        const spvId = form.getFieldValue('spv')
        if (!dcLocation || !spvId) return
        form.setFieldsValue({ foremanId: null })
        setForemanList([])
        try {
            setLoading(true)
            const res = await getForemanList({ dcLocation, spvId })
            setLoading(false)
            setForemanList(removeDuplicateObjectInArray(res?.result, 'foremanId') || [])
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    const handleSubmit = async (e) => {
        setLoading(true)
        const values = await form.validateFields()
        submit()
        await onOk({ userId: values.foremanId, ...values })
        setLoading(false)
        close()
    }

    useEffect(() => {
        if (!visible) return
        const fetchApi = async () => {
            setLoading(true)
            const SpvList = await getSpvUsers()
            const groupDCList = await getSpvGroupDc()
            setSpvGroupDc(groupDCList?.data)
            setSpvList(SpvList?.data)
            setLoading(false)
        }
        fetchApi()
    }, [visible])
    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>Set PIC & Date</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="fromTo"
                    label="Date & Time"
                    rules={[{ required: true }]}
                >
                    <DatePicker.RangePicker
                        size="large"
                        className="rounded-10 h-50 "
                        style={{ width: '100%' }}
                        onChange={() => {}}
                        showTime
                    />
                </Form.Item>

                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="groupDc"
                    label="Group DC"
                    initialValue={initialValues?.spv || null}
                    rules={[{ required: true }]}
                >
                    <Select
                        loading={loading}
                        placeholder="Select DC"
                        className="rounded-10 h-50"
                        size="large"
                        onChange={() => {
                            setForemanList([])
                            form.setFieldsValue({ foremanId: null, spv: null })
                        }}
                        notFoundContent={loading ? <Spin size="small" /> : undefined}
                    >
                        {spvGroupDc.map((groupDC, idx) => (
                            <Select.Option
                                value={groupDC.groupDcLocationId}
                                key={groupDC.groupDcLocationId + idx}
                            >
                                {groupDC.groupDcLocationId} - {groupDC.description}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.groupDc !== currentValues.groupDc
                    }
                >
                    {({ getFieldValue }) => {
                        const selectedGroupDC = getFieldValue('groupDc')
                        return (
                            <Form.Item
                                className="my-1"
                                style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                                name="spv"
                                label="SPV"
                                initialValue={initialValues?.spv || null}
                                // dependencies={['groupDc']}
                                rules={[{ required: true }]}
                            >
                                <Select
                                    loading={loading}
                                    placeholder="Select SPV"
                                    className="rounded-10 h-50"
                                    size="large"
                                    onChange={fetchForemanList}
                                    notFoundContent={loading ? <Spin size="small" /> : undefined}
                                >
                                    {spvList
                                        .filter((spv) => {
                                            return spv.groupDcLocationId === selectedGroupDC
                                        })
                                        .map((spv, idx) => (
                                            <Select.Option
                                                value={spv.username}
                                                key={spv.username + idx}
                                            >
                                                {spv.username}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        )
                    }}
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.groupDc !== currentValues.groupDc ||
                        prevValues.spv !== currentValues.spv
                    }
                >
                    {({ getFieldValue }) => {
                        return (
                            <Form.Item
                                className="my-1"
                                style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                                name="foremanId"
                                label="Foreman"
                                initialValue={initialValues?.foreman || null}
                                rules={[{ required: true }]}
                            >
                                <Select
                                    loading={loading}
                                    placeholder="Select Foreman"
                                    className="rounded-10 h-50"
                                    notFoundContent={loading ? <Spin size="small" /> : undefined}
                                    size="large"
                                >
                                    {foremanList.map((foreman, idx) => (
                                        <Select.Option
                                            value={foreman.foremanId}
                                            key={foreman.foremanId + idx}
                                        >
                                            {foreman.foremanId}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )
                    }}
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={close}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    // loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default SetPicAndDate
