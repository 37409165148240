import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 150,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'Dc Location',
        dataIndex: 'dcLocation',
        key: 'dcLocation',
        width: 200,
    },
    {
        title: 'Closing Month',
        dataIndex: 'closingMonth',
        key: 'closingMonth',
    },
    {
        title: 'Allow Back Date',
        dataIndex: 'allowBackDate',
        key: 'allowBackDate',
    },
]
