import React, { useContext, useState, useEffect, useMemo } from 'react'
import BarChart from 'components/Common/Chart/BarChart'

import { getMaterialStockData } from 'api/dashboard/wh-inventory'

// Context
import { CardDatePickerContext } from 'components/Common/CardDatePicker'
import { DashboardWhInventoryContext } from '../../index'

import { options } from './options'

function ChartMaterialPosition() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const { dateRange } = useContext(CardDatePickerContext)
    const { dcLocation } = useContext(DashboardWhInventoryContext)

    // Data and Labels
    const labels = useMemo(() => data.map((dt) => dt.label), [data])
    const datasets = [
        {
            label: 'Stock',
            data: data.map((dt) => dt.value),
        },
    ]

    useEffect(() => {
        if (!dcLocation) return
        const { startDate, endDate } = dateRange
        const reqBody = {
            startDate,
            endDate,
            dcLocationId: [dcLocation?.value],
        }
        const fetchData = async () => {
            setLoading(true)
            try {
                const res = await getMaterialStockData(reqBody)
                const materialPositionData = Object.entries(res?.data?.stockPosition.material)
                    .map(([label, value]) => ({ label, value }))
                    .sort((a, b) => b.value - a.value)

                setData(materialPositionData)
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }

        fetchData()
    }, [dateRange, dcLocation])

    return (
        <BarChart loading={loading} options={options()} datasets={datasets} labels={[...labels]} />
    )
}

export default ChartMaterialPosition
