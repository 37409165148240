import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewPalletAssignmentSort } from 'api/master/palletAssignmentSort'
import { auth } from 'auth'

function AddPalletAssignmentSortModal({ close, visible }) {
    const user = auth.getUserInfo()
    const modifiedPayload = {
        // dcLocationId: '1', // Value darimana? // delete me!
        // sortId: '2', // delete me!
        createBy: user?.userName || 'BCO_MJK2', // delete me! ["BCO_MJK2"]
    }

    return (
        <ModalForm
            title="Add Strategy-Pallet Assignment Sort"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={createNewPalletAssignmentSort}
            successMsg={(values) => `New Pallet Assignment Sort ${values.sortId} was created`}
            close={close}
            visible={visible}
        />
    )
}

export default AddPalletAssignmentSortModal
