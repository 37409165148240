import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const palletAssignmentSortColumns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'DC Location ID',
        dataIndex: 'dcLocationId',
        key: 'dcLocationId',
    },
    {
        title: 'Sort',
        dataIndex: 'sortId',
        key: 'sortId',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Characteristic',
        dataIndex: 'characteristic',
        key: 'characteristic',
        render: (id, rec) => <>{`${id} - ${rec.characteristicName}`}</>,
    },
]
