import React from 'react'
import SelectDebounceFetch from './index'
import { getOutboundStatus } from 'api/transaction/plannerDoExecution'

function SelectOutboundStatus({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Outbound Status"
            fetchOptions={async (searchTerm) => {
                const res = await getOutboundStatus({ filter: searchTerm, total: 10 })
                const list = res?.result.map((data) => ({
                    label: data.description,
                    value: data.status,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectOutboundStatus
