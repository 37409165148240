import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

export const formItems = (initialValues) => [
    {
        name: 'dcLocationId',
        label: 'DC Location',
        initialValue: { value: initialValues?.dcLocationId || null },
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.DcLocation />,
    },
    {
        name: 'plantId',
        label: 'Plant',
        initialValue: { value: initialValues?.plantId || null },
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.Plant />,
    },
    {
        name: 'factoryPlant',
        label: 'Factory Plant',
        initialValue: initialValues?.factoryPlant || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.Plant placeholder="Factory Plant" />,
    },
]
