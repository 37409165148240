import React from 'react'
import SelectDebounceFetch from './index'
import {getTransactionStatus } from 'api/report/outbound'

function SelectCheckinoutStatus({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Changhe Bin Status"
            fetchOptions={async (searchTerm) => {
                const res = await getTransactionStatus({ filter: searchTerm, total: 10 })
                const list = res?.result?.map((bin) => ({
                    label: bin.description,
                    value: bin.status,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectCheckinoutStatus
