import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'

import { getAssignPallets } from 'api/transaction/plannerDoExecution'

const columns = [
    {
        title: 'Pallet ID',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Quantity',
        dataIndex: 'qty',
        key: 'qty',
        align: 'center',
    },
    // {
    //     title: 'Available Quantity',
    //     dataIndex: '',
    //     key: '',
    //     align: 'center',
    // },
    {
        title: 'Over Qty',
        dataIndex: 'overQty',
        key: 'overQty',
        align: 'center',
        render: (text, record) => {
            const isOverQuantity = +text > 0
            if (isOverQuantity) return <p className="text-red-600">{text}</p>
            return <p>{text}</p>
        },
    },
    {
        title: 'Actual Qty',
        dataIndex: 'actualQty',
        key: 'actualQty',
        align: 'center'
    },
    {
        title: 'BIN ID',
        dataIndex: 'binId',
        key: 'binId',
        align: 'center'
    },
]

function PlannerDisplay({ payload, ...props }) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!payload?.id) return
        if (!props?.visible) return

        const fetchData = async () => {
            setLoading(true)
            const params = {
                id: payload?.transactionId,
                doItem: payload?.documentItem,
                doNumber: payload?.documentNumber,
            }
            const res = await getAssignPallets(params)
            setData(res.data)
            setLoading(false)
        }

        fetchData()
    }, [payload, props.visible])

    return (
        <SimpleModal title="Display Pallet" width={800} footer={null} closable {...props}>
            <Table
               style={{overflow: 'auto', width: '100%', fontSize: '11px !important'}}
                loading={loading}
                dataSource={data}
                columns={columns}
                rowClassName={(_, index) =>
                    `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                }
                pagination={false}
            />
        </SimpleModal>
    )
}

export default PlannerDisplay
