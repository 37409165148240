import React from 'react'
import { Card } from 'antd'

function RoundedCard({ ...props }) {
    return (
        <Card
            bodyStyle={{ padding: 20 }}
            style={{
                background: '#FFFFFF',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
                borderRadius: 16,
                padding: 0,
            }}
            {...props}
        />
    )
}

export default RoundedCard
