import React from 'react'
import SimpleTabs, { TabPane } from 'components/Common/SimpleTabs'

// tabs
import ListOutstandingMaterial from './tabs/list-outstanding-material'
import ListPoStoDo from './tabs/list-po-sto-do'
import ListScrap from './tabs/list-scrap'
import ListOutstandingNoPallet from './tabs/list-no-pallet'
function PlannerFisikWMS() {
    return (
        <>
            <SimpleTabs withSearchAndFilterArea>
                {TabPane({
                    children: <ListOutstandingMaterial />,
                    tabTitle: 'List Outstanding Material',
                    key: 1,
                })}
                {TabPane({
                    children: <ListPoStoDo />,
                    tabTitle: 'List PO STO-DO',
                    key: 2,
                })}
                {TabPane({
                    children: <ListScrap />,
                    tabTitle: 'List Scrap',
                    key: 3,
                })}
                {TabPane({
                    children: <ListOutstandingNoPallet />,
                    tabTitle: 'List Outstanding No Pallet',
                    key: 4,
                })}
            </SimpleTabs>
        </>
    )
}

export default PlannerFisikWMS
