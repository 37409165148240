import React, { useState, useEffect } from 'react'
import { Form, Input, DatePicker, Select, TimePicker, Button, Modal } from 'antd'

import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
import SelectUomByMaterial from 'components/Common/SelectFetchByMaterial/selectUomByMaterial'
import SelectPriorityBinByPlant from 'components/Common/SelectFetchByPlant/selectPriorityBinByPlant'

export const FORM_TYPE = {
    NO_POL: 'no pol',
    MATERIAL: 'material',
}

function SetPriorityForm({
    handleSave,
    plantForBin, // plant ini digunakan saat filter priorityBin sesuai dengan DC Location
    tabKey,
    noBooking,
    materialId,
    vehicleNo,
    description,
}) {
    const [editMode, setEditMode] = useState(true)
    const [materialState, setMaterial] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        setMaterial(materialId)
    }, [materialId])

    return (
        <Form
            form={form}
            requiredMark={false}
            layout="vertical"
            preserve={false}
            onValuesChange={(changedValues, allValues) => {}}
            onFinish={(values) => {
                const modifiedValues = {
                    noBooking: noBooking || '', // 14/3 Tanya pak Hadi, No Booking dapat darimana. Defisit stock gapunya noBooking
                    noPol: values?.noPol || '',
                    materialId: materialId || '',
                    description: description,
                    requiredQty: +values.requiredQty || 0,
                    uom: values.uom || '',
                    fromDate: values.fromDate.format('YYYY-MM-DD'),
                    fromTime: values.fromTime.format('hh:mm:ss'),
                    toDate: values.toDate.format('YYYY-MM-DD'),
                    toTime: values.toTime.format('hh:mm:ss'),
                    binPriority: values.binPriority.value,
                    type: values.type,
                    plant: values.plant.value,
                }

                handleSave({ index: tabKey, formValue: modifiedValues })
                setEditMode(!editMode)

                if (editMode) Modal.success({ title: 'Data saved!' })
            }}
            onFinishFailed={({ values, errorFields, outOfDate }) => {
                if (errorFields.length > 0) return
                setEditMode(!editMode)
            }}
            style={{
                display: 'grid',
                gridTemplateColumns: `repeat(2, 50%)`,
                gap: 10,
            }}
        >
            <Form.Item
                rules={[{ required: true }]}
                initialValue={`${materialId} - ${description}`}
                name="materialId"
                label="No Material"
                className="my-1"
                style={{
                    gridColumnStart: 1,
                    gridColumnEnd: 3,
                }}
            >
                <Input placeholder="No Material" className="rounded-5" size="large" disabled />
            </Form.Item>

            <Form.Item
                rules={[{ required: true }]}
                initialValue={noBooking}
                name="NoBooking"
                label="No Booking"
                className="my-1"
            >
                <Input
                    placeholder="No Booking"
                    defaultValue={noBooking}
                    className="rounded-5"
                    size="large"
                    disabled={!!noBooking || !editMode}
                />
            </Form.Item>

            <Form.Item
                className="my-1"
                rules={[{ required: true }]}
                initialValues={vehicleNo}
                name="noPol"
                label="No Pol"
            >
                <Input
                    placeholder="No Pol"
                    className="rounded-5"
                    defaultValue={vehicleNo}
                    size="large"
                    disabled={!!vehicleNo || !editMode}
                />
            </Form.Item>

            <Form.Item
                className="my-1"
                rules={[{ required: true }]}
                name="requiredQty"
                label="Required Qty"
            >
                <Input
                    type="number"
                    placeholder="Required Qty"
                    className="rounded-5"
                    size="large"
                    disabled={!editMode}
                />
            </Form.Item>

            <Form.Item className="my-1" rules={[{ required: true }]} name="uom" label="UOM">
                <SelectUomByMaterial materialId={materialState} className="rounded-5" />
            </Form.Item>

            <Form.Item
                className="my-1"
                rules={[{ required: true }]}
                name="fromDate"
                label="From Date"
                disabled={!editMode}
            >
                <DatePicker
                    size="large"
                    className="rounded-5"
                    style={{
                        width: '100%',
                    }}
                    onChange={() => {}}
                    disabled={!editMode}
                />
            </Form.Item>

            <Form.Item
                className="my-1"
                rules={[{ required: true }]}
                name="fromTime"
                label="From Time"
            >
                <TimePicker
                    size="large"
                    className="rounded-5"
                    style={{ width: '100%' }} // onChange={() => {}}
                    disabled={!editMode}
                />
            </Form.Item>

            <Form.Item className="my-1" rules={[{ required: true }]} name="toDate" label="To Date">
                <DatePicker
                    size="large"
                    className="rounded-5"
                    style={{ width: '100%' }} // onChange={() => {}}
                    disabled={!editMode}
                />
            </Form.Item>

            <Form.Item className="my-1" rules={[{ required: true }]} name="toTime" label="To Time">
                <TimePicker
                    size="large"
                    className="rounded-5"
                    style={{ width: '100%' }} // onChange={() => {}}
                    disabled={!editMode}
                />
            </Form.Item>

            <Form.Item
                className="my-1"
                rules={[{ required: true }]}
                name="binPriority"
                label="Priority BIN"
            >
                {/* <SelectPriorityBinByPlant plant={plantForBin} className="rounded-5" /> */}
                <SelectDebounceFetch.PriorityBin
                    withoutLabel
                    placeholder="Priority Bin"
                    className="rounded-5"
                    size="large"
                    // disabled={!editMode}
                />
            </Form.Item>

            <Form.Item className="my-1" rules={[{ required: true }]} name="type" label="Type">
                <Select placeholder="Type" className="rounded-5" size="large" disabled={!editMode}>
                    <Select.Option value="factory">Factory</Select.Option>
                    <Select.Option value="in transit">In Transit</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item className="my-1" rules={[{ required: true }]} name="plant" label="Plant">
                <SelectDebounceFetch.Plant
                    withoutLabel
                    placeholder="Plant"
                    className="rounded-5"
                    size="large"
                    disabled={!editMode}
                />
            </Form.Item>
            <Form.Item
                style={{
                    gridColumnStart: 1,
                    gridColumnEnd: 3,
                    alignSelf: 'right',
                    justifySelf: 'right',
                }}
            >
                <Button type="primary" htmlType="submit">
                    {editMode ? 'Save' : 'Edit'}
                </Button>
            </Form.Item>
        </Form>
    )
}

export default SetPriorityForm
