import React from 'react'

import RichTable from 'components/Common/RichTable'
import { columns } from './columns'

import { getFwdAgentList } from 'api/master/fwdAgent'

function FwdAgent() {
    return <RichTable columns={columns()} showSearch={true} getListApi={getFwdAgentList} />
}

export default FwdAgent
