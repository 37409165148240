import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Row, Col } from 'antd'
import Card from 'components/Common/Card'
import { getWhDashboardSummary } from 'api/dashboard/wh-inventory'
import { StyledNumber, TitleWithTooltip } from '../_sharedComponents'

const FORMAT_DATE = 'YYYY-MM-DD'
function SummarySection({ dcLocation }) {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const reqBody = {
                start_date: moment().format(FORMAT_DATE),
                end_date: moment().format(FORMAT_DATE),
                dcLocationId: [dcLocation?.value],
            }
            try {
                setLoading(true)
                const res = await getWhDashboardSummary(reqBody)
                setData(res.data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.error(error)
            }
        }
        fetchData()
    }, [dcLocation])

    return (
        <Row gutter={20}>
            <Col span={4}>
                <Card loading={loading} className="h-33">
                    <TitleWithTooltip tooltip="Bin Capacity">Bin Capacity</TitleWithTooltip>
                    <StyledNumber data={[{ number: `${data?.binCapacity || 0}%`, text: '' }]} />
                </Card>
            </Col>
            <Col span={4}>
                <Card loading={loading} className="h-33">
                    <TitleWithTooltip tooltip="Pallet Usage">Pallet Usage</TitleWithTooltip>
                    <StyledNumber data={[{ number: `${data?.palletUsage || 0}%`, text: '' }]} />
                </Card>
            </Col>
            <Col span={8}>
                <Card loading={loading} className="h-33">
                    <Row>
                        <Col span={12}>
                            <TitleWithTooltip tooltip="SKU Stock">SKU Stock</TitleWithTooltip>
                            <StyledNumber data={[{ number: data?.skuStock, text: '' }]} />
                        </Col>
                        <Col span={12}>
                            <TitleWithTooltip tooltip="Total CTN">Total CTN</TitleWithTooltip>
                            <StyledNumber
                                data={[
                                    {
                                        number: Number(data?.totalQty),
                                        text: '',
                                        color: '#FFB400',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={8}>
                <Card loading={loading} className="h-33">
                    <Row>
                        <Col span={12}>
                            <TitleWithTooltip tooltip="Count">Count</TitleWithTooltip>
                            <StyledNumber data={[{ number: data?.expiredCount, text: '' }]} />
                        </Col>
                        <Col span={12}>
                            <TitleWithTooltip tooltip="Expired">Expired</TitleWithTooltip>
                            <StyledNumber
                                data={[
                                    { number: Number(data?.expired), text: '', color: '#ED1C24' },
                                ]}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default SummarySection
