import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'

import HeaderDashboard from './header'
import Sidebar from './sidebar'

import { auth } from 'auth'

const { Content } = Layout

function Dashboard() {
    const [showSideMenu, setShowSideMenu] = useState(false)
    const navigate = useNavigate()
    const toggleSideMenu = () => {
        setShowSideMenu(!showSideMenu)
    }

    useEffect(() => {
        const isAuthenticated = auth.isAuthenticated()
        if (!isAuthenticated) navigate('/login')
    }, [navigate])

    return (
        <Layout className="relative h-screen overflow-auto">
            <Sidebar showSideMenu={showSideMenu} />
            <Layout className="relative overflow-auto site-layout">
                <HeaderDashboard toggleSideMenu={toggleSideMenu} />
                <Content className="mx-4">
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    )
}

export default Dashboard
