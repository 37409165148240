import React, { useState } from 'react'
import { Modal, Button } from 'antd'

import useQueryRefresh from 'customHooks/useQueryRefresh'

import { checkXlsxValidity } from 'utils/base64'
import { UploadIc } from 'assets'

import DropFileModal from '../DropFileModal'

function XlsxUploader({ title = 'Upload Xlsx', callApi }) {
    const [visible, setVisible] = useState(false)
    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)
    const { doRefresh } = useQueryRefresh()

    const onUploadProgress = (progressEvent) => {
        const progressing = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        setProgress(progressing)
    }

    const handleFiles = async (files) => {
        const [isValidXlsxFile] = checkXlsxValidity(files[0])
        if (!isValidXlsxFile) return
        let formData = new FormData()
        formData.append('file', files[0])

        try {
            setLoading(true)
            setProgress(15)
            const res = await callApi(formData, onUploadProgress)
            if (!res.success) return setProgress(0)
            setVisible(false)
            setLoading(false)
            Modal.success({
                title: 'Success',
                content: 'File uploaded!',
                onOk: (close) => {
                    doRefresh()
                    close()
                },
            })
        } catch (error) {
            console.error(error)
            setLoading(false)
            setProgress(0)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Button
                onClick={() => setVisible(true)}
                className="outLinedBlue flex justify-center items-center w-48 h-45 rounded-10 mx-1"
                size="middle "
            >
                <UploadIc />
                <span className="ml-2">Upload</span>
            </Button>
            <DropFileModal
                loading={loading}
                visible={visible}
                close={() => {
                    setVisible(false)
                    setProgress(0)
                }}
                handleFiles={handleFiles}
                title={title}
                multiple={false}
                progressPercentage={progress}
            />
        </>
    )
}

export default XlsxUploader
