import React from 'react'
import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'

import SelectCompany from './select-company'
import SelectPlant from './select-plant'
import SelectPlantByDc from './select-plant-by-dc'
import SelectBin from './select-bin'
import SelectGate from './select-gate'
import SelectDcLocation from './select-dc-location'
import SelectSloc from './select-sloc'
import SelectSlocByPlant from './select-sloc-by-plant'
import SelectMaterial from './select-material'
import SelectRoute from './select-route'
import SelectPoType from './select-po-type'
import SelectPurchasingGroup from './select-purchasing-group'
import SelectPurchasingOrg from './select-purchasing-org'
import SelectCharacteristicSort from './select-characteristic-sort'
import SelectPriorityBin from './select-priority-bin'
import SelectDockingId from './select-docking'
import SelectReasonMovement from './select-reason-movement'
import SelectRole from './select-role'
import SelectMenuId from './select-menu-id'
import SelectIpSystem from './select-ip-system'
import SelectGl from './select-gl'
import SelectTransactionStatus from './select-transaction-status'
import SelectPalletStatus from './select-pallet-status'
import SelectPickerPalletStatus from './picker-pallet-status'
import SelectCheckPalletStatus from './check-pallet-status'
import SelectChangeBinStatus from './select-change-bin-status'
import SelectDraftStatus from './isdraft-status'
import SelectScanPalletStatus from './scan-pallet-status'
import SelectTransactionType from './select-transaction-type'
import SelectCheckinoutStatus from './select-checkinout-status'
import SelectOutboundStatus from './select-outbound-status'
import InputSkeleton from 'components/Common/SkeletonLoading/inputSkeleton'

import { removeDuplicateObjectInArray } from 'utils/array'

function DebounceSelect({
    loading,
    fetchOptions,
    debounceTimeout = 500,
    style,
    className,
    size = 'large',
    withStar = false,
    onChange,
    mode,
    id = 'debounceSelect',
    ...props
}) {
    const [fetching, setFetching] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const fetchRef = React.useRef(0)

    const [searchTerm, setSearchTerm] = React.useState('')

    const isMultipleMode = mode === 'multiple'

    const checkIsAllOptionSelected = (value1) => {
        if (isMultipleMode) return value1?.findIndex((val) => val.value === '*') > -1
        return value1?.value === '*'
    }

    const doDisableOptions = () => {
        const disabledOptions = options.map((opt) => ({
            value: opt.value,
            label: opt.label,
            disabled: opt.value === '*' ? false : true,
        }))
        setOptions(disabledOptions)
    }

    const doEnableOptions = () => {
        const disabledOptions = options.map((opt) => ({
            value: opt.value,
            label: opt.label,
            disabled: false,
        }))
        setOptions(disabledOptions)
    }

    const handleOnchange = (valueObjWithKey, valueObj) => {
        // Callback onchange
        onChange(valueObjWithKey, valueObj)
        const isAllValuesSelected = checkIsAllOptionSelected(valueObjWithKey)
        if (isAllValuesSelected) doDisableOptions()
        if (!isAllValuesSelected) doEnableOptions()
    }

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            setSearchTerm(value || '')
            fetchRef.current += 1
            const fetchId = fetchRef.current
            setOptions([])
            setFetching(true)
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return
                }

                // Add 'All' In Option List
                if (withStar) newOptions.unshift({ label: 'All (*)', value: '*' })

                setOptions(removeDuplicateObjectInArray(newOptions, 'value'))
                setFetching(false)
            })
        }

        return debounce(loadOptions, debounceTimeout)
    }, [fetchOptions, debounceTimeout, withStar])

    if (loading)
        return (
            <InputSkeleton
                className={className ? className : `rounded-5`}
                style={{ width: '100%', borderRadius: '5 !important', ...style }}
                size={size}
            />
        )

    return (
        <div id={id}>
            <Select
                allowClear
                labelInValue
                showSearch
                filterOption={false}
                onSearch={debounceFetcher}
                notFoundContent={
                    fetching ? (
                        <Spin size="small" />
                    ) : searchTerm.length === 0 ? (
                        'Type to search...'
                    ) : undefined
                }
                getPopupContainer={() => document.getElementById(id)}
                size={size}
                className={className ? className : `rounded-5`}
                style={{ width: '100%', borderRadius: '5 !important', ...style }}
                onChange={handleOnchange}
                mode={mode}
                {...props}
                // value={values}
                options={options}
            />
        </div>
    )
}

DebounceSelect.Company = SelectCompany
DebounceSelect.Plant = SelectPlant
DebounceSelect.PlantByDc = SelectPlantByDc
DebounceSelect.Bin = SelectBin
DebounceSelect.Gate = SelectGate
DebounceSelect.DcLocation = SelectDcLocation
DebounceSelect.Sloc = SelectSloc
DebounceSelect.SlocByPlant = SelectSlocByPlant
DebounceSelect.Material = SelectMaterial
DebounceSelect.Route = SelectRoute
DebounceSelect.PoType = SelectPoType
DebounceSelect.PurchasingGroup = SelectPurchasingGroup
DebounceSelect.PurchasingOrg = SelectPurchasingOrg
DebounceSelect.CharacteristicSort = SelectCharacteristicSort
DebounceSelect.PriorityBin = SelectPriorityBin
DebounceSelect.Docking = SelectDockingId
DebounceSelect.ReasonMovement = SelectReasonMovement
DebounceSelect.Role = SelectRole
DebounceSelect.MenuId = SelectMenuId
DebounceSelect.IpSystem = SelectIpSystem
DebounceSelect.Gl = SelectGl
DebounceSelect.TransactionStatus = SelectTransactionStatus
DebounceSelect.PalletStatus = SelectPalletStatus
DebounceSelect.CheckPalletStatus = SelectCheckPalletStatus
DebounceSelect.PickerPalletStatus = SelectPickerPalletStatus
DebounceSelect.ChangeBinStatus = SelectChangeBinStatus
DebounceSelect.DraftStatus = SelectDraftStatus
DebounceSelect.StatusScanPallet = SelectScanPalletStatus
DebounceSelect.TransactionType = SelectTransactionType
DebounceSelect.CheckinTransactionStatus = SelectCheckinoutStatus
DebounceSelect.OutboundStatus = SelectOutboundStatus

export default DebounceSelect
