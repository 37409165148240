import React from 'react'
import { Chart as ChartJS } from 'chart.js'
import { Line } from 'react-chartjs-2'

import 'chart.js/auto'
import 'chartjs-adapter-moment'
import DivSkeletonLoading from 'components/Common/SkeletonLoading/divSkeletonLoading'

// START ADD SPACING BETWEEN LEGEND AND CHART
ChartJS.register({
    id: 'customSpacingLegend',
    beforeInit(chart) {
        // Get reference to the original fit function
        const originalFit = chart.legend.fit

        // Override the fit function
        chart.legend.fit = function fit() {
            // Call original function and bind scope in order to use `this` correctly inside it
            originalFit.bind(chart.legend)()
            // Change the height as suggested in another answers
            this.height += 15
        }
    },
})
// END ADD SPACING BETWEEN LEGEND AND CHART

export default function LineChart({
    labels = [],
    datasets = [],
    borderedContainer = true,
    options = {},
    loading = true,
}) {
    return (
        <div
            className="p-4 rounded-2xl mt-4"
            style={{ border: borderedContainer ? '1px solid #DDDDDD' : 'none' }}
        >
            {loading && <DivSkeletonLoading height={300} />}
            {!loading && <Line options={options} data={{ labels, datasets }} />}
        </div>
    )
}
