export const columns = () => [
    {
        title: 'No Material',
        dataIndex: 'materialId',
        key: 'materialId',
        render: (text) => <p className="w-20">{text}</p>,
    },
    {
        title: 'Material Description',
        dataIndex: 'description',
        key: 'description',
        render: (text) => <p className="min-w-max">{text}</p>,
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Plant',
        dataIndex: 'plant',
        key: 'plant',
    },
    {
        title: 'GL',
        dataIndex: 'gl',
        key: 'gl',
    },
    {
        title: 'Cost Center',
        dataIndex: 'costCenter',
        key: 'costCenter',
    },
]
