import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'

export const columns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col
                    span={12}
                    className={'cursor-pointer'}
                    onClick={() => onCLickDeleteIcon(record)}
                >
                    <DeleteIcon />
                </Col>
                <Col span={12} className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'Role Menu',
        dataIndex: 'roleId',
        key: 'roleId',
        align: 'center',
        width: 150,
    },
    {
        title: 'Role Description',
        dataIndex: 'description',
        key: 'description',
        width: 250,
    },
    {
        title: 'Menu ID',
        dataIndex: 'menuId',
        key: 'menuId',
        align: 'center',
        width: 150,
    },
    {
        title: 'Menu Description',
        dataIndex: 'menuDescription',
        key: 'menuDescription',
    },
]
