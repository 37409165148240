import { Input } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

export const formItems = (initialValues) => {
    return [
        {
            name: 'plantId',
            label: 'Plant ID',
            initialValue: { value: initialValues?.plantId || null },
            rules: [
                {
                    required: true,
                },
            ],
            children: <SelectDebounceFetch.Plant disabled={initialValues?.plantId} />,
        },
        {
            name: 'sectorId',
            label: 'Sector ID',
            initialValue: initialValues?.sectorId || null,
            rules: [
                {
                    required: true,
                },
                {
                    max: 4,
                    message: 'The max character length is 4',
                },
            ],
            children: (
                <Input
                    disabled={initialValues?.sectorId}
                    placeholder="Sector"
                    className="rounded-5"
                    size="large"
                />
            ),
        },
        {
            name: 'slocId',
            label: 'Sloc ID',
            initialValue: initialValues?.slocId || null,
            rules: [
                {
                    required: true,
                },
                {
                    max: 4,
                    message: 'The max character length is 4',
                },
            ],
            // children: <SelectDebounceFetch.Sloc disabled={initialValues?.slocId} />,
            children: (
                <Input
                    placeholder="sloc Id"
                    className="rounded-5"
                    size="large"
                    disabled={initialValues?.slocId}
                />
            ),
        },
        {
            name: 'storlocId',
            label: 'Storloc ID',
            initialValue: initialValues?.storlocId || null,
            rules: [
                {
                    required: true,
                },
                {
                    max: 4,
                    message: 'The max character length is 4',
                },
            ],
            children: (
                <Input
                    placeholder="Storloc"
                    className="rounded-5"
                    size="large"
                    disabled={initialValues?.storlocId}
                />
            ),
        },
        {
            name: 'description',
            label: 'Description',
            initialValue: initialValues?.description || null,
            rules: [
                {
                    required: true,
                },
                {
                    max: 45,
                    message: 'The max character length is 45',
                },
            ],
            children: <Input placeholder="Description" className="rounded-5" size="large" />,
        },
    ]
}
