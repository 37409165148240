import React from 'react'
import SelectDebounceFetch from './index'
import { getTransactionType } from 'api/master/spvForemanTeam'

function SelectTransactionType({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Transaction Type"
            fetchOptions={async (searchTerm) => {
                const res = await getTransactionType({ filter: searchTerm, total: 10 })
                const list = res?.result?.map((data) => ({
                    label: data,
                    value: data,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectTransactionType
