import React, { useState } from 'react'

import { capitalize } from 'utils/formatter'
import { camelCase } from 'lodash'
import useSearchParams from 'customHooks/useSearchParams'

import { LOCAL_STORAGE_KEYS } from 'config/localStorage'
import { FILTER_BY } from 'config/constant/groupFilter'

import MainInput from './MainInput'
import Overlay from './Overlay'

const { WMS_FILTER_ALL_VALUES } = LOCAL_STORAGE_KEYS
const { BY_MATERIAL, BY_COMPANY, BY_PLANT, BY_BUILDING, BY_DRAFT_STATUS, BY_DC_LOCATION, BY_CHANGE_BIN_STATUS, BY_CHECK_PALLET_STATUS, BY_PICKER_PALLET_STATUS, BY_SCAN_PALLET_STATUS, BY_CHECKIN_STATUS, BY_GATE, BY_PALLET_STATUS,BY_TRANSACTION_TYPE,BY_TRANSACTION_STATUS} = FILTER_BY

const GroupFilter = ({
    title = 'Filter',
    style = {},
    filterByOptions = [BY_MATERIAL, BY_COMPANY, BY_PLANT, BY_BUILDING, BY_DRAFT_STATUS, BY_DC_LOCATION, BY_CHANGE_BIN_STATUS, BY_CHECK_PALLET_STATUS, BY_PICKER_PALLET_STATUS, BY_SCAN_PALLET_STATUS, BY_CHECKIN_STATUS, BY_GATE, BY_PALLET_STATUS,BY_TRANSACTION_TYPE,BY_TRANSACTION_STATUS],
}) => {
    const [showOverlay, setShowOverlay] = useState(false)
    const [allValues, setAllValues] = useState({})

    const { currentQuery, setSearchParams } = useSearchParams()

    const persistAllValues = () => {
        localStorage.setItem(WMS_FILTER_ALL_VALUES, JSON.stringify(allValues))
    }

    const setAllValuesFromLocalStorage = () => {
        const allValuesFromLocalStorage = localStorage.getItem(WMS_FILTER_ALL_VALUES)
        if (!allValuesFromLocalStorage) return
        setAllValues(JSON.parse(allValuesFromLocalStorage))
    }

    const updateURIQuery = () => {
        console.log(Object.entries(allValues))
        const filterObject = Object.entries(allValues)
            // ?.filter(([key, value]) => value.length > 0)
            ?.map(([fieldName, values]) => ({
             
                [`filter${camelCase(fieldName)}`]: values.map((value) => value.value)?.join(' '),
            }))
            ?.reduce((prev, cur) => ({ ...prev, ...cur }), {})
 
        console.log('filterObject', filterObject)

        setSearchParams({ ...currentQuery, ...filterObject })
    }

    const onClickSelect = (newValues) => {
        setAllValues({
            ...allValues,
            ...newValues,
        })
    }

    const closeOverlay = () => setShowOverlay(false)

    const handleOnClickCancel = () => {
        setAllValuesFromLocalStorage()
        closeOverlay()
    }

    const handleOnClickSave = () => {
        updateURIQuery()
        persistAllValues()
        closeOverlay()
    }

    React.useEffect(() => {
        return localStorage.removeItem(WMS_FILTER_ALL_VALUES)
    }, [])

    return (
        <div className={`flex items-center relative`} style={style}>
            <p className="w-10">{capitalize(title)} </p>
            <MainInput
                filterByOptions={filterByOptions}
                onFocus={() => setShowOverlay(true)}
                currentQuery={currentQuery}
            />
            {showOverlay && (
                <Overlay
                    filterByOptions={filterByOptions}
                    onClickSelect={onClickSelect}
                    onCancel={handleOnClickCancel}
                    onSave={handleOnClickSave}
                    allValues={allValues}
                />
            )}
        </div>
    )
}

export default GroupFilter
