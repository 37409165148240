import React, { useReducer } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import CreateDoExecutionWoModal from './modals/create-do-execution-wo'

import { DO_WO_CHECKIN_STATUS } from 'config/constant/do-execution-wo-checkin'

import { columns } from './columns'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import {
    getListDeliveryWoCheckin,
    deleteDoExecutionWo,
    doPlannerCheckIn,
} from 'api/transport/do-execution-wo-checkin'

const initialState = {
    loading: false,
    selectedRows: [],
    createDoModal: false,
    confirmationDeleteModal: false,
    rowPayload: null,
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ROWS':
            return { ...state, selectedRows: [...action.selectedRows] }
        case 'TOGGLE_CREATE_DO_WO_MODAL':
            return { ...state, createDoModal: !state.createDoModal }
        case 'TOGGLE_CONFIRMATION_DELETE_MODAL':
            return { ...state, confirmationDeleteModal: !state.confirmationDeleteModal }
        case 'TOGGLE_CONFIRMATION_SUBMIT_MODAL':
            return { ...state, confirmationSubmitModal: !state.confirmationSubmitModal }
        case 'SET_ROW_PAYLOAD':
            return { ...state, rowPayload: action.rowPayload }
        default:
            return state
    }
}

function DoExecutionWo({ ...props }) {
    const [state, dispatch] = useReducer(stateReducer, initialState)
    const { doRefresh } = useQueryRefresh()

    const onChangeSelectedRows = (rowKeys, rows) => {
        dispatch({ type: 'SET_SELECTED_ROWS', selectedRows: rows })
    }

    const onCLickEditIcon = (row) => {
        dispatch({ type: 'TOGGLE_CREATE_DO_WO_MODAL' })
        dispatch({ type: 'SET_ROW_PAYLOAD', rowPayload: { isEditMode: true, ...row } })
    }

    const onCLickDisplayIcon = (row) => {
        dispatch({ type: 'TOGGLE_CREATE_DO_WO_MODAL' })
        dispatch({ type: 'SET_ROW_PAYLOAD', rowPayload: { isDisplayMode: true, ...row } })
    }

    const onClickDeleteIcon = (row) => {
        dispatch({ type: 'TOGGLE_CONFIRMATION_DELETE_MODAL' })
        dispatch({ type: 'SET_ROW_PAYLOAD', rowPayload: row })
    }

    const onCLickCreate = (row) => {
        dispatch({ type: 'TOGGLE_CREATE_DO_WO_MODAL' })
        dispatch({ type: 'SET_ROW_PAYLOAD', rowPayload: null })
    }

    const onCLickSubmit = () => {
        const rowToSubmit = state?.selectedRows[0]
        const isSubmitted = rowToSubmit.statusDescription === DO_WO_CHECKIN_STATUS.SUBMITTED
        if (isSubmitted)
            return Modal.error({
                title: 'Oops',
                content: 'This transaction has already submitted',
            })
        dispatch({ type: 'TOGGLE_CONFIRMATION_SUBMIT_MODAL' })
        dispatch({ type: 'SET_ROW_PAYLOAD', rowPayload: state?.selectedRows[0] })
    }

    const handleDelete = async () => {
        if (!state.rowPayload?.transactionId) {
            return Modal.error({
                title: 'Oops',
                content: `This transaction don't have transaction ID`,
            })
        }

        const reqBody = { transactionId: state?.rowPayload?.transactionId }

        try {
            const res = await deleteDoExecutionWo(reqBody)
            // if (!res.success) return res
            doRefresh()
            return res
        } catch (error) {
            console.error(error)
            return { success: false }
        }
    }

    const handleSubmit = async () => {
        if (!state?.selectedRows[0]?.transactionId) {
            return Modal.error({
                title: 'Oops',
                content: 'This transaction dont have transaction ID',
            })
        }

        try {
            const reqBody = { transactionId: state?.selectedRows[0]?.transactionId }
            const res = await doPlannerCheckIn(reqBody)
            doRefresh()
            return res
        } catch (error) {
            console.error(error)
            return { success: false }
        }
    }

    const buttonsArea = () => (
        <Row justify="end">
            <Button
                onClick={onCLickSubmit}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                disabled={state?.selectedRows?.length !== 1}
                size="middle"
            >
                Submit
            </Button>
            <Button
                onClick={onCLickCreate}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle"
            >
                Create
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                columns={columns(onCLickEditIcon, onCLickDisplayIcon, onClickDeleteIcon)}
                showButtonsArea={true}
                showSearch={true}
                withRowSelection={true}
                withSearchAndFilterArea={true}
                ButtonsAreaComponent={buttonsArea}
                getListApi={getListDeliveryWoCheckin}
                withDatePicker={true}
                onChangeSelectedRows={onChangeSelectedRows}
                scroll={{ x: 1300 }}
            />
            <CreateDoExecutionWoModal
                payload={state.rowPayload}
                visible={state.createDoModal}
                close={() => {
                    dispatch({ type: 'TOGGLE_CREATE_DO_WO_MODAL' })
                    dispatch({ type: 'SET_ROW_PAYLOAD', rowPayload: null })
                }}
                isDisplayMode={state?.rowPayload?.isDisplayMode}
                isEditMode={state?.rowPayload?.isEditMode}
            />
            <ConfirmationModal
                visible={state.confirmationDeleteModal}
                title="Are you sure you want to delete?"
                content={`This will permanently delete? Action can not be undone.
                Transaction ID: ${state?.rowPayload?.transactionId}`}
                successTitleMsg="Delete success!"
                close={() => {
                    dispatch({ type: 'TOGGLE_CONFIRMATION_DELETE_MODAL' })
                    dispatch({ type: 'SET_ROW_PAYLOAD', rowPayload: null })
                }}
                onOk={handleDelete}
                centered={false}
                okText="Delete"
            />
            <ConfirmationModal
                visible={state.confirmationSubmitModal}
                title="Are you sure you want to Submit?"
                content={`Transaction ID: ${state?.selectedRows[0]?.transactionId}`}
                successTitleMsg="Submitted!"
                close={() => {
                    dispatch({ type: 'TOGGLE_CONFIRMATION_SUBMIT_MODAL' })
                    dispatch({ type: 'SET_ROW_PAYLOAD', rowPayload: null })
                }}
                onOk={handleSubmit}
                centered={false}
                okText="Submit"
            />
        </>
    )
}

export default DoExecutionWo
