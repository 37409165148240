import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewPlantSectorSloc } from 'api/master/plantSectorSloc'

function AddPlantStarLocModal({ close, visible }) {
    return (
        <ModalForm
            title="Add Plant, Sector, SLoc"
            formItems={formItems}
            callApi={createNewPlantSectorSloc}
            successMsg={(values) => {
                return `New PlantStarLoc was created`
            }}
            close={close}
            visible={visible}
        />
    )
}

export default AddPlantStarLocModal
