import React, { useState } from 'react'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'
import useQueryRefresh from 'customHooks/useQueryRefresh'
import { resetUser } from 'api/maintain-user/user'

function ConfirmationResetModal({ payload, visible, close }) {
    const [loading, setLoading] = useState(false)
    const { doRefresh } = useQueryRefresh()

    const handleReset = async () => {
        const reqBody = {username : payload.username}
        setLoading(true)

        try {
            const res = await resetUser(reqBody)
            if (!res.success) return res
            setLoading(false)
            doRefresh()
            return res
        } catch (error) {
            setLoading(false)
            console.error(error)
            return { success: false }
        }
    }

    return (
        <ConfirmationModal
            loading={loading}
            visible={visible}
            title={"Are you sure you want to Reset Login "+payload.username+"?"}
            content={`This will permanently Reset Login user. Action can not be undone.`}
            successTitleMsg={"Reset "+payload.username+" success!"}
            close={close}
            onOk={handleReset}
            centered={false}
            okText="Reset"
        />
    )
}

export default ConfirmationResetModal
