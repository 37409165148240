import React from 'react'
import RichTable from 'components/Common/RichTable'
import { getCostCenterList } from 'api/master/costCenter'
import { columns } from './columns'

const CostCenter = () => {
    // const buttons = () => (
    //     <Row justify="end">
    //         <Button
    //             onClick={() => {}}
    //             type="primary"
    //             className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
    //             size="middle"
    //         >
    //             Get From SAP
    //         </Button>
    //     </Row>
    // )

    return (
        <>
            <RichTable
                // rowKey="id"
                columns={columns()}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                // ButtonsAreaComponent={buttons}
                getListApi={getCostCenterList}
                // withDatePicker={true}
            />
        </>
    )
}

export default CostCenter
