import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, InfoYellowIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const palletOutboundColumns = (transactionId) => [
    {
        title: 'Outbond ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
        render: () => <>{transactionId}</>,
    },
    {
        title: 'DO Number',
        dataIndex: 'doNumber',
        key: 'doNumber',
    },
    {
        title: 'DO Item',
        dataIndex: 'doItem',
        key: 'doItem',
    },
    {
        title: 'Pallet ID',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Pallet Code',
        dataIndex: 'palletCode',
        key: 'palletCode',
    },
    {
        title: 'Company ID',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Sloc ID',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Sector',
        dataIndex: 'sectorId',
        key: 'sectorId',
    },
    {
        title: 'Gate',
        dataIndex: 'gateId',
        key: 'gateId',
    },
    {
        title: 'Bin',
        dataIndex: 'binId',
        key: 'binId',
    },
    {
        title: 'Stagging IN',
        dataIndex: 'staggingIn',
        key: 'staggingIn',
    },
    {
        title: 'Stagging Out',
        dataIndex: 'staggingOut',
        key: 'staggingOut',
    },
    {
        title: 'Docking ID',
        dataIndex: 'dockingId',
        key: 'dockingId',
    },
    {
        title: 'Plant ID',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Material Id',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Ref Doc Number',
        dataIndex: 'refDocNumber',
        key: 'refDocNumber',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'Over Qty',
        dataIndex: 'overQty',
        key: 'overQty',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Status Desciption',
        dataIndex: 'statusDescription',
        key: 'statusDescription',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Manufacture Date',
        dataIndex: 'manufactureDate',
        key: 'manufactureDate',
    },
    {
        title: 'Sled',
        dataIndex: 'slad',
        key: 'slad',
    },
    {
        title: 'Created Date',
        dataIndex: 'createDate',
        key: 'createDate',
    },
    {
        title: 'User',
        dataIndex: 'userId',
        key: 'userId',
    },
]
