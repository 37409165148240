// import { Row, Col } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Pallet ID',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Pallet Code',
        dataIndex: 'palletCode',
        key: 'palletCode',
    },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Sloc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Material Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Status',
        dataIndex: 'stockStatus',
        key: 'stockStatus',
    },
    {
        title: 'Expired Date',
        dataIndex: 'sled',
        key: 'sled',
    },
    {
        title: 'Location BIN',
        dataIndex: 'binId',
        key: 'binId',
    },
    {
        title: 'Available',
        dataIndex: 'availableQty',
        key: 'availableQty',
    },
    {
        title: 'Status',
        dataIndex: 'statusPallet',
        key: 'statusPallet',
    },
    {
        title: 'Pallet Return Docking',
        dataIndex: 'returnBinPallet',
        key: 'returnBinPallet',
    },
    {
        title: 'Data resource',
        dataIndex: 'dataResource',
        key: 'dataResource',
    },
    {
        title: 'Is Hold',
        dataIndex: 'isHold',
        key: 'isHold',
    },
    {
        title: 'Is Freeze',
        dataIndex: 'isFreeze',
        key: 'isFreeze',
    }
]
