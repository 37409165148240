import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { updatePalletId } from 'api/master/palletId'

function UpdatePalletIdModal({ payload, close, visible }) {
    return (
        <ModalForm
            title="Edit Pallet"
            formItems={formItems}
            initialValues={payload}
            callApi={updatePalletId}
            successMsg={(values) => `Pallet was updated`}
            close={close}
            visible={visible}
        />
    )
}

export default UpdatePalletIdModal
