import React from 'react'

import RichTable from 'components/Common/RichTable'
import { columns } from './columns'

import { getShipmentTypeList } from 'api/master/shipmentType'

function ShipmentType() {
    return <RichTable columns={columns()} showSearch={true} getListApi={getShipmentTypeList} />
}

export default ShipmentType
