import React, { useState } from 'react'
import moment from 'moment'
import { Form, Modal, Divider, Button, Select, TimePicker } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
import useQueryRefresh from 'customHooks/useQueryRefresh'

const DAYS_OPT = [
    { value: 'Mon', label: 'Monday' },
    { value: 'Tue', label: 'Tuesday' },
    { value: 'Wed', label: 'Wednesday' },
    { value: 'Thu', label: 'Thursday' },
    { value: 'Fri', label: 'Friday' },
    { value: 'Sat', label: 'Saturday' },
    { value: 'Sun', label: 'Sunday' },
]
const SHIFT_OPT = ['1', '2', '3']
const TIME_FORMAT = 'HH:mm:ss'

function AddEditFormModal({
    close,
    visible,
    payload: initialValues = {},
    successMsg = 'success',
    title = '',
    callApi,
    additionalPayload = {},
}) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        const payload = {
            dcLocation: values.dcLocation.value,
            day: values.day.value,
            shift: values.shift.value,
            startTime: values.startTime.format(TIME_FORMAT),
            endTime: values.endTime.format(TIME_FORMAT),
        }

        const modifiedPayload = {
            ...payload,
            ...additionalPayload,
        }

        try {
            const res = await callApi(modifiedPayload)
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="dcLocation"
                    label="DC Location"
                    initialValue={{ value: initialValues?.dcLocation || null }}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDebounceFetch.DcLocation disabled={initialValues?.dcLocation} />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="day"
                    label="Day"
                    initialValue={{ value: initialValues?.day || null }}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Select
                        labelInValue
                        disabled={initialValues?.day}
                        placeholder="Select day"
                        className="rounded-5"
                        size="large"
                    >
                        {DAYS_OPT.map((day) => (
                            <Select.Option key={day.value} value={day.value}>
                                {day.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="shift"
                    label="Shift"
                    initialValue={{ value: initialValues?.shift || null }}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Select
                        labelInValue
                        disabled={initialValues?.shift}
                        placeholder="Select shift"
                        className="rounded-5"
                        size="large"
                    >
                        {SHIFT_OPT.map((shift) => (
                            <Select.Option key={shift} value={shift}>
                                {shift}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="startTime"
                    label="Start Time"
                    initialValue={moment(initialValues?.startTime || '00:00:00', TIME_FORMAT)}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <TimePicker className="rounded-5 w-full" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="endTime"
                    label="End Time"
                    initialValue={moment(initialValues?.endTime || '00:00:00', TIME_FORMAT)}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <TimePicker className="rounded-5 w-full" size="large" />
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={close}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddEditFormModal
