import React, { useState, useEffect } from 'react'
import { Form, Modal, Divider, Button, Input, Select } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

import useQueryRefresh from 'customHooks/useQueryRefresh'
import SelectSlocByPlant from 'components/Common/SelectFetchByPlant/selectSlocByPlant'

const stringToNumber = (str) => +str || str

function AddEditFormModal({
    close,
    visible,
    payload: initialValues,
    successMsg = 'success',
    title = '',
    callApi,
}) {
    const [loading, setLoading] = useState(false)
    const [plantState, setPlant] = useState('')
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const isEditMode = !!initialValues?.binId

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        const payload = {
            companyId: values.companyId.value || initialValues?.companyId,
            plantId: values.plantId.value || initialValues?.plantId,
            slocId: values.slocId.value || initialValues?.slocId,
            building: values.building || initialValues?.building,
            zone: values.zone || initialValues?.zone,
            sector: values.sector || initialValues?.sector,
            beam: stringToNumber(values.beam) || initialValues?.beam,
            level: stringToNumber(values.level) || initialValues?.level,
            subBeam: stringToNumber(values.subBeam) || initialValues?.subBeam,
            binNo: values.binNo || initialValues?.binNo,
            stagingIn: values.stagingIn || initialValues?.stagingIn,
            stagingOut: values.stagingOut || initialValues?.stagingOut,
            groupPriority: stringToNumber(values.groupPriority) || initialValues?.groupPriority,
            capacity: stringToNumber(values.capacity) || initialValues?.capacity,
            isAvailable: values.isAvailable || initialValues?.isAvailable,
            isActive: values.isActive || initialValues?.isActive,
        }

        const modifiedPayload = {
            ...payload,
            binId: initialValues?.binId
                ? initialValues?.binId
                : `${values?.prefixCode}-${payload?.building}-${payload?.binNo}.${payload?.beam}.${payload?.level}.${payload?.subBeam}`,
        }

        try {
            const res = await callApi(modifiedPayload)
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        setPlant(initialValues?.plant)
    }, [initialValues?.material, initialValues?.plant])

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="companyId"
                    label="Company"
                    initialValue={{
                        label: initialValues?.companyId || null,
                        value: initialValues?.companyId || null,
                    }}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <SelectDebounceFetch.Company disabled={initialValues?.companyId} />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="plantId"
                    label="Plant"
                    initialValue={{
                        label: initialValues?.plantId || null,
                        value: initialValues?.plantId || null,
                    }}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <SelectDebounceFetch.Plant
                        disabled={initialValues?.plantId}
                        onChange={(e) => setPlant(e.value)}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="slocId"
                    label="Sloc"
                    initialValue={{
                        label: initialValues?.slocId || null,
                        value: initialValues?.slocId || null,
                    }}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <SelectSlocByPlant
                        plant={plantState}
                        disabled={!plantState}
                        className="rounded-5"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="building"
                    label="Building"
                    initialValue={initialValues?.building || null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 4,
                            message: 'The max character length is 4',
                        },
                    ]}
                >
                    <Input
                        placeholder="Building"
                        className="rounded-5"
                        size="large"
                        disabled={initialValues?.building}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="zone"
                    label="Zone"
                    initialValue={initialValues?.zone || null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 10,
                            message: 'The max character length is 10',
                        },
                    ]}
                >
                    <Input placeholder="Zone" className="rounded-5" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="sector"
                    label="Sector"
                    initialValue={initialValues?.sector || null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 10,
                            message: 'The max character length is 10',
                        },
                    ]}
                >
                    <Input placeholder="Sector" className="rounded-5" size="large" />
                </Form.Item>
                {!isEditMode && (
                    <Form.Item
                        className="my-1"
                        // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="prefixCode"
                        label="Prefix Code"
                        initialValue={initialValues?.prefixCode || null}
                        shouldUpdate={true}
                        rules={[
                            {
                                required: true,
                            },
                            {
                                max: 2,
                                message: 'The max character length is 2',
                            },
                            {
                                min: 2,
                                message: 'The min character length is 2',
                            },
                        ]}
                    >
                        <Input placeholder="Sector" className="rounded-5" size="large" />
                    </Form.Item>
                )}
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="beam"
                    label="Beam"
                    initialValue={initialValues?.beam ? String(initialValues?.beam) : null}
                    shouldUpdate={true}
                    type="string"
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 3,
                            message: 'The max character length is 3',
                        },
                    ]}
                >
                    <Input
                        placeholder="Beam"
                        className="rounded-5"
                        size="large"
                        type="string"
                        disabled={initialValues?.beam}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="level"
                    label="Level"
                    initialValue={initialValues?.level ? String(initialValues?.level) : null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 3,
                            message: 'The max character length is 3',
                        },
                    ]}
                >
                    <Input
                        placeholder="Level"
                        className="rounded-5"
                        size="large"
                        disabled={initialValues?.level}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="subBeam"
                    label="Sub Beam"
                    initialValue={initialValues?.subBeam ? String(initialValues?.subBeam) : null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 3,
                            message: 'The max character length is 3',
                        },
                    ]}
                >
                    <Input
                        placeholder="Sub Beam"
                        className="rounded-5"
                        size="large"
                        disabled={initialValues?.subBeam}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="binNo"
                    label="BIN"
                    initialValue={initialValues?.binNo || null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 3,
                            message: 'The max character length is 3',
                        },
                    ]}
                >
                    <Input
                        placeholder="BIN"
                        className="rounded-5"
                        size="large"
                        disabled={initialValues?.binNo}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="stagingIn"
                    label="Staging In"
                    initialValue={initialValues?.stagingIn || null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 10,
                            message: 'The max character length is 10',
                        },
                    ]}
                >
                    <Input placeholder="Staging In" className="rounded-5" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="stagingOut"
                    label="Staging Out"
                    initialValue={initialValues?.stagingOut || null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 10,
                            message: 'The max character length is 10',
                        },
                    ]}
                >
                    <Input placeholder="Staging Out" className="rounded-5" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="groupPriority"
                    label="Group Ranking"
                    initialValue={
                        initialValues?.groupPriority ? String(initialValues?.groupPriority) : null
                    }
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 3,
                            message: 'The max character length is 3',
                        },
                    ]}
                >
                    <Input placeholder="Group Ranking" className="rounded-5" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="capacity"
                    label="Capacity"
                    initialValue={initialValues?.capacity ? String(initialValues?.capacity) : null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 3,
                            message: 'The max character length is 3',
                        },
                    ]}
                >
                    <Input placeholder="Capacity" className="rounded-5" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="isAvailable"
                    label="Available"
                    initialValue={initialValues?.isAvailable || null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select placeholder="Available" className="rounded-5" size="large">
                        <Select.Option value="Y">Yes</Select.Option>
                        <Select.Option value="N">No</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="isActive"
                    label="Active"
                    initialValue={initialValues?.isActive || null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select placeholder="Active" className="rounded-5" size="large">
                        <Select.Option value="Y">Yes</Select.Option>
                        <Select.Option value="N">No</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={close}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddEditFormModal
