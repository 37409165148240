import React, { useState } from 'react'
import { Button, Row } from 'antd'

import RichTable from 'components/Common/RichTable'
import { plantSectorSlocColumns } from './columns'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddPlantSectorSlocModal from './modals/add-plant-sector-sloc'
import UpdatePlantSectorSlocModal from './modals/update-plant-sector-sloc'

import { getPlantSectorSlocList } from 'api/master/plantSectorSloc'

function PlantSectorSloc() {
    const [showUpdatePlantSectorSlocModal, setUpdatePlantSectorSlocModal] = useState(false)
    const [showAddPlantSectorSlocModal, setAddPlantSectorSlocModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdatePlantSectorSlocModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddPlantSectorSlocModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                // rowKey="storlocId"
                columns={plantSectorSlocColumns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                getListApi={getPlantSectorSlocList}
                type="rich"
            />
            <AddPlantSectorSlocModal
                close={() => setAddPlantSectorSlocModal(false)}
                visible={showAddPlantSectorSlocModal}
            />
            <UpdatePlantSectorSlocModal
                close={() => setUpdatePlantSectorSlocModal(false)}
                visible={showUpdatePlantSectorSlocModal}
                payload={modalFormPayload}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default PlantSectorSloc
