import React from 'react'
import { Button } from 'antd'
import { FILTER_COMPONENT } from 'config/constant/groupFilter'

const Overlay = ({ filterByOptions, onClickSelect, onCancel, onSave, allValues }) => (
    <div
        className={`absolute px-4 pt-4 pb-2 bg-white shadow-2xl z-20 mt-2`}
        style={{ minWidth: 250, maxWidth: 400, top: 40, left: 40, right: 0 }}
    >
        {filterByOptions?.map((fieldName) => {
            const FilterComponent = FILTER_COMPONENT[fieldName]
            return (
                <div key={fieldName} className="mb-2">
                    <p className="font-bold mt-2 mb-1">{fieldName}</p>
                    <FilterComponent
                        onChange={(values) => {
                            onClickSelect({ [fieldName]: values })
                        }}
                        value={allValues[fieldName]}
                    />
                </div>
            )
        })}
        <div className="flex justify-end items-center">
            <Button type="link" onClick={onCancel}>
                Cancel
            </Button>
            <Button type="link" onClick={onSave}>
                Save
            </Button>
        </div>
    </div>
)

export default Overlay
