import React from 'react'
import SelectDebounceFetch from './index'
import { getPurchaseGroupList } from 'api/master/purchasingGroup'

function SelectPoType({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Purchasing Group"
            fetchOptions={async (searchTerm) => {
                const res = await getPurchaseGroupList({
                    filter: searchTerm,
                    limit: 10,
                })
                return res.result.data.map((group) => ({
                    label: `${group.purchaseGroup} - ${group.description}`,
                    value: group.purchaseGroup,
                }))
            }}
            {...props}
        />
    )
}

export default SelectPoType
