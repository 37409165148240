// import { Row, Col } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'No Booking',
        dataIndex: 'bookingNo',
        key: 'bookingNo',
    },
    {
        title: 'DO',
        dataIndex: 'doItem',
        key: 'doItem',
    },
    {
        title: 'No Pol',
        dataIndex: 'vehicleNo',
        key: 'vehicleNo',
    },
    {
        title: 'Docking',
        dataIndex: 'dockingId',
        key: 'dockingId',
    },
    {
        title: 'Loader',
        dataIndex: 'loaderId',
        key: 'loaderId',
    },
    {
        title: 'Picker',
        dataIndex: 'pickerId',
        key: 'pickerId',
    },
    {
        title: 'No Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Sloc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
    },
]
