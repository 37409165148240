import React, { useState, useContext } from 'react'
import { Modal, Button } from 'antd'
import { RichTableContext } from 'components/Common/RichTable'

import useQueryRefresh from 'customHooks/useQueryRefresh'

import { massAssignPallet } from 'api/transaction/plannerDoExecution'
import { removeDuplicateObjectInArray } from 'utils/array'

function MassAssignPalletModal({ visible, close }) {
    const [loading, setLoading] = useState(false)
    const { doRefresh } = useQueryRefresh()
    const richTableContext = useContext(RichTableContext)
    const { selectedRows, rowKey } = richTableContext

    const payload = removeDuplicateObjectInArray(selectedRows, rowKey)

    // const handleSubmit = async () => {
    //     const reqBody = payload
    //         .map((i) => ({
    //             transactionId: i.transactionId,
    //             doNumber: i.documentNumber, // tanya pa rul
    //             doItem: +i.documentItem, // tanya pa rul
    //             plant: i.plantStock,
    //             material: i.material,
    //             reqQty: i.openQty,
    //             bookingNumber: i.bookingNumber,
    //         }))
    //         .filter((i) => i.reqQty > 0)
    //         .filter((i) => !!i.transactionId)

    //     // TO DO
    //     if (reqBody.length === 0) {
    //         Modal.info({
    //             title: 'No Outbound ID to assign pallet',
    //         })
    //         return
    //     }

    //     setLoading(true)
    //     try {
    //         const res = await massAssignPallet(reqBody)
    //         if (!res.success) return { success: false }
    //         setLoading(false)
    //         doRefresh()
    //         return res
    //     } catch (error) {
    //         setLoading(false)
    //         console.error(error)
    //         return { success: false }
    //     }
    // }

    const handleOk = async (e) => {
        const reqBody = payload
            .map((i) => ({
                transactionId: i.transactionId,
                doNumber: i.documentNumber, // tanya pa rul
                doItem: +i.documentItem, // tanya pa rul
                plant: i.plantStock,
                material: i.material,
                reqQty: i.openQty,
                bookingNumber: i.bookingNumber,
            }))
            .filter((i) => i.reqQty > 0)
            .filter((i) => !!i.transactionId)

        if (reqBody.length === 0) {
            Modal.info({ title: 'No Outbound ID to assign pallet' })
            return close()
        }

        setLoading(true)
        try {
            const res = await massAssignPallet(reqBody)
            if (!res.success) return { success: false }
            Modal.success({ title: 'Mass Assign Pallet success!' })
            setLoading(false)
            doRefresh()
        } catch (error) {
            setLoading(false)
            console.error(error)
        }

        setLoading(false)
        close()

        // RESET ROW SELECTION RICH TABLE CONTEXT
        if (richTableContext && richTableContext.resetRowSelections)
            richTableContext.resetRowSelections()
    }

    return (
        <Modal
            loading={loading}
            close={close}
            // onOk={handleSubmit}
            centered={false}
            closable={false}
            visible={visible}
            onCancel={() => {
                setLoading(false)
                close()
            }}
            footer={null}
            destroyOnClose
            width={450}
            bodyStyle={{ padding: 20 }}
            zIndex={1001}
        >
            <div style={{ color: '#101820' }}>
                <div className="text-2xl font-bold mb-1">
                    Are you sure you want to Mass Assign Pallet?
                </div>
                <div
                    className="mt-5"
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                        gap: 10,
                    }}
                >
                    <Button
                        className="h-11 rounded-10 text-nabati-pink border-nabati-pink"
                        onClick={() => {
                            setLoading(false)
                            close()
                        }}
                        type="ghost"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="h-11 rounded-10"
                        onClick={handleOk}
                        type="primary"
                        loading={loading}
                    >
                        Mass Assign Pallet
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default MassAssignPalletModal
