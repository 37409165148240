import React from 'react'
import SkeletonLoading from 'components/Common/SkeletonLoading/divSkeletonLoading'

import SingleQrBox from './SingleQrBox'
import { sliceIntoChunks } from 'utils/array'

function QrPageBody({ loading, groupedQrCodes, columnPerPage }) {
    const pages = sliceIntoChunks(groupedQrCodes, +columnPerPage)

    if (loading) return <SkeletonLoading />
    return (
        <div className="overflow-y-scroll bg-nabati-grey p-10" style={{ maxHeight: '70vh' }}>
            {pages.map((page) => (
                <div className="p-5 m-5 flex min-w-min bg-white shadow-md ">
                    {page.map((section) => {
                        return (
                            <div
                                style={{
                                    borderRight: '1px dashed gray',
                                    height: 'fit-content',
                                }}
                            >
                                {section.map((row) => {
                                    return (
                                        <div
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: `repeat(${row.length}, 40mm)`,
                                                gap: '1mm',
                                            }}
                                        >
                                            {row?.length > 0 &&
                                                row.map((singleQrObj) => (
                                                    <SingleQrBox
                                                        key={singleQrObj.binId}
                                                        singleQrObj={singleQrObj}
                                                    />
                                                ))}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            ))}
        </div>
    )
}
export default QrPageBody
