import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon, EyeIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = (onCLickEditIcon, onCLickDeleteIcon, onCLicDisplayIcon) => [
    {
        title: 'Actions',
        width: 150,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
                <Col className={'cursor-pointer'} onClick={() => onCLicDisplayIcon(record)}>
                    <EyeIcon />
                </Col>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Doc Sap',
        dataIndex: 'docSap',
        key: 'docSap',
    },
    {
        title: 'Year',
        dataIndex: 'yearSap',
        key: 'yearSap',
    },
    {
        title: 'No Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Material Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
]
