import DatePickerQueryInput from 'components/Common/DatePickerQueryInput'
// import QuerySelect from 'components/Common/QuerySelect'
import React from 'react'
import SearchQueryInput from 'components/Common/SearchQueryInput'
import SimpleContainer from 'components/layouts/simple'
import GroupFilter from 'components/Common/GroupFilter'

import { FILTER_BY } from 'config/constant/groupFilter'

const { BY_MATERIAL, BY_COMPANY, BY_PLANT, BY_BUILDING } = FILTER_BY
const TODAY = 'Today'

function SearchAndFilterArea({ withDatePicker, filterField, withSearch }) {
    return (
        <SimpleContainer>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4,  1fr)',
                    gap: 20,
                }}
            >
                {!withSearch && (
                    <SearchQueryInput
                        style={{
                            gridColumnStart: `${withDatePicker ? 'span 4' : 'span 2'}`,
                            maxWidth: `${withDatePicker ? 'calc(50% - 8px)' : 'unset'}`,
                        }}
                    />
                )}
                    <GroupFilter
                        filterByOptions={filterField}
                        style={{ gridColumn: '1 / 3' }}
                    />
              
                {withDatePicker && (
                    <DatePickerQueryInput
                        initialPreset={TODAY}
                        style={{ gridColumnStart: 'span 2' }}
                    />
                )}
            </div>
        </SimpleContainer>
    )
}

export default SearchAndFilterArea
