import React from 'react'
import SelectDebounceFetch from './index'
import { getMenuList } from 'api/maintain-user/menu-id'

function SelectMaterial({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Menu ID"
            fetchOptions={async (searchTerm) => {
                const res = await getMenuList({ filter: searchTerm })
                const list = res?.result.data?.map((menu) => ({
                    label: `${menu.menuId} - ${menu.description}`,
                    value: menu.menuId,
                }))
                return list || []
                // return [{ label: '300330', value: '300330' }]
            }}
            {...props}
        />
    )
}

export default SelectMaterial
