import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, InfoYellowIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const reportPalletIdColumns = (
) => [
    {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Pallet id',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Pallet Code',
        dataIndex: 'palletCode',
        key: 'palletCode',
    },
    {
        title: 'Company id',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Sloc',
        dataIndex: 'sloc',
        key: 'sloc',
    },
    {
        title: 'Sector',
        dataIndex: 'sector',
        key: 'sector',
    },
    {
        title: 'Plant',
        dataIndex: 'plant',
        key: 'plant',
    },
    {
        title: 'Material',
        dataIndex: 'material',
        key: 'material',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'Uom',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Unstricted Stock',
        dataIndex: 'unstrictedStock',
        key: 'unstrictedStock',
    },
    {
        title: 'Quality Inpection Stock',
        dataIndex: 'qualityInpectionStock',
        key: 'qualityInpectionStock',
    },
    {
        title: 'Blocked Stock',
        dataIndex: 'blockedStock',
        key: 'blockedStock',
    },
    {
        title: 'Stock Status',
        dataIndex: 'stockStatus',
        key: 'stockStatus',
    },
    {
        title: 'Pallet Status',
        dataIndex: 'palletStatus',
        key: 'palletStatus',
    },
    {
        title: 'Reject Reason',
        dataIndex: 'rejectReason',
        key: 'rejectReason',
    },
    {
        title: 'Barcode Sap',
        dataIndex: 'barcodeSap',
        key: 'barcodeSap',
    },
    {
        title: 'Create Date',
        dataIndex: 'createDate',
        key: 'createDate',
    },
    {
        title: 'User Id',
        dataIndex: 'userId',
        key: 'userId',
    },
     {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
    }
]
