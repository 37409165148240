import { Input } from 'antd'

export const formItems = (initialValues) => [
    {
        name: 'transactionId',
        label: 'Transaction ID',
        initialValue: initialValues?.transactionId || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <Input placeholder="Transaction ID" className="rounded-5" size="large" />,
    },
    {
        name: 'date',
        label: 'Date',
        initialValue: initialValues?.date || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <Input placeholder="Date" className="rounded-5" size="large" />,
    },
    {
        name: 'docSap',
        label: 'Doc SAP',
        initialValue: initialValues?.docSap || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <Input placeholder="Doc SAP" className="rounded-5" size="large" />,
    },
    {
        name: 'year',
        label: 'Year',
        initialValue: initialValues?.year || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <Input placeholder="Year" className="rounded-5" size="large" />,
    },
    {
        name: 'noMaterial',
        label: 'No Material',
        initialValue: initialValues?.noMaterial || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <Input placeholder="No Material" className="rounded-5" size="large" />,
    },
    {
        name: 'materialDescription',
        label: 'Material Description',
        initialValue: initialValues?.materialDescription || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <Input placeholder="Material Description" className="rounded-5" size="large" />,
    },
    {
        name: 'qty',
        label: 'Qty',
        initialValue: initialValues?.qty || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <Input placeholder="Qty" className="rounded-5" size="large" />,
    },
    {
        name: 'uom',
        label: 'Uom',
        initialValue: initialValues?.uom || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <Input placeholder="Uom" className="rounded-5" size="large" />,
    },

    {
        name: 'plantId',
        label: 'Plant',
        initialValue: initialValues?.plantId || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <Input placeholder="Plant" className="rounded-5" size="large" />,
    },
    {
        name: 'plantDescription',
        label: 'Plant Description',
        initialValue: initialValues?.plantDescription || null,
        rules: [
            {
                required: true,
            },
        ],
        children: <Input placeholder="Plant Description" className="rounded-5" size="large" />,
    },
]
