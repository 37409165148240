import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import useQueryRefresh from 'customHooks/useQueryRefresh'
// import { getSpvList } from "api/master/spvForemanTeam";
import { getSpvGroupDc, getSpvUsers } from 'api/transaction/plannerDoExecution'

import notify from 'utils/notify'

const AssignSPV = ({ transactionId = '', close, startLoadingApi = async () => {}, ...props }) => {
    const [loading, setLoading] = useState(false)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [spvGroupDc, setSpvGroupDc] = useState([])
    const [spvList, setSpvList] = useState([])

    const [selectedGroupDC, setSelectedGroupDC] = useState('')
    const [selectedSpv, setSelectedSpv] = useState('')

    const { doRefresh } = useQueryRefresh()

    const openConfirmationModal = () => {
        if (!selectedSpv || !selectedGroupDC) {
            return notify.error('Please Select SPV')
        }
        setConfirmationModal(true)
    }

    const handleStartLoading = async () => {
        setLoading(true)
        try {
            const res = await startLoadingApi({
                transactionId: transactionId,
                spvUserId: selectedSpv,
            })
            setLoading(false)
            if (res.success === false) return { ...res, success: false }
            close()
            setSelectedGroupDC('')
            setSelectedSpv('')
            doRefresh()
            setConfirmationModal(false)
            return { ...res, success: true }
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    useEffect(() => {
        if (!props.visible) return
        // if (!payload) return;
        const fetchApi = async () => {
            setLoading(true)
            const SpvList = await getSpvUsers()
            const groupDCList = await getSpvGroupDc()
            setSpvGroupDc(groupDCList?.data)
            setSpvList(SpvList?.data)
            setLoading(false)
        }
        fetchApi()
    }, [transactionId, props.visible])

    return (
        <>
            <SimpleModal
                title="Assign SPV"
                okText="Create"
                width={636}
                close={() => {
                    setSelectedGroupDC('')
                    setSelectedSpv('')
                    close()
                }}
                onOk={openConfirmationModal}
                {...props}
            >
                <p className="mb-2 text-base text-nabati-grey">Group DC</p>
                <Select
                    className="rounded-10 h-50 w-full"
                    placeholder="Group DC"
                    size="large"
                    value={selectedGroupDC || undefined}
                    onChange={(item) => {
                        setSelectedGroupDC(item)
                        setSelectedSpv('')
                    }}
                    loading={loading}
                    // disabled
                >
                    {/* <Select.Option value={''} disabled>
                        Select Group DC
                    </Select.Option> */}
                    {spvGroupDc.map((groupDC, idx) => (
                        <Select.Option
                            value={groupDC.groupDcLocationId}
                            key={groupDC.groupDcLocationId + idx}
                        >
                            {groupDC.groupDcLocationId} - {groupDC.description}
                        </Select.Option>
                    ))}
                </Select>
                <p className="mt-2 mb-2 text-base text-nabati-grey">SPV</p>
                <Select
                    className="rounded-10 h-50 w-full"
                    placeholder="SPV"
                    size="large"
                    value={selectedSpv}
                    onChange={(item) => setSelectedSpv(item)}
                    disabled={!selectedGroupDC}
                    loading={loading}
                >
                    <Select.Option value={''} disabled>
                        Select SPV
                    </Select.Option>
                    {spvList
                        .filter((item) => item.groupDcLocationId === selectedGroupDC)
                        .map((spv, idx) => (
                            <Select.Option value={spv.username} key={spv.username + idx}>
                                {spv.username}
                            </Select.Option>
                        ))}
                </Select>
            </SimpleModal>
            <ConfirmationModal
                visible={confirmationModal}
                close={() => setConfirmationModal(false)}
                title="Confirm Start Loading"
                content="Are you sure to start loading process SPV ?"
                successTitleMsg="Success Start Loading"
                successContentMsg={(res) => res?.message || ''}
                onOk={handleStartLoading}
            />
        </>
    )
}

export default AssignSPV
