import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewPlant } from 'api/master/plant'

import { auth } from 'auth'

function AddPlantModal({ close, visible }) {
    const user = auth.getUserInfo()
    const modifiedPayload = {
        createBy: user?.userName || 'BCO_MJK2', // delete me! ["BCO_MJK2"]
    }

    return (
        <ModalForm
            title="Add Plant"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={createNewPlant}
            successMsg={(values) => `New Plant ${values.plantId} was created`}
            close={close}
            visible={visible}
        />
    )
}

export default AddPlantModal
