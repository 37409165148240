import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getPrioritybinList = async (params) => {
    const response = await call(METHODS.GET, '/master/prioritybin', params)
    return response.data
}

export const createNewPrioritybin = async (data) => {
    const url = `/master/prioritybin/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updatePrioritybin = async (data) => {
    const url = `/master/prioritybin/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deletePrioritybin = async (data) => {
    const url = `/master/prioritybin/delete`
    const response = await call(METHODS.DELETE, url, data)
    return response.data
}

export const generateQrPriorityBin = async (data) => {
    const response = await call(METHODS.POST, `/master/prioritybin/qr`, data)
    return response.data
}

export const bulkDeletePrioritybin = async () => {
    // const fakeRes = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve("");
    //   }, 500);
    // });
    // const response = await fakeRes;
    // return response;
}
