import React, { useState } from 'react'
import { message } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import SelectGlByPlant from 'components/Common/SelectFetchByPlant/selectGlByPlant'
import SelectCostCenterByPlant from 'components/Common/SelectFetchByPlant/selectCostCenterByPlant'

function AddGlAndCostCenter({ visible, payload, plant, doEditItem, close, ...props }) {
    const [gl, setGl] = useState('')
    const [costCenter, setCostCenter] = useState('')

    const handleOk = () => {
        if (!gl && payload?.gl) return message.error('Please Select GL')
        if (!costCenter && payload?.costCenter) return message.error('Please Select Cost Center')

        const editedItem = { ...payload, gl, costCenter }
        doEditItem(editedItem)
        message.success('GL and Cost Center Added')
        close()
    }

    return (
        <>
            <SimpleModal
                title={`Add GL & Cost Center`}
                visible={visible}
                okText="OK"
                width={636}
                onOk={handleOk}
                close={close}
                {...props}
            >
                <p className="mb-2 text-base text-nabati-grey">GL</p>
                <SelectGlByPlant
                    plant={plant || ''}
                    onChange={(value) => setGl(value)}
                    defaultValue={payload?.gl}
                />
                <p className="mt-2 mb-2 text-base text-nabati-grey">Cost Center</p>
                <SelectCostCenterByPlant
                    plant={plant || ''}
                    onChange={(value) => setCostCenter(value)}
                    defaultValue={payload?.costCenter}
                />
            </SimpleModal>
        </>
    )
}

export default AddGlAndCostCenter
