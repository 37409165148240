import { Navigate, Outlet } from 'react-router-dom'
import Company from 'components/pages/master-data/company'
// import ApkVersion from 'components/pages/master-data/apk-version'
import Plant from 'components/pages/master-data/plant'
import PlantSloc from 'components/pages/master-data/plant-sloc'
import PlantSectorSloc from 'components/pages/master-data/plant-sector-sloc'
import AssignPlant from 'components/pages/master-data/assign-plant'
import PalletID from 'components/pages/master-data/pallet-id'
import BIN from 'components/pages/master-data/bin'
import Material from 'components/pages/master-data/material'
import AssignMaterialToBin from 'components/pages/master-data/assign-material-to-bin'
import DCLocation from 'components/pages/master-data/dc-location'
import DCLocationPlant from 'components/pages/master-data/dc-location-plant'
import Gate from 'components/pages/master-data/gate'
import Gl from 'components/pages/master-data/gl'
import Docking from 'components/pages/master-data/docking'
import CharacteristicSort from 'components/pages/master-data/characteristic-sort'
import PalletAssignmentSort from 'components/pages/master-data/pallet-assignment-sort'
import SPVForemanTeam from 'components/pages/master-data/spv-foreman-team'
import CheckingArmada from 'components/pages/master-data/checking-armada'
import HoldReason from 'components/pages/master-data/hold-reason'
import MoveReason from 'components/pages/master-data/move-reason'
import PriorityBin from 'components/pages/master-data/priority-bin'
import ReasonMovement from 'components/pages/master-data/reason-movement'
import Route from 'components/pages/master-data/route'
import CostCenter from 'components/pages/master-data/cost-center'
import Order from 'components/pages/master-data/order'
import POType from 'components/pages/master-data/po-type'
import PurchasingOrganization from 'components/pages/master-data/purchasing-organization'
import PurchasingGroup from 'components/pages/master-data/purchasing-group'
import Country from 'components/pages/master-data/country'
import Vendor from 'components/pages/master-data/vendor'
import ShipmentType from 'components/pages/master-data/shipment-type'
import TransportPlant from 'components/pages/master-data/transport-plant'
import FwdAgent from 'components/pages/master-data/fwd-agent'
import ShippingType from 'components/pages/master-data/shipping-type'
import DashboardProductionShift from 'components/pages/master-data/dashboard-production-shift'
import DashboardProductionTarget from 'components/pages/master-data/dashboard-production-target'
import DashboardDcCapacity from 'components/pages/master-data/dashboard-dc-capacity'

export const masterDataRoutes = {
    path: '/master-data',
    element: <Outlet />,
    children: [
        { index: false, element: <Navigate to="company" /> },
        { path: 'company', element: <Company /> },
        // { path: 'apk-version', element: <ApkVersion /> },
        { path: 'plant', element: <Plant /> },
        { path: 'plant-sloc', element: <PlantSloc /> },
        { path: 'plant-sector-sloc', element: <PlantSectorSloc /> },
        { path: 'assign-plant-to-company', element: <AssignPlant /> },
        { path: 'pallet-id', element: <PalletID /> },
        { path: 'bin', element: <BIN /> },
        { path: 'master-material', element: <Material /> },
        { path: 'assign-material-to-bin', element: <AssignMaterialToBin /> },
        { path: 'dc-location', element: <DCLocation /> },
        { path: 'dc-location-plant', element: <DCLocationPlant /> },
        { path: 'gate', element: <Gate /> },
        { path: 'gl', element: <Gl /> },
        { path: 'docking', element: <Docking /> },
        { path: 'characteristic-sort', element: <CharacteristicSort /> },
        { path: 'pallet-assignment-sort', element: <PalletAssignmentSort /> },
        { path: 'spv-foreman-team', element: <SPVForemanTeam /> },
        { path: 'checking-armada', element: <CheckingArmada /> },
        { path: 'hold-reason', element: <HoldReason /> },
        { path: 'move-reason', element: <MoveReason /> },
        { path: 'priority-bin', element: <PriorityBin /> },
        { path: 'reason-movement', element: <ReasonMovement /> },
        { path: 'route', element: <Route /> },
        { path: 'cost-center', element: <CostCenter /> },
        { path: 'order', element: <Order /> },
        { path: 'po-type', element: <POType /> },
        { path: 'purchasing-organization', element: <PurchasingOrganization /> },
        { path: 'purchasing-group', element: <PurchasingGroup /> },
        { path: 'country', element: <Country /> },
        { path: 'vendor', element: <Vendor /> },
        { path: 'shipment-type', element: <ShipmentType /> },
        { path: 'transport-plant', element: <TransportPlant /> },
        { path: 'forwarder', element: <FwdAgent /> },
        { path: 'shipping-type', element: <ShippingType /> },
        { path: 'dashboard-production-shift', element: <DashboardProductionShift /> },
        { path: 'dashboard-production-target', element: <DashboardProductionTarget /> },
        { path: 'dashboard-dc-capacity', element: <DashboardDcCapacity /> },
        { path: '*', element: <Navigate to="company" /> },
    ],
}
