import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewGate } from 'api/master/gate'
import { auth } from 'auth'

function AddGateModal({ close, visible }) {
    const user = auth.getUserInfo()
    const modifiedPayload = {
        createBy: user?.userName || 'BCO_MJK2', // delete me! ["BCO_MJK2"]
    }

    return (
        <ModalForm
            title="Add Gate"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={createNewGate}
            successMsg={(values) => `New Gate ${values.gateId} was created`}
            close={close}
            visible={visible}
            gridColumn={2}
        />
    )
}

export default AddGateModal
