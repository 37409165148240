import React from 'react'
import RichTable from 'components/Common/RichTable'
import { getOrderList } from 'api/master/order'
import { columns } from './columns'

const Order = () => {
    return (
        <>
            <RichTable
                columns={columns()}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                getListApi={getOrderList}
            />
        </>
    )
}

export default Order
