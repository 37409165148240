import React from 'react'
import { Spin } from 'antd'

const LoadingFallback = ({ text = 'Loading...' }) => (
    <>
        <div
            className="relative flex justify-center items-center shadow-md cursor-pointer rounded-10"
            style={{
                width: 50,
                height: 50,
                position: 'relative',
                backgroundSize: 'contain',
            }}
        >
            <Spin />
        </div>
        <p>{text}</p>
    </>
)

export default LoadingFallback
