import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import moment from 'moment'

import SimpleModal from 'components/Common/Modals/SimpleModal'
import SetPriority from '../set-priority'

import { getDeficitStockBooking } from 'api/transaction/plannerSetPriority'
import { auth } from 'auth'
import { columns } from './columns'

const addRowKeyToData = (data) => {
    if (!data) return []
    return data.map((row, index) => ({ ...row, rowKey: index }))
}

function AddPriorityByDeficitStock({ handleSetPriority, close, visible, ...props }) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showPriorityModal, setPriorityModal] = useState(false)
    const user = auth.getUserInfo()

    const handleOk = () => {
        // close()
        setPriorityModal(true)
    }

    const rowSelection = () => ({
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRows(selectedRows)
        },
        selectedRowKeys,
    })

    useEffect(() => {
        if (!visible) return
        setSelectedRowKeys([])
        setSelectedRows([])

        const fromDate = moment().subtract('1', 'months').startOf('month').format('YYYY-MM-DD')
        const toDate = moment().endOf('month').format('YYYY-MM-DD')

        const fetchData = async () => {
            setLoading(true)
            const payload = {
                fromDate, // default 2 bulan feb-maret
                toDate, // default 2 bulan feb-maret
                plants: user.plantId.split(','), // ambil dari user login
                materials: [''], // Kirim array kosong saja
            }

            const res = await getDeficitStockBooking(payload)
            if (res.success) setData(addRowKeyToData(res.data))
            setLoading(false)
        }

        fetchData()
        // INVESTIGATE AND DELETE THIS DISABLE ESLINT
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    return (
        <>
            <SimpleModal
                title="Add Priority by Deficit Stock-Check In"
                okText={'Set Priority & Add BIN'}
                width={1188}
                close={close}
                onOk={handleOk}
                visible={visible}
                {...props}
            >
                <div className="flex items-center h-8">
                    {<p className="mr-5 font-bold">Selected: {selectedRowKeys.length}</p>}
                </div>
                <Table
                    loading={loading}
                    dataSource={data ? (data.length > 0 ? data : []) : []}
                    columns={columns}
                    rowClassName={(_, index) =>
                        `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                    }
                    pagination={false}
                    rowSelection={rowSelection()}
                    rowKey="rowKey"
                />
            </SimpleModal>
            <SetPriority
                visible={showPriorityModal}
                close={() => setPriorityModal(false)}
                closeParent={close}
                payload={selectedRows}
                tabKey="vehicleNo"
            />
        </>
    )
}

export default AddPriorityByDeficitStock
