import { Input, Select } from 'antd'

const tableOptions = [
    { label: 'WMS_BIN', value: 'WMS_BIN' },
    { label: 'WMS_PALLET_INVENTORY', value: 'WMS_PALLET_INVENTORY' },
]

const fieldOptions = [
    { label: 'GROUP_PRIORITY', value: 'GROUP_PRIORITY' },
    { label: 'MANUFACTURE_DATE', value: 'MANUFACTURE_DATE' },
    { label: 'SLED', value: 'SLED' },
]

export const formItems = (initialValues, form) => {
    return [
        {
            name: 'characteristicName',
            label: 'Name',
            initialValue: initialValues?.characteristicName || null,
            rules: [
                {
                    required: true,
                },
                {
                    max: 15,
                    message: 'The max character length is 15',
                },
            ],
            children: (
                <Input
                    disabled={initialValues?.characteristicName}
                    placeholder="Name"
                    className="rounded-5"
                    size="large"
                />
            ),
        },
        {
            name: 'table',
            label: 'Table',
            initialValue: initialValues?.table || null,
            rules: [
                {
                    required: true,
                },
            ],
            children: (
                <Select placeholder="Table" className="rounded-5" size="large">
                    {tableOptions.map((item) => (
                        <Select.Option value={item.value}>{item.label}</Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            name: 'field',
            label: 'Field',
            initialValue: initialValues?.field || null,
            rules: [
                {
                    required: true,
                },
            ],
            children: (
                <Select placeholder="Field" className="rounded-5" size="large">
                    {fieldOptions.map((item) => (
                        <Select.Option value={item.value}>{item.label}</Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            name: 'sorted',
            label: 'Sorted',
            initialValue: initialValues?.sorted || null,
            rules: [
                {
                    required: true,
                },
            ],
            children: (
                <Select showSearch placeholder="Sorted" className="rounded-5" size="large">
                    <Select.Option value="A/Z">A/Z</Select.Option>
                    <Select.Option value="Z/A">Z/A</Select.Option>
                </Select>
            ),
        },
    ]
}
