import React, { useState, useEffect } from 'react'
import { Modal, Divider, Button, Table } from 'antd'

import { getMaterialPlantSloc } from 'api/master/material'

const columns = [
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Sloc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]

const ViewSlocModal = ({ title = 'Title Required', close, payload, visible, ...props }) => {
    const [tableData, setDataTable] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!visible) return
        const fetchData = async () => {
            setLoading(true)
            const res = await getMaterialPlantSloc({ materialId: payload.materialId })
            setLoading(false)
            if (!res.success) return
            setDataTable(res.result)
        }

        fetchData()
    }, [payload.materialId, visible])

    return (
        <>
            <Modal
                okText="OK"
                width={636}
                onOk={() => {}}
                closable={false}
                footer={null}
                bodyStyle={{ padding: 0 }}
                visible={visible}
                {...props}
            >
                <p className="px-3 pt-3 text-lg">
                    {typeof title === 'function' ? title(payload) : title}
                </p>
                <Divider className="border-black mt-2 -mb-0 border-t-1" />
                <Table
                    loading={loading}
                    dataSource={tableData}
                    columns={columns}
                    rowClassName={(_, index) => (index % 2 === 0 ? '' : 'bg-nabati-lightGray')}
                    pagination={false}
                />
                <div className="flex justify-end items-center p-3">
                    <Button
                        className="rounded-10 text-nabati-pink border-nabati-pink"
                        style={{ width: 197, height: 45 }}
                        onClick={() => {
                            setDataTable([])
                            close()
                        }}
                        type="ghost"
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default ViewSlocModal
