import React from 'react'
import SelectDebounceFetch from './index'
import { getBinList } from 'api/master/bin'
const data = [
    {
        value: 0,
        label: "palleted"
    },
    {
        value: 1,
        label: "transfer"
    },
    {
        value: 2,
        label: "transfered"
    },
    {
        value: 3,
        label: "rejected"
    },
    {
        value: 9,
        label: "rejected"
    }
]
function SelectPalletStatus({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Pallet Status"
            fetchOptions={async (searchTerm) => {
                const list = data
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectPalletStatus
