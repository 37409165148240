import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const plantSectorSlocColumns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Sector',
        dataIndex: 'sectorId',
        key: 'sectorId',
        render: (text) => `Sector ${text}`,
    },
    {
        title: 'Sloc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
