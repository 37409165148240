import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'
import { API_URL } from 'config/env'

export const getPalletIdList = async (params) => {
    const response = await call(METHODS.GET, '/master/palletid', params)
    return response.data
}

export const createNewPalletId = async (data) => {
    const url = `/master/palletid/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updatePalletId = async (data) => {
    const url = `/master/palletid/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deletePalletId = async (data) => {
    const url = `/master/palletid/delete`
    const response = await call(METHODS.DELETE, url, data)
    return response.data
}

export const bulkDeletePalletId = async () => {
    const fakeRes = new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve('')
        }, 500)
    })
    const response = await fakeRes
    return response
}

export const generateQrPallet = async (data) => {
    const response = await call(METHODS.POST, `/master/palletid/qr`, data)
    return response.data
}

export const downloadPalletTemplate = async (data) => {
    window.open(API_URL + '/master/palletid/download')
}

export const uploadPalletTemplate = async (formData, onUploadProgress) => {
    const options = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    }
    const response = await call(METHODS.POST, `/master/palletid/import`, formData, options)
    return response.data
}
