import React from 'react'
import { logoImg } from 'assets/images'

function Maintenance() {
    return (
        <div className="w-full min-h-screen text-center text-white bg-nabati-blue">
            <div className="flex justify-center items-center mb-10">
                <img src={logoImg} className="mb-5 mt-2" alt="logo nabati" />
                <div className="text-white font-bold">
                    <p className="text-2xl">Warehouse Management System</p>
                    <p className="text-sm">Kaldu Sari Nabati Indonesia</p>
                </div>
            </div>
            <p className="text-5xl pt-28">Maintenance...</p>
        </div>
    )
}

export default Maintenance
