import { DARK_RED_2 } from 'config/colors'

export const options = () => ({
    responsive: true,
    elements: {
        bar: {
            backgroundColor: [DARK_RED_2],
        },
    },
    scales: {
        // y: {
        //     min: 0,
        //     max: 10000,
        //     ticks: {
        //         stepSize: 2000,
        //     },
        // },
    },
    plugins: {
        legend: {
            display: true,
            position: 'top',
            boxHeight: 100,
            labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                font: { size: 14, weight: 400 },
                padding: 10,
            },
        },
        title: {
            display: true,
            text: 'Top 10 Expired Material',
            font: { size: 16, weight: 700 },
            padding: 0,
        },
        datalabels: {
            display: true,
            color: '#444444',
            anchor: 'end',
            offset: -20,
            align: 'start',
        },
    },
})
