import React from 'react'
import { Input } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import { camelCaseToTitleCase } from 'utils/formatter'

const MainInput = ({ filterByOptions, onFocus, currentQuery, ...props }) => {
    return (
        <Input
            size="large"
            className={`rounded-10 customized ml-2`}
            style={{ width: '100%' }}
            placeholder="Select"
            onFocus={onFocus}
            value={Object.entries(currentQuery)
                .filter(([key, value]) => value.length > 0)
                .filter(([key, value]) =>
                    filterByOptions.find(
                        (item) => camelCaseToTitleCase(key.replace('filter', '')) === item
                    )
                )
                .map(([key, value]) => [
                    camelCaseToTitleCase(key.replace('filter', '')),
                    value.split(' ').join(','),
                ])
                .reduce((acc, curr) => `${acc} ${curr[0]}:${curr[1]};`, '')}
            suffix={<DownOutlined className="text-xs" style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
        />
    )
}

export default MainInput
