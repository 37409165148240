import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'
// import { API_URL_LOGIN } from "config/env";

export const signInApi = async ({ username, password, device }) => {
    // const options = { baseURL: API_URL_LOGIN };

    const data = { username, password, device }
    const response = await call(METHODS.POST, '/login', data)
    return response?.data || null
}

export const signOutApi = async ({ username, device }) => {
    // const options = { baseURL: API_URL_LOGIN };

    const data = { username, device }
    const response = await call(METHODS.POST, '/logout', data)
    return response?.data || null
}

export const checkPassword = async ({ username, password }) => {
    // const options = { baseURL: API_URL_LOGIN };
    const data = { username, password }
    const response = await call(METHODS.POST, '/login', data, { skipErrorInterceptor: true })
    return response?.data || null
}

export const logout = async () => {
    const response = await call('POST', '/logout')
    return response?.data
}
