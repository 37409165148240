import React , { createContext, useState, useEffect, useContext, useCallback } from 'react'
// import { Button, Tag, Space, Table, Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons'
import { auth } from 'auth'
import GridCardArea from './grid-card-area'
import InventoryArea from './inventory-area'
import InventoryVsDoArea from './inventory-vs-do-area'
import SectionFilter from '../_sharedComponents/SectionFilter'
import SearchAndFilterArea from 'components/Common/SearchAndFilterArea'
import useDashboardState from '../useDashboardState'
import { FILTER_BY } from 'config/constant/groupFilter'
import {
    getPlannerDashboardCountTransaction, getPlannerDashboardInventory, plannerDefisitStockBooking
} from 'api/transaction/plannerDashboard'
import {
    findDcLocation
} from 'api/master/dcLocation'
import {
    plannerDefisitStock
} from 'api/transaction/plannerDashboard'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import { TIME_PRESET, DATE_FORMAT, TIME_RANGE_PRESET } from 'config/constant/timeAndDate'
export const DashboardPlannerContext = createContext()


const  THIS_MONTH = 'This Month'
const columnsDummyInventory = [
    {
        title: 'Plant',
        dataIndex: 'plant',
        key: 'plant',
    },
    {
        title: 'Stock',
        dataIndex: 'stock',
        key: 'stock',
        align: 'right',
    },
    {
        title: 'Uom',
        dataIndex: 'uom',
        key: 'uom',
        align: 'left',
    },
]

const columnsDummyInventoryVsDo = [
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Material No',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Req Qty',
        dataIndex: 'stock',
        key: 'stock',
        render: (text) => <p className="text-red">{text}</p>,
    },
]


const { BY_MATERIAL, BY_COMPANY, BY_PLANT, BY_BUILDING } = FILTER_BY
function PlannerDashboard() {
    const [countTransaction, setDataCountTransaction] = useState([])
    const [countInventory, setDataInventory] = useState([])
    const [dcDesc, setDc] = useState([])
    const [difisitStock, setDataDefisit] = useState([])
    const [difisitStockBooking, setDataDefisitBooking] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    // const { dateRange } = useContext(CardDatePickerContext)
    const [loading, setLoading] = useState(false)
    const { dcLocation, onChangeDcLocation } = useDashboardState()
    const user = auth.getUserInfo()
        // const isNeedDateQueryWithoutFromTo = useCallback(() => {

        //     const hasFrom = !!searchParams.get('from')
        //     const hasTo = !!searchParams.get('to')
        //     const hasFromTo = hasFrom && hasTo

        //     if (!hasFromTo) return true
        //     return false
        // }, [searchParams])
        useEffect(() => {
        if (!dcLocation) return
        const locationDc = user?.dcLocationId.replace(",", " ");
        const arrDcLocation = user?.dcLocationId.split(",");
        const resultTemp = []
        const resultTempDc = []
        const  reqBody = {
            dcLocation: locationDc,
        }

        const fetchData = async () => {
            setLoading(true)
            try {
          
                const { data } = await getPlannerDashboardCountTransaction(reqBody)
                setDataCountTransaction([
                    { name: 'Booking Number', count: data?.bookingNumber ?? 0, icon: <InfoCircleOutlined /> },
                    { name: 'DO Check IN', count: data?.doCheckIn ?? 0, icon: <InfoCircleOutlined /> },
                    { name: 'DO Outstanding', count: data?.doOutstanding ?? 0, icon: <InfoCircleOutlined /> },
                    { name: 'DO STO', count: data?.doSto ?? 0, icon: <InfoCircleOutlined /> },
                    { name: 'DO Return', count: data?.doReturn ?? 0, icon: <InfoCircleOutlined /> },
                    { name: 'Reservation', count: data?.reservation ?? 0, icon: <InfoCircleOutlined /> },
                    { name: 'Internal Order', count: data?.internalOrder ?? 0, icon: <InfoCircleOutlined /> },
                    { name: 'Outstanding DO WH', count: data?.outstandingDowh ?? 0, icon: <InfoCircleOutlined /> },
                    { name: 'Other', count: data?.other ?? 0,  icon: '' },
                ])
                for(var i = 0; i < arrDcLocation.length; i++){
                    const  reqBodyInventory = {
                        dcLocation: arrDcLocation[i],
                    }
                    const dashboardInventory = await getPlannerDashboardInventory(reqBodyInventory);
                    if(dashboardInventory.data.length > 0){
                        resultTemp.push(dashboardInventory.data)
                        resultTempDc.push(dashboardInventory.data[0].dcName)
                    }
                }
                setDataInventory(resultTemp)
                setDc(resultTempDc)
                const from = searchParams.get('from') ?? moment().startOf('month')
                const to = searchParams.get('to') ?? moment().endOf('month')
                const arrPlants = searchParams.get('filterbyPlant');
                const reqBodyDefisitStock = {
                    fromDate : moment(from,'DD/MM/YYYY').format('YYYY-MM-DD'),
                    toDate : moment(to,'DD/MM/YYYY').format('YYYY-MM-DD'),
                    plants : (arrPlants != null ) ? arrPlants.split(" ") : user?.plantId.split(","),
                    material : [""]
                }
                const defisitData = await plannerDefisitStock(reqBodyDefisitStock )
                setDataDefisit(defisitData.data)
                const defisitDataStockBooking = await plannerDefisitStockBooking(reqBodyDefisitStock )
                setDataDefisitBooking(defisitDataStockBooking.data)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        // if (isNeedDateQueryWithoutFromTo()) return
        fetchData()
    },[searchParams])
    return (
        <div className="mb-96">
            <DashboardPlannerContext.Provider value={{ dcLocation, onChangeDcLocation }}>
            <SearchAndFilterArea filterField={[BY_PLANT]} withDatePicker={true} withSearch={true}  />
            <GridCardArea cards={countTransaction} />
            <InventoryArea columns={columnsDummyInventory} data={countInventory} label={dcDesc} />
       
            <InventoryVsDoArea columns={columnsDummyInventoryVsDo} data={difisitStock} dataBooking={difisitStockBooking}  />
          
            </DashboardPlannerContext.Provider>

        </div>
    )
}

export default PlannerDashboard
