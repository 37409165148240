import React from 'react'
import SelectDebounceFetch from './index'
import { getDcLocationList } from 'api/master/dcLocation'

function SelectDcLocation({ placeholder = '', ...props }) {
    return (
        <SelectDebounceFetch
            placeholder={placeholder ? placeholder : 'DC Location'}
            fetchOptions={async (searchTerm) => {
                const res = await getDcLocationList({ filter: searchTerm, total: 10 })
                const list = res?.result?.data.map((dc) => ({
                    label: `${dc.dcLocationId} - ${dc.name}`,
                    value: dc.dcLocationId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectDcLocation
