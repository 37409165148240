import { DARK_PINK_1 } from 'config/colors'

export const options = () => ({
    responsive: true,
    elements: {
        bar: {
            backgroundColor: [DARK_PINK_1],
        },
    },
    scales: {
        // y: {
        //     min: 0,
        //     max: 10000,
        //     ticks: {
        //         stepSize: 2000,
        //     },
        // },
    },
    plugins: {
        legend: {
            display: false,
            // position: 'top',
            // boxHeight: 100,
            // labels: {
            //     usePointStyle: true,
            //     pointStyle: 'rectRounded',
            //     font: { size: 14, weight: 400 },
            //     padding: 10,
            // },
        },
        title: {
            display: true,
            text: 'Loading Process Duration (Hours)',
            font: { size: 16, weight: 700 },
            padding: 0,
            color: '#000000',
        },
        datalabels: {
            display: true,
            color: '#000000',
            anchor: 'end',
            offset: -20,
            align: 'start',
        },
    },
})
