import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'

export const columns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'Name',
        dataIndex: 'characteristicName',
        key: 'characteristicName',
    },
    {
        title: 'Table',
        dataIndex: 'table',
        key: 'table',
    },
    {
        title: 'Field',
        dataIndex: 'field',
        key: 'field',
    },
    {
        title: 'Sorted',
        dataIndex: 'sorted',
        key: 'sorted',
    },
]
