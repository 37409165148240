import React, { useState } from 'react'
import { Table } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import SearchQueryInput from 'components/Common/SearchQueryInput'
// import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

const dataSource = [
    {
        key: '1',
        bookingId: '1200001',
        customer: 'PMA 1',
        customerDescription: 'PMA Area 1',
        status: 'deleted',
    },
    {
        key: '2',
        bookingId: '1200002',
        customer: 'PMA 2',
        customerDescription: 'PMA Area 2',
        status: null,
    },
    {
        key: '3',
        bookingId: '1200003',
        customer: 'PMA 1',
        customerDescription: 'PMA Area 1',
        status: 'new',
    },
    {
        key: '4',
        bookingId: '1200004',
        customer: 'PMA 2',
        customerDescription: 'PMA Area 2',
        status: null,
    },
]

const columns = [
    {
        title: 'Booking ID',
        dataIndex: 'bookingId',
        key: 'bookingId',
    },
    {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
    },
    {
        title: 'Customer Description',
        dataIndex: 'customerDescription',
        key: 'customerDescription',
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text, record) => {
    //     const color = {
    //       deleted: "red",
    //       new: "green",
    //     };
    //     if (!text) return <></>;
    //     return (
    //       <Tag className="rounded-5" color={color[text]}>
    //         {text}
    //       </Tag>
    //     );
    //   },
    // },
]

function AddBookingId({ ...props }) {
    const [selectedRowKeys, setSelectedRows] = useState([])

    const rowSelection = () => ({
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRowKeys)
        },
        selectedRowKeys,
    })

    return (
        <SimpleModal title="Add Booking ID" okText="Create" width={636} zIndex={2000} {...props}>
            <SearchQueryInput
                className="mt-2 mb-3"
                suffix={<div ClassName="text-nabati-blue"></div>}
            />
            <div className="flex items-center h-8">
                {<p className="mr-5 font-bold">Selected: {selectedRowKeys.length}</p>}
            </div>
            <Table
                dataSource={dataSource}
                columns={columns}
                rowClassName={(_, index) =>
                    `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                }
                pagination={false}
                rowSelection={rowSelection()}
                rowKey="bookingId"
            />
        </SimpleModal>
    )
}

export default AddBookingId
