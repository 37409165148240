import React from 'react'
import SelectDebounceFetch from './index'
import { getBinList } from 'api/master/bin'
const data = [
    {
        value: '1',
        label: "True"
    },
    {
        value: '0',
        label: "False"
    },
   
]
function SelectScanPalletStatus({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Scan Pallet Status"
            fetchOptions={async (searchTerm) => {
                const list = data
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectScanPalletStatus
