import { CaretDownOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { useReducer } from 'react'
import { FILTER_BY } from 'config/constant/groupFilter'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import RichTable from 'components/Common/RichTable'
import SimpleContainer from 'components/layouts/simple'
import { reportCheckinOutColumns } from './columns'
import {
   getListCheckinOut
} from 'api/report/outbound'
import {
    SET_SELECTED_ROWS,
    TOGGLE_PLANNER_DISPLAY
} from 'config/constant/common'
const initialState = {
    selectedRows: [],
    ddetailDataModal: false,
    detailDataPayload:null
}
const { BY_DC_LOCATION, BY_TRANSACTION_TYPE, BY_CHECKIN_STATUS } = FILTER_BY
const stateReducer = (state, action) => {
    switch (action.type) {
        default:
            return state
    }
}
const ReportCheckinOut = () => {
    const [state, dispatch] = useReducer(stateReducer, initialState)
	return (
        <RichTable
            rowKey="transactionId"
            columns={reportCheckinOutColumns()}
            showButtonsArea={false}
            withRowSelection={false}
            withSearchAndFilterArea={true}
            filterField={[BY_DC_LOCATION,BY_TRANSACTION_TYPE,BY_CHECKIN_STATUS]}
            getListApi={getListCheckinOut}
            withDatePicker={true}
            type="rich"
            scroll={{ x: 1300 }}
        >
        </RichTable>
	)
}

export default ReportCheckinOut