import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewAssignPlant } from 'api/master/assignPlantToCompany'

function AddAssignPlantModal({ close, visible }) {
    return (
        <ModalForm
            title="Add Company"
            formItems={formItems}
            callApi={createNewAssignPlant}
            successMsg={(values) => `New Plant was assigned to Company`}
            close={close}
            visible={visible}
        />
    )
}

export default AddAssignPlantModal
