import React, { useState } from 'react'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import useQueryRefresh from 'customHooks/useQueryRefresh'

import { deletePrioritybin } from 'api/master/priorityBin'

function ConfirmationDeleteModal({ payload, visible, close }) {
    const [loading, setLoading] = useState(false)
    const { doRefresh } = useQueryRefresh()

    const handleDelete = async () => {
        const reqBody = [
            {
                companyId: payload.companyId,
                plantId: payload.plantId,
                building: payload.building,
                priorityId: payload.priorityId,
                sortNo: payload.sortNo,
            },
        ]
        setLoading(true)

        try {
            const res = await deletePrioritybin(reqBody)
            if (!res.success) return res
            setLoading(false)
            doRefresh()
            return res
        } catch (error) {
            setLoading(false)
            console.error(error)
            return { success: false }
        }
    }

    return (
        <ConfirmationModal
            loading={loading}
            visible={visible}
            title="Are you sure you want to delete?"
            content={`This will permanently delete ${payload?.priorityId} Action can not be undone.`}
            successTitleMsg="Delete success!"
            close={close}
            onOk={handleDelete}
            centered={false}
            okText="Delete"
        />
    )
}

export default ConfirmationDeleteModal
