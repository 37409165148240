import { CaretDownOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { useReducer } from 'react'
import { FILTER_BY } from 'config/constant/groupFilter'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import RichTable from 'components/Common/RichTable'
import SimpleContainer from 'components/layouts/simple'
import { reportTransDcColumns } from './columns'
import {
    getListTransferDc,
} from 'api/report/inbound'
import {
    SET_SELECTED_ROWS,
    TOGGLE_PLANNER_DISPLAY
} from 'config/constant/common'
const initialState = {
    selectedRows: [],
    ddetailDataModal: false,
    detailDataPayload:null
}
const { BY_MATERIAL, BY_COMPANY, BY_PLANT, BY_BUILDING ,BY_GATE, BY_TRANSACTION_STATUS, BY_PALLET_STATUS, BY_CHECK_PALLET_STATUS,BY_PICKER_PALLET_STATUS} = FILTER_BY
const stateReducer = (state, action) => {
    switch (action.type) {
        default:
            return state
    }
}
const ReportTransferDc = () => {
    const [state, dispatch] = useReducer(stateReducer, initialState)
	return (
        <RichTable
            columns={reportTransDcColumns()}
            showButtonsArea={false}
            withRowSelection={false}
            withSearchAndFilterArea={true}
            filterField={[BY_MATERIAL,BY_PLANT,BY_GATE,BY_TRANSACTION_STATUS,BY_PALLET_STATUS,BY_CHECK_PALLET_STATUS,BY_PICKER_PALLET_STATUS]}
            getListApi={getListTransferDc}
            withDatePicker={true}
            type="rich"
            scroll={{ x: 1300 }}
        >
        </RichTable>
	)
}

export default ReportTransferDc