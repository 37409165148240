import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'

// Modal Component
import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddEditModal from './modals/add-edit-form'
import QrPrintPreviewModal from './modals/qr-code-print-preview'
import SelectionPrintQrModal from './modals/selection-print-qr-bin'
import XlsxUploader from 'components/Common/Uploader/XlsxUploader'
// import SelectPrintColumnButton from './modals/qr-code-print-preview/SelectPrintColumnButton'

import {
    getBinList,
    deleteBin,
    uploadBinTemplate,
    createNewBin,
    updateBin,
    downloadBinTemplate,
} from 'api/master/bin'

import { binColumns } from './columns'
import { DownloadIc, QrIc } from 'assets'

function Bin() {
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [selectedRows, setSelectedRows] = useState([])

    // Modal State
    const [showUpdateBinModal, setUpdateBinModal] = useState(false)
    const [showAddBinModal, setAddBinModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showQrListModal, setShowQrListModal] = useState(false)
    const [selectionQrModal, setSelectionQrModal] = useState(false)

    const { doRefresh } = useQueryRefresh()

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateBinModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = selectedRows.map((row) => ({
                    companyId: row.companyId,
                    plantId: row.plantId,
                    slocId: row.slocId,
                    binId: row.binId,
                }))

                const res = await deleteBin(reqBody)

                if (!res.success) return
                doRefresh()
            },
        })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete" onClick={handleBulkDelete} />
    )

    const handleDownload = async () => {
        await downloadBinTemplate()
    }

    const buttons = () => (
        <Row justify="end">
            {/* <SelectPrintColumnButton /> */}
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddBinModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-48 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
            <Button
                onClick={handleDownload}
                className="outLinedBlue flex justify-center items-center w-48 h-45 rounded-10 mx-1"
                size="middle "
            >
                <DownloadIc />
                <span className="ml-2">Download Template</span>
            </Button>
            <XlsxUploader callApi={uploadBinTemplate} />
            <Button
                // disabled={selectedRows?.length === 0}
                onClick={() => setSelectionQrModal(true)}
                className="outLinedBlue flex justify-center items-center w-48 h-45 rounded-10 mx-1"
                size="middle "
            >
                <QrIc />
                <span className="ml-2">Print QR Code</span>
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                rowKey="binId"
                columns={binColumns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getBinList}
                // withDatePicker={true}
                scroll={{ x: 1300 }}
                type="rich"
            />
            <AddEditModal
                title="Add Bin"
                close={() => setAddBinModal(false)}
                visible={showAddBinModal}
                payload={null}
                successMsg="Success add BIN"
                callApi={createNewBin}
            />
            <AddEditModal
                title="Edit Bin"
                close={() => setUpdateBinModal(false)}
                visible={showUpdateBinModal}
                payload={modalFormPayload}
                isEditMode={true}
                successMsg={(values) => 'Success edit BIN'}
                callApi={updateBin}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
            <QrPrintPreviewModal
                binIds={[...selectedRows].map((i) => ({ id: i.binId }))}
                visible={showQrListModal}
                close={() => setShowQrListModal(false)}
            />
            <SelectionPrintQrModal
                visible={selectionQrModal}
                close={() => setSelectionQrModal(false)}
            />
        </>
    )
}

export default Bin
