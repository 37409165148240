import React from 'react'
import { Row, Col } from 'antd'
import CardDatePicker from 'components/Common/CardDatePicker'

// CHART COMPONENT
import ChartCheckerInbound from './charts/chart-checker-inbound'
import ChartProductivityInbound from './charts/chart-productivity-inbound'
// import ChartCapacityVsProductionSloc from './chart-capacity-vs-prod-sloc'

function SectionOne() {
    return (
        <CardDatePicker className="mt-5">
            <Row gutter={16}>
                <Col span={12}>
                    <ChartCheckerInbound />
                </Col>
                <Col span={12}>
                    <ChartProductivityInbound />
                </Col>
            </Row>
        </CardDatePicker>
    )
}

export default SectionOne
