import React, { useContext } from 'react'
import { Button, Table, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import useDashboardState from '../useDashboardState'
import SimpleCard from 'components/layouts/simple'
import { Link, useNavigate } from 'react-router-dom'
import { scrollToTop } from 'utils/misc'


function InventoryArea({ columns, data, label }) {

    return (
        <SimpleCard className="my-5">
            <p className="text-xl font-bold mt-1 mb-4">Inventory</p>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    gap: 18,
                }}
            >
                { (data.length > 0) ? data.map((item, index) => (
                    <div
                        className="flex flex-col justify-between p-1 rounded-lg"
                        style={{
                            border: '0.5px solid #BBBBBB',
                        }}
                    >
                        <div className="flex justify-between items-center m-1 text-base font-bold">
                            <p className="mr-2 text-sm">Inventory {label[index]}</p>
                            <Tooltip
                                title="tooltip"
                                color="#F4FBFC"
                                overlayInnerStyle={{
                                    color: 'black',
                                    fontWeight: 'bold',
                                }}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                        <div>
                            <Table
                                className="text-sm"
                                showHeader={false}
                                columns={columns}
                                dataSource={data[index]}
                                size="small"
                                pagination={false}
                                rowClassName={(_, index) =>
                                    index % 2 === 0 ? '' : 'bg-nabati-lightGray'
                                }
                            />
                        </div>
                        <div className="m-1 mt-3">
                        {/*  */}
                        <Link to="/transaction/planner-report-inventory">
                            <Button type="link"  className="w-full h-8 rounded-lg text-nabati-blue border-nabati-blue">
                                More Info
                            </Button>
                        </Link>
                        </div>
                    </div>
                )) 
                : <div
                className="flex flex-col justify-between p-1 rounded-lg"
                style={{
                    border: '0.5px solid #BBBBBB',
                }}
            >
                <div className="flex justify-between items-center m-1 text-base font-bold">
                    <p className="mr-2 text-sm">Inventory </p>
                    <Tooltip
                        title="tooltip"
                        color="#F4FBFC"
                        overlayInnerStyle={{
                            color: 'black',
                            fontWeight: 'bold',
                        }}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
              
                <div className="m-1 mt-3">
                {/*  */}
                <Link to="/transaction/planner-report-inventory">
                    <Button type="link"  className="w-full h-8 rounded-lg text-nabati-blue border-nabati-blue">
                        More Info
                    </Button>
                </Link>
                </div>
            </div>
                }
            </div>
        </SimpleCard>
    )
}

export default InventoryArea
