import React, { useReducer } from 'react'
import { Button, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import ConfirmationSubmitModal from 'components/Common/Modals/ConfirmationModal'

import AssignSPVModal from '../planner-do-execution/modals/assign-spv'
import CreateReservationModal from './modals/create-reservation'
import useQueryRefresh from 'customHooks/useQueryRefresh'
import { columns } from './columns'

import {
    getListReservation,
    // createPlannerReservationDraft,
    startLoadingScrap,
    startLoadingReservation,
    postScrapToSap,
    postReservationToSap,
} from 'api/transaction/plannerGoodIssue'

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ROWS':
            return { ...state, selectedRows: [...action.selectedRows] }
        case 'TOGGLE_CREATE_RESERVATION':
            return { ...state, createReservationModal: !state.createReservationModal }
        case 'TOGGLE_ASSIGN_SPV':
            return { ...state, assignSpvModal: !state.assignSpvModal }
        case 'SET_ASSIGN_SPV_API':
            return { ...state, assignSpvApi: action.assignSpvApi }
        case 'TOGGLE_SUBMIT_RESERVATION':
            return { ...state, submitReservationModal: !state.submitReservationModal }
        case 'SET_MODAL_PAYLOAD':
            return { ...state, modalFormPayload: action.modalFormPayload }
        default:
            return state
    }
}

const initialState = {
    selectedRows: [],
    createReservationModal: false,
    assignSpvModal: false,
    assignSpvApi: () => {},
    submitReservationModal: false,
    modalFormPayload: null,
}

function PlannerStockGoodIssue() {
    const [state, dispatch] = useReducer(stateReducer, initialState)
    const { doRefresh } = useQueryRefresh()

    const onChangeSelectedRows = (rowKeys, rows) => {
        dispatch({ type: 'SET_SELECTED_ROWS', selectedRows: rows })
    }

    const onClickCreateReservation = () => {
        dispatch({ type: 'TOGGLE_CREATE_RESERVATION' })
        dispatch({ type: 'SET_MODAL_PAYLOAD', modalFormPayload: { createMode: true } })
    }

    const onClickStartLoading = () => {
        const type = state.selectedRows[0].type.toLowerCase() === 'scrap' ? 'scrap' : 'reservation'

        if (type === 'scrap') {
            return Modal.error({
                title: 'Oops',
                content: 'Start loading only for reservation CC/IO',
            })
        }

        const callApi = type === 'scrap' ? startLoadingScrap : startLoadingReservation

        dispatch({ type: 'TOGGLE_ASSIGN_SPV' })
        dispatch({ type: 'SET_ASSIGN_SPV_API', assignSpvApi: callApi })
    }

    const onClickSubmit = () => {
        dispatch({ type: 'TOGGLE_SUBMIT_RESERVATION' })
    }

    const handleSubmit = async () => {
        const type = state.selectedRows[0].type.toLowerCase() === 'scrap' ? 'scrap' : 'reservation'
        const callApi = type === 'scrap' ? postScrapToSap : postReservationToSap

        const reqBody = {
            transaction_id: state.selectedRows[0].transactionId,
        }
        try {
            const res = await callApi(reqBody)
            if (!res.success) {
                const isErrorHasLogs = !!res?.logs || !!res.errors
                const errorLogs = res?.logs || res.errors || []

                if (!isErrorHasLogs) {
                    dispatch({ type: 'TOGGLE_SUBMIT_RESERVATION' })
                    return { success: false }
                }
                Modal.error({
                    title: 'Oops',
                    content: errorLogs.reduce(
                        (curr, err) => (
                            <>
                                {curr}
                                <br />
                                <span>{`Type: ${err.type} - ${err.message}`}</span>
                            </>
                        ),
                        ''
                    ),
                    zIndex: 1002,
                    width: 500,
                })
                dispatch({ type: 'TOGGLE_SUBMIT_RESERVATION' })
                return res
            }
            doRefresh()
            return res
        } catch (error) {
            console.error(error)
            return { success: false }
        }
    }

    const onClickEditIcon = (row) => {
        dispatch({ type: 'TOGGLE_CREATE_RESERVATION' })
        dispatch({ type: 'SET_MODAL_PAYLOAD', modalFormPayload: row })
    }

    const onClickDisplayIcon = (row) => {
        dispatch({ type: 'TOGGLE_CREATE_RESERVATION' })
        dispatch({ type: 'SET_MODAL_PAYLOAD', modalFormPayload: { ...row, displayMode: true } })
    }

    const buttons = () => (
        <ButtonsGroupGrid>
            <Button
                onClick={onClickCreateReservation}
                type="primary"
                className="w-157 h-45 rounded-10 mx-2"
            >
                Create Reservation
            </Button>
            <Button
                onClick={onClickStartLoading}
                className="flatBlue w-157 h-45 rounded-10 mx-2"
                disabled={state.selectedRows.length !== 1}
            >
                Start Loading
            </Button>
            <Button
                onClick={onClickSubmit}
                className="flatPink w-157 h-45 rounded-10 mx-2"
                disabled={
                    state.selectedRows.length !== 1 || state.selectedRows[0].reservationNumber
                }
            >
                Submit
            </Button>
        </ButtonsGroupGrid>
    )

    return (
        <RichTable
            columns={columns(onClickEditIcon, onClickDisplayIcon)}
            showButtonsArea={true}
            showSearch={true}
            withRowSelection={true}
            withSearchAndFilterArea={true}
            ButtonsAreaComponent={buttons}
            getListApi={getListReservation}
            withDatePicker={true}
            onChangeSelectedRows={onChangeSelectedRows}
            scroll={{ x: 1300 }}
            withFloatingRowSelectionInfo
        >
            <CreateReservationModal
                payload={state.modalFormPayload}
                visible={state.createReservationModal}
                close={() => {
                    dispatch({ type: 'TOGGLE_CREATE_RESERVATION' })
                    dispatch({ type: 'SET_MODAL_PAYLOAD', modalFormPayload: null })
                }}
            />
            <AssignSPVModal
                visible={state.assignSpvModal}
                close={() => dispatch({ type: 'TOGGLE_ASSIGN_SPV' })}
                transactionId={state.selectedRows[0]?.transactionId || ''}
                startLoadingApi={state.assignSpvApi}
            />
            <ConfirmationSubmitModal
                visible={state.submitReservationModal}
                title="Are you sure Submit Reservation?"
                content={`Transaction ID: ${state.selectedRows[0]?.transactionId}`}
                successTitleMsg="Submit Success!"
                successContentMsg={(res) => `SAP NUMBER: ${res?.sapNumber}`}
                close={() => dispatch({ type: 'TOGGLE_SUBMIT_RESERVATION' })}
                onOk={handleSubmit}
                centered={false}
                okText="Submit"
            />
        </RichTable>
    )
}

export default PlannerStockGoodIssue
