import moment from 'moment'
import { useSearchParams } from 'react-router-dom'

const DATE_FORMAT = 'DD-MM-YYYY'

const DEFAULT_ARGS = {
    resetAllQuery: false,
    resetToToday: false,
}

function useQueryRefresh() {
    const [searchParams, setSearchParams] = useSearchParams()

    const doRefresh = ({ resetAllQuery, resetToToday } = DEFAULT_ARGS) => {
        // rc = Random Refresh Code
        const randomRefreshCode = Date.now()

        if (resetToToday) {
            return setSearchParams({
                ...Object.fromEntries([...searchParams]),
                from: moment().format(DATE_FORMAT),
                to: moment().format(DATE_FORMAT),
            })
        }

        if (!resetAllQuery) {
            return setSearchParams({
                ...Object.fromEntries([...searchParams]),
                rrc: randomRefreshCode,
            })
        }

        if (resetAllQuery) {
            return setSearchParams({ rrc: randomRefreshCode })
        }
    }

    return { doRefresh }
}

export default useQueryRefresh
