import { useState, useContext } from 'react'
import { Button, Modal, message, Input } from 'antd'
import { CheckCircleIcon } from 'assets'
import { RichTableContext } from 'components/Common/RichTable'
import { checkPassword } from 'api/user'
import { getLocalStorage } from 'utils/localStorage'
import { LOCAL_STORAGE_KEYS } from 'config/localStorage'

const defaultSuccessTitle = (
    <div className="flex justify-center items-center text-nabati-green text-2xl">
        <CheckCircleIcon /> <span className="m-2">Success</span>
    </div>
)

export default function ConfirmWithPasswordModal({
    visible,
    close,
    onOk = async () => {},
    onCancel = () => {},
    title = defaultSuccessTitle,
    content,
    successTitleMsg = 'Success',
    successContentMsg = () => '' || '',
    okText = 'OK',
    cancelText = 'Cancel',
    width = 450,
    ...props
}) {
    const [loading, setLoading] = useState(false)
    const [inputPassword, setInputPassword] = useState('')
    const richTableContext = useContext(RichTableContext)

    const username = JSON.parse(getLocalStorage(LOCAL_STORAGE_KEYS.USER))?.userName

    const handleOk = async (e) => {
        setLoading(true)
        let res = null
        try {
            const isPasswordPassed = await checkPassword({
                username,
                password: inputPassword,
            })

            if (!isPasswordPassed) {
                message.error('Incorrect Password')
                setLoading(false)
                return
            }

            res = await onOk(e)
            if (res?.success === false) {
                if (!res?.message) message.error('Oops... Something went wrong')
                return setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            return console.error(err)
        }
        setInputPassword('')
        setLoading(false)

        if (!res || res.success === false) return
        Modal.success({
            title: successTitleMsg,
            onOk: close,
            content:
                typeof successContentMsg === 'function'
                    ? successContentMsg(res)
                    : successContentMsg,
        })

        // RESET ROW SELECTION RICH TABLE CONTEXT
        if (richTableContext && richTableContext.resetRowSelections)
            richTableContext.resetRowSelections()

        setLoading(false)
    }

    return (
        <Modal
            closable={false}
            visible={visible}
            onCancel={() => {
                setInputPassword('')
                setLoading(false)
                close()
            }}
            footer={null}
            destroyOnClose
            width={width}
            bodyStyle={{ padding: 20 }}
            zIndex={1001}
            {...props}
        >
            <div style={{ color: '#101820' }}>
                {title && <div className="text-2xl font-bold mb-1">{title}</div>}
                {content && <div>{content}</div>}
                <Input.Password
                    className="mt-5 rounded-10 h-50 w-full"
                    placeholder="Input Password Login"
                    size="large"
                    value={inputPassword}
                    onChange={(e) => setInputPassword(e.target.value)}
                />
                <div
                    className="mt-5"
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                        gap: 10,
                    }}
                >
                    <Button
                        className="h-11 rounded-10 text-nabati-pink border-nabati-pink"
                        onClick={() => {
                            setInputPassword('')
                            onCancel()
                            close()
                        }}
                        type="ghost"
                    >
                        {cancelText}
                    </Button>
                    <Button
                        className="h-11 rounded-10"
                        onClick={handleOk}
                        type="primary"
                        loading={loading}
                    >
                        {okText}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
