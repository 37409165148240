import { toCamelCase, camelCaseToTitleCase } from 'utils/formatter'

export const parseBodyToArrObj = (body) => {
    if (!body) return []
    const result = Object.entries(toCamelCase(JSON.parse(body))).map(([name, initialValue]) => ({
        name,
        label: camelCaseToTitleCase(name) || '',
        initialValue,
        mode: Array.isArray(initialValue) ? 'multiple' : undefined,
        isMultiple: Array.isArray(initialValue),
    }))

    return result
}

export const stringifyBodyObj = (obj) => {
    const objValue = Object.entries(obj)
        .map(([key, value]) => {
            const isMultiple = Array.isArray(value)
            return { key, value: isMultiple ? value.map((v) => v.value) : value.value }
        })
        .reduce((acc, cur) => {
            return { ...acc, [cur.key]: cur.value }
        }, {})
    const result = JSON.stringify(objValue)
    return result
}
