import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getGlList = async (params) => {
    const response = await call(METHODS.GET, '/master/gl', params)
    return response.data
}

export const searchGlList = async (params) => {
    // params = {
    //     key: '0041010001',
    // }
    const response = await call(METHODS.GET, '/master/gl/search', params)
    return response.data
}

export const getGlOptionByPlant = async (params) => {
    const response = await call(METHODS.POST, '/master/gl/getbyplant', params)
    return response.data
}
