import React, { useCallback, useReducer, useEffect } from 'react'
import moment from 'moment'
import { Button, Row, Col, Modal, Input, DatePicker } from 'antd'

import { auth } from 'auth'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import ImageUploader from 'components/Common/Uploader/ImageUploader'
// import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
// import SelectGlByPlant from 'components/Common/SelectFetchByPlant/selectGlByPlant'
// import SelectCostCenterByPlant from 'components/Common/SelectFetchByPlant/selectCostCenterByPlant'
// import SelectOrderByPlant from 'components/Common/SelectFetchByPlant/selectOrderByPlant'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import DataTable from './data-table'
import { createScrapDraft, moveBinPlanner } from 'api/transaction/plannerFisikWms'


const initState = {
    loading: false,
    formData: {
        transactionId: '',
        plant: '',
        reservationDate: moment().format('YYYY-MM-DD'), //pstngDate
        docDate: moment().format('YYYY-MM-DD'),
        refDocNo: '', // ???
        headerTxt: '',
        deliveryNote: '',
        billOfLanding: '',
        images: [],
        source: '1', // ???
    },
    dataTable: [],
    initialDataTable: [],
    confirmationSaveModal: false,
    confirmationDeleteModal: false,
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'SET_FORM_DATA':
            return { ...state, formData: { ...state.formData, ...action.payload } }
        case 'SET_DATA_TABLE':
            return { ...state, dataTable: action.dataTable }
        case 'SET_INITIAL_DATA_TABLE':
            return { ...state, initialDataTable: action.initialDataTable }
        case 'TOGGLE_CONFIRM_SAVE':
            return { ...state, confirmationSaveModal: action.toggle }
        case 'TOGGLE_CONFIRM_DELETE':
            return { ...state, confirmationDeleteModal: action.toggle }
        case 'RESET':
            return initState
        default:
            return state
    }
}

function CreateMoveToBin({ close, payload, isOnEditMode, visible, ...props }) {
    const [state, dispatch] = useReducer(stateReducer, initState)
    const { doRefresh } = useQueryRefresh()

    const setDataTable = useCallback((dataTable) => {
        dispatch({ type: 'SET_DATA_TABLE', dataTable })
    }, [])

    const validateBeforeSave = () => {
        const error = []
        // state.dataTable.forEach((dt) => {
        //     if (!dt.gl || !dt.costCenter)
        //         error.push(`${dt.materialId}: Please add GL and Cost Center`)
        // })
        if (error.length > 0) return error
        return error
    }

    const onClickSave = () => {
        const error = validateBeforeSave()
        if (error.length > 0)
            return Modal.error({
                title: 'Oops',
                width: 500,
                content: error.reduce(
                    (acc, cur) => (
                        <>
                            {acc}
                            <p>{cur}</p>
                        </>
                    ),
                    <></>
                ),
            })
        dispatch({ type: 'TOGGLE_CONFIRM_SAVE', toggle: true })
    }

    const handleSave = async () => {

        const reqBody = state.initialDataTable
        console.log("reqBody")
        console.log(reqBody)
        dispatch({ type: 'SET_LOADING', loading: true })
        try {
            const res = await moveBinPlanner(reqBody)
            if (!res.success) return res
            dispatch({ type: 'SET_LOADING', loading: false })
            doRefresh()
            close()
            return res
        } catch (error) {
            console.error(error)
            dispatch({ type: 'SET_LOADING', loading: false })
            return { success: false }
        }
    }

    useEffect(() => {
        if (!visible) return
        if (isOnEditMode) return

        dispatch({ type: 'RESET' })
        dispatch({
            type: 'SET_FORM_DATA',
            payload: {
                transactionId: payload?.transactionId,
                company: payload?.companyId,
                plant: payload?.plantId,
            },
        })
        console.log("payload ===================================")
        console.log(payload)
        dispatch({
            type: 'SET_INITIAL_DATA_TABLE',
            initialDataTable: payload
        })
    }, [payload, isOnEditMode, visible])

    return (
        <>
            <SimpleModal
                title={'Move To Bin'}
                width={1190}
                footer={null}
                visible={visible}
                {...props}
            >

                <DataTable
                    isOnEditMode={isOnEditMode}
                    setDataTable={setDataTable}
                    initialDataTable={state.initialDataTable}
                    loading={state.loading}e
                />
                <Row justify="end" className="mt-5">
                    <Button onClick={close} className="outLinedPink w-157 h-45 rounded-10 mx-2">
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={onClickSave}
                        className="w-157 h-45 rounded-10 mx-2"
                    >
                        Save
                    </Button>
                </Row>
            </SimpleModal>
            <ConfirmationModal
                title="Confirm Submit?"
                content={`Are you sure to create this?`}
                successTitleMsg="Success!"
                successContentMsg={(res) => `Data successfully move`}
                onOk={handleSave}
                centered={false}
                okText="Save"
                visible={state.confirmationSaveModal}
                close={() => dispatch({ type: 'TOGGLE_CONFIRM_SAVE', toggle: false })}
            />
        </>
    )
}

export default CreateMoveToBin
