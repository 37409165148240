import React, { useContext, useState, useEffect, useMemo } from 'react'
import BarChart from 'components/Common/Chart/BarChart'

import { getCheckerDurationProductivity } from 'api/dashboard/inbound'

// Context
import { CardDatePickerContext } from 'components/Common/CardDatePicker'
import { DashboardInboundContext } from '../../index'

import { options } from './options'

function ChartProductivityInbound() {
    const [loading, setLoading] = useState(false)
    const [checkerData, setCheckerData] = useState([])

    const { dateRange } = useContext(CardDatePickerContext)
    const { dcLocation } = useContext(DashboardInboundContext)

    // Data and Labels
    const labels = useMemo(() => Object.keys(checkerData || {}), [checkerData])
    const datasets = [{ label: 'Series1', data: [...Object.values(checkerData || {})] }]

    useEffect(() => {
        if (!dcLocation) return
        const { startDate, endDate } = dateRange
        const reqBody = {
            startDate,
            endDate,
            dcLocationId: [dcLocation?.value],
        }
        const fetchData = async () => {
            setLoading(true)
            try {
                const res = await getCheckerDurationProductivity(reqBody)
                setCheckerData(res?.data?.checkerInboundProductivity || {})
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }

        fetchData()
    }, [dateRange, dcLocation])

    return (
        <BarChart loading={loading} options={options()} datasets={datasets} labels={[...labels]} />
    )
}

export default ChartProductivityInbound
