import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getCountryList = async (params) => {
    const response = await call(METHODS.GET, '/master/country', params)
    return response.data
}

export const getCountryFromSap = async (params) => {
    const response = await call(METHODS.GET, '/master/country/getsap', params)
    return response.data
}
