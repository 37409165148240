import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, InfoYellowIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const reportChangeBinColumns = (
) => [
    {
        title : 'Transaction Id',
        dataIndex : 'transactionId',
        key : 'transactionId',
    },
    {
        title : 'Status',
        dataIndex : 'status',
        key : 'status',
    },
    {
        title : 'Is Draft',
        dataIndex : 'isDraft',
        key : 'isDraft',
    },
    {
        title : 'Total Pallet',
        dataIndex : 'totalPallet',
        key : 'totalPallet',
    },
    {
        title : 'Total Sku',
        dataIndex : 'totalSku',
        key : 'totalSku',
    },
    {
        title : 'Draft',
        dataIndex : 'isDraft',
        key : 'isDraft',
        render: (text) => {
            if (text == 1) return 'Yes'
            if (text == 0) return 'No'
            return '-'
        },
    },
    {
        title : 'User Id',
        dataIndex : 'userId',
        key : 'userId',
    },
    {
        title : 'Transaction Id Draft',
        dataIndex : 'transactionIdDraft',
        key : 'transactionIdDraft',
    },
    {
        title : 'Pallet Id',
        dataIndex : 'palletId',
        key : 'palletId',
    },
    {
        title : 'Pallet Ciode',
        dataIndex : 'palletCode',
        key : 'palletCode',
    },
    {
        title : 'Plant Id',
        dataIndex : 'plantId',
        key : 'plantId',
    },
    {
        title : 'Material Id',
        dataIndex : 'materialId',
        key : 'materialId',
    },
    {
        title : 'Batch',
        dataIndex : 'batch',
        key : 'batch',
    },
    {
        title : 'Company Id',
        dataIndex : 'companyId',
        key : 'companyId',
    },
    {
        title : 'Description',
        dataIndex : 'description',
        key : 'description',
    },
    {
        title : 'Bin Id',
        dataIndex : 'binId',
        key : 'binId',
    },
    {
        title : 'To Bin Id',
        dataIndex : 'toBinId',
        key : 'toBinId',
    },
    {
        title : 'Qty',
        dataIndex : 'qty',
        key : 'qty',
    },
    {
        title : 'Uom',
        dataIndex : 'uom',
        key : 'uom',
    },
    {
        title : 'Stock Status',
        dataIndex : 'stockStatus',
        key : 'stockStatus',
    },
    {
        title : 'Sloc Id',
        dataIndex : 'slocId',
        key : 'slocId',
    },
    {
        title : 'Sector Id',
        dataIndex : 'sectorId',
        key : 'sectorId',
    },
    {
        title : 'Gate Id',
        dataIndex : 'gateId',
        key : 'gateId',
    },
    {
        title : 'Stagging In',
        dataIndex : 'staggingIn',
        key : 'staggingIn',
    },
    {
        title : 'Stagging Out',
        dataIndex : 'staggingOut',
        key : 'staggingOut',
    },
    {
        title : 'Stagging In Actual',
        dataIndex : 'staggingInActual',
        key : 'staggingInActual',
    },
    {
        title : 'Docking Id',
        dataIndex : 'dockingId',
        key : 'dockingId',
    },
    {
        title : 'Sled',
        dataIndex : 'sled',
        key : 'sled',
    },
    {
        title : 'Manufacture Date',
        dataIndex : 'manufactureDate',
        key : 'manufactureDate',
    },
    {
        title : 'Status Scan Pallet',
        dataIndex : 'statusScanPallet',
        key : 'statusScanPallet',
    },
    {
        title : 'Count Pallet',
        dataIndex : 'countPallet',
        key : 'countPallet',
    },
    {
        title : 'Is Process',
        dataIndex : 'isProcess',
        key : 'isProcess',
    },
    {
        title : 'Is Reachtruck',
        dataIndex : 'isReachtruck',
        key : 'isReachtruck',
    }
]
