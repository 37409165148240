import React, { useState } from 'react'
import { Table } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import { CheckCircleIcon } from 'assets'

import { createNewDoExecution } from 'api/transaction/plannerDoExecution'

import useQueryRefresh from 'customHooks/useQueryRefresh'

import { auth } from 'auth'

const columns = [
    {
        title: 'Booking ID',
        dataIndex: 'bookingNumber',
        key: 'bookingNumber',
    },
    {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
    },
    {
        title: 'Customer Description',
        dataIndex: 'customerName',
        key: 'customerName',
    },
]

const CreateOutbondIdModal = ({ onClickAddOutbondId, payload, close, ...props }) => {
    const [showConfirmationModal, setConfirmationModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const { doRefresh } = useQueryRefresh()

    const handleOk = async () => {
        const user = auth.getUserInfo()
        setLoading(true)
        const params = payload.map((item) => ({
            idInOut: item.idInOut,
            doNumber: item.documentNumber,
            openQty: +item.qty,
            refDocNo: item.bookingNumber,
            doItem: +item.documentItem,
            dcLocationId: user.dcLocationId,
            companyId: user.companyId,
            plantId: user.plantId,
            information: item.information,
            companyDoHeader: item.companyDoHeader,
            // spvUserId: "",
        }))

        const res = await createNewDoExecution(params)
        if (res.success) {
            setConfirmationModal(true)
            close()
            doRefresh()
        }

        setLoading(false)
    }

    return (
        <>
            <SimpleModal
                title="Create Outbond ID"
                okText="Create"
                onOk={handleOk}
                close={close}
                loading={loading}
                {...props}
            >
                <Table
                    dataSource={payload}
                    columns={columns}
                    rowClassName={(_, index) =>
                        `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                    }
                    pagination={false}
                />
            </SimpleModal>
            <ConfirmationModal
                visible={showConfirmationModal}
                close={() => setConfirmationModal(false)}
                title={
                    <>
                    <div className="flex justify-center items-center text-nabati-green text-2xl">
                        <CheckCircleIcon /> <span className="m-2">Success Created</span><br/>  
                        <br/> 
                    </div>
                     <div className="flex justify-center items-center text-nabati-green text-2xl">
                        <span className="m-2" style={{fontSize:16}}>Do Number Has Been Locked</span> 
                     </div>
                     </>
                }
                isConfirmationOnly
            />
        </>
    )
}

export default CreateOutbondIdModal
