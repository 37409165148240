import React, { useState } from 'react'
import { message } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

function ChangePlantModal({
    visible,
    payload,
    doEditItem,
    // changePlantForAllItem,
    close,
    ...props
}) {
    const [plant, setPlant] = useState('')

    const handleOk = () => {
        if (!plant) return message.error('Please Select Plant')
        const editedItem = { ...payload, plant }
        doEditItem(editedItem)
        message.success('Plant Changed!')
        close()
    }

    return (
        <>
            <SimpleModal
                title="Change Plant"
                visible={visible}
                okText="OK"
                width={636}
                onOk={handleOk}
                close={close}
                {...props}
            >
                <p className="mb-2 text-base text-nabati-grey">Plant</p>
                <SelectDebounceFetch.Plant
                    onChange={({ value }) => setPlant(value)}
                    className="rounded-10 h-50"
                    style={{ width: '100%', borderRadius: '5 !important' }}
                    size="large"
                />
            </SimpleModal>
        </>
    )
}

export default ChangePlantModal
