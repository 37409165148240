import { Navigate, Outlet } from 'react-router-dom'
import DoExecutionWoCheckIn from 'components/pages/transport/do-execution-wo-check-in'

export const transportRoutes = {
    path: '/transport',
    element: <Outlet />,
    children: [
        { index: false, element: <Navigate to="do-execution-wo-check-in" /> },
        { path: 'do-execution-wo-check-in', element: <DoExecutionWoCheckIn /> },
        { path: '*', element: <Navigate to="do-execution-wo-check-in" /> },
    ],
}
