import React, { useState, useEffect } from 'react'
import { Form, Modal, Divider, Button, Select } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

// Hooks
import useQueryRefresh from 'customHooks/useQueryRefresh'

// API
import { getBinByPlant, getBeam, getBinId } from 'api/master/bin'

import QrPrintPreviewModal from './qr-code-print-preview'

function SelectionPrintQrCode({ close, visible }) {
    const [loading, setLoading] = useState(false)

    // Select Value State
    const [companyState, setCompany] = useState('')
    const [plantState, setPlant] = useState('')
    const [binState, setBin] = useState('')

    // SELECT OPTIONS
    const [binOptions, setBinOptions] = useState([])
    const [beamOptions, setBeamOptions] = useState([])

    // BIN ID RESULT AFTER SUBMIT
    const [printPreviewModal, setPrintPreview] = useState(false)
    const [binIds, setBinIds] = useState([])

    const [form] = Form.useForm()
    const { submit, getFieldsValue, setFieldsValue } = form
    const { doRefresh } = useQueryRefresh()

    const resetState = () => {
        setCompany('')
        setPlant('')
        setBin('')
        setBinOptions([])
        setBeamOptions([])
    }

    const onChangeCompany = (e) => {
        setCompany(e?.value || '')
        setFieldsValue({ beam: undefined })
    }

    const onChangePlant = (e) => {
        setPlant(e?.value || '')
        setFieldsValue({ binNo: undefined, beam: undefined })
    }

    const onChangeBin = (e) => {
        setBin(e?.value || '')
        setFieldsValue({ beam: undefined })
    }

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        const payload = {
            companyId: values.companyId.value,
            plantId: values.plantId.value,
            binNo: values.binNo.value,
            beam: values.beam.map((b) => b.value),
        }

        try {
            const res = await getBinId(payload)
            if (res.success || res.status) {
                setBinIds(res?.data || [])
                setPrintPreview(true)
                resetState()
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }

        setLoading(false)
    }

    useEffect(() => {
        // GET BIN OPTIONS
        if (!plantState) return
        setLoading(true)
        const fetchBinOptions = async () => {
            try {
                const res = await getBinByPlant({ plantId: plantState })
                setBinOptions(res?.data || [])
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }
        fetchBinOptions()
    }, [plantState])

    useEffect(() => {
        // GET BEAM/LINE OPTIONS
        if (!companyState || !plantState || !binState) return
        setLoading(true)
        const fetchBeamOptions = async () => {
            const { plantId, companyId, binNo } = getFieldsValue(true)
            try {
                const res = await getBeam({
                    plantId: plantId?.value || '',
                    companyId: companyId?.value || '',
                    binNo: binNo?.value || '',
                })
                setBeamOptions(res?.data || [])
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }
        fetchBeamOptions()
    }, [getFieldsValue, plantState, binState, companyState])

    return (
        <>
            <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
                <p className="text-lg">
                    <b>Selection Print QR Code</b>
                </p>
                <Divider className="border-black my-4" />
                <Form
                    requiredMark={false}
                    layout="vertical"
                    form={form}
                    preserve={false}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                        gap: 10,
                    }}
                >
                    <Form.Item
                        className="my-1"
                        style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="companyId"
                        label="Company"
                        shouldUpdate={true}
                        rules={[{ required: true }]}
                    >
                        <SelectDebounceFetch.Company onChange={onChangeCompany} />
                    </Form.Item>
                    <Form.Item
                        className="my-1"
                        style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="plantId"
                        label="Plant"
                        shouldUpdate={true}
                        rules={[{ required: true }]}
                    >
                        <SelectDebounceFetch.Plant onChange={onChangePlant} />
                    </Form.Item>
                    <Form.Item
                        className="my-1"
                        style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="binNo"
                        label="BIN"
                        shouldUpdate={true}
                        rules={[{ required: true }]}
                    >
                        <Select
                            labelInValue
                            disabled={!plantState}
                            placeholder="Select BIN"
                            className="rounded-5"
                            size="large"
                            onChange={onChangeBin}
                        >
                            {binOptions?.map((opt) => (
                                <Select.Option key={opt.binNo} value={opt.binNo}>
                                    {opt.binNo}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        className="my-1"
                        style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="beam"
                        label="Beam/Line"
                        shouldUpdate={true}
                        rules={[{ required: true }]}
                    >
                        <Select
                            labelInValue
                            mode="multiple"
                            disabled={!plantState || !binState || !companyState}
                            placeholder="Select Beam"
                            className="rounded-5"
                            size="large"
                        >
                            {beamOptions?.map((opt) => (
                                <Select.Option key={opt.beam} value={opt.beam}>
                                    {opt.beam}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
                <div className="flex justify-end items-center">
                    <Button
                        className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                        style={{ width: 197, height: 45 }}
                        onClick={() => {
                            close()
                            resetState()
                        }}
                        type="ghost"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="rounded-10 mx-1 mt-5 "
                        style={{ width: 197, height: 45 }}
                        onClick={handleSubmit}
                        type="primary"
                        loading={loading}
                    >
                        Print QR
                    </Button>
                </div>
            </Modal>
            <QrPrintPreviewModal
                binIds={[...binIds].map((i) => ({ id: i.binId }))}
                visible={printPreviewModal}
                close={() => setPrintPreview(false)}
            />
        </>
    )
}

export default SelectionPrintQrCode
