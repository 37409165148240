import React, { useState, useEffect } from 'react'
import { Select, Spin } from 'antd'
import InputSkeleton from 'components/Common/SkeletonLoading/inputSkeleton'

import { removeDuplicateObjectInArray } from 'utils/array'

import { auth } from 'auth'

const SelectCompanyByUser = ({
    loading,
    onChange = () => {},
    placeholder = 'Company',
    className = 'rounded-10 h-50',
    style = { width: '100%' },
    size = 'large',
    ...props
}) => {
    const [loadingOpt, setLoadingOpt] = useState(false)
    const [optList, setOptList] = useState([])
    const user = auth.getUserInfo()

    useEffect(() => {
        const fetchOptions = async () => {
            setLoadingOpt(true)
            const opt = user?.companyId?.split(',').map((company) => ({
                label: company,
                value: company,
            }))
            setOptList(removeDuplicateObjectInArray(opt, 'value'))
            setLoadingOpt(false)
        }

        fetchOptions()
    }, [])

    if (loading) return <InputSkeleton />
    return (
        <Select
            loading={loadingOpt}
            placeholder={placeholder}
            className={className}
            style={style}
            size={size}
            onChange={onChange}
            dropdownRender={(menu) => (
                <>
                    {!loadingOpt && menu}
                    {loadingOpt && (
                        <div className="text-center p-10">
                            <Spin />
                        </div>
                    )}
                </>
            )}
            options={optList}
            {...props}
        />

        // <Select
        //     showSearch
        //     loading={loadingOpt}
        //     onChange={onChange}
        //     placeholder={placeholder}
        //     className="rounded-10 h-50"
        //     style={{ width: '100%', borderRadius: '5 !important' }}
        //     size="large"
        // dropdownRender={(menu) => (
        //     <>
        //         {!loadingOpt && menu}
        //         {loadingOpt && (
        //             <div className="text-center p-10">
        //                 <Spin />
        //             </div>
        //         )}
        //     </>
        // )}
        //     options={optList}
        //     {...props}
        // />
    )
}

export default SelectCompanyByUser
