import React, { useState, useEffect } from 'react'
import { Collapse } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useMenu } from '../useMenuHooks'
import useMatchRoute from 'customHooks/useMatchRoute'
import { scrollToTop } from 'utils/misc'

import StyledParentMenuList from './StyledParentMenuList'
import SingleSubMenuCard from './SingleSubMenuCard'

const { Panel } = Collapse

export default function SidebarMenus({ showSideMenu }) {
    const [openedParentMenu, setOpenedParentMenu] = useState([])
    const { authorizedMenu } = useMenu()
    const navigate = useNavigate()
    const activePath = useMatchRoute()

    const onClickSubMenu = (currentMenu, parentMenu) => {
        const path = `${parentMenu?.path || ''}${currentMenu?.path}`
        navigate(path, { replace: true })
        scrollToTop()
    }

    useEffect(() => {
        setOpenedParentMenu([])
    }, [showSideMenu])

    return (
        <div className="bg-nabati-blue">
            <Collapse
                ghost
                activeKey={openedParentMenu}
                onChange={(activeMenu) => {
                    // only set 1 active parent menu
                    setOpenedParentMenu([activeMenu[activeMenu.length - 1]])
                }}
            >
                {authorizedMenu.map((parentMenu) => {
                    const isActiveMenu = parentMenu.path === activePath.parentPath
                    const isParentMenuOpen = openedParentMenu.includes(parentMenu.path)
                    return (
                        <Panel
                            key={parentMenu.path}
                            className={'m-0 sidebarMenu'}
                            showArrow={false}
                            header={
                                <StyledParentMenuList
                                    isActiveMenu={isActiveMenu}
                                    showSideMenu={showSideMenu}
                                    icon={parentMenu.icon}
                                    path={parentMenu.path}
                                    title={parentMenu.title}
                                    submenu={parentMenu.submenu}
                                    isParentMenuOpen={isParentMenuOpen}
                                    setOpenedParentMenu={setOpenedParentMenu}
                                    floatingSubmenu={
                                        <SingleSubMenuCard
                                            subPath={activePath.subPath}
                                            onClickSubMenu={onClickSubMenu}
                                            parentMenu={parentMenu}
                                            onFloatingMenu
                                        />
                                    }
                                />
                            }
                        >
                            {showSideMenu && (
                                <SingleSubMenuCard
                                    subPath={activePath.subPath}
                                    onClickSubMenu={onClickSubMenu}
                                    parentMenu={parentMenu}
                                />
                            )}
                        </Panel>
                    )
                })}
            </Collapse>
        </div>
    )
}
