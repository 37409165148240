import { Modal, Tooltip } from 'antd'
import { useState } from 'react'

import { FilePdfOutlined } from '@ant-design/icons'
import { Close } from 'assets'
import LoadingFallback from './LoadingFallback'

function ShowImageModal({ file, visible, close }) {
    const isImage = file.type === 'image/png' || file.type === 'image/jpeg'

    return (
        <Modal
            okText={'Close'}
            title={file.name}
            visible={visible}
            onOk={close}
            onCancel={close}
            width={1000}
            cancelButtonProps={{ className: 'hidden' }}
            zIndex={2000}
        >
            {isImage ? (
                <img src={file.data} alt={file.name} />
            ) : (
                <iframe src={file.data} width="100%" height="450px" title={file.name} />
            )}
        </Modal>
    )
}

function SingleFileBox({ file, onClickDeleteImage = async () => {} }) {
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const isImage = file.type === 'image/png' || file.type === 'image/jpeg'

    const handleDelete = async () => {
        setDeleteLoading(true)
        await onClickDeleteImage(file)
        setDeleteLoading(false)
    }

    if (deleteLoading) return <LoadingFallback text="deleting..." />
    return (
        <Tooltip title={file?.name} placement="bottom">
            <div
                className="relative shadow-md cursor-pointer rounded-10"
                style={{
                    width: 50,
                    height: 50,
                    backgroundSize: 'contain',
                }}
                onClick={() => setShowModal(true)}
            >
                {!file.data && <p className="ml-2">error image</p>}
                {isImage ? (
                    <img src={file.data} alt={file.name} />
                ) : (
                    <div className="text-center h-12 text-2xl">
                        <div className="text-nabati-pink">
                            <FilePdfOutlined />
                        </div>
                        <p className="text-xs">.pdf</p>
                    </div>
                )}
            </div>
            <Close className="absolute -top-1 -right-1 cursor-pointer" onClick={handleDelete} />
            <ShowImageModal file={file} visible={showModal} close={() => setShowModal(false)} />
        </Tooltip>
    )
}

export default SingleFileBox
