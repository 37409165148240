import React from 'react'
import { Row, Col } from 'antd'
import CardDatePicker from 'components/Common/CardDatePicker'

// CHART COMPONENT
import ChartPalletUsage from './charts/chart-pallet-usage'
import ChartBinUsage from './charts/chart-bin-usage'

function SectionTwo() {
    return (
        <CardDatePicker className="mt-5">
            <Row gutter={24}>
                <Col span={24}>
                    <ChartPalletUsage />
                </Col>
                <Col span={24}>
                   <ChartBinUsage />
                </Col>
            </Row>
        </CardDatePicker>
    )
}

export default SectionTwo
