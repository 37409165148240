import React from 'react'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

const ButtonRowSelection = ({
    icon = <DeleteOutlined />,
    onClick = () => {},
    label = 'Your Label Here',
}) => {
    return (
        <Button
            onClick={onClick}
            icon={icon}
            className="flex items-center justify-center rounded-10 text-nabati-red border-nabati-red"
        >
            {label}
        </Button>
    )
}

export default ButtonRowSelection
