export function range(start, end) {
    const result = []
    for (let i = start; i < end; i++) {
        result.push(i)
    }
    return result
}

export const sortArrayByKey = (array, key) => {
    return [...array].sort((a, b) => {
        if (a[key] > b[key]) return 1
        if (b[key] > a[key]) return -1
        return 0
    })
}

export const sortByKey = (key) => (a, b) => {
    if (+a[key] > +b[key]) return 1
    if (+b[key] > +a[key]) return -1
    return 0
}

export const removeDuplicateObjectInArray = (array = [], key = 'id') => {
    return Object.values(array.reduce((acc, cur) => Object.assign(acc, { [cur[key]]: cur }), {}))
}

export const checkIfDuplicateExists = (arr) => {
    return new Set(arr).size !== arr.length
}

export const filterArrayByArray = (arr1 = [], arr2 = [], key = 'id') => {
    const result = arr1.filter((obj) => !arr2.some((x) => x[key] === obj[key]))
    return result
}

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}

export function isUniqueStrArray(stringArr) {
    return stringArr.filter(onlyUnique).length === 1
}

export const compareData = (dt1, dt2) => {
    const isNumb = !isNaN(dt1) && !isNaN(dt2)
    if (isNumb) return +dt1 - +dt2
    return dt1.localeCompare(dt2)
}

export const arrayToStringComa = (arrOrStr) => {
    // input: ['a', 'b', 3]
    // output: 'a,b,3'
    if (typeof arrOrStr === 'string') return arrOrStr
    if (typeof arrOrStr === 'number') return String(arrOrStr)
    return arrOrStr?.map((item) => item).join() || '-'
}

export const stringComaToArray = (str) => {
    // input: 'a,b,3'
    // output: ['a', 'b', 3]
    if (!str) return []
    return String(str).split(',')
}

export const groupByKey = (array, key) => {
    return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash
        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
    }, {})
}

export function sliceIntoChunks(arr, chunkSize) {
    const res = []
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize)
        res.push(chunk)
    }
    return res
}
