import React from 'react'
import { Row, Col } from 'antd'
import CardDatePicker from 'components/Common/CardDatePicker'

// CHART COMPONENT
import ChartLoadingProcessDuration from './charts/loading-process-duration'
import ChartProductivityOutbound from './charts/productivity-outbound'

function SectionOne() {
    return (
        <CardDatePicker className="mt-5">
            <Row gutter={16}>
                <Col span={12}>
                    <ChartLoadingProcessDuration />
                </Col>
                <Col span={12}>
                    <ChartProductivityOutbound />
                </Col>
            </Row>
        </CardDatePicker>
    )
}

export default SectionOne
