import axios from 'axios'

import { METHODS } from 'config/constant/common'
import { errorInterceptor, responseInterceptor } from 'api/interceptors'
import { auth } from 'auth'
import { toSnakeCase } from 'utils/formatter'
import { API_URL } from 'config/env'
const instance = axios.create()
instance.interceptors.response.use(responseInterceptor, errorInterceptor)

export const DEFAULT_PAGE_SIZE = 50
export const call = (method, subUrl = '', data = {}, options) => {
    const token = auth.getToken()
    const config = {
        url: subUrl,
        method,
        baseURL: API_URL,
        // withCredentials: true,
        ...options,
        headers: {
            'Content-Type': 'application/json',
            ...(options && options.headers ? options.headers : {}),
            Authorization: `Bearer ${token}`,
        },
    }

    const payload = data

    if (method === METHODS.GET) {
        Object.keys(payload).forEach((key) => {
            if (payload[key] === null || payload[key] === '') {
                delete payload[key]
            }

            delete payload.rrc // delete Random Refresh Code
        })

        if (!payload.page) payload.page = 1
        if (!payload.total) payload.total = DEFAULT_PAGE_SIZE // delete me! delete salah satu
        if (!payload.limit) payload.limit = DEFAULT_PAGE_SIZE // delete me! delete salah satu
        // if (!payload.from) payload.from = moment().format('DD-MM-YYYY')
        // if (!payload.to) payload.to = moment().format('DD-MM-YYYY')

        config.params = toSnakeCase(payload)
    } else if (config.headers['Content-Type'] === 'multipart/form-data') {
        config.data = payload
    } else {
        // row key is autogenerate from antd table (Rich table)
        if (typeof payload.rowKey === 'number') delete payload.rowKey

        config.data = toSnakeCase(payload)
    }
    return instance.request(config)
}

export const call_sloc_plant = (method, subUrl = '', data = {}, options) => {
    const token = auth.getToken()
    const config = {
        url: subUrl,
        method,
        baseURL: API_URL,
        // withCredentials: true,
        ...options,
        headers: {
            'Content-Type': 'application/json',
            ...(options && options.headers ? options.headers : {}),
            Authorization: `Bearer ${token}`,
        },
    }

    const payload = data

    if (method === METHODS.GET) {
        Object.keys(payload).forEach((key) => {
            if (payload[key] === null || payload[key] === '') {
                delete payload[key]
            }

            delete payload.rrc // delete Random Refresh Code
        })

        if (!payload.page) payload.page = 1
        if (!payload.total) payload.total = null // delete me! delete salah satu
        if (!payload.limit) payload.limit = null // delete me! delete salah satu
        // if (!payload.from) payload.from = moment().format('DD-MM-YYYY')
        // if (!payload.to) payload.to = moment().format('DD-MM-YYYY')

        config.params = toSnakeCase(payload)
    } else if (config.headers['Content-Type'] === 'multipart/form-data') {
        config.data = payload
    } else {
        // row key is autogenerate from antd table (Rich table)
        if (typeof payload.rowKey === 'number') delete payload.rowKey

        config.data = toSnakeCase(payload)
    }
    return instance.request(config)
}
