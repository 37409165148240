import React from 'react'
import SelectDebounceFetch from './index'
import { getPlantSectorSlocList } from 'api/master/plantSectorSloc'

function SelectSloc({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Sloc"
            fetchOptions={async (searchTerm) => {
                const res = await getPlantSectorSlocList({ filter: searchTerm, limit: 10 })
                const list = res?.result?.data.map((sloc) => ({
                    label: `${sloc.slocId} - ${sloc.description}`,
                    value: sloc.slocId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectSloc
