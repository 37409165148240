import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Row, Col } from 'antd'
import Card from 'components/Common/Card'
import { getInboundSummary } from 'api/dashboard/inbound'
import { StyledNumber, TitleWithTooltip } from '../_sharedComponents'

const FORMAT_DATE = 'YYYY-MM-DD'
function SummarySection({ dcLocation }) {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const reqBody = {
                start_date: moment().format(FORMAT_DATE),
                end_date: moment().format(FORMAT_DATE),
                dcLocationId: [dcLocation?.value],
            }
            try {
                setLoading(true)
                const { result } = await getInboundSummary(reqBody)
                setData(result.data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.error(error)
            }
        }
        fetchData()
    }, [dcLocation])

    return (
        <Row gutter={20}>
            <Col span={6}>
                <Card loading={loading} className="h-33">
                    <TitleWithTooltip tooltip="Pallet ID">Pallet ID</TitleWithTooltip>
                    <StyledNumber data={[{ number: data?.palletId, text: '' }]} />
                </Card>
            </Col>
            <Col span={6}>
                <Card loading={loading} className="h-33">
                    <TitleWithTooltip tooltip="Transfer to DC">Transfer to DC</TitleWithTooltip>
                    <StyledNumber
                        data={[
                            { number: data?.transferDc?.create, text: 'Created', color: '#444444' },
                            {
                                number: data?.transferDc?.progress,
                                text: 'Progress',
                                color: '#FFB400',
                            },
                            { number: data?.transferDc?.done, text: 'Done', color: '#01A862' },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card loading={loading} className="h-33">
                    <TitleWithTooltip tooltip="DC Checked">DC Checked</TitleWithTooltip>
                    <StyledNumber
                        data={[
                            { number: data?.dcChecked?.pass, text: 'Pass', color: '#01A862' },
                            { number: data?.dcChecked?.reject, text: 'Rejected', color: '#B40E0E' },
                        ]}
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card loading={loading} className="h-33">
                    <TitleWithTooltip tooltip="DC Picked">DC Picked</TitleWithTooltip>
                    <StyledNumber data={[{ number: data?.dcPicked, text: '' }]} />
                </Card>
            </Col>
        </Row>
    )
}

export default SummarySection
