import { DeleteFilled } from '@ant-design/icons'

export const columns = (isOnEditMode, onCLickDelete) => {
    const baseColumns = [
        {
            title: 'Company',
            dataIndex: 'companyId',
            key: 'companyId',
            width: 100,
        },
        {
            title: 'Plant',
            dataIndex: 'plantId',
            key: 'plantId',
            width: 100,
        },
        {
            title: 'Pallet ID',
            dataIndex: 'palletId',
            key: 'palletId',
            width: 100,
        },
        {
            title: 'Pallet Code',
            dataIndex: 'palletCode',
            key: 'palletCode',
            width: 150,
        },
        {
            title: 'Material',
            dataIndex: 'materialId',
            key: 'materialId',
            width: 100,
        },
        {
            title: 'Material Description',
            dataIndex: 'description',
            key: 'description',
            width: 350,
        },
        {
            title: 'Storage Loc',
            dataIndex: 'slocId',
            key: 'slocId',
            width: 150,
        },
        {
            title: 'Batch',
            dataIndex: 'batch',
            key: 'batch',
            width: 100,
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
            width: 100,
        },
        {
            title: 'UOM',
            dataIndex: 'uom',
            key: 'uom',
            width: 100,
        },
    ]

    if (isOnEditMode)
        return [
            ...baseColumns,
            {
                title: 'Action',
                dataIndex: 'palletId',
                key: 'palletId',
                align: 'center',
                width: 100,
                render: (text, record) => (
                    <DeleteFilled
                        onClick={() => onCLickDelete(record)}
                        className="text-nabati-pink text-lg cursor-pointer hover:text-nabati-blue"
                    />
                ),
            },
        ]

    return baseColumns
}
