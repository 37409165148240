export const columns = () => [
    {
        title: 'Company ID',
        dataIndex: 'companyId',
        key: 'companyId',
        width: 200,
    },
    {
        title: 'Forwarder Agent',
        dataIndex: 'lifnr',
        key: 'lifnr',
        width: 200,
    },
    {
        title: 'Description',
        dataIndex: 'name1',
        key: 'name1',
    },
]
