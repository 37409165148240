import React, { createContext, useState } from 'react'
import { Tabs } from 'antd'
import SimpleContainer from 'components/layouts/simple'
import SearchAndFilterArea from 'components/Common/SearchAndFilterArea'

const { TabPane } = Tabs

const SimpleTabsContext = createContext()

const SimpleTabs = ({
    children,
    onChange = () => {},
    withSearchAndFilterArea = false,
    ...props
}) => {
    const [buttonsHeader, setButtonsHeader] = useState(null)

    return (
        <SimpleTabsContext.Provider value={{ buttonsHeader, setButtonsHeader }}>
            {buttonsHeader && <SimpleContainer>{buttonsHeader}</SimpleContainer>}
            {withSearchAndFilterArea && <SearchAndFilterArea />}
            <Tabs
                tabBarStyle={{
                    background: '#F6FFFB',
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    height: 80,
                }}
                destroyInactiveTabPane
                defaultActiveKey="1"
                onChange={onChange}
                {...props}
            >
                {children}
            </Tabs>
        </SimpleTabsContext.Provider>
    )
}

function Tab({ tabTitle, key, children }) {
    return (
        <TabPane tab={<p className="w-full px-4 text-center">{tabTitle}</p>} key={key}>
            <SimpleContainer
                style={{
                    marginTop: -17,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                }}
            >
                {children}
            </SimpleContainer>
        </TabPane>
    )
}

SimpleTabs.TabPane = Tab

export { SimpleTabsContext, Tab as TabPane }
export default SimpleTabs
