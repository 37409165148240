import React, { useMemo } from 'react'
import { Form, Input, Select } from 'antd'
import { parseBodyToArrObj } from './helper'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
import { camelCaseToTitleCase } from 'utils/formatter'

const registeredField = (props) => {
    return {
        company: <SelectDebounceFetch.Company {...props} />,
        companyId: <SelectDebounceFetch.Company {...props} />,
        glId: <SelectDebounceFetch.Gl {...props} />,
        gl: <SelectDebounceFetch.Gl {...props} />,
        plant: <SelectDebounceFetch.Plant {...props} />,
        material: <SelectDebounceFetch.Material {...props} />,
        materialType: <SelectDebounceFetch.Material {...props} />,
    }
}
const getFields = ({ name = '', mode = undefined }) => {
    const defaultField = {
        [undefined]: (
            <Input placeholder={camelCaseToTitleCase(name)} className="rounded-5" size="large" />
        ),
        multiple: (
            <Select
                mode="tags"
                labelInValue
                className="rounded-5"
                size="large"
                tokenSeparators={[',']}
            />
        ),
    }

    return registeredField({ mode })[name] || defaultField[mode]
}

function BodyForm({ body = {} }) {
    const bodyItems = useMemo(() => parseBodyToArrObj(body), [body])

    const getInitialValue = (item) => {
        const isRegisteredField = !!registeredField()[item.name]
        if (item.isMultiple) return item.initialValue.map((value) => ({ value, label: value }))
        if (!item.isMultiple && !isRegisteredField) return item.initialValue
        return { value: item.initialValue }
    }

    if (bodyItems.length === 0) return <></>
    return (
        <>
            <div
                className="font-bold text-lg mt-6"
                style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
            >
                Body Form
            </div>
            {bodyItems.map((item) => (
                <Form.Item
                    key={item.name}
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name={item.name}
                    label={item.label}
                    initialValue={getInitialValue(item)}
                    shouldUpdate={true}
                    // rules={[{ required: true }]}
                >
                    {getFields(item)}
                </Form.Item>
            ))}
        </>
    )
}

export default BodyForm
