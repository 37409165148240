import React from 'react'
// import { getGenerateDataPerPage } from 'utils/misc'
import { SingleQrBoxToPrint } from './SingleQrBox'
import { sliceIntoChunks } from 'utils/array'

function QrCodePrint({ groupedQrCodes, columnPerPage = 2 }) {
    const pages = sliceIntoChunks(groupedQrCodes, +columnPerPage)
    return (
        <>
            {pages.map((page) => {
                // console.log('page', page)
                return (
                    <div className="pagebreak" style={{ display: 'flex', margin: '10mm' }}>
                        {page.map((section, index) => {
                            return (
                                <div
                                    style={{
                                        // display: 'inline-block',
                                        borderRight: '1px dashed gray',
                                        height: 'fit-content',
                                        width: 'fit-content',
                                        verticalAlign: 'top',
                                        // backgroundColor: index % 2 === 0 ? 'red' : 'blue',
                                    }}
                                >
                                    {section.map((row) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: `repeat(${row.length}, 37.5mm)`,
                                                    gap: '2.5mm',
                                                    justifyContent: 'center',
                                                    width: `${row.length * 40}mm`,
                                                }}
                                            >
                                                {row?.length > 0 &&
                                                    row.map((singleQrObj) => (
                                                        <SingleQrBoxToPrint
                                                            key={singleQrObj.binId}
                                                            singleQrObj={singleQrObj}
                                                        />
                                                    ))}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </>
    )
}

export default QrCodePrint
