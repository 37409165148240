import React, { useCallback, useReducer, useEffect } from 'react'
import moment from 'moment'

import { Button, Row, Col, Input, DatePicker, Modal } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'

import SelectChangeType from 'components/Common/SelectFetchByApi/selectChangeType'
import SelectCompany from 'components/Common/SelectByUser/select-company-by-user'
import InputSkeleton from 'components/Common/SkeletonLoading/inputSkeleton'

import { changeStatusInventory, findInventoryById } from 'api/transaction/reportInventory'
import useQueryRefresh from 'customHooks/useQueryRefresh'
import DataTable from './data-table'
import { auth } from 'auth'
const user = auth.getUserInfo()

const getRequestBody = ({ formData, dataTable }) => {
    return {
        transactionId: formData.transactionId || '',
        companyId: formData.companyId, // dari company user
        plantId: dataTable?.length > 0 ? dataTable[0]?.plant : '', // udah pasti 1 plant, ambil dari pallet
        changeType: formData.changeType, // ambil dari List
        pstngDate: formData.pstngDate,
        docDate: formData.docDate,
        refDocNo: formData.refDocNo,
        headerTxt: formData.headerTxt,
        prUname: user?.userName || '', // dari user
        items: dataTable
            .filter((item) => item.pallets.length > 0)
            .map((item) => ({
                item: item.item,
                material: item.material,
                description: item.description,
                plant: item.plant,
                stgeLoc: item.stgeLoc,
                batch: item.batch,
                entryQnt: item.entryQnt,
                entryUom: item.entryUom,
                pallets: item.pallets.map((pallet) => ({
                    palletId: pallet.palletId,
                    palletCode: pallet.palletCode,
                    binId: pallet.binId,
                    qty: pallet.qty,
                    uom: pallet.uom,
                    sled: pallet.sled,
                    manufactureDate: pallet.manufactureDate,
                    stockStatus: pallet.stockStatus,
                    slocId: pallet.slocId,
                    sectorId: pallet.sectorId,
                    gateId: pallet.gateId,
                    dockingId: pallet.dockingId,
                    staggingIn: pallet.staggingIn,
                })),
            })),
    }
}
const initialState = {
    loading: false,
    selectedRows: [],
    confirmationSaveModal: false,
    formData: {
        companyId: '',
        changeType: '',
        docDate: moment().format('YYYY-MM-DD'),
        pstngDate: moment().format('YYYY-MM-DD'),
        refDocNo: '',
        headerTxt: '',
    },
    dataTable: [],
    initialDataTable: [],
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'SET_SELECTED_ROWS':
            return { ...state, selectedRows: [...action.selectedRows] }
        case 'TOGGLE_CONFIRMATION_SAVE':
            return { ...state, confirmationSaveModal: !state.confirmationSaveModal }
        case 'SET_FORM_DATA':
            return { ...state, formData: { ...state.formData, ...action.payload } }
        case 'SET_DATA_TABLE':
            return { ...state, dataTable: action.dataTable }
        case 'SET_INITIAL_DATA_TABLE':
            return { ...state, initialDataTable: action.initialDataTable }
        case 'RESET':
            return initialState
        default:
            return state
    }
}

function CreateChangeStatus({ close, payload, visible, ...props }) {
    const [state, dispatch] = useReducer(stateReducer, initialState)
    const { doRefresh } = useQueryRefresh()

    const isOnDisplayMode = payload?.isOnDisplayMode || false

    const setDataTable = useCallback((dataTable) => {
        dispatch({ type: 'SET_DATA_TABLE', dataTable })
    }, [])

    const onclickSave = () => {
        dispatch({ type: 'TOGGLE_CONFIRMATION_SAVE' })
    }

    const handleSave = async () => {
        const reqBody = getRequestBody(state)
        dispatch({ type: 'SET_LOADING', loading: true })

        try {
            const res = await changeStatusInventory(reqBody)
            dispatch({ type: 'SET_LOADING', loading: false })
            if (!res.success) return res
            close()
            doRefresh()
            return res
        } catch (error) {
            dispatch({ type: 'SET_LOADING', loading: false })
            console.error(error)
            return { success: false }
        }
    }

    const handleCancel = () => {
        close()
        dispatch({ type: 'SET_LOADING', loading: false })
    }

    useEffect(() => {
        dispatch({ type: 'RESET' })
        if (!payload?.transactionId) return
        dispatch({ type: 'SET_LOADING', loading: true })

        const fetchData = async () => {
            const res = await findInventoryById({ id: payload.transactionId })
            if (!res.success) {
                return Modal.error({
                    title: 'Oops',
                    content: res?.message || '',
                })
            }

            dispatch({
                type: 'SET_FORM_DATA',
                payload: {
                    changeType: res.data.changeType,
                    companyId: res.data.companyId,
                    docDate: res.data.docDate,
                    docNum: res.data.docNum,
                    headerTxt: res.data.headerTxt,
                    plantId: res.data.plantId,
                    prUname: res.data.prUname,
                    pstngDate: res.data.pstngDate,
                    refDocNo: res.data.refDocNo,
                    sapDocNumber: res.data.sapDocNumber,
                    sapDocYear: res.data.sapDocYear,
                    source: res.data.source,
                    transactionId: res.data.transactionId,
                },
            })
            dispatch({
                type: 'SET_INITIAL_DATA_TABLE',
                initialDataTable:
                    res?.data?.items?.map((item, idx) => ({
                        item: item.item || '-',
                        material: item.material || '-',
                        description: item.description || '-',
                        plant: item.plant || '-',
                        plantDescription: item.plantDescription || '-',
                        stgeLoc: item.stgeLoc || '-',
                        slocDescription: item.stgeLocDescription || item.slocDescription || '-',
                        batch: item.batch || '-',
                        entryQnt: item.entryQnt || '-',
                        entryUom: item.entryUom || '-',
                        pallets: item.pallets || [],
                    })) || [],
            })
            dispatch({ type: 'SET_LOADING', loading: false })
        }

        fetchData()
    }, [payload?.transactionId, visible])

    return (
        <>
            <SimpleModal
                title={
                    payload ? `Transaction ID: ${payload.transactionId}` : 'Create Change Status'
                }
                width={1190}
                footer={null}
                visible={visible}
                {...props}
            >
                <Row gutter={20} className="mb-5">
                    <Col span={8}>
                        <p className="text-nabati-grey text-base mb-1">Transaction </p>
                        <SelectChangeType
                            loading={state.loading}
                            defaultValue={state?.formData?.changeType}
                            onChange={(value) => {
                                dispatch({
                                    type: 'SET_FORM_DATA',
                                    payload: { changeType: value },
                                })
                            }}
                            disabled={isOnDisplayMode}
                        />
                    </Col>
                    <Col span={8}>
                        <p className="text-nabati-grey text-base mb-1">Company</p>
                        <SelectCompany
                            loading={state.loading}
                            defaultValue={state?.formData?.companyId}
                            onChange={(value) => {
                                dispatch({
                                    type: 'SET_FORM_DATA',
                                    payload: { companyId: value },
                                })
                            }}
                            disabled={isOnDisplayMode}
                        />
                    </Col>
                </Row>
                <Row gutter={20} className="mb-5">
                    <Col span={8}>
                        <p className="text-nabati-grey text-base mb-1">Posting Date</p>
                        {state.loading ? (
                            <InputSkeleton />
                        ) : (
                            <DatePicker
                                size="large"
                                className="rounded-10 h-50"
                                style={{ width: '100%' }}
                                defaultValue={
                                    state?.formData?.pstngDate
                                        ? moment(state?.formData?.pstngDate)
                                        : ''
                                }
                                onChange={(e) => {
                                    dispatch({
                                        type: 'SET_FORM_DATA',
                                        payload: { pstngDate: e.format('YYYY-MM-DD') },
                                    })
                                }}
                                disabled={isOnDisplayMode}
                            />
                        )}
                    </Col>
                    <Col span={8}>
                        <p className="text-nabati-grey text-base mb-1">Date</p>
                        {state.loading ? (
                            <InputSkeleton />
                        ) : (
                            <DatePicker
                                size="large"
                                className="rounded-10 h-50"
                                style={{ width: '100%' }}
                                defaultValue={
                                    state?.formData?.docDate ? moment(state?.formData?.docDate) : ''
                                }
                                onChange={(e) => {
                                    dispatch({
                                        type: 'SET_FORM_DATA',
                                        payload: { docDate: e.format('YYYY-MM-DD') },
                                    })
                                }}
                                disabled={isOnDisplayMode}
                            />
                        )}
                    </Col>
                </Row>

                <Row gutter={20} className="mb-10">
                    <Col span={8}>
                        <p className="text-nabati-grey text-base mb-1">Material Slip</p>
                        {state.loading ? (
                            <InputSkeleton />
                        ) : (
                            <Input
                                size="large"
                                className="rounded-10 h-50"
                                style={{ width: '100%' }}
                                defaultValue={state?.formData?.refDocNo}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'SET_FORM_DATA',
                                        payload: { refDocNo: e.target.value },
                                    })
                                }}
                                disabled={isOnDisplayMode}
                            />
                        )}
                    </Col>
                    <Col span={8}>
                        <p className="text-nabati-grey text-base mb-1">Doc. Header Text</p>
                        {state.loading ? (
                            <InputSkeleton />
                        ) : (
                            <Input
                                size="large"
                                className="rounded-10 h-50"
                                style={{ width: '100%' }}
                                defaultValue={state?.formData?.headerTxt}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'SET_FORM_DATA',
                                        payload: { headerTxt: e.target.value },
                                    })
                                }}
                                disabled={isOnDisplayMode}
                            />
                        )}
                    </Col>
                </Row>

                <DataTable
                    isOnDisplayMode={isOnDisplayMode}
                    setDataTable={setDataTable}
                    initialDataTable={state.initialDataTable}
                    loading={state.loading}
                />
                <ButtonsGroupGrid align="right" className="mt-5">
                    {!isOnDisplayMode && (
                        <Button
                            onClick={onclickSave}
                            type="primary"
                            className="w-157 h-45 rounded-10 mx-2"
                        >
                            Draft
                        </Button>
                    )}
                    <Button
                        onClick={handleCancel}
                        className="outLinedPink  w-157 h-45 rounded-10 mx-2"
                    >
                        {isOnDisplayMode ? 'Close' : 'Cancel'}
                    </Button>
                </ButtonsGroupGrid>
            </SimpleModal>
            <ConfirmationModal
                visible={state.confirmationSaveModal}
                close={() => dispatch({ type: 'TOGGLE_CONFIRMATION_SAVE' })}
                title="Confirm Save"
                content="Are you sure to save as DRAFT?"
                successTitleMsg="Saved"
                successContentMsg={(res) => `Document Number: ${res.documentNumber}`}
                onOk={handleSave}
            />
        </>
    )
}

export default CreateChangeStatus
