import React, { useState } from 'react'
import { Form, Modal, Divider, Button, Input, Select } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

import useQueryRefresh from 'customHooks/useQueryRefresh'

function AddEditDocking({
    close,
    visible,
    payload: initialValues = {},
    successMsg = 'success',
    title = '',
    callApi,
    additionalPayload = {},
}) {
    const [loading, setLoading] = useState(false)
    const [isNoActive, setIsNoActive] = useState(initialValues?.noActive || false)
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        const payload = {
            dcLocationId: values?.dcLocationId?.value,
            dockingId: values.dockingId,
            building: values.building,
            description: values.description,
            noActive: values.noActive,
            reason: values.reason,
            isUsed: values.isUsed,
        }

        const modifiedPayload = {
            ...payload,
            ...additionalPayload,
        }

        try {
            const res = await callApi(modifiedPayload)
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="dcLocationId"
                    label="DC Location"
                    initialValue={{ value: initialValues?.dcLocationId || null }}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDebounceFetch.DcLocation disabled={initialValues?.dcLocationId} />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="dockingId"
                    label="Docking ID"
                    initialValue={initialValues?.dockingId}
                    shouldUpdate={true}
                    rules={[
                        { required: true },
                        {
                            max: 10,
                            message: 'The max character length is 10',
                        },
                    ]}
                >
                    <Input
                        disabled={initialValues?.dockingId}
                        placeholder="Docking ID"
                        className="rounded-5"
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="building"
                    label="Building"
                    initialValue={initialValues?.building}
                    shouldUpdate={true}
                    rules={[
                        { required: true },
                        {
                            max: 50,
                            message: 'The max character length is 50',
                        },
                    ]}
                >
                    <Input
                        // disabled={initialValues?.dockingId}
                        placeholder="Docking ID"
                        className="rounded-5"
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="description"
                    label="Description"
                    initialValue={initialValues?.description}
                    shouldUpdate={true}
                    rules={[
                        { required: true },
                        {
                            max: 20,
                            message: 'The max character length is 20',
                        },
                    ]}
                >
                    <Input placeholder="Description" className="rounded-5" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="noActive"
                    label="Active"
                    initialValue={initialValues?.noActive}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder="Active"
                        className="rounded-5"
                        size="large"
                        onChange={(e) => {
                            const isNoActive = e === 'Y'
                            setIsNoActive(isNoActive)
                        }}
                    >
                        {/* Y = No    N = Yes   => the origin data from backend is noActive. We need to reverse 'yes' and 'no' to make it clear from user's perspective  */}
                        <Select.Option value="Y">No</Select.Option>
                        <Select.Option value="N">Yes</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="reason"
                    label="Reason"
                    initialValue={initialValues?.reason}
                    shouldUpdate={true}
                    rules={[
                        { required: isNoActive ? true : false },
                        {
                            max: 30,
                            message: 'The max character length is 30',
                        },
                    ]}
                >
                    <Input placeholder="Reason" className="rounded-5" size="large" />
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={() => {
                        setLoading(false)
                        close()
                    }}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddEditDocking
