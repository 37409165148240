// import { Row, Col } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const summaryColumns = () => [
    {
        title: 'Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Sloc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'Qty Check',
        dataIndex: 'qtyStocker',
        key: 'qtyStocker',
    },
    {
        title: 'Diff',
        dataIndex: 'different',
        key: 'different',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
]

export const detailColumns = () => [
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
        width: 400,
        render: (text, record) => (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{text}</div>
        ),
    },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Pallet ID',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Pallet Code',
        dataIndex: 'palletCode',
        key: 'palletCode',
    },
    {
        title: 'Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Material Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Storage Loc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Status',
        dataIndex: 'statusDescription',
        key: 'statusDescription',
    },
    {
        title: 'Pallet Exist',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Different',
        dataIndex: 'different',
        key: 'different',
    },
    {
        title: 'Qty Check',
        dataIndex: 'qtyStocker',
        key: 'qtyStocker',
    },
    {
        title: 'Notes',
        dataIndex: 'reason',
        key: 'reason',
    },
    {
        title: 'Status Pallet',
        dataIndex: 'palletExist',
        key: 'palletExist',
        render: (text) => <>{text === 0 ? 'Not Ok' : 'Ok'}</>,
    },
]
