import React from 'react'
import SelectDebounceFetch from './index'
import { getRoleMenuList } from 'api/maintain-user/role-menu'

function SelectRole({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Role"
            fetchOptions={async (searchTerm) => {
                const res = await getRoleMenuList({ filter: searchTerm })
                const list = res?.result?.data?.map((role) => ({
                    label: `${role.roleId} - ${role.description}`,
                    value: role.roleId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectRole
