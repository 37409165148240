import React, { useState, useEffect } from 'react'
import { Form, Modal, Divider, Button, Input } from 'antd'

import useQueryRefresh from 'customHooks/useQueryRefresh'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
import SelectRole from 'components/Common/SelectFetchByApi/selectRole'
import SelectUserType from 'components/Common/SelectFetchByApi/selectUserType'
import SelectPlantByCompanies from 'components/Common/AsyncSelectWithDependency/byCompanies/SelectPlantByCompanies'
import SelectGateByDcLocations from 'components/Common/AsyncSelectWithDependency/byDcLocations/SelectGateByDcLocations'

import SelectDockingByDcLocations from 'components/Common/AsyncSelectWithDependency/byDcLocations/SelectDockingByDcLocations'
import SelectSlocByPlants from 'components/Common/AsyncSelectWithDependency/byPlants/SelectSlocByPlants'

import SelectStagingOutByDcLocations from 'components/Common/AsyncSelectWithDependency/byDcLocations/SelectStagingOutByDcLocations'
import SelectStagingInByDcLocations from 'components/Common/AsyncSelectWithDependency/byDcLocations/SelectStagingInByDcLocations'

import { arrayToStringComa, stringComaToArray } from 'utils/array'

function AddEditUserId({
    close,
    visible,
    payload: initialValues = {},
    successMsg = 'success',
    title = '',
    callApi,
    additionalPayload = {},
}) {
    const [loading, setLoading] = useState(false)
    const [companies, setCompanies] = useState([])
    const [dcLocations, setDcLocations] = useState([])
    const [plants, setPlants] = useState([])
    const [form] = Form.useForm()
    const { submit, setFieldsValue } = form
    const { doRefresh } = useQueryRefresh()

    const isEditMode = !!initialValues?.username

    const checkIsAllOptionSelected = (incomingValue) => {
        const isMultipleMode = Array.isArray(incomingValue)
        if (isMultipleMode) return incomingValue.findIndex((val) => val.value === '*') > -1
        return incomingValue.value === '*'
    }

    const proceedIfAllOptSelected = (values, fieldName = '') => {
        const isAllValuesSelected = checkIsAllOptionSelected(values)
        if (isAllValuesSelected) setFieldsValue({ [fieldName]: [{ value: '*' }] })
    }

    const onCompanyChange = (values) => {
        const isAllValuesSelected = checkIsAllOptionSelected(values)
        if (isAllValuesSelected) setFieldsValue({ companyId: [{ value: '*' }] })
        setCompanies(isAllValuesSelected ? '*' : [...values].map((c) => c.value))
        setFieldsValue({ plantId: null })
    }

    const onDcLocationChange = (values) => {
        const isAllValuesSelected = checkIsAllOptionSelected(values)
        if (isAllValuesSelected) setFieldsValue({ dcLocationId: [{ value: '*' }] })
        setDcLocations(isAllValuesSelected ? '*' : [...values].map((c) => c.value))
        setFieldsValue({ gateId: null, dockingId: null })
    }

    const onPlantChange = (values) => {
        const isAllValuesSelected = checkIsAllOptionSelected(values)
        if (isAllValuesSelected) setFieldsValue({ plantId: [{ value: '*' }] })
        setPlants(isAllValuesSelected ? '*' : [...values].map((c) => c.value))
        setFieldsValue({ slocId: null })
    }

    const handleCancel = () => {
        setLoading(false)
        setCompanies([])
        setDcLocations([])
        close()
    }

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        const reqBody = {
            ...initialValues,
            ...values,
            ...additionalPayload,
        }

        const modifiedPayload = () => ({
            ...additionalPayload,
            id: reqBody.id || undefined,
            username: reqBody.username,
            password: isEditMode ? undefined : reqBody.password,
            passwordConfirmation: isEditMode ? undefined : reqBody.passwordConfirmation,
            userType: arrayToStringComa(reqBody.userType),
            roleId: arrayToStringComa(reqBody.roleId),
            dcLocationId: arrayToStringComa(reqBody.dcLocationId.map((i) => i.value)),
            groupDcLocationId: arrayToStringComa(reqBody.dcLocationId.map((i) => i.value)),
            companyId: arrayToStringComa(reqBody.companyId.map((i) => i.value)),
            position: arrayToStringComa(reqBody.position),
            plantId: arrayToStringComa(reqBody.plantId.map((i) => i.value)),
            slocId: arrayToStringComa(reqBody.slocId.map((i) => i.value)),
            gateId: arrayToStringComa(reqBody.gateId.map((i) => i.value)),
            dockingId: arrayToStringComa(reqBody.dockingId.map((i) => i.value)),
            // Tambahan
            groupId: '*',
            stagingIn: arrayToStringComa(reqBody.stagingIn.map((i) => i.value)),
            stagingOut: arrayToStringComa(reqBody.stagingOut.map((i) => i.value)),
        })

        try {
            const res = await callApi(modifiedPayload())
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        if (!initialValues) return
        const initialDcLocationIds = initialValues?.dcLocationId?.split(',') || []
        const initialCompanyIds = initialValues?.companyId?.split(',') || []
        const initialPlantIds = initialValues?.plantId?.split(',') || []
        setCompanies(initialCompanyIds)
        setDcLocations(initialDcLocationIds)
        setPlants(initialPlantIds)
    }, [initialValues])

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="username"
                    label="Username"
                    initialValue={initialValues?.username}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Input
                        disabled={initialValues?.username}
                        placeholder="Username"
                        className="rounded-5"
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="position"
                    label="User Description"
                    initialValue={initialValues?.position}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <Input placeholder="User Description" className="rounded-5" size="large" />
                </Form.Item>
                {!isEditMode && (
                    <Form.Item
                        className="my-1"
                        // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="password"
                        label="Password"
                        initialValue={initialValues?.password}
                        shouldUpdate={true}
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder="Password"
                            className="rounded-5"
                            size="large"
                            type="password"
                        />
                    </Form.Item>
                )}
                {!isEditMode && (
                    <Form.Item
                        className="my-1"
                        // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="passwordConfirmation"
                        label="Password Confirmation"
                        initialValue={initialValues?.passwordConfirmation}
                        // shouldUpdate={true}
                        dependencies={['password']}
                        rules={[
                            { required: true },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }

                                    return Promise.reject(
                                        new Error(
                                            'The two passwords that you entered do not match!'
                                        )
                                    )
                                },
                            }),
                        ]}
                    >
                        <Input
                            placeholder="Password Confirmation"
                            className="rounded-5"
                            size="large"
                            type="password"
                        />
                    </Form.Item>
                )}
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="userType"
                    label="User Type"
                    initialValue={initialValues?.userType}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectUserType className="rounded-5" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="roleId"
                    label="Role Id"
                    initialValue={initialValues?.roleId}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectRole className="rounded-5" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="dcLocationId"
                    label="DC Location Id"
                    initialValue={stringComaToArray(initialValues?.dcLocationId).map((value) => ({
                        value,
                    }))}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDebounceFetch.DcLocation
                        onChange={onDcLocationChange}
                        withStar
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="companyId"
                    label="Company Id"
                    initialValue={stringComaToArray(initialValues?.companyId).map((value) => ({
                        value,
                    }))}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDebounceFetch.Company
                        onChange={onCompanyChange}
                        withStar
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="plantId"
                    label="Plant Id"
                    initialValue={stringComaToArray(initialValues?.plantId).map((value) => ({
                        value,
                    }))}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectPlantByCompanies
                        onChange={onPlantChange}
                        companies={companies}
                        className="rounded-5"
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="slocId"
                    label="Sloc Id"
                    initialValue={stringComaToArray(initialValues?.slocId).map((value) => ({
                        value,
                    }))}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectSlocByPlants
                        onChange={(values) => proceedIfAllOptSelected(values, 'slocId')}
                        plants={plants}
                        className="rounded-5"
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="gateId"
                    label="Gate Id"
                    initialValue={stringComaToArray(initialValues?.gateId).map((value) => ({
                        value,
                    }))}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectGateByDcLocations
                        onChange={(values) => proceedIfAllOptSelected(values, 'gateId')}
                        dcLocations={dcLocations}
                        className="rounded-5"
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="dockingId"
                    label="Docking Id"
                    initialValue={stringComaToArray(initialValues?.dockingId).map((value) => ({
                        value,
                    }))}
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <SelectDockingByDcLocations
                        onChange={(values) => proceedIfAllOptSelected(values, 'dockingId')}
                        dcLocations={dcLocations}
                        className="rounded-5"
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="stagingIn"
                    label="Staging In"
                    // initialValue={initialValues?.stagingIn}
                    initialValue={stringComaToArray(initialValues?.stagingIn).map((value) => ({
                        value,
                    }))}
                    shouldUpdate={true}
                    rules={[
                        { required: true, message: `If you confuse, you can fill it with '*'` },
                    ]}
                >
                    <SelectStagingInByDcLocations
                        onChange={(values) => proceedIfAllOptSelected(values, 'stagingIn')}
                        dcLocations={dcLocations}
                        className="rounded-5"
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    // style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="stagingOut"
                    label="Staging Out"
                    // initialValue={initialValues?.stagingOut}
                    initialValue={stringComaToArray(initialValues?.stagingOut).map((value) => ({
                        value,
                    }))}
                    shouldUpdate={true}
                    rules={[
                        { required: true, message: `If you confuse, you can fill it with '*'` },
                    ]}
                >
                    <SelectStagingOutByDcLocations
                        onChange={(values) => proceedIfAllOptSelected(values, 'stagingOut')}
                        dcLocations={dcLocations}
                        className="rounded-5"
                        mode="multiple"
                    />
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={handleCancel}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddEditUserId
