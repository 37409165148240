import React, { useState } from 'react'
import { Button, Row } from 'antd'
import { createNewPalletId } from 'api/master/palletId'
import RichTable from 'components/Common/RichTable'
import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddCompanyModal from './modals/add-pallet-id'
import UpdatePalletIdModal from './modals/update-pallet-id'
import QrCodeListModal from './modals/qr-code-list'
import XlsxUploader from 'components/Common/Uploader/XlsxUploader'

import {
    bulkDeletePalletId,
    getPalletIdList,
    downloadPalletTemplate,
    uploadPalletTemplate,
    updatePalletId,
} from 'api/master/palletId'
import { palletIdColumns } from './columns'

import { DownloadIc, QrIc } from 'assets'

function PalletId() {
    const [showUpdateCompanyModal, setUpdateCompanyModal] = useState(false)
    const [showAddCompanyModal, setAddCompanyModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showQrListModal, setShowQrListModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [selectedRows, setSelectedRows] = useState([])

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateCompanyModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const handleDownload = async () => {
        await downloadPalletTemplate()
    }

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddCompanyModal(true)
                }}
                type="primary"
                className="bg-nabati-pink flex justify-center items-center w-48 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
            <Button
                onClick={handleDownload}
                className="outLinedBlue flex justify-center items-center w-48 h-45 rounded-10 mx-1"
                size="middle "
            >
                <DownloadIc />
                <span className="ml-2">Download Template</span>
            </Button>
            <XlsxUploader callApi={uploadPalletTemplate} />
            <Button
                disabled={selectedRows?.length === 0}
                onClick={() => setShowQrListModal(true)}
                className="outLinedBlue flex justify-center items-center w-48 h-45 rounded-10 mx-1"
                size="middle "
            >
                <QrIc />
                <span className="ml-2">View QR Code</span>
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                columns={palletIdColumns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getPalletIdList}
                bulkDeleteApi={bulkDeletePalletId}
                type="rich"
            />
            <AddCompanyModal
                close={() => setAddCompanyModal(false)}
                visible={showAddCompanyModal}
                payload={modalFormPayload}
                callApi={createNewPalletId}
                title="Add Pallet"
            />
            <AddCompanyModal
                close={() => setUpdateCompanyModal(false)}
                visible={showUpdateCompanyModal}
                payload={modalFormPayload}
                callApi={updatePalletId}
                title="Update Pallet"
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
            <QrCodeListModal
                payload={[...selectedRows]}
                visible={showQrListModal}
                close={() => setShowQrListModal(false)}
            />
        </>
    )
}

export default PalletId
