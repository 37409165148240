import React, { useState, useEffect } from 'react'
import { Form, Modal, Divider, Button } from 'antd'

import useQueryRefresh from 'customHooks/useQueryRefresh'

// For Debounce Select
const objectMap = (obj, fn) =>
    Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

const ModalForm = ({
    title = 'Add Your Modal Title',
    initialValues,
    formItems: formItemsConfig,
    visible,
    close,
    okText = 'Save',
    cancelText = 'Cancel',
    callApi,
    successMsg = `New item was created`,
    width = 576,
    gridColumn = 1,
    submitWithApi = true,
    preserveLabelInValue = false, // Bug Fix Planner Stock Transfer
    onValuesChange = () => {},
}) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const formItems = formItemsConfig(initialValues)

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        let payload = { ...initialValues, ...values }
        let modifiedPayload = objectMap(payload, (item) => {
            if (preserveLabelInValue) return item

            if (item?.value) return item?.value
            return item
        })

        if (!submitWithApi) {
            setLoading(false)
            const res = callApi(modifiedPayload)
            if (!res?.success === false) return

            return close()
        }

        try {
            const res = await callApi(modifiedPayload)
            // delete me! delete res.status
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${label} is required!',
    }

    useEffect(() => {
        form.resetFields()
    }, [form, initialValues])

    return (
        <Modal visible={visible} destroyOnClose width={width} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                validateMessages={validateMessages}
                onValuesChange={(changedValues, allValues) =>
                    onValuesChange(changedValues, allValues, form)
                }
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${gridColumn}, ${100 / gridColumn}%)`,
                    gap: 10,
                }}
            >
                {formItems.map((formItem) => {
                    return (
                        <Form.Item
                            className="my-1"
                            style={{ width: '100%' }}
                            {...formItem}
                            key={formItem.name.toString()}
                        />
                    )
                })}
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={close}
                    type="ghost"
                >
                    {cancelText}
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    {okText}
                </Button>
            </div>
        </Modal>
    )
}

export default ModalForm
