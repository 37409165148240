import React from 'react'
import SelectDebounceFetch from './index'
import { getGateList } from 'api/master/gate'

function SelectGate({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Gate"
            fetchOptions={async (searchTerm) => {
                const res = await getGateList({ filter: searchTerm, total: 10 })
                const list = res?.result?.data.map((gate) => ({
                    label: gate.gateId,
                    value: gate.gateId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectGate
