import {
    // SmHome,
    SmMasterData,
    SmUser,
    SmTransaction,
    SmTransport,
    SmUtility,
    SmDashboard,
    SmReport
} from 'assets'

export const PARENT_MENU_DICTIONARY = {
    Dashboard: {
        icon: <SmDashboard />,
        path: '/dashboard',
        menuOrder: 0,
    },
    'Master Data': {
        icon: <SmMasterData />,
        path: '/master-data',
        menuOrder: 1,
    },
    'Maintain User': {
        icon: <SmUser />,
        path: '/maintain-user',
        menuOrder: 2,
    },
    Transaction: {
        icon: <SmTransaction />,
        path: '/transaction',
        menuOrder: 3,
    },
    Transport: {
        icon: <SmTransport />,
        path: '/transport',
        menuOrder: 4,
    },
    Utility: {
        icon: <SmUtility />,
        path: '/utility',
        menuOrder: 5,
    },
    Report: {
        icon: <SmReport />,
        path: '/report',
        menuOrder: 5,
    },
    [undefined]: {
        icon: <SmDashboard />,
        path: '/others',
        menuOrder: 999,
    },
}
