import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getRoleMenuList = async (params) => {
    const response = await call(METHODS.GET, '/master/rolemenu', params)
    return response.data
}

export const getMenuByRoleId = async (params) => {
    const response = await call(METHODS.POST, '/master/rolemenu/listbyroleid', params)
    return response.data
}

export const createNewRoleMenu = async (data) => {
    const url = `/master/rolemenu/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updateRoleMenu = async (data) => {
    const url = `/master/rolemenu/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deleteRoleMenu = async (params) => {
    const url = `/master/rolemenu/delete`
    const response = await call(METHODS.DELETE, url, params)
    return response.data
}
