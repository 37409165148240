import React, { useState, useEffect, useCallback } from 'react'
import { Select, Spin } from 'antd'
import InputSkeleton from 'components/Common/SkeletonLoading/inputSkeleton'
import { removeDuplicateObjectInArray } from 'utils/array'

function AsyncSelectWithDependency({
    loading: parentLoading,
    dependencyState = [],
    onChange,
    placeholder = 'Select',
    labelInValue = true,
    callApi,
    loadOptions,
    withStar = true,
    mode,
    id = 'asyncSelect',
    // value: valueProps,
    ...props
}) {
    const [loading, setLoading] = useState(false)
    const [optList, setOptList] = useState([])

    const isMultipleMode = mode === 'multiple'

    const checkIsAllOptionSelected = (value1) => {
        if (isMultipleMode) return value1?.findIndex((val) => val?.value === '*') > -1
        return value1?.value === '*'
    }

    const doDisableOptions = () => {
        const disabledOptions = optList.map((opt) => ({
            value: opt.value,
            label: opt.label,
            disabled: opt.value === '*' ? false : true,
        }))
        setOptList(disabledOptions)
    }

    const doEnableOptions = () => {
        const disabledOptions = optList.map((opt) => ({
            value: opt.value,
            label: opt.label,
            disabled: false,
        }))
        setOptList(disabledOptions)
    }

    const handleOnchange = (valueObjWithKey, valueObj) => {
        // Callback onchange
        onChange(valueObjWithKey, valueObj)
        const isAllValuesSelected = checkIsAllOptionSelected(valueObjWithKey)
        if (isAllValuesSelected) doDisableOptions()
        if (!isAllValuesSelected) doEnableOptions()
    }

    const memoizeCallApi = useCallback(callApi, [callApi])
    const memoizeLoadOptions = useCallback(loadOptions, [loadOptions])
    useEffect(() => {
        if (dependencyState.length === 0) return
        const fetchOrderOptions = async () => {
            setLoading(true)
            const res = await memoizeCallApi(dependencyState)
            const opt = memoizeLoadOptions(res)

            setOptList(removeDuplicateObjectInArray(opt, 'value'))
            setLoading(false)
        }
        fetchOrderOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependencyState])

    if (parentLoading)
        return (
            <InputSkeleton
                className={props.className ? props.className : 'rounded-10 h-50'}
                style={{ width: '100%', borderRadius: '5 !important', ...props.style }}
                size="large"
            />
        )
    return (
        <div id={id}>
            <Select
                labelInValue={labelInValue}
                mode={mode}
                allowClear
                showSearch
                loading={loading}
                onChange={handleOnchange}
                placeholder={placeholder}
                className="rounded-10 h-50"
                style={{ width: '100%', borderRadius: '5 !important' }}
                size="large"
                getPopupContainer={() => document.getElementById(id)}
                filterOption={(input, option) => {
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                dropdownRender={(menu) => (
                    <>
                        {!loading && !parentLoading && menu}
                        {(loading || parentLoading) && (
                            <div className="text-center p-10">
                                <Spin />
                            </div>
                        )}
                    </>
                )}
                options={withStar ? [{ value: '*', label: 'All (*)' }, ...optList] : optList}
                {...props}
            />
        </div>
    )
}

export default AsyncSelectWithDependency
