import moment from 'moment'

export const DATE_FORMAT = 'DD-MM-YYYY'
export const TIME_PRESET = {
    TODAY: 'Today',
    THIS_WEEK: 'This Week',
    THIS_MONTH: 'This Month',
    THIS_YEAR: 'This Year',
}

const { TODAY, THIS_WEEK, THIS_MONTH, THIS_YEAR } = TIME_PRESET

export const TIME_RANGE_PRESET = {
    [TODAY]: [moment(), moment()],
    [THIS_WEEK]: [moment().startOf('week'), moment().endOf('week')],
    [THIS_MONTH]: [moment().startOf('month'), moment().endOf('month')],
    [THIS_YEAR]: [moment().startOf('year'), moment().endOf('year')],
}
