import React, { useState, useEffect, useMemo } from 'react'
import { Table, Button, Modal } from 'antd'

import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import SimpleContainer from 'components/layouts/simple'

import SetPicAndDateModal from '../set-pic-and-date'
import SetFilterModal from '../set-filter'

import useQueryRefresh from 'customHooks/useQueryRefresh'

// API
import {
    searchPallet,
    submitStockOpname,
    getDetailStocker,
    deletePallet,
    submitStockOpnameFromDraft,
} from 'api/transaction/result-cycle-count-opname'

import { OPNAME_STATUS } from 'config/constant/result-cycle-count-opname'
import { columns } from './columns'

const initFilterValues = {
    material: [],
    company: [],
    plant: [],
    building: [],
}

function StockOpnameSetting({ close, visible, payload }) {
    const [loading, setLoading] = useState(false)

    const [picAndDate, setPicAndDate] = useState({})
    const [filterValues, setFilterValues] = useState(initFilterValues)

    const isOnEditMode = useMemo(() => !!payload, [payload])

    // Modal State
    const [confirmSubmitEditModal, setConfirmSubmitEditModal] = useState(false)
    const [confirmSubmitModal, setConfirmSubmitModal] = useState(false)
    const [confirmDraftModal, setConfirmDraftModal] = useState(false)
    const [picDateModal, setPicDateModal] = useState(false)
    const [filterModal, setFilterModal] = useState(false)
    const [palletData, setPalletData] = useState([])

    // Custom Hooks
    const { doRefresh } = useQueryRefresh()

    const onClose = () => {
        setLoading(false)
        setFilterValues(initFilterValues)
        setPicAndDate({})
        setPalletData([])
        close()
    }

    const onCLickSubmit = () => {
        if (!picAndDate.userId)
            return Modal.error({ title: 'Oops', content: 'Please set PIC and Date first' })
        setConfirmSubmitModal(true)
    }

    const onCLickSubmitFromEdit = () => {
        setConfirmSubmitEditModal(true)
    }

    const onCLickSaveAsDraft = () => {
        if (!picAndDate.userId)
            return Modal.error({ title: 'Oops', content: 'Please set PIC and Date first' })
        setConfirmDraftModal(true)
    }

    const getRequestBody = (status) =>
        palletData.map((item) => ({
            companyId: item.companyId,
            plantId: item.plantId,
            palletId: item.palletId,
            palletCode: item.palletCode,
            materialId: item.materialId,
            description: item.description,
            batch: item.batch,
            qty: item.qty,
            uom: item.uom,
            binId: item.binId,
            slocId: item.slocId,
            sectorId: item.sectorId,
            gateId: item.gateId,
            dockingId: item.dockingId,
            stagingIn: item.staggingIn,
            status: OPNAME_STATUS[status],
            type: '01', // Default dari BE
            userId: picAndDate?.foremanId || '',
            from: picAndDate?.fromTo[0].format('YYYY-MM-DD hh:mm:ss') || '',
            to: picAndDate?.fromTo[1].format('YYYY-MM-DD hh:mm:ss') || '',
        }))

    const handleSubmit = async (status) => {
        const reqBody = getRequestBody(status)
        try {
            setLoading(true)
            const res = await submitStockOpname(reqBody)
            setLoading(false)
            if (!res.success) return res
            doRefresh()
            onClose()
            return res
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    const handleSubmitFromEdit = async () => {
        const reqBody = { id: payload.transactionId }
        try {
            setLoading(true)
            const res = await submitStockOpnameFromDraft(reqBody)
            setLoading(false)
            doRefresh()
            onClose()
            return res
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    const onCLickDeletePallet = async (row) => {
        const { palletId } = row
        try {
            setLoading(true)
            const del = await deletePallet({ transactionId: payload?.transactionId, palletId })
            if (!del.success) return
            const res = await getDetailStocker({ id: payload?.transactionId })
            setPalletData(res?.result?.detail || [])

            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    const onChangeSetFilter = async (values) => {
        setFilterValues({ ...filterValues, ...values })
    }

    const submitSetFilter = async () => {
        const reqQuery = {
            material: filterValues?.material
                ?.map((arr) => arr.value)
                .reduce((acc, cur) => acc + ',' + cur, ''),
            company: filterValues?.company
                ?.map((arr) => arr.value)
                .reduce((acc, cur) => acc + ',' + cur, ''),
            plant: filterValues.plant
                ?.map((arr) => arr?.value)
                .reduce((acc, cur) => acc + ',' + cur, ''),
            building: filterValues?.building
                ?.map((arr) => arr.value)
                .reduce((acc, cur) => acc + ',' + cur, ''),
        }

        try {
            setLoading(true)
            const res = await searchPallet(reqQuery)
            setPalletData(res?.data || [])
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!isOnEditMode) return // only fetch on Edit Mode
        const fetchData = async () => {
            const res = await getDetailStocker({ id: payload?.transactionId })
            setPalletData(res?.result?.detail || [])
        }
        fetchData()
    }, [payload, isOnEditMode])

    return (
        <>
            <SimpleModal
                title="Stock Opname Setting"
                width={1190}
                close={onClose}
                footer={false}
                loading={loading}
                visible={visible}
            >
                {!isOnEditMode && (
                    <SimpleContainer>
                        <ButtonsGroupGrid align="left" className="">
                            <Button
                                loading={loading}
                                onClick={() => setFilterModal(true)}
                                type="primary"
                                className="w-157 h-45 rounded-10 mx-2"
                            >
                                Set Filter
                            </Button>
                            <Button
                                onClick={() => setPicDateModal(true)}
                                className="flatBlue w-157 h-45 rounded-10 mx-2"
                            >
                                Set PIC & Date
                            </Button>
                        </ButtonsGroupGrid>
                    </SimpleContainer>
                )}
                <SimpleContainer>
                    <Table
                        loading={loading}
                        dataSource={palletData}
                        columns={columns(isOnEditMode, onCLickDeletePallet)}
                        rowClassName={(_, index) =>
                            `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                        }
                        pagination={false}
                        scroll={{ y: 300, x: 1000 }}
                    />
                </SimpleContainer>
                <ButtonsGroupGrid align="right" className="mb-3 mt-10">
                    {isOnEditMode && (
                        <Button
                            disabled={palletData?.length === 0}
                            onClick={onCLickSubmitFromEdit}
                            type="primary"
                            className="w-157 h-45 rounded-10 mx-2"
                            loading={loading}
                        >
                            Submit
                        </Button>
                    )}
                    {!isOnEditMode && (
                        <Button
                            disabled={palletData?.length === 0}
                            onClick={onCLickSubmit}
                            type="primary"
                            className="w-157 h-45 rounded-10 mx-2"
                            loading={loading}
                        >
                            Submit
                        </Button>
                    )}
                    {!isOnEditMode && (
                        <Button
                            disabled={palletData?.length === 0}
                            onClick={onCLickSaveAsDraft}
                            className="outLinedPink w-157 h-45 rounded-10 mx-2"
                            loading={loading}
                        >
                            Save as Draft
                        </Button>
                    )}
                    <Button onClick={onClose} className="outLinedGrey w-157 h-45 rounded-10 mx-2">
                        {isOnEditMode ? 'Close' : 'Cancel'}
                    </Button>
                </ButtonsGroupGrid>
            </SimpleModal>
            <SetPicAndDateModal
                visible={picDateModal}
                close={() => setPicDateModal(false)}
                onChange={() => {}}
                onOk={setPicAndDate}
            />
            <SetFilterModal
                visible={filterModal}
                close={() => setFilterModal(false)}
                onChange={onChangeSetFilter}
                onSubmit={submitSetFilter}
                values={filterValues}
                resetValues={() => setFilterValues(initFilterValues)}
            />
            <ConfirmationModal
                visible={confirmSubmitModal}
                title="Are you sure you want to Submit?"
                successTitleMsg="Submitted!"
                successContentMsg={(r) => (
                    <p>
                        {r.documentNumber}: {r.message}
                    </p>
                )}
                close={() => setConfirmSubmitModal(false)}
                onOk={() => handleSubmit('SUBMIT')}
                centered={false}
                okText="Submit"
            />
            <ConfirmationModal
                visible={confirmSubmitEditModal}
                title="Are you sure you want to Submit?"
                successTitleMsg="Edited Data Submitted!"
                close={() => setConfirmSubmitEditModal(false)}
                onOk={handleSubmitFromEdit}
                centered={false}
                okText="Submit"
            />
            <ConfirmationModal
                visible={confirmDraftModal}
                title="Are you sure you want to save as draft?"
                successContentMsg={(res) => (
                    <>
                        <p>{res.documentNumber}</p>
                        <p>Saved as draft!</p>
                    </>
                )}
                close={() => setConfirmDraftModal(false)}
                onOk={() => handleSubmit('DRAFT')}
                centered={false}
                okText="Submit"
            />
        </>
    )
}

export default StockOpnameSetting
