import React, { useState } from 'react'
import { Button, Form, Input, Table } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
import SelectPlantByCompanies from 'components/Common/AsyncSelectWithDependency/byCompanies/SelectPlantByCompanies'
import { CheckCircleIcon } from 'assets'

import {  getSingleDoExecution} from 'api/transaction/plannerDoExecution'

import useQueryRefresh from 'customHooks/useQueryRefresh'

import { auth } from 'auth'


const GetSingleDoNumberModal = ({ onClickAddOutbondId, close, ...props }) => {
    const [showConfirmationModal, setConfirmationModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [doNumber, setDoNumber] = useState("")
    const [plantId, setPlantId] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [action, setAction] = useState("")
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const changeDoNumber = async (e) => {
        setDoNumber(e.target.value ?? "")
       
    }
    const changeCompany =  (e) => {
        console.log(e.value)
        setCompanyId(e.value)
    
    }
    const changePlant =  (e) => {
        console.log(e)
        setPlantId(e.value)
    }
    const handleOk = async () => {
        const values = await form.validateFields()
        setLoading(true)
        submit()
        const params = {
            doNumber: [doNumber],
            company : companyId,
            plant : plantId,
            createDate : ""
        }
        console.log(params)
        const res = await getSingleDoExecution(params)
        if (res.success) {
            setConfirmationModal(true)
            close()
            doRefresh()
        }

        setLoading(false)
    }

    return (
        <>
            <SimpleModal
                title="Call Delivery Order Sap"
                okText="Submit"
                onOk={handleOk}
                close={close}
                loading={loading}
                {...props}
            >
                 <Form
                    requiredMark={false}
                    layout="vertical"
                    form={form}
                    preserve={false}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                        gap: 10,
                    }}
                >
                    <Form.Item
                        className="my-1"
                        style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="doNumber"
                        label="Do Number"
                        shouldUpdate={true}
                        rules={[{ required: true }]}
                    >
                       <Input placeholder={"Do Number"} className="rounded-5" size="large"  onChange={changeDoNumber}/>
                    </Form.Item>
                    <Form.Item
                        className="my-1"
                        style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="companyId"
                        label="Company"
                        shouldUpdate={true}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                    <SelectDebounceFetch.Company onChange={changeCompany} />
                    </Form.Item>
                    <Form.Item
                        className="my-1"
                        style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                        name="plantId"
                        label="Plant ID"
                        shouldUpdate={true}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                    <SelectPlantByCompanies
                        onChange={changePlant}
                        companies={[companyId]}
                        className="rounded-5"
                    />
                    </Form.Item>
                </Form>
            </SimpleModal>
            <ConfirmationModal
                visible={showConfirmationModal}
                close={() => setConfirmationModal(false)}
                title={
                    <>
                    <div className="flex justify-center items-center text-nabati-green text-2xl">
                        <CheckCircleIcon /> <span className="m-2">Success Call Data</span><br/>  
                        <br/> 
                    </div>
                     {/* <div className="flex justify-center items-center text-nabati-green text-2xl">
                        <span className="m-2" style={{fontSize:16}}>Do Number Has Been</span> 
                     </div> */}
                     </>
                }
                isConfirmationOnly
            />
        </>
    )
}

export default GetSingleDoNumberModal
