import React, { useCallback, useReducer, useEffect } from 'react'
import moment from 'moment'
import { Button, Radio, Row, Col, Modal, Input, DatePicker } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { auth } from 'auth'

import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

import SelectGlByPlant from 'components/Common/SelectFetchByPlant/selectGlByPlant'
import SelectCostCenterByPlant from 'components/Common/SelectFetchByPlant/selectCostCenterByPlant'
import SelectOrderByPlant from 'components/Common/SelectFetchByPlant/selectOrderByPlant'
import PdfImageUploader from 'components/Common/Uploader/PdfOrImageUploader'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import DataTable from './data-table'
import { revampReservationFile } from './helper'

import {
    getReservationDraft,
    getScrapDraft,
    createScrapDraft,
    createPlannerReservationDraft,
    deleteReservation,
    deleteScrap,
    deleteImageFromDb,
} from 'api/transaction/plannerGoodIssue'

const TRANSACTION_TYPE = {
    SCRAP: 'Scrap - scrap',
    COST_CENTER: 'Res-IO - CC',
    ORDER: 'Res-IO - ORD',
}

const generateImagePayload = (images) => {
    const result = images.map((img, idx, arr) => {
        // const lastImageId = (() => {
        //     if (idx === 0) return 0
        //     return images[idx - 1].imageId
        // })()
        return {
            ...img,
            imageId: idx + 1,
            data: img.data.split(',')[1], // remove 'data:image/png;base64' from the string
            name: img.name,
        }
    })
    return result
}

const getBodyRequest = (state) => {
    const user = auth.getUserInfo()
    const reservationType = state.transactionType.split(' - ')[1] // 'CC or ORD'
    const IS_SCRAP_TYPE = reservationType.toLowerCase() === 'scrap'

    // const items = regroupingItemsByPalletBatch(state.dataTable)
    const items = [...state.dataTable]

    const scrapDraftBodyRequest = {
        transactionId: state.formData.transactionId,
        docnum: state.formData.transactionId,
        plant: state.formData.plant, // dari input atas ?
        pstngDate: state.formData.reservationDate, // apa beda pstg_date dengan doc_date?
        docDate: state.formData.reservationDate, // apa beda pstg_date dengan doc_date?
        refDocNo: state.formData.refDocNo, // material slip
        headerTxt: state.formData.headerTxt, // header text
        prUname: user?.username || user?.userName || '', // ???
        items: items.map((el, idx) => ({
            item: (+idx + 1) * 10, // ??? bisa sama dengan index saja kah?
            batch: el.pallets[0].batch,
            material: el.material,
            description: el.materialDescription,
            plant: el.plant,
            glAccount: el.gl,
            costcenter: el.costCenter,
            entryQnt: +el.qty,
            entryUom: el.poUnit,
            stgeLoc: el.slocId,
            moveReas: +el.reasonMovement || '', //5552, // Tunggu API. Defaultnya = 5551 atau 5552
            grRcpt: el.goodReceipt,
            itemText: el.text,
            pallets: el.pallets.map((i) => ({
                palletId: i.palletId,
                palletCode: i.palletCode,
                qty: i.qty,
                overQty: i.overQty,
                batch: i.batch,
            })),
        })),
        images: generateImagePayload(state.formData.images),
    }

    const plannerReservationDraftBodyRequest = {
        // 'COST CENTER' DAN 'GL Account' TIDAK DIGUNAKAN DISINI ???
        transactionId: state.formData.transactionId,
        docnum: state.formData.transactionId,
        plant: state.formData.plant, // dari input atas ?
        reservationDate: state.formData.reservationDate, // dari input atas ?
        reservationType: reservationType, // tanya Pa Roel lagi CC or ORD
        movementPlant: state.formData.plant, // Ada plant lagi. Ambil dari mana???
        userName: user.username || user.userName, // ???
        movementType: reservationType.toLowerCase().includes('cc') ? '201' : '261', // dari201, // 201 = CC       261 = order
        costCenter: state.formData.costCenter,
        glAccount: state.formData.gl,
        orderNumber: state.formData.order, // dari input Order
        items: items.map((el, idx) => ({
            item: (+idx + 1) * 10,
            batch: el.pallets[0].batch,
            material: el.material,
            description: el.materialDescription,
            stgeLoc: el.slocId,
            plant: el.plant,
            entryQnt: +el.qty,
            entryUom: el.poUnit,
            reqDate: state.formData.reservationDate,
            pallets: el.pallets.map((i) => ({
                palletId: i.palletId,
                palletCode: i.palletCode,
                qty: i.qty,
                overQty: i.overQty,
                batch: i.batch,
            })),
        })),
        images: generateImagePayload(state.formData.images),
    }

    if (IS_SCRAP_TYPE) return scrapDraftBodyRequest
    return plannerReservationDraftBodyRequest
}

const initState = {
    loading: false,
    transactionType: TRANSACTION_TYPE.SCRAP,
    formData: {
        transactionId: '',
        plant: '',
        plantDescription: '',
        gl: '',
        costCenter: '',
        order: '',
        reservationNumber: '',
        scrapId: '',
        reservationDate: moment().format('YYYY-MM-DD'),
        images: [],
    },
    dataTable: [],
    initialDataTable: [],
    confirmationSaveModal: false,
    confirmationDeleteModal: false,
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'SET_TRANSACTION_TYPE':
            return { ...state, transactionType: action.transactionType }
        case 'SET_FORM_DATA':
            return { ...state, formData: { ...state.formData, ...action.payload } }
        case 'SET_DATA_TABLE':
            return { ...state, dataTable: action.dataTable }
        case 'SET_INITIAL_DATA_TABLE':
            return { ...state, initialDataTable: action.initialDataTable }
        case 'TOGGLE_CONFIRM_SAVE':
            return { ...state, confirmationSaveModal: action.toggle }
        case 'TOGGLE_CONFIRM_DELETE':
            return { ...state, confirmationDeleteModal: action.toggle }
        case 'RESET':
            return initState
        default:
            return state
    }
}

function CreateReservation({ close, payload, ...props }) {
    const [state, dispatch] = useReducer(stateReducer, initState)
    const { doRefresh } = useQueryRefresh()

    const isOnDisplayMode = payload?.displayMode || false
    const isOnEditMode = !!payload?.transactionId
    // const isOnCreateMode = payload?.createMode || false

    const setDataTable = useCallback((dataTable) => {
        dispatch({ type: 'SET_DATA_TABLE', dataTable })
    }, [])

    const validateFormDataInput = () => {
        const error = []
        if (!state.transactionType) error.push('Please Select Transaction Type')
        if (error.length > 0) return error

        if (!state.formData.plant) error.push('Plant is required.')

        if (!state.formData.gl) {
            if (state.transactionType !== TRANSACTION_TYPE.SCRAP) error.push('GL is required.')
        }
        if (!state.formData.costCenter) {
            if (state.transactionType !== TRANSACTION_TYPE.SCRAP)
                error.push('Cost Center is required.')
        }
        if (!state.formData.order) {
            if (state.transactionType === TRANSACTION_TYPE.ORDER) error.push('Order is required.')
        }

        if (!state.formData.reservationDate) error.push('Reservation Date is required.')
        if (error.length > 0) return error

        if (state.dataTable.length === 0) error.push('Add at least one item to save.')
        if (error.length > 0) return error

        const isContainNullPallet =
            state?.dataTable?.map((data) => data?.pallets).filter((a) => a?.length === 0)?.length >
            0
        if (isContainNullPallet) error.push('Please assign pallet for every items')
        if (error.length > 0) return error

        return error
    }

    const onClickSave = () => {
        const error = validateFormDataInput()
        if (error.length > 0) {
            return Modal.error({
                title: 'Oops',
                content: error.reduce((acc, cur) => (
                    <div>
                        <div>{acc}</div>
                        <div>{cur}</div>
                    </div>
                )),
            })
        }

        dispatch({ type: 'TOGGLE_CONFIRM_SAVE', toggle: true })
    }

    const onClickDelete = () => {
        dispatch({ type: 'TOGGLE_CONFIRM_DELETE', toggle: true })
    }

    const handleSave = async () => {
        const type = state.transactionType.split(' - ')[0]
        const bodyReq = getBodyRequest(state)

        const callApi =
            type.toLowerCase() === 'scrap' ? createScrapDraft : createPlannerReservationDraft

        try {
            dispatch({ type: 'SET_LOADING', loading: true })
            const res = await callApi(bodyReq)
            if (res.success) {
                doRefresh()
                close()
                dispatch({ type: 'RESET' })
            }
            dispatch({ type: 'SET_LOADING', loading: false })
            return res
        } catch (error) {
            dispatch({ type: 'SET_LOADING', loading: false })
            console.error(error)
        }
    }

    const handleDelete = async () => {
        const bodyReq = { transactionId: payload?.transactionId }
        const callApi = payload.type === 'Scrap' ? deleteScrap : deleteReservation
        const res = await callApi(bodyReq)
        if (res.success) {
            doRefresh()
            close()
            dispatch({ type: 'RESET' })
        }
        return res
    }

    useEffect(() => {
        dispatch({ type: 'RESET' })
        if (!payload?.transactionId) return
        dispatch({ type: 'SET_LOADING', loading: true })
        dispatch({ type: 'SET_TRANSACTION_TYPE', transactionType: '' })

        const fetchData = async () => {
            const callApi = payload.type === 'Scrap' ? getScrapDraft : getReservationDraft

            const res = await callApi({ id: payload.transactionId })
            if (!res.success) {
                return Modal.error({
                    title: 'Oops',
                    content: res?.message || '',
                })
            }

            dispatch({
                type: 'SET_TRANSACTION_TYPE',
                transactionType: res?.data?.reservationType?.toLowerCase()?.includes('cc')
                    ? TRANSACTION_TYPE.COST_CENTER
                    : res?.data?.reservationType?.toLowerCase()?.includes('ord')
                    ? TRANSACTION_TYPE.ORDER
                    : TRANSACTION_TYPE.SCRAP,
            })

            dispatch({
                type: 'SET_FORM_DATA',
                payload: {
                    transactionId: payload?.transactionId, // untuk edit
                    docnum: res?.data?.transactionId,
                    reservationNumber: res?.data?.reservationNumber,
                    scrapId: res?.data?.scrapId,
                    company: res?.data?.company,
                    plant: res?.data?.plant,
                    gl: res?.data?.glAccount,
                    costCenter: res?.data?.costCenter,
                    reservationType: res?.data?.reservationType,
                    reservationDate: res?.data?.reservationDate || res?.data.docDate,
                    movementType: res?.data?.movementType,
                    movementPlant: res?.data?.movementPlant,
                    movementSloc: res?.data?.movementSloc,
                    userName: res?.data?.userName,
                    order: res?.data?.orderNumber,
                    images: res?.data?.images?.map((img) => revampReservationFile(img)) || [],
                    headerTxt: res?.data?.headerTxt || '',
                    refDocNo: res?.data?.refDocNo || '',
                },
            })

            dispatch({
                type: 'SET_INITIAL_DATA_TABLE',
                initialDataTable: res?.data?.items?.map((el, idx) => ({
                    key: parseInt(Math.random() * 1000000000),
                    material: el.material || '-',
                    materialDescription: el.description || '-',
                    qty: el.entryQnt || '-',
                    poUnit: el.entryUom || '-',
                    plant: el.plant || '-',
                    plantDescription: el.plantDescription || '-',
                    slocId: el.stgeLoc || '-',
                    slocDescription: el.slocDescription || el.stgeLocDescription || '-',
                    route: res?.data?.shippings ? res?.data?.shippings[idx]?.route : '-',
                    routeDescription: el.routeDescription || '-',
                    reasonMovement: el.moveReas,
                    pallets: el.pallets || [],
                    costCenter: el.costcenter || '',
                    gl: el.glAccount || '',
                    text: el.itemText || '',
                    goodReceipt: el.grRcpt || '',
                })),
            })
            dispatch({ type: 'SET_LOADING', loading: false })
        }

        fetchData()
    }, [payload?.transactionId, payload?.type])

    console.log('isOnDisplayMode', isOnDisplayMode)

    return (
        <>
            <SimpleModal
                title={
                    isOnDisplayMode ? (
                        <p>View Reservation - {payload?.transactionId}</p>
                    ) : payload?.transactionId ? (
                        <p>Edit Reservation - {payload?.transactionId}</p>
                    ) : (
                        'Create Reservation'
                    )
                }
                width={1190}
                footer={null}
                {...props}
            >
                <Radio.Group
                    className="my-3 text-base font-bold"
                    onChange={(e) => {
                        if (state.dataTable.length > 0) {
                            return Modal.error({
                                title: 'Oops',
                                content: 'Please delete all item to change transaction type',
                            })
                        }
                        dispatch({ type: 'SET_TRANSACTION_TYPE', transactionType: e.target.value })
                    }}
                    value={state.transactionType}
                    disabled={isOnDisplayMode}
                >
                    <Radio value={TRANSACTION_TYPE.SCRAP}>Scrap</Radio>
                    <Radio value={TRANSACTION_TYPE.COST_CENTER}>Cost Center</Radio>
                    <Radio value={TRANSACTION_TYPE.ORDER}>Order</Radio>
                </Radio.Group>

                <Row gutter={16}>
                    <Col span={16}>
                        <Row gutter={[16, 16]} className="mb-5">
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Plant</p>
                                <SelectDebounceFetch.Plant
                                    loading={state.loading}
                                    onChange={(plant) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: {
                                                plant: plant?.value || '',
                                                plantDescription: plant?.label || '',
                                                gl: '',
                                                costCenter: '',
                                                order: '',
                                            },
                                        })
                                    }}
                                    defaultValue={
                                        state?.formData?.plant
                                            ? { value: state?.formData?.plant }
                                            : undefined
                                    }
                                    className="rounded-10 h-50"
                                    style={{ width: '100%', borderRadius: '5 !important' }}
                                    size="large"
                                    disabled={isOnDisplayMode}
                                />
                            </Col>

                            {state.transactionType !== TRANSACTION_TYPE.SCRAP && (
                                <Col span={12}>
                                    <p className="text-nabati-grey text-base mb-1">GL Account</p>
                                    <SelectGlByPlant
                                        loading={state.loading}
                                        value={state?.formData?.gl || undefined}
                                        plant={state?.formData?.plant || ''}
                                        onChange={(value) => {
                                            dispatch({
                                                type: 'SET_FORM_DATA',
                                                payload: { gl: value },
                                            })
                                        }}
                                        disabled={isOnDisplayMode}
                                    />
                                </Col>
                            )}

                            {state.transactionType !== TRANSACTION_TYPE.SCRAP && (
                                <Col span={12}>
                                    <p className="text-nabati-grey text-base mb-1">Cost Center</p>
                                    <SelectCostCenterByPlant
                                        loading={state.loading}
                                        value={state?.formData?.costCenter || undefined}
                                        plant={state?.formData?.plant || ''}
                                        onChange={(value) => {
                                            dispatch({
                                                type: 'SET_FORM_DATA',
                                                payload: { costCenter: value },
                                            })
                                        }}
                                        disabled={isOnDisplayMode}
                                    />
                                </Col>
                            )}

                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Date</p>
                                <DatePicker
                                    size="large"
                                    className="rounded-10 h-50"
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD"
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { reservationDate: e.format('YYYYMMDD') },
                                        })
                                    }}
                                    defaultValue={moment(state?.formData?.reservationDate)}
                                    disabled={isOnDisplayMode}
                                />
                            </Col>
                            {state.transactionType !== TRANSACTION_TYPE.SCRAP && (
                                <Col span={12}>
                                    <p className="text-nabati-grey text-base mb-1">Order</p>
                                    <SelectOrderByPlant
                                        loading={state.loading}
                                        value={state?.formData?.order || undefined}
                                        plant={state?.formData?.plant || ''}
                                        onChange={(value) => {
                                            dispatch({
                                                type: 'SET_FORM_DATA',
                                                payload: { order: value },
                                            })
                                        }}
                                        disabled={isOnDisplayMode}
                                    />
                                </Col>
                            )}
                            {state.transactionType !== TRANSACTION_TYPE.SCRAP && (
                                <Col span={12}>
                                    <p className="text-nabati-grey text-base mb-1">
                                        Reservation No.
                                    </p>
                                    <Input
                                        placeholder="Reservation No."
                                        onChange={(e) => {
                                            dispatch({
                                                type: 'SET_FORM_DATA',
                                                payload: { reservationNumber: e.target.value },
                                            })
                                        }}
                                        size="large"
                                        className="rounded-10 h-50"
                                        style={{ width: '100%' }}
                                        value={payload?.reservationNumber || undefined}
                                        disabled={true}
                                    />
                                </Col>
                            )}
                            {state.transactionType === TRANSACTION_TYPE.SCRAP && (
                                <Col span={12}>
                                    <p className="text-nabati-grey text-base mb-1">Scrap Id</p>
                                    <Input
                                        onChange={(e) => {
                                            dispatch({
                                                type: 'SET_FORM_DATA',
                                                payload: { scrapId: e.target.value },
                                            })
                                        }}
                                        size="large"
                                        className="rounded-10 h-50"
                                        style={{ width: '100%' }}
                                        value={payload?.scrapId || undefined}
                                        placeholder="Scrap ID"
                                        disabled={true}
                                    />
                                </Col>
                            )}
                            {state.transactionType === TRANSACTION_TYPE.SCRAP && (
                                <>
                                    <Col span={12}>
                                        <p className="text-nabati-grey text-base mb-1">
                                            Material Slip
                                        </p>
                                        <Input
                                            placeholder="Material Slip"
                                            onChange={(e) => {
                                                dispatch({
                                                    type: 'SET_FORM_DATA',
                                                    payload: { refDocNo: e.target.value },
                                                })
                                            }}
                                            size="large"
                                            className="rounded-10 h-50"
                                            style={{ width: '100%' }}
                                            value={state?.formData?.refDocNo || ''}
                                            disabled={isOnDisplayMode}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <p className="text-nabati-grey text-base mb-1">
                                            Doc. Header Text
                                        </p>
                                        <Input
                                            placeholder="Doc. Header Text"
                                            onChange={(e) => {
                                                dispatch({
                                                    type: 'SET_FORM_DATA',
                                                    payload: { headerTxt: e.target.value },
                                                })
                                            }}
                                            size="large"
                                            className="rounded-10 h-50"
                                            style={{ width: '100%' }}
                                            disabled={isOnDisplayMode}
                                            value={state?.formData?.headerTxt || undefined}
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Col>
                    <Col span={8}>
                        <p className="text-nabati-grey text-base mb-1 mt-24">Upload Berita Acara</p>
                        <PdfImageUploader
                            multiple
                            loading={state.loading}
                            title="Upload PDF or Images"
                            initialValues={state.formData.images}
                            onChange={(data) => {
                                dispatch({
                                    type: 'SET_FORM_DATA',
                                    payload: {
                                        images: data.map((img, ind) => ({
                                            ...img,
                                            name: img.name,
                                            data: img.data,
                                        })),
                                    },
                                })
                            }}
                            onDeleteImage={async (img) => {
                                if (!payload?.transactionId) return
                                await deleteImageFromDb({
                                    transactionId: payload?.transactionId,
                                    imageId: img.imageId,
                                })
                            }}
                        />
                    </Col>
                </Row>
                <DataTable
                    isOnEditMode={!!state.formData.transactionId}
                    setDataTable={setDataTable}
                    initialDataTable={state.initialDataTable}
                    isOnDisplayMode={isOnDisplayMode}
                    loading={state.loading}
                    isScrapType={state.transactionType === TRANSACTION_TYPE.SCRAP}
                    plantFromHeader={{
                        value: state?.formData?.plant,
                        label: state?.formData?.plantDescription || state?.formData?.plant,
                    }} // the plant for scrap must be the same with plant in add item
                />

                <div className="flex mt-6">
                    {isOnEditMode && !isOnDisplayMode && (
                        <Button
                            type="primary"
                            onClick={onClickDelete}
                            className="w-157 h-45 rounded-10 flex justify-center items-center"
                        >
                            <DeleteOutlined style={{ marginBottom: 2 }} /> Delete
                        </Button>
                    )}
                    <Button
                        onClick={() => {
                            close()
                            dispatch({ type: 'RESET' })
                        }}
                        className="outLinedPink w-157 h-45 rounded-10 ml-auto mx-2"
                    >
                        {isOnDisplayMode ? 'Close' : 'Cancel'}
                    </Button>
                    {!isOnDisplayMode && (
                        <Button
                            type="primary"
                            onClick={onClickSave}
                            className="w-157 h-45 rounded-10 mx-2"
                        >
                            Save
                        </Button>
                    )}
                </div>
            </SimpleModal>
            <ConfirmationModal
                title="Confirm Save"
                visible={state.confirmationSaveModal}
                close={() => dispatch({ type: 'TOGGLE_CONFIRM_SAVE', toggle: false })}
                onOk={handleSave}
                content="Are you sure to save Reservation as DRAFT?"
                successTitleMsg="Saved"
                successContentMsg={(res) =>
                    `Transaction ID: ${res?.documentNumber || res?.data?.transactionId}`
                }
            />
            <ConfirmationModal
                title="Confirm Delete"
                visible={state.confirmationDeleteModal}
                close={() => dispatch({ type: 'TOGGLE_CONFIRM_DELETE', toggle: false })}
                onOk={handleDelete}
                content={`Transaction ID: ${payload?.transactionId}`}
                successTitleMsg={`${payload?.transactionId} Deleted`}
                // successContentMsg={(res) =>
                //     `Transaction ID: ${res?.documentNumber || res?.data?.transactionId}`
                // }
            />
        </>
    )
}

export default CreateReservation
