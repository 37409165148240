import React from 'react'

import { getSlocIdByPlant } from 'api/master/plantSloc'
import AsyncSelectWithDependency from 'components/Common/AsyncSelectWithDependency'

function SelectSlocByPlants({
    loading,
    plants = [],
    onChange,
    placeholder = 'Select Plant',
    ...props
}) {
    const loadOptions = (res) => {
        return res.result.map((item) => ({
            label: `${item.slocId} - ${item.description}`,
            value: item.slocId,
        }))
    }

    return (
        <AsyncSelectWithDependency
            labelInValue={true}
            placeholder={placeholder}
            callApi={(val) => getSlocIdByPlant({ plantId: val })}
            dependencyState={plants}
            loading={loading}
            onChange={onChange}
            loadOptions={loadOptions}
            {...props}
        />
    )
}

export default SelectSlocByPlants
