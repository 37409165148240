import React from 'react'
import SelectDebounceFetch from './index'
import { getPoTypeList } from 'api/master/poType'

function SelectPoType({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Select Po Type"
            fetchOptions={async (searchTerm) => {
                const res = await getPoTypeList({
                    filter: searchTerm,
                    limit: 10,
                })
                return res.result.data.map((po) => ({
                    label: `${po.poType} - ${po.description}`,
                    value: po.poType,
                }))
            }}
            {...props}
        />
    )
}

export default SelectPoType
