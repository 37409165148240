import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'
import { options } from '../options'

export {
    createPoStoDraft,
    createPoStoSapFromDraft,
    deletePoStoSap,
    getPoStoDraft,
} from '../plannerStockTransfer'
export { createScrapDraft, postScrapToSap, deleteScrap } from '../plannerGoodIssue'

export const getOutstandingMaterial = async (params) => {
    const response = await call(METHODS.GET, '/planner-fisik', params, options)
    return response.data
}
export const getOutstandingMaterialUngroup = async (params) => {
    const response = await call(METHODS.GET, '/planner-fisik-ungroup', params, options)
    return response.data
}
export const getPoStoList = async (params) => {
    const response = await call(METHODS.GET, '/planner-fisik-posto', params, options)
    return response.data
}

export const getScrapList = async (params) => {
    const response = await call(METHODS.GET, '/planner-fisik-scrap', params, options)
    return response.data
}

export const getScrapDetail = async (params) => {
    const response = await call(METHODS.GET, `/planner-fisik-scrap-detail`, params, options)
    return response.data
}

export const moveBinPlanner = async (params) => {
    const response = await call(METHODS.POST, '/planner-fisik-move-bin', params, options)
    return response.data
}

// export const getDeficitStock = async (params) => {
//     const response = await call(METHODS.POST, '/planner-priority-deficit-stock', params, options)
//     return response.data
// }
