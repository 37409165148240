import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'

import { options, optionsib } from '../options'

export const getPlannerDoExecutionList = async (params) => {
    const response = await call(METHODS.GET, '/do-execution', params, options)
    return response.data
}

export const createNewDoExecution = async (params) => {
    const response = await call(METHODS.POST, '/create-do-execution', params, options)
    return response.data
}

export const unlockNewDoExecution = async (params) => {
    const response = await call(METHODS.POST, '/unlock-do-execution', params, options)
    return response.data
}

export const lockNewDoExecution = async (params) => {
    const response = await call(METHODS.POST, '/lock-do-execution', params, options)
    return response.data
}

export const lockUnlockDoExecution = async (params) => {
    const response = await call(METHODS.POST, '/lock-unlock-do-execution', params, options)
    return response.data
} 

export const getSingleDoExecution = async (params) => {
    const response = await call(METHODS.POST, '/outbound/get-single-do', params, optionsib)
    return response.data
}
export const cancelLoadingExecution = async (params) => {
    const response = await call(METHODS.POST, '/cancel-loading', params, options)
    return response.data
}

export const generatePallet = async (params) => {
    const response = await call(METHODS.POST, '/generate-pallet', params, options)
    return response.data
}

export const assignPallet = async (params) => {
    const response = await call(METHODS.POST, '/assign-pallet', params, options)
    return response.data
    // return params;
}

export const addInformation = async (params) => {
    const response = await call(METHODS.POST, '/add-information', params, options)
    return response.data
}

export const displayBooking = async (params) => {
    // const response = await call(METHODS.GET, '/display-booking', params, options)
    const response = await call(METHODS.GET, '/display-outstanding-id', params, options)
    return response.data
}

export const startLoadingDoExecution = async (params) => {
    const response = await call(METHODS.POST, '/start-loading', params, options)
    return response.data
}

export const getSpvGroupDc = async (params) => {
    const response = await call(METHODS.GET, '/list-spv-group-dc', params, options)
    return response.data
}

export const getSpvUsers = async (params) => {
    const response = await call(METHODS.GET, '/list-spv', params, options)
    return response.data
}

export const deleteSpv = async (params) => {
    const response = await call(METHODS.POST, '/delete-spv', params, options)
    return response.data
}

export const getAssignPallets = async (params) => {
    const response = await call(METHODS.GET, '/assign-pallet', params, options)
    return response.data
}

export const deleteAssignPallets = async (params) => {
    const response = await call(METHODS.DELETE, '/assign-pallet', params, options)
    return response.data
}

export const putAssignPallets = async (params) => {
    const response = await call(METHODS.PUT, '/assign-pallet', params, options)
    return response.data
}

export const getPlannerDoExecution = async (params) => {
    const response = await call(METHODS.GET, '/find-do-execution', params, options)
    return response.data
}

export const getOutstandingItemDo = async (params) => {
    const response = await call(METHODS.GET, '/list-oustanding-item-do', params, options)
    return response.data
}

export const putDoExecution = async (params) => {
    const response = await call(METHODS.PUT, '/do-execution', params, options)
    return response.data
}

export const updateQtyDoExecution = async (params) => {
    const response = await call(METHODS.POST, '/update-qty-do-execution', params, options)
    return response.data
}

export const massAssignPallet = async (params) => {
    const response = await call(METHODS.POST, '/mass-assign-pallet', params, options)
    return response.data
}

export const getDetailDo = async (params) => {
    const payload = {
        ...params,
        transactionId: params.transactionId,
        doNumber: params.doNumber,
        company: params.company,
    }
    const response = await call(METHODS.GET, '/do-details', payload, options)
    return response.data
}

export const giSapWoTruck = async (params) => {
    const payload = {
        ...params,
        transactionId: params.transactionId,
        doNumber: params.doNumber,
        postingDate: params.postingDate,
    }
    const response = await call(METHODS.POST, '/gi-sap-wo-truck', payload, {
        ...options,
        skipResponseInterceptor: true, // this flag is to prevent notification error
    })
    return response.data
}

export const assignBookingId = async (params) => {
    const payload = {
        ...params,
        doNumber: params.doNumber,
        bookingId: params.bookingId,
    }
    const response = await call(METHODS.POST, '/assign-booking', payload, options)
    return response.data
}

export const getOutboundStatus = async (params) => {
    const response = await call(METHODS.GET, '/outbound/status', params)
    return response.data
}