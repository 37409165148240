import { Row, Col, Tag } from 'antd'
import { EditIcon, EditIconDisabled, EyeIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = (onCLickEditIcon, onCLickDisplayIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => {
            const isCanEdit = () => {
                if (record.reservationNumber) return false
                if (record.scrapId) return false
                return true
            }
            return (
                <div
                    style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        minWidth: 70,
                    }}
                >
                    <Row gutter={8}>
                        {isCanEdit() ? (
                            <Col
                                className={'cursor-pointer'}
                                onClick={() => onCLickEditIcon(record)}
                            >
                                <EditIcon />
                            </Col>
                        ) : (
                            <Col className={'cursor-not-allowed'}>
                                <EditIconDisabled />
                            </Col>
                        )}
                        <Col
                            className={'cursor-pointer'}
                            onClick={() => onCLickDisplayIcon(record)}
                        >
                            <EyeIcon />
                        </Col>
                    </Row>
                </div>
            )
        },
    },
    {
        title: 'Date',
        dataIndex: 'reservationDate',
        key: 'reservationDate',
        render: (text) => <p className="w-20">{text}</p>,
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (text) => <p className="w-12">{text}</p>,
    },
    {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        render: (text) => <p className="w-16">{text}</p>,
    },
    {
        title: 'Plant',
        dataIndex: 'plant',
        key: 'plant',
    },
    {
        title: 'Sloc',
        dataIndex: 'stgeLoc',
        key: 'stgeLoc',
    },
    {
        title: 'No Document',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Res No',
        dataIndex: 'reservationNumber',
        key: 'reservationNumber',
    },
    {
        title: 'Item',
        dataIndex: 'item',
        key: 'item',
    },
    {
        title: 'Material',
        dataIndex: 'material',
        key: 'material',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text) => <p className="min-w-max">{text}</p>,
    },
    {
        title: 'Qty',
        dataIndex: 'entryQnt',
        key: 'entryQnt',
    },
    {
        title: 'UOM',
        dataIndex: 'entryUom',
        key: 'entryUom',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
            const color = {
                // This color scheme is just example. For the real case ask PM
                '01': 'red',
                '02': 'green',
                '03': 'yellow',
                '04': 'grey',
            }

            // const label = {
            //     // This text scheme is just example. For the real case ask PM
            //     '01': 'Cancel Foreman',
            //     '02': 'Ready to load',
            //     '03': 'yellow',
            //     '04': 'Draft',
            // }

            return <Tag color={color[text] || 'grey'}>{record.statusDescription || '-'}</Tag>
        },
    },
]
