import React, { useCallback, useReducer, useEffect } from 'react'
import { Button, Row, Col, Modal, Input, DatePicker } from 'antd'
import moment from 'moment'

import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
// import SelectGlByPlant from 'components/Common/SelectFetchByPlant/selectGlByPlant'
// import SelectCostCenterByPlant from 'components/Common/SelectFetchByPlant/selectCostCenterByPlant'
// import SelectOrderByPlant from 'components/Common/SelectFetchByPlant/selectOrderByPlant'
import useQueryRefresh from 'customHooks/useQueryRefresh'
import ImageUploader from 'components/Common/Uploader/ImageUploader'
import { createPoStoDraft, getPoStoDraft } from 'api/transaction/plannerFisikWms'

import DataTable from './data-table'

const getBodyRequest = (state) => {
    const bodyReq = {
        source: '1',
        transactionId: state.formData.transactionId,
        plant: state.formData.plant,
        company: state.formData.companyCode || '',
        docnum: state.formData.transactionId || '', // leave it empty
        docType: state.formData.poType, // PO Type
        transactionType: state.formData.transactionType, // 0='In Plant' 1='Out Plant'   ????
        isNeedForwarder: state.formData.isNeedForwarder, // true false  ==> true/false
        building: state.formData.building, // dari PlantSectorSloc
        purchOrg: state.formData.purchasingOrg,
        purGroup: state.formData.purchasingGroup,
        docDate: state.formData.docDate,
        supplPlnt: state.formData.supplyingPlant || state.formData.plant, // dapat dari mana ==> sama dengan 'plant'
        collectNo: '', // kosongkan saja
        poRelInd: '', // kirim string kosong
        images: state.formData.images.map((img, idx) => ({
            ...img,
            imageId: idx + 1,
            data: img.data.split(',')[1],
            name: img.name,
        })),
        referenceId: state.dataTable.map((el) => el.referenceId).join(','),
        items: state.dataTable.map((el, idx) => {
            return {
                referenceId: el.referenceId,
                poItem: +idx + 1, // index + 1
                material: el.materialId,
                description: el.description,
                plant: el.plant,
                slocId: el.slocId,
                quantity: el?.qty ? +el?.qty : 0,
                poUnit: el.uom,
                batch: el.batch,
                pallets: el.pallets,
            }
        }),
        shippings: state.dataTable.map((el, idx) => ({
            poItem: +idx + 1, // index + 1
            shipPoint: state.formData.plant,
            // shipPoint: el.plant,
            route: el.route,
        })),
        schedules: state.dataTable.map((el, idx) => ({
            poItem: +idx + 1, // index + 1
            schedLine: 1, // always 1. Default == 1
            deliveryDate: moment().format('YYYY-MM-DD'), // Default now()
            quantity: +el.qty, // sama dengan quantity diatas
        })),
    }

    return bodyReq
}

const initState = {
    loading: false,
    formData: {
        transactionId: '',
        poType: '',
        plant: '',
        supplyingPlant: '',
        reservationDate: moment().format('YYYY-MM-DD'), //pstngDate
        docDate: moment().format('YYYY-MM-DD'),
        headerTxt: '',
        images: [],
        purchasingOrg: '',
        purchasingGroup: '',
        docType: '',
        transactionType: '',
        isNeedForwarder: false,
        building: '',
    },
    dataTable: [],
    initialDataTable: [],
    confirmationSaveModal: false,
    confirmationDeleteModal: false,
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'SET_FORM_DATA':
            return { ...state, formData: { ...state.formData, ...action.payload } }
        case 'SET_DATA_TABLE':
            return { ...state, dataTable: action.dataTable }
        case 'SET_INITIAL_DATA_TABLE':
            return { ...state, initialDataTable: action.initialDataTable }
        case 'TOGGLE_CONFIRM_SAVE':
            return { ...state, confirmationSaveModal: action.toggle }
        case 'TOGGLE_CONFIRM_DELETE':
            return { ...state, confirmationDeleteModal: action.toggle }
        case 'RESET':
            return initState
        default:
            return state
    }
}

function CreatePoStoDo({ close, payload, isEditMode: isOnEditMode, visible, ...props }) {
    const [state, dispatch] = useReducer(stateReducer, initState)
    const { doRefresh } = useQueryRefresh()

    const setDataTable = useCallback((dataTable) => {
        dispatch({ type: 'SET_DATA_TABLE', dataTable })
    }, [])

    const onClickSave = () => {
        dispatch({ type: 'TOGGLE_CONFIRM_SAVE', toggle: true })
    }

    const handleSave = async () => {
        const reqBody = getBodyRequest(state)
        console.log('reqBody', reqBody)
        dispatch({ type: 'SET_LOADING', loading: true })
        try {
            const res = await createPoStoDraft(reqBody)
            dispatch({ type: 'SET_LOADING', loading: false })
            if (!res.success) return { success: false }
            doRefresh()
            close()
            return res
        } catch (error) {
            console.error(error)
            dispatch({ type: 'SET_LOADING', loading: false })
            return { success: false }
        }
    }

    useEffect(() => {
        if (isOnEditMode) return
        if (!visible) return
        dispatch({ type: 'RESET' })
        dispatch({
            type: 'SET_FORM_DATA',
            payload: { company: payload?.companyId, plant: payload?.plantId },
        })
        dispatch({
            type: 'SET_INITIAL_DATA_TABLE',
            initialDataTable: payload?.items?.map((item) => ({
                ...item,
                key: parseInt(Math.random() * 1000000000),
                materialId: item?.materialId || '-',
                description: item?.description || '-',
                batch: item?.batch || '-',
                qty: item?.qty || '-',
                uom: item?.uom || '-',
                plant: item?.plant || '-',
                slocId: item?.slocId || '-',
            })),
        })
    }, [isOnEditMode, payload?.companyId, payload?.items, payload?.plantId, visible])

    useEffect(() => {
        if (!isOnEditMode) return
        if (!visible) return
        dispatch({ type: 'SET_LOADING', loading: true })
        const fetchData = async () => {
            const res = await getPoStoDraft({ id: payload?.transactionId })
            if (!res.success) {
                return Modal.error({
                    title: 'Oops',
                    content: res?.message || '',
                })
            }

            dispatch({
                type: 'SET_FORM_DATA',
                payload: {
                    doNumber: res?.data?.doNumber,
                    poNumber: res?.data?.poNumber,
                    transactionId: res?.data?.transactionId,
                    transactionType: res?.data?.transactionType,
                    docType: res?.data?.docType,
                    poType: res?.data?.docType,
                    purchasingOrg: res?.data?.purchOrg,
                    purchasingGroup: res?.data?.purGroup,
                    docDate: res?.data?.docDate,
                    plant: res?.data?.plant,
                    supplyingPlant: res?.data?.supplPlnt,
                    building: res?.data?.building,
                    // isNeedForwarder: res?.data?.isNeedForwarder,
                    isNeedForwarder: false,
                    company: res?.data?.company,
                    docnum: res?.data?.docnum,
                },
            })
            dispatch({
                type: 'SET_INITIAL_DATA_TABLE',
                initialDataTable: res?.data?.items?.map((el, idx) => ({
                    key: parseInt(Math.random() * 1000000000),
                    materialId: el.material || '-',
                    description: el.description || '-',
                    qty: el.quantity || '-',
                    uom: el.poUnit || '-',
                    plant: el.plant || '-',
                    plantDescription: el.plantDescription || '-',
                    slocId: el.slocId || '-',
                    slocDescription: el.slocDescription || '-',
                    route: res?.data?.shippings[idx].route || '-',
                    routeDescription: el.routeDescription || '-',
                    pallets: el.pallets || [],
                })),
            })
            dispatch({ type: 'SET_LOADING', loading: false })
        }

        fetchData()
    }, [isOnEditMode, payload?.transactionId, visible])

    return (
        <>
            <SimpleModal
                title={isOnEditMode ? 'Edit POSTO > DO STO' : 'Create POSTO > DO STO'}
                width={1190}
                footer={null}
                visible={visible}
                {...props}
            >
                <Row gutter={16}>
                    <Col span={16}>
                        <Row gutter={[16, 16]} className="mb-5">
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">PO Type</p>
                                <SelectDebounceFetch.PoType
                                    loading={state.loading}
                                    onChange={({ value }) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { poType: value },
                                        })
                                    }}
                                    defaultValue={{ value: state?.formData?.poType }}
                                    className="rounded-10 h-50"
                                    style={{ width: '100%', borderRadius: '5 !important' }}
                                    size="large"
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Supplying Plant</p>
                                <SelectDebounceFetch.Plant
                                    loading={state.loading}
                                    onChange={({ value }) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { supplyingPlant: value },
                                        })
                                    }}
                                    defaultValue={{ value: state?.formData?.supplyingPlant }}
                                    className="rounded-10 h-50"
                                    style={{ width: '100%', borderRadius: '5 !important' }}
                                    size="large"
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Purc. Org.</p>
                                <SelectDebounceFetch.PurchasingOrg
                                    loading={state.loading}
                                    onChange={({ value }) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { purchasingOrg: value },
                                        })
                                    }}
                                    defaultValue={{ value: state?.formData?.purchasingOrg }}
                                    className="rounded-10 h-50"
                                    style={{ width: '100%', borderRadius: '5 !important' }}
                                    size="large"
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Posting Date</p>
                                <DatePicker
                                    size="large"
                                    className="rounded-10 h-50"
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD"
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { reservationDate: e.format('YYYYMMDD') },
                                        })
                                    }}
                                    defaultValue={moment(state?.formData?.reservationDate)}
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Purc. Group.</p>
                                <SelectDebounceFetch.PurchasingGroup
                                    loading={state.loading}
                                    onChange={({ value }) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { purchasingGroup: value },
                                        })
                                    }}
                                    defaultValue={{ value: state?.formData?.purchasingGroup }}
                                    className="rounded-10 h-50"
                                    style={{ width: '100%', borderRadius: '5 !important' }}
                                    size="large"
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Document Date</p>
                                <DatePicker
                                    size="large"
                                    className="rounded-10 h-50"
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD"
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { docDate: e.format('YYYYMMDD') },
                                        })
                                    }}
                                    defaultValue={moment(state?.formData?.docDate)}
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Company Code</p>
                                <SelectDebounceFetch.Company
                                    loading={state.loading}
                                    onChange={({ value }) => {
                                        console.log('value', value)
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { companyCode: value },
                                        })
                                    }}
                                    defaultValue={{ value: state?.formData?.companyCode }}
                                    className="rounded-10 h-50"
                                    style={{ width: '100%', borderRadius: '5 !important' }}
                                    size="large"
                                />
                            </Col>
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">
                                    Document Header Text
                                </p>
                                <Input
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: { headerTxt: e.target.value },
                                        })
                                    }}
                                    size="large"
                                    className="rounded-10 h-50"
                                    style={{ width: '100%' }}
                                    value={payload?.headerTxt}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row gutter={[16, 16]} className="mb-5">
                            <Col span={24}>
                                <p className="text-nabati-grey text-base mb-1">Add Attachment</p>
                                <ImageUploader
                                    multiple
                                    initialValues={state.formData.images}
                                    onChange={(data) => {
                                        dispatch({
                                            type: 'SET_FORM_DATA',
                                            payload: {
                                                images: data.map((img, ind) => ({
                                                    imageId: ind + 1,
                                                    name: img.name,
                                                    data: img.data,
                                                })),
                                            },
                                        })
                                    }}
                                    onDeleteImage={() => {}}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <DataTable
                    // isOnEditMode={!!state.formData.transactionId}
                    setDataTable={setDataTable}
                    initialDataTable={state.initialDataTable}
                    // isOnDisplayMode={isOnDisplayMode}
                    loading={state.loading}
                />
                <Row justify="end" className="mt-5">
                    <Button onClick={close} className="outLinedPink w-157 h-45 rounded-10 mx-2">
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={onClickSave}
                        className="w-157 h-45 rounded-10 mx-2"
                    >
                        Save
                    </Button>
                </Row>
            </SimpleModal>
            <ConfirmationModal
                title="Confirm Submit?"
                content={`Are you sure to create this?`}
                successTitleMsg="Success!"
                successContentMsg={(res) => `Document Number: ${res?.data?.transactionId}`}
                onOk={handleSave}
                centered={false}
                okText="Save"
                visible={state.confirmationSaveModal}
                close={() => dispatch({ type: 'TOGGLE_CONFIRM_SAVE', toggle: false })}
            />
        </>
    )
}

export default CreatePoStoDo
