import React, { useEffect, useState } from 'react'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import { displayBooking } from 'api/transaction/plannerDoExecution'

function LabeledItem({ title, content, className = 'my-4' }) {
    return (
        <div className={className}>
            <p className="font-bold text-sm text-nabati-grey">{title}</p>
            <p>{content}</p>
        </div>
    )
}

const DisplayBooking = ({ close, payload, ...props }) => {
    const [bookingData, setBookingData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!payload?.transactionId) return
        if (!props.visible) return
        const callApi = async () => {
            setLoading(true)
            const { data } = await displayBooking({ id: payload?.transactionId })
            setBookingData(data)
        }
        callApi()
        setLoading(false)
    }, [payload, props.visible])

    return (
        <SimpleModal
            title="Display Booking"
            footer={false}
            closable
            width={810}
            close={() => {
                close()
                setBookingData(null)
            }}
            loading={loading}
            {...props}
        >
            {!loading && (
                <>
                    <div>
                        <LabeledItem title="Transaction ID" content={bookingData?.transactionId || '-'} />
                        
                    </div>
                    <div
                        className="mt-7"
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: 16,
                        }}
                    >
                        <LabeledItem
                            title="Customer Name"
                            content={bookingData?.customerName || '-'}
                            className="my-0"
                        />
                        <LabeledItem
                            title="Company"
                            content={bookingData?.companyId || '-'}
                            className="my-0"
                        />
                         <LabeledItem
                            title="Vehicle No"
                            content={bookingData?.vehicleNo || '-'}
                            className="my-0"
                        />
                        <LabeledItem
                            title="Driver Name"
                            content={bookingData?.driverName || '-'}
                            className="my-0"
                        />
                        <LabeledItem
                            title="Phone Number"
                            content={bookingData?.phoneNumber || '-'}
                            className="my-0"
                        />
                         <LabeledItem
                            title="Create Date"
                            content={bookingData?.createDate || '-'}
                            className="my-0"
                        />
                    </div>
                    <div
                        className="mt-7"
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: 16,
                        }}
                    >
                        <LabeledItem
                            title="Check In"
                            content={bookingData?.checkInDate || '-'}
                            className="my-0"
                        />
                        <LabeledItem
                            title="Check-Out"
                            content={bookingData?.checkOutDate || '-'}
                            className="my-0"
                        />
                        <LabeledItem
                            title="Loading Start"
                            content={bookingData?.loadingStart || '-'}
                            className="my-0"
                        />
                        <LabeledItem
                            title="Loading End"
                            content={bookingData?.loadingEnd || '-'}
                            className="my-0"
                        />
                        
                          <LabeledItem
                            title="SPV User"
                            content={bookingData?.spvUserId || '-'}
                            className="my-0"
                        />
                         <LabeledItem
                            title="Foreman User"
                            content={bookingData?.foremanUserId || '-'}
                            className="my-0"
                        />
                         <LabeledItem
                            title="Loader"
                            content={bookingData?.loaderId || '-'}
                            className="my-0"
                        />
                        <LabeledItem
                            title="Picker"
                            content={bookingData?.userId || '-'}
                            className="my-0"
                        />
                          <LabeledItem
                            title="Docking Id"
                            content={bookingData?.dockingId || '-'}
                            className="my-0"
                        />
                        <LabeledItem
                            title="SKU"
                            content={bookingData?.materialId || '-'}
                            className="my-0"
                        />
                          <LabeledItem
                            title="Qty"
                            content={bookingData?.qty || 0}
                            className="my-0"
                        />
                        <LabeledItem
                            title="Open Qty"
                            content={bookingData?.openQty || 0}
                            className="my-0"
                        />
                       <LabeledItem
                            title="Picked"
                            content={bookingData?.picked || 0}
                            className="my-0"
                        />
                        <LabeledItem
                            title="Un Picked"
                            content={bookingData?.unpick || 0}
                            className="my-0"
                        />
                        <LabeledItem
                            title="Loaded"
                            content={bookingData?.loaded || 0}
                            className="my-0"
                        />
                    </div>{' '}
                </>
            )}
        </SimpleModal>
    )
}

export default DisplayBooking
