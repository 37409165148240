import React from 'react'
import { Table } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'

const columns = [
    {
        title: 'Pallet ID',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Quantity',
        dataIndex: 'qty',
        key: 'qty',
        align: 'center',
    },
    {
        title: 'Available Quantity',
        dataIndex: '',
        key: '',
        align: 'center',
    },
    {
        title: 'Over Qty',
        dataIndex: 'overQty',
        key: 'overQty',
        align: 'center',
        render: (text, record) => {
            const isOverQuantity = +text > 0
            if (isOverQuantity) return <p className="text-red-600">{text}</p>
            return <p>{text}</p>
        },
    },
]

function DisplayPallet({ payload, ...props }) {
    return (
        <SimpleModal title="Display Pallet" width={636} footer={null} closable {...props}>
            {payload?.pallets && (
                <Table
                    dataSource={payload?.pallets}
                    columns={columns}
                    rowClassName={(_, index) =>
                        `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                    }
                    pagination={false}
                />
            )}
        </SimpleModal>
    )
}

export default DisplayPallet
