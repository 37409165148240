import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, InfoYellowIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const reportCheckinOutColumns = (
) => [
    {
        title: 'Id In Out',
        dataIndex: 'idInOut',
        key: 'idInOut',
    },
    
    {
        title: 'Ref Doc No',
        dataIndex: 'refDocNo',
        key: 'refDocNo',
    },
    {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
    },
    {
        title: 'Dc Location',
        dataIndex: 'dcLocation',
        key: 'dcLocation',
    },
    {
        title: 'Vehicle No',
        dataIndex: 'vehicleNo',
        key: 'vehicleNo',
    },
    {
        title: 'Driver Name',
        dataIndex: 'driverName',
        key: 'driverName',
    },
    {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
    },
    {
        title: 'Check In Date',
        dataIndex: 'checkInDate',
        key: 'checkInDate',
    },
    {
        title: 'Check Out Date',
        dataIndex: 'checkOutDate',
        key: 'checkOutDate',
    },
    {
        title: 'Loading Start Date',
        dataIndex: 'loadingStartDate',
        key: 'loadingStartDate',
    },
    {
        title: 'Loading End Date',
        dataIndex: 'loadingEndDate',
        key: 'loadingEndDate',
    },
    {
        title: 'Image Check In',
        dataIndex: 'image',
        key: 'image',
    },
    {
        title: 'Image Check Out',
        dataIndex: 'imageCheckout',
        key: 'imageCheckout',
    },
    {
        title: 'Transaction Status',
        dataIndex: 'transactionStatus',
        key: 'transactionStatus',
    },
    {
        title: 'Supplier Name',
        dataIndex: 'supplierName',
        key: 'supplierName',
    },
    {
        title: 'Outbound',
        dataIndex: 'outbound',
        key: 'outbound',
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
    },
    {
        title: 'Created On',
        dataIndex: 'createdOn',
        key: 'createdOn',
    },
    {
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy',
    },
    {
        title: 'Do Create Date',
        dataIndex: 'doCreateDate',
        key: 'doCreateDate',
    },
    {
        title: 'Transaction Id',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Spv User',
        dataIndex: 'spvUserId',
        key: 'spvUserId',
    },
    {
        title: 'Foreman User',
        dataIndex: 'foremanUserId',
        key: 'foremanUserId',
    },
    {
        title: 'Loader Id',
        dataIndex: 'loaderId',
        key: 'loaderId',
    },
    {
        title: 'Loading Start',
        dataIndex: 'loadingStart',
        key: 'loadingStart',
    },
    {
        title: 'Loading End',
        dataIndex: 'loadingEnd',
        key: 'loadingEnd',
    },
    {
        title: 'Customer Id',
        dataIndex: 'customerId',
        key: 'customerId',
    },
    {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName',
    },
    {
        title: 'Total SKU',
        dataIndex: 'totalSku',
        key: 'totalSku',
    },
    {
        title: 'Picked',
        dataIndex: 'picked',
        key: 'picked',
    },
    {
        title : 'Unpick',
        dataIndex : 'unpick',
        key : 'unpick',
    },
    {
        title : 'Loaded',
        dataIndex : 'loaded',
        key : 'loaded',
    },
    {
        title : 'Transaction Type',
        dataIndex : 'transactionType',
        key : 'transactionType',
    },
    {
        title : 'Loading Duration',
        dataIndex : 'laodingDuration',
        key : 'laodingDuration',
    },
    {
        title : 'Parking Create Date',
        dataIndex : 'parkingCrateDate',
        key : 'parkingCrateDate',
    },
]
