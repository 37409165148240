import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getDcLocationList = async (params) => {
    const response = await call(METHODS.GET, '/master/dclocation', params)
    return response.data
}

export const createNewDcLocation = async (data) => {
    const url = `/master/dclocation/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const findDcLocation = async (data) => {
    const url = `/master/dclocation/show`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updateDcLocation = async (data) => {
    const url = `/master/dclocation/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deleteDcLocation = async (data) => {
    const url = `/master/dclocation/delete`
    const response = await call(METHODS.DELETE, url, data)
    return response.data
}

export const bulkDeleteDcLocation = async () => {
    // const fakeRes = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve("");
    //   }, 500);
    // });
    // const response = await fakeRes;
    // return response;
}
