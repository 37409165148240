import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'

export const getListChangeBin = async (params) => {
    console.log(params)
    const parameter = {
        filter: params.filter,
        material: params.filterbyMaterial,
        companyId: params.filterbyCompany,
        plantId: params.filterbyPlant,
        from: params.from,
        to: params.to,
        total: params.total ?? 50,
        page: params.page ?? 1,
        status: params.filterbyChangeBinStatus ?? null,
        isDraft: params.filterbydraftStatus ?? null,
    }
    console.log(parameter, params)
    const response = await call(METHODS.GET, '/report-outbound/changebin', parameter)
    return response.data
}

export const getListReachTruck = async (params) => {
    console.log(params)
    const parameter = {
        filter: params.filter,
        material: params.filterbyMaterial,
        companyId: params.filterbyCompany,
        plantId: params.filterbyPlant,
        from: params.from,
        to: params.to,
        total: params.total ?? 50,
        page: params.page ?? 1,
        statusScanPallet: params.filterbyScanPalletStatus ?? null,
    }
    console.log(parameter)
    const response = await call(METHODS.GET, '/report-outbound/reachtruck', parameter)
    return response.data
}

export const getListCheckinOut = async (params) => {
    console.log(params)
    const parameter = {
        filter: params.filter,
        from: params.from,
        to: params.to,
        total: params.total ?? 50,
        page: params.page ?? 1,
        status: params.filterbyCheckinStatus ?? null,
        dcLocation: params.filterbyDcLocation ?? null,
        transactionType: params.filterbyTransactionType ?? null,
    }
    console.log(parameter)
    const response = await call(METHODS.GET, '/report-outbound/checkinout', parameter)
    return response.data
}

export const getTransactionStatus = async (params) => {
    const response = await call(METHODS.GET, '/checkinout/transactionstatus', params)
    return response.data
}
