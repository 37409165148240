export const columns = () => [
    {
        title: 'System ID',
        dataIndex: 'systemId',
        key: 'systemId',
        width: 200,
    },
    {
        title: 'Shipment Type',
        dataIndex: 'shipmentType',
        key: 'shipmentType',
        width: 200,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
