import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import RichTable from 'components/Common/RichTable'
import { columns } from './columns'

import { getListStockOpname, deleteWhStocker } from 'api/transaction/result-cycle-count-opname'

import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import CompareWmsVsSapModal from '../../modals/compare-stock-wms-vs-sap'
import StockOpnameSetting from '../../modals/stock-opname-setting'
import { OPNAME_STATUS } from 'config/constant/result-cycle-count-opname'
import useQueryRefresh from 'customHooks/useQueryRefresh'

const initModalState = { visible: false, payload: null }
function ListStockOpname() {
    const [loading, setLoading] = useState(false)
    const [showCompareWmsVsSapModal, setCompareWmsVsSap] = useState(initModalState)
    const [showStockOpnameModal, setStockOpnameModal] = useState(initModalState)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])

    const { doRefresh } = useQueryRefresh()

    const onClickCreate = () => {
        setStockOpnameModal({ visible: true, payload: null })
    }

    const onClickEdit = () => {
        const payload = selectedRows[0]
        const transactionStatus = payload.status
        const isSubmitted = transactionStatus === OPNAME_STATUS.SUBMIT
        if (isSubmitted) {
            return Modal.error({ title: 'Oops', content: 'You can not edit submitted transaction' })
        }

        setStockOpnameModal({ visible: true, payload })
    }

    const onClickDelete = async () => {
        const payload = selectedRows[0]
        const transactionStatus = payload.status
        const isSubmitted = transactionStatus === OPNAME_STATUS.SUBMIT
        if (isSubmitted) {
            return Modal.error({
                title: 'Oops',
                content: 'You can not delete submitted transaction',
            })
        }
        setConfirmDeleteModal(true)
    }

    const handleDelete = async () => {
        const payload = selectedRows[0]
        try {
            setLoading(true)
            const res = await deleteWhStocker({ transactionId: payload.transactionId })
            setLoading(false)
            doRefresh()
            return res
        } catch (error) {
            console.error(error)
        }
    }

    const onClickCompareWmsVsSapModal = () => {
        const payload = selectedRows[0]
        if (payload.statusDescription !== 'Count') {
            return Modal.error({
                title: 'Oops',
                content: 'You can only compare data with opname status Count',
            })
        }

        setCompareWmsVsSap({ visible: true, payload })
    }

    return (
        <div>
            <ButtonsGroupGrid align="left" className="mb-7">
                <Button onClick={onClickCreate} type="primary" className="w-157 h-45 rounded-10">
                    Create
                </Button>
                <Button
                    disabled={selectedRows.length !== 1}
                    onClick={onClickEdit}
                    className="outLinedPink w-157 h-45 rounded-10"
                >
                    Edit
                </Button>
                <Button
                    loading={loading}
                    disabled={selectedRows.length !== 1}
                    onClick={onClickDelete}
                    className="outLinedPink w-157 h-45 rounded-10"
                >
                    Delete
                </Button>
                <Button
                    onClick={onClickCompareWmsVsSapModal}
                    className="flatBlue w-157 h-45 rounded-10"
                    disabled={selectedRows.length !== 1}
                >
                    Compare vs SAP
                </Button>
            </ButtonsGroupGrid>
            <RichTable
                columns={columns()}
                showButtonsArea={false}
                withRowSelection={true}
                withSearchAndFilterArea={false}
                getListApi={getListStockOpname}
                // type={TABLE_TYPE.BASIC}
                onChangeSelectedRows={(rowKeys, rows) => {
                    setSelectedRows(rows)
                }}
            >
                <CompareWmsVsSapModal
                    visible={showCompareWmsVsSapModal.visible}
                    close={() => setCompareWmsVsSap(initModalState)}
                    payload={showCompareWmsVsSapModal.payload}
                />
                <StockOpnameSetting
                    visible={showStockOpnameModal.visible}
                    close={() => setStockOpnameModal(initModalState)}
                    payload={showStockOpnameModal.payload}
                />
                <ConfirmationModal
                    visible={confirmDeleteModal}
                    title="Are you sure you want to Delete?"
                    successTitleMsg="Success!"
                    successContentMsg={(res) => <p>Data Deleted!</p>}
                    close={() => setConfirmDeleteModal(false)}
                    onOk={handleDelete}
                    centered={false}
                    okText="Submit"
                />
            </RichTable>
        </div>
    )
}

export default ListStockOpname
