import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'
import { API_URL } from 'config/env'

export const getAssignMaterialToBinList = async (params) => {
    const response = await call(METHODS.GET, '/master/assignmaterial', params)
    // const response = await call(METHODS.GET, '/master/material', params) // delete me!
    return response.data
}

export const createNewAssignMaterialToBin = async (data) => {
    const url = `/master/assignmaterial/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updateAssignMaterialToBin = async (data) => {
    const url = `/master/assignmaterial/${data.companyId}/update`
    const response = await call(METHODS.PUT, url, data)
    return response.data
}

export const deleteAssignMaterialToBin = async (data) => {
    const url = `/master/assignmaterial/delete`
    const response = await call(METHODS.DELETE, url, data)
    return response.data
}

export const bulkDeleteAssignMaterialToBin = async () => {
    // const fakeRes = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve("");
    //   }, 500);
    // });
    // const response = await fakeRes;
    // return response;
}

export const downloadMaterialToBinTemplate = async (data) => {
    window.open(API_URL + '/master/assignmaterial/download')
}

export const uploadMaterialToBinTemplate = async (formData) => {
    const options = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }
    const response = await call(METHODS.POST, `/master/assignmaterial/import`, formData, options)
    return response.data
}
