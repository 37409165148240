import React, { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'antd'

import { getBase64FromFile, checkPdfOrImageValidity } from 'utils/base64'
import { AddUploadIcon } from 'assets'

import DropFileModal from '../DropFileModal'
import SingleFileBox from './SingleFileBox'
import LoadingFallback from './LoadingFallback'

const FILE_TYPES = {
    pdf: 'application/pdf',
    png: 'image/png',
    PNG: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
}

export async function base64ToFile(dataUrl, fileName) {
    const res = await fetch(dataUrl)
    const blob = await res.blob()
    return new File([blob], fileName, { type: 'image/png' })
}

export async function getUrlFromBase64(dataUrl, fileName) {
    const res = await fetch(dataUrl)
    const blob = await res.blob()
    const file = new File([blob], fileName, { type: 'image/png' })
    return URL.createObjectURL(file)
}

function PdfImageUploader({
    onChange = () => {},
    onDeleteImage = async () => {},
    multiple,
    title = 'Upload',
    initialValues = [],
}) {
    const [visible, setVisible] = useState(false)
    const [base64Files, setBase64Files] = useState([])

    const initialFileFormPayload = useRef(initialValues)

    const onClickDeleteImage = async (imgToDelete) => {
        // callBack
        const idxToDelete = base64Files.findIndex(
            (cur) => cur.name === imgToDelete.name && cur.data === imgToDelete.data
        )
        const imageToDelete = base64Files[idxToDelete]
        try {
            await onDeleteImage(imageToDelete)
            const newList = base64Files.filter((image, idx) => idx !== idxToDelete)
            const updatedFiles = [...newList]
            setBase64Files(updatedFiles)
            onChange(updatedFiles)
        } catch (error) {
            console.error(error)
        }
    }

    const handleFiles = async (files) => {
        let incomingFiles64 = []
        let err = false
        for (let i = 0; i < files.length; i++) {
            const [isValidImage] = checkPdfOrImageValidity(files[i])

            if (!isValidImage) err = true

            const newImage = {
                ...files[i],
                name: files[i].name,
                type: files[i].type,
                data: await getBase64FromFile(files[i]),
            }
            incomingFiles64.push(newImage)
        }

        if (err) return

        const updatedFiles = [...base64Files, ...incomingFiles64]
        setBase64Files(updatedFiles)
        onChange(updatedFiles)
        setVisible(false)
    }

    useEffect(() => {
        const populateImage = async () => {
            const files = initialFileFormPayload.current
            let incomingFile64 = []
            let err = false
            for (let i = 0; i < files.length; i++) {
                const fileExtension = files[i].name.split('.')[1]
                const newImage = {
                    ...files[i],
                    name: files[i].name,
                    type: FILE_TYPES[fileExtension],
                    data: files[i].data,
                }
                incomingFile64.push(newImage)
            }

            if (err) return

            setBase64Files([...incomingFile64])
        }
        populateImage()
    }, [])

    return (
        <>
            <Row justify="start" gutter={[8, 8]}>
                {base64Files.map((img, idx) => (
                    <Col key={idx + img}>
                        <SingleFileBox
                            onClickDeleteImage={onClickDeleteImage}
                            file={img}
                            gridNumber={idx}
                        />
                    </Col>
                ))}
                <Col>
                    <AddUploadIcon className="cursor-pointer" onClick={() => setVisible(true)} />
                </Col>
            </Row>

            <DropFileModal
                visible={visible}
                close={() => setVisible(false)}
                handleFiles={handleFiles}
                title={title}
                multiple={multiple}
            />
        </>
    )
}

const Main = (props) => {
    if (props.loading) return <LoadingFallback />
    return <PdfImageUploader {...props} />
}

export default Main
