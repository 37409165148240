import { Row, Col } from 'antd'
import { DeleteIcon } from 'assets'

export const columns = (isDisplayMode, isEditMode, onClickDeleteIcon = () => {}) => {
    const DELETED_CLASS = 'line-through text-nabati-red'
    const table = [
        {
            title: 'Booking ID',
            dataIndex: 'bookingId',
            key: 'bookingId',
            render: (text, rec) => (
                <p className={`${rec.flag === 'D' ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
        {
            title: 'Shipment No',
            dataIndex: 'shipmentNo',
            key: 'shipmentNo',
            render: (text, rec) => (
                <p className={`${rec.flag === 'D' ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
        {
            title: 'Delivery Number',
            dataIndex: 'doNumber',
            key: 'doNumber',
            render: (text, rec) => (
                <p className={`${rec.flag === 'D' ? DELETED_CLASS : ''}`}>
                    {text ? text : rec.deliveryNumber}
                </p>
            ),
        },
        {
            title: 'Forwarder ID',
            dataIndex: 'forwarderId',
            key: 'forwarderId',
            render: (text, rec) => (
                <p className={`${rec.flag === 'D' ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
        {
            title: 'Forwarder Name',
            dataIndex: 'forwarderName',
            key: 'forwarderName',
            render: (text, rec) => (
                <p className={`${rec.flag === 'D' ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
        {
            title: 'Customer ID',
            dataIndex: 'customerId',
            key: 'customerId',
            render: (text, rec) => (
                <p className={`${rec.flag === 'D' ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (text, rec) => (
                <p className={`${rec.flag === 'D' ? DELETED_CLASS : ''}`}>{text}</p>
            ),
        },
    ]

    const actionColumn = {
        title: 'Actions',
        render: (_, record) => {
            return (
                <div
                    style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        minWidth: 50,
                    }}
                >
                    <Row gutter={8} justify="start" align="middle">
                        {/* <Col
                                span={8}
                                className={'cursor-pointer'}
                                onClick={() => onCLickEditIcon(record)}
                            >
                                <EditIcon />
                            </Col>
                            <Col
                                span={8}
                                className={'cursor-pointer'}
                                onClick={() => onCLickDisplayIcon(record)}
                            >
                                <EyeIcon />
                            </Col> */}
                        <Col
                            span={8}
                            className={'cursor-pointer'}
                            onClick={() => onClickDeleteIcon(record)}
                        >
                            <DeleteIcon />
                        </Col>
                    </Row>
                </div>
            )
        },
    }

    if (isEditMode) return [actionColumn, ...table]
    return table
}
