import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'

import { options } from '../options'

export const getListDeliveryWoCheckin = async (params) => {
    const response = await call(METHODS.GET, '/do-execution-wo', params, options)
    return response.data
}

export const getListBooking = async (params) => {
    const response = await call(METHODS.GET, '/do-execution-list-booking', params, options)
    return response.data
}

export const createDoExecutionWo = async (params) => {
    const response = await call(METHODS.POST, '/create-do-execution-wo', params, options)
    return response.data
}

export const changeDoExecutionWo = async (params, id) => {
    const response = await call(METHODS.PUT, `/do-execution-wo?id=${id}`, params, options)
    return response.data
}

export const deleteDoExecutionWo = async (params) => {
    const response = await call(METHODS.DELETE, '/do-execution-wo', params, options)
    return response.data
}

export const findDoWo = async (params) => {
    const response = await call(METHODS.GET, '/do-execution-wo-find', params, options)
    return response.data
}

export const doPlannerCheckIn = async (params) => {
    const response = await call(METHODS.POST, '/planner-checkin', params, options)
    return response.data
}
