// import { Row, Col } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = () => [
    {
        title: 'Purchasing Organization',
        dataIndex: 'purchaseOrganization',
        key: 'purchaseOrganization',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
