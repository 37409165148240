import { Row, Col } from 'antd'
import { DeleteIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const priorityBinColumns = (onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Building',
        dataIndex: 'building',
        key: 'building',
    },
    {
        title: 'Sloc ID',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Group Priority',
        dataIndex: 'sortNo',
        key: 'sortNo',
    },
    {
        title: 'Priority ID',
        dataIndex: 'priorityId',
        key: 'priorityId',
    },
]
