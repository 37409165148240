import React, { useEffect, useReducer } from 'react'
import { Table, Button, Modal } from 'antd'

import AddItemModal from '../add-item'
import EditItemModal from '../edit-item'
import DisplayPalletModal from '../display-pallet'
import EditPalletModal from '../edit-pallet'
import AssignPalletModal from '../assign-pallet'
import ConfirmationDeleteItem from './confirmation-delete-modal'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'

import { filterArrayByArray } from 'utils/array'
import { columns } from './column'

const initialState = {
    data: [],
    selectedRowKeys: [],
    selectedRows: [],
    displayPalletModal: false,
    editPalletModal: false,
    assignPalletModal: false,
    confirmDeleteModal: false,
    editItemModal: false,
    addItemModal: false,
    rowPayload: null,
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_INITIAL_ITEMS':
            return {
                ...state,
                data: [...action.initialItems],
            }
        case 'RESET_DATA':
            return {
                ...state,
                data: [],
            }
        case 'ADD_ITEM':
            return {
                ...state,
                data: [...state.data, action.item].map((el, idx) => ({
                    ...el,
                    key: parseInt(Math.random() * 1000000000),
                    pallets: el.pallets || [],
                })),
            }
        case 'SET_EDIT_ITEM':
            return {
                ...state,
                data: state.data.map((el, index) => {
                    const itemIndex = state.data.findIndex((p) => p.key === action.item.key)
                    if (index === itemIndex) return action.item
                    return el
                }),
            }
        case 'DELETE_ITEM':
            return {
                ...state,
                data: state.data.filter((el) => el.key !== action.item.key),
            }
        case 'BULK_DELETE_ITEMS':
            return {
                ...state,
                data: filterArrayByArray(state.data, action.items, 'key'),
            }
        case 'TOGGLE_ASSIGN_PALLET':
            return { ...state, assignPalletModal: !state.assignPalletModal }
        case 'TOGGLE_DISPLAY_PALLET':
            return {
                ...state,
                displayPalletModal: !state.displayPalletModal,
                rowPayload: action.rowPayload,
            }
        case 'TOGGLE_EDIT_PALLET':
            return {
                ...state,
                editPalletModal: !state.editPalletModal,
                rowPayload: action.rowPayload,
            }
        case 'TOGGLE_CONFIRM_DELETE':
            return { ...state, confirmDeleteModal: !state.confirmDeleteModal }
        case 'TOGGLE_EDIT_ITEM':
            return { ...state, editItemModal: !state.editItemModal }
        case 'TOGGLE_ADD_ITEM':
            return { ...state, addItemModal: !state.addItemModal }
        case 'SET_SELECTED_ROW_KEYS':
            return {
                ...state,
                selectedRowKeys: [...action.selectedRowKeys],
                selectedRows: [...action.selectedRows],
            }
        default:
            return state
    }
}

const DataTable = ({
    setDataTable,
    initialDataTable = [],
    isOnDisplayMode,
    isInPlantTransactionType,
    formDataPlant,
    formDataSloc,
    loading,
}) => {
    const [state, dispatch] = useReducer(stateReducer, initialState)

    const rowSelection = () => {
        if (isOnDisplayMode) return null
        return {
            onChange: (selectedRowKeys, selectedRows) => {
                dispatch({ type: 'SET_SELECTED_ROW_KEYS', selectedRowKeys, selectedRows })
            },
            selectedRowKeys: [...state.selectedRowKeys],
        }
    }

    const clearRowSelection = () => {
        dispatch({ type: 'SET_SELECTED_ROW_KEYS', selectedRowKeys: [], selectedRows: [] })
    }

    const bulkDeleteItems = (items) => {
        dispatch({ type: 'BULK_DELETE_ITEMS', items })
        clearRowSelection()
    }

    const onClickEditItem = (row) => {
        dispatch({ type: 'TOGGLE_EDIT_PALLET', rowPayload: row })
    }

    const onClickDisplayItem = (row) => {
        dispatch({ type: 'TOGGLE_DISPLAY_PALLET', rowPayload: row })
    }

    const addItem = (item) => {
        const isMaterialAlreadyOnTheTable =
            state.data.findIndex((cur) => cur.material === item.material) > -1

        if (isMaterialAlreadyOnTheTable) {
            Modal.error({
                title: 'Oops',
                content: `Material ${item.material} already exist`,
            })

            return { success: false }
        }

        dispatch({ type: 'ADD_ITEM', item })
        return { success: true }
    }

    const doEditItem = (editedItem) => {
        dispatch({ type: 'SET_EDIT_ITEM', item: editedItem })
        clearRowSelection()
        return { success: true }
    }

    const onEditButtonClick = () => {
        const itemToEdit = state.selectedRows[0]
        const itemHasPalletsAssigned = itemToEdit.pallets.length > 0

        if (itemHasPalletsAssigned)
            return Modal.error({
                title: 'Oops',
                content: `Pallets already assigned to this data, please delete pallets first`,
            })
        dispatch({ type: 'TOGGLE_EDIT_ITEM' })
    }

    const ButtonGroupBottom = (
        <ButtonsGroupGrid align="left" className="mb-3">
            <Button
                onClick={() => dispatch({ type: 'TOGGLE_ASSIGN_PALLET' })}
                type="primary"
                className="w-157 h-45 rounded-10 mx-2"
                disabled={state?.selectedRows?.length !== 1}
            >
                Assign Pallet
            </Button>
            <Button
                onClick={() => {}}
                className="outLinedPink w-157 h-45 rounded-10 mx-2"
                disabled={state?.selectedRows?.length === 0}
            >
                Mass Assign Pallet
            </Button>
            <Button
                onClick={() => dispatch({ type: 'TOGGLE_ADD_ITEM' })}
                className="flatBlue w-157 h-45 rounded-10 mx-2"
            >
                Add Item
            </Button>
            <Button
                onClick={onEditButtonClick}
                className="outLinedBlue w-157 h-45 rounded-10 mx-2"
                disabled={state?.selectedRows?.length !== 1}
            >
                Edit Item
            </Button>
            <Button
                onClick={() => dispatch({ type: 'TOGGLE_CONFIRM_DELETE' })}
                className="outLinedPink w-157 h-45 rounded-10 mx-2"
                disabled={state?.selectedRows?.length === 0}
            >
                Delete Item
            </Button>
        </ButtonsGroupGrid>
    )

    useEffect(() => {
        setDataTable(state.data)
    }, [setDataTable, state.data])

    useEffect(() => {
        if (initialDataTable.length === 0) return dispatch({ type: 'RESET_DATA' })
        dispatch({ type: 'ADD_INITIAL_ITEMS', initialItems: [...initialDataTable] })
    }, [initialDataTable])

    return (
        <>
            {!isOnDisplayMode && ButtonGroupBottom}
            <Table
                loading={loading}
                rowKey="key"
                dataSource={state.data}
                columns={columns(onClickEditItem, onClickDisplayItem, isOnDisplayMode)}
                rowClassName={(_, index) =>
                    `customizedPaddingTd customizedSmallFont ${
                        index % 2 === 0 ? 'bg-nabati-lightGray' : ''
                    }`
                }
                pagination={false}
                rowSelection={rowSelection()}
            />
            <DisplayPalletModal
                visible={state.displayPalletModal}
                payload={state.rowPayload}
                close={() => dispatch({ type: 'TOGGLE_DISPLAY_PALLET', rowPayload: {} })}
            />
            <EditPalletModal
                assignPalletToItem={doEditItem}
                visible={state.editPalletModal}
                payload={state.rowPayload}
                formDataPlant={formDataPlant}
                formDataSloc={formDataSloc}
                close={() => dispatch({ type: 'TOGGLE_EDIT_PALLET', rowPayload: {} })}
            />
            <AssignPalletModal
                assignPalletToItem={doEditItem}
                payload={state.selectedRows[0]}
                visible={state.assignPalletModal}
                formDataPlant={formDataPlant}
                formDataSloc={formDataSloc}
                close={() => dispatch({ type: 'TOGGLE_ASSIGN_PALLET' })}
            />
            <AddItemModal
                addItem={addItem}
                visible={state.addItemModal}
                isInPlantTransactionType={isInPlantTransactionType}
                close={() => dispatch({ type: 'TOGGLE_ADD_ITEM' })}
            />
            <EditItemModal
                assignEditedItem={doEditItem}
                visible={state.editItemModal}
                close={() => dispatch({ type: 'TOGGLE_EDIT_ITEM' })}
                payload={state.selectedRows[0]}
            />
            <ConfirmationDeleteItem
                bulkDeleteItems={bulkDeleteItems}
                payload={state.selectedRows}
                close={() => dispatch({ type: 'TOGGLE_CONFIRM_DELETE' })}
                visible={state.confirmDeleteModal}
            />
        </>
    )
}

export default DataTable
