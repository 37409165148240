import React, { useEffect, useReducer, useState,  } from 'react'
import { Table, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import useQueryRefresh from 'customHooks/useQueryRefresh'
import SimpleModal from 'components/Common/Modals/SimpleModal'
// import SearchQueryInput from 'components/Common/SearchQueryInput'
import notify from 'utils/notify'
import { getOutstandingItemDo , updateQtyDoExecution } from 'api/transaction/plannerDoExecution'
// import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

import { filterArrayByArray } from 'utils/array'

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ROWS':
            return {
                ...state,
                selectedRowKeys: action.selectedRowKeys,
                selectedRows: action.selectedRows,
            }
        case 'SET_DATA':
            return {
                ...state,
                data: action.data,
                loading: false,
                selectedRowKeys: [],
                selectedRows: [],
            }
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
            case 'SET_IS_SUCCESS_UPDATED':
                return { ...state, isSuccessUpdated: action.isSuccessUpdated, loading: action.loading }
        case 'RESET':
            return {
                ...state,
                data: [],
                selectedRowKeys: [],
                selectedRows: [],
            }
        default:
            return state
    }
}

const initialState = {
    selectedRowKeys: [],
    selectedRows: [],
    data: [],
    loading: false,
}

const ChangeQtyOutstanding = ({ payload, close, insertData, currentData, ...props }) => {
    const selectedData = currentData.data.find(o => o.id === currentData.selectedRowKeys[0]);
    const [state, dispatch] = useReducer(reducer, initialState)
    const [openQty, setOpenQty] = useState(selectedData?.openQty)
    const { doRefresh } = useQueryRefresh()

    const handleChangeQty = async () => {
        dispatch({ type: 'SET_LOADING', loading: true })
        const reqBody = {
            reqQty: parseFloat(openQty),
            transactionId: payload?.transactionId,
            doNumber: selectedData?.doNumber,
            doItem: parseInt(selectedData?.doItem),
        }
        try {
            const res = await updateQtyDoExecution(reqBody)
            if (res.success) {
                setOpenQty(openQty)
                dispatch({ type: 'RESET' })
                notify.success('Successfully Updated',"SUCCESS",3)
                doRefresh()

            }else{
                notify.success('Transaction id / data not found',"FAILED",3)
                close()
                doRefresh()
            }
        } catch (error) {
            dispatch({ type: 'SET_LOADING', loading: false })
            close()
            doRefresh()
        }
        close()
        doRefresh()
    }

    const updateQty = (val) => {
        setOpenQty(val.target.value)
    }

    useEffect(() => {
        if (!props.visible) return

        dispatch({ type: 'SET_LOADING', loading: true })
        // const fetchData = async () => {
        //     const res = await getOutstandingItemDo()
        //     const data = filterArrayByArray(res?.data || [], currentData || [], 'id')
        //     dispatch({ type: 'SET_DATA', data: data.map((el) => ({ ...el, hidden: false })) })
        // }
        // fetchData()
        setOpenQty(selectedData?.openQty)
        return dispatch({ type: 'SET_LOADING', loading: false })
    }, [props?.payload, props.visible, currentData])
    return (
        <SimpleModal
            loading={state.loading}
            title= {"Qty Outstanding : "+selectedData?.openQty || openQty}
            okText="Update"
            cancelText="Close"
            onOk={handleChangeQty}
            close={() => {
                close()
                dispatch({ type: 'RESET' })
            }}
            successTitleMsg="Success Updated"
            width={300}
            zIndex={2000}
            {...props}
        >
            Update To
             <Input
                className="rounded-10 mt-2 mb-3"
                onChange={updateQty}
                size="large"
                type="number"
                placeholder={openQty}
                defaultValue={openQty}
            />
        </SimpleModal>
    )
}

export default ChangeQtyOutstanding
