import { useState, useEffect } from 'react'
import moment from 'moment'

import { camelCaseToTitleCase } from 'utils/formatter'
import { PRIMARY_BLUE, YELLOW_1, GREEN_1, DARK_BLUE_2, PRIMARY_PINK } from 'config/colors'

// TO DO: make generate colors function by Array Color
const COLOR_PATTERN = [PRIMARY_BLUE, YELLOW_1, PRIMARY_PINK, GREEN_1, DARK_BLUE_2, PRIMARY_PINK]

function useDashboardChart({ dcLocation, dateRange, callApi }) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    // Data and Labels
    const datasets = Object.entries(data || {}).map(([key, val], ind) => ({
        label: camelCaseToTitleCase(key),
        data: val
            .map((value) => ({ x: value.date, y: parseInt(value.value) }))
            .sort((a, b) => {
                if (moment(a.x).isBefore(b.x)) return -1
                if (moment(a.x).isAfter(b.x)) return 1
                return 0
            }),
        borderColor: COLOR_PATTERN[ind],
        backgroundColor: COLOR_PATTERN[ind],
    }))

    useEffect(() => {
        if (!dcLocation) return
        const { startDate, endDate } = dateRange
        const reqBody = {
            startDate,
            endDate,
            dcLocationId: [dcLocation?.value],
        }
        const fetchData = async () => {
            setLoading(true)
            try {
                const res = await callApi(reqBody)
                setData(res?.data || {})
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }

        fetchData()
    }, [callApi, dateRange, dcLocation])

    return { loading, datasets }
}

export default useDashboardChart
