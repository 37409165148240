import React, { useContext, useState, useEffect, useMemo } from 'react'
import BarChart from 'components/Common/Chart/BarChart'
import moment from 'moment'
import { getProductivitySpvAll } from 'api/dashboard/outbound'

// Context
import { CardDatePickerContext } from 'components/Common/CardDatePicker'
import { DashboardOutboundContext } from '../../index'

import { options } from './options'
import ConfirmWithPasswordModal from 'components/Common/Modals/ConfirmWithPasswordModal'

function ChartProductivityOutbound() {
    const [loading, setLoading] = useState(false)
    const [dataLabel, setDataLabel] = useState([])
    const [dataValue, setDataValue] = useState([])
    // const [checkerDataValue, setCheckerDataValue] = useState([])

    const { dateRange } = useContext(CardDatePickerContext)
    const { dcLocation } = useContext(DashboardOutboundContext)
    const datasets = useMemo(() => Object.values(dataValue || {}), [dataValue])
    const labels = useMemo(() => Object.values(dataLabel || {}), [dataLabel])

    useEffect(() => {
        if (!dcLocation) return
        const { startDate, endDate } = dateRange
        const reqBody = {
            from: moment(startDate).format('DD-MM-YYYY'),
            to: moment(endDate).format('DD-MM-YYYY'),
            dcLocationId: [dcLocation?.value],
        }
        const fetchData = async () => {
            setLoading(true)
            try {
                const { data } = await getProductivitySpvAll(reqBody)
                setDataLabel(data.label)
                setDataValue(data.value)
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }

        fetchData()
    }, [dateRange, dcLocation])

    return (
        <BarChart loading={loading} options={options()} datasets={datasets} labels={[...labels]} />
    )
}

export default ChartProductivityOutbound
