import React, { useEffect, useState } from 'react'
import { getGenerateDataPerPage } from 'utils/misc'

const QR_PER_PAGE = 12
function QrCodePrint({ data }) {
    const [dataPerPage, setDataPerPage] = useState([])

    useEffect(() => {
        const newData = getGenerateDataPerPage([...data], QR_PER_PAGE) // 20 QR List per page
        setDataPerPage(newData)
    }, [data])

    return (
        <div style={{ fontFamily: 'sans-serif', color: 'black' }}>
            {dataPerPage.map((QrList) => (
                <div style={{ pageBreakAfter: 'always' }}>
                    <p
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            textAlign: 'center',
                            margin: 30,
                        }}
                    >
                        GATE - QR CODE
                    </p>
                    <div
                        style={{
                            boxSizing: 'border-box',
                            display: 'grid',
                            gridTemplateColumns: `repeat(auto-fit, 52mm)`,
                            placeContent: 'center',
                        }}
                    >
                        {QrList.map((dt) => (
                            <div
                                className="cont"
                                key={dt.gateId}
                                style={{
                                    boxSizing: 'border-box',
                                    width: '50mm',
                                    height: '50mm',
                                    border: '2px solid black',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    margin: '1mm 0',
                                    pageBreakBefore: 'auto',
                                    background: '#fff',
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: 'center',
                                        left: '50%',
                                        position: 'absolute',
                                        transform: 'translateX(-50%)',
                                        top: 7,
                                        zIndex: 100,
                                        fontSize: 10,
                                    }}
                                >
                                    <div style={{ fontWeight: 700 }}>GATE</div>
                                </div>
                                <img
                                    alt={dt.gateId}
                                    src={`data:image/svg+xml;base64,${dt.qr}`}
                                    style={{
                                        boxSizing: 'border-box',
                                        maxWidth: '70%',
                                        height: 'auto',
                                        position: 'absolute',
                                        borderStyle: 'none',
                                        background: '#fff',
                                        top: 26,
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        bottom: 7,
                                        fontSize: 12,
                                        // background: 'red',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    Gate {dt.gateId} | {dt.fromPlant}-{dt.toPlant}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default QrCodePrint
