import React, { useReducer, useEffect, useContext } from 'react'
import { Table, Input, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'
import { RichTableContext } from 'components/Common/RichTable'

import {
    getAssignPallets,
    deleteAssignPallets,
    generatePallet,
    putAssignPallets,
} from 'api/transaction/plannerDoExecution'

import notify from 'utils/notify'
import { columns } from './columns'

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ROWS':
            return { ...state, selectedRowKeys: action.selectedRowKeys }
        case 'SET_PALLETS':
            return {
                ...state,
                pallets: action.pallets,
                openQty:
                    state.isNewGeneratedData && action.pallets.length === 0
                        ? 0
                        : action.pallets.reduce(
                              (prev, cur) => prev - Number(cur.qty),
                              state.requestQty
                          ),
            }
        case 'SET_NEW_GENERATED_PALLETS':
            return {
                ...state,
                newGeneratedPalletsOnly: action.newGeneratedPalletsOnly,
            }
        case 'SET_LOADING_TRUE':
            return { ...state, loading: true }
        case 'SET_LOADING_FALSE':
            return { ...state, loading: false }
        case 'ALLOW_SAVE_BUTTON':
            return {
                ...state,
                isAllowedToSave: state.pallets.length === 0 ? false : true,
            }
        case 'DISABLE_ROW_SELECTION':
            return { ...state, isNewGeneratedData: true }
        case 'CALCULATE_QTY':
            return {
                ...state,
                openQty: action?.openQty,
                requestQty: action?.requestQty,
            }
        case 'TOGGLE_CONFIRMATION_MODAL':
            return { ...state, showConfirmationModal: !state.showConfirmationModal }
        case 'RESET':
            return {
                ...state,
                pallets: [],
                selectedRowKeys: [],
                isAllowedToSave: false,
                isNewGeneratedData: false,
            }
        default:
            return state
    }
}

const initialState = {
    selectedRowKeys: [],
    pallets: [],
    newGeneratedPalletsOnly: [],
    loading: false,
    showConfirmationModal: false,
    isAllowedToSave: false,
    openQty: 0,
    requestQty: 0,
    isNewGeneratedData: true,
}

const PlannerChange = ({ payload, close, ...props }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { doRefresh } = useQueryRefresh()

    const { resetRowSelections } = useContext(RichTableContext)

    const rowSelection = ({ active }) => {
        if (!active) return false
        return {
            onChange: (selectedRowKeys, selectedRows) => {
                dispatch({ type: 'SET_SELECTED_ROWS', selectedRowKeys })
            },
            selectedRowKeys: state.selectedRowKeys,
        }
    }

    const handleDeleteAssignedPallet = async () => {
        const params = {
            transactionId: payload.transactionId,
            pallets: state.pallets
                .filter((p) => state.selectedRowKeys.includes(p.palletId))
                .map((p) => ({
                    doNumber: payload?.documentNumber,
                    doItem: +payload?.documentItem,
                    palletId: p.palletId,
                    palletCode: p.palletCode,
                    companyId: payload?.company,
                    plantId: payload?.plantStock,
                    materialId: payload?.material,
                    description: payload?.description,
                    batch: p.batch,
                    refDocNo: payload?.bookingNumber,
                    qty: +p.qty,
                    overQty: +p.overQty,
                    uom: payload?.uom,
                })),
        }

        await deleteAssignPallets(params)
        doRefresh()
    }

    const handleGeneratePallets = async () => {
        const params = {
            reqQty: state?.openQty,
            plant: payload.plantStock,
            material: payload.material,
        }

        dispatch({ type: 'SET_LOADING_TRUE' })
        const { data } = await generatePallet(params)
        console.log('data', data)
        dispatch({ type: 'SET_PALLETS', pallets: [...state.pallets, ...(data || [])] })
        dispatch({ type: 'SET_NEW_GENERATED_PALLETS', newGeneratedPalletsOnly: [...(data || [])] })
        dispatch({ type: 'ALLOW_SAVE_BUTTON' })
        dispatch({ type: 'DISABLE_ROW_SELECTION' })
        dispatch({ type: 'SET_LOADING_FALSE' })
    }

    const handleSave = async () => {
        dispatch({ type: 'SET_LOADING_TRUE' })

        const params = {
            transactionId: payload.transactionId,
            pallets: state.newGeneratedPalletsOnly.map((p) => ({
                doNumber: payload?.documentNumber,
                doItem: +payload?.documentItem,
                palletId: p.palletId,
                palletCode: p.palletCode,
                companyId: payload?.company,
                plantId: payload.plantStock,
                slocId: p.slocId,
                sectorId: p.sectorId,
                gateId: p.gateId,
                binId: p.binId,
                staggingIn: p.staggingIn,
                dockingId: p.dockingId,
                materialId: p?.material,
                description: p?.description,
                batch: p.batch,
                refDocNo: payload?.bookingNumber,
                qty: +p.qty,
                overQty: +p.overQty,
                uom: p?.uom,
                manufactureDate: p.manufactureDate,
                sled: p.sled,
            })),
        }

        try {
            const { success, message } = await putAssignPallets(params)
            dispatch({ type: 'SET_LOADING_FALSE' })
            if (!success) return
            notify.success(message)
            resetRowSelections()
            doRefresh()
            close()
        } catch (error) {
            dispatch({ type: 'SET_LOADING_FALSE' })
            console.error(error)
        }
    }

    useEffect(() => {
        if (!payload?.id) return
        if (!props?.visible) return
        if (state.showConfirmationModal) return

        const fetchPallets = async () => {
            dispatch({ type: 'SET_LOADING_TRUE' })
            const params = {
                id: payload?.transactionId,
                doItem: payload?.documentItem,
                doNumber: payload?.documentNumber,
            }
            const { data } = await getAssignPallets(params)
            dispatch({ type: 'SET_PALLETS', pallets: data || [] })
            dispatch({ type: 'SET_LOADING_FALSE' })
        }
        dispatch({ type: 'RESET' })

        fetchPallets()
    }, [payload, props.visible, state.showConfirmationModal])

    useEffect(() => {
        dispatch({
            type: 'CALCULATE_QTY',
            openQty: payload?.openQty,
            requestQty: payload?.qty,
        })
    }, [payload])

    return (
        <>
            <SimpleModal title="Change" footer={null} width={636} {...props}>
                <p className="mb-2 text-base text-nabati-grey">Request Quantity</p>
                <Input
                    className="rounded-10 h-50 w-1/2"
                    placeholder="Request Quantity"
                    suffix={<p className="text-xs">DUS</p>}
                    size="large"
                    value={state?.requestQty}
                />
                <p className="mt-2 mb-2 text-base text-nabati-grey">Open Quantity</p>
                <div className="flex mb-4">
                    <Input
                        className="rounded-10 h-50 w-1/2"
                        placeholder="Request Quantity"
                        value={state?.openQty}
                        suffix={<p className="text-xs">{payload?.uom}</p>}
                        size="large"
                    />
                    <Button
                        loading={state.loading}
                        onClick={handleGeneratePallets}
                        className="flatBlue ml-3 h-50 w-160 rounded-10"
                    >
                        Generate
                    </Button>
                </div>
                <div className="flex items-center h-8">
                    {<p className="mr-5 font-bold">Selected: {state.selectedRowKeys.length}</p>}
                    {state.selectedRowKeys.length > 0 && (
                        <Button
                            onClick={() => {
                                dispatch({ type: 'TOGGLE_CONFIRMATION_MODAL' })
                            }}
                            icon={<DeleteOutlined />}
                            className="flex items-center justify-center rounded-10 text-nabati-red border-nabati-red ml-2"
                        >
                            Delete
                        </Button>
                    )}
                </div>
                <Table
                    loading={state.loading}
                    rowKey="palletId"
                    dataSource={state?.pallets}
                    columns={columns}
                    rowClassName={(_, index) =>
                        `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                    }
                    pagination={false}
                    rowSelection={rowSelection({ active: !state.isNewGeneratedData })}
                />
                <div className="flex justify-end items-center">
                    <Button
                        className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                        style={{ width: 197, height: 45 }}
                        onClick={() => {
                            resetRowSelections()
                            close()
                        }}
                        type="ghost"
                    >
                        Close
                    </Button>
                    <Button
                        className="rounded-10 mx-1 mt-5 "
                        style={{ width: 197, height: 45 }}
                        onClick={handleSave}
                        type="primary"
                        disabled={!state.isAllowedToSave}
                        loading={state.loading}
                    >
                        Save
                    </Button>
                </div>
            </SimpleModal>
            <ConfirmationModal
                loading={state.loading}
                visible={state.showConfirmationModal}
                close={() => dispatch({ type: 'TOGGLE_CONFIRMATION_MODAL' })}
                title="Confirm Remove Pallets?"
                content="Are you sure want to Pallets?"
                successTitleMsg="Success"
                successContentMsg="Pallets Removed"
                errorContentMsg=""
                onOk={handleDeleteAssignedPallet}
            />
        </>
    )
}

export default PlannerChange
