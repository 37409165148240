import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, InfoYellowIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const reportCheckPalletColumns = (
) => [
    {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Po Number',
        dataIndex: 'poNumber',
        key: 'poNumber',
    },
    {
        title: 'Po Item',
        dataIndex: 'poItem',
        key: 'poItem',
    },
    {
        title: 'Do Number',
        dataIndex: 'doNumber',
        key: 'doNumber',
    },
    {
        title: 'Do Item',
        dataIndex: 'doItem',
        key: 'doItem',
    },
    {
        title: 'Shipment Number',
        dataIndex: 'shipmentNumber',
        key: 'shipmentNumber',
    },
    {
        title: 'Posting Date',
        dataIndex: 'postingDate',
        key: 'postingDate',
    },
    {
        title: 'Document Date',
        dataIndex: 'documentDate',
        key: 'documentDate',
    },
    {
        title: 'Delivery Note',
        dataIndex: 'deliveryNote',
        key: 'deliveryNote',
    },
    {
        title: 'Doc Header',
        dataIndex: 'docHeaderText',
        key: 'docHeaderText',
    },
    {
        title: 'User Id',
        dataIndex: 'userId',
        key: 'userId',
    },
     {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
    },
    
    // {
    //     title: 'Supplying Plant',
    //     dataIndex: 'supplyingPlant',
    //     key: 'supplyingPlant',
    // },
   
    // {
    //     title: 'Description',
    //     dataIndex: 'shortText',
    //     key: 'shortText',
    // },
    // {
    //     title: 'Order Quantity',
    //     dataIndex: 'orderQuantity',
    //     key: 'orderQuantity',
    // },
    // {
    //     title: 'Order Unit',
    //     dataIndex: 'orderUnit',
    //     key: 'orderUnit',
    // },
    // {
    //     title: 'Outstanding Quantity',
    //     dataIndex: 'outstandingQuantity',
    //     key: 'outstandingQuantity',
    // },
    {
        title: 'Item Transaction',
        dataIndex: 'itemTransactionId',
        key: 'itemTransactionId',
    },
    {
        title: 'Item Po Number',
        dataIndex: 'itemPoNumber',
        key: 'itemPoNumber',
    },
    {
        title: 'Item Po Item',
        dataIndex: 'itemPoItem',
        key: 'itemPoItem',
    },
    {
        title: 'Gate',
        dataIndex: 'gate',
        key: 'gate',
    },
    {
        title: 'From Plant',
        dataIndex: 'fromPlant',
        key: 'fromPlant',
    },
    {
        title: 'To Plant',
        dataIndex: 'toPlant',
        key: 'toPlant',
    },
    {
        title: 'Pallet id',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Pallet Code',
        dataIndex: 'palletCode',
        key: 'palletCode',
    },
    {
        title: 'Pallet Status',
        dataIndex: 'palletStatus',
        key: 'palletStatus',
    },
    {
        title: 'Material',
        dataIndex: 'material',
        key: 'material',
    },
    {
        title: 'Transaction Status',
        dataIndex: 'transactionStatus',
        key: 'transactionStatus',
    },
    {
        title: 'Accepted',
        dataIndex: 'accepted',
        key: 'accepted',
    },
    {
        title: 'Reason Qty',
        dataIndex: 'reasonQty',
        key: 'reasonQty',
    },
    {
        title: 'Reason Qlty',
        dataIndex: 'reasonQlty',
        key: 'reasonQlty',
    },
    {
        title: 'Item Created At',
        dataIndex: 'ItemCreatedAt',
        key: 'itemCreatedAt',
    },
    {
        title: 'Item Updated At',
        dataIndex: 'itemUpdatedAt',
        key: 'itemUpdatedAt',
    },
]
