import React, { useState } from 'react'
import { Button, Row } from 'antd'

import RichTable from 'components/Common/RichTable'
import { allowTransferDcColumns } from './columns'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddEditModal from './modals/add-edit-form'
// import UpdateAllowTransferDcModal from './modals/updateallowtransferdc'

import { createNewAllowTransferDc, getAllowTransferDcList, updateAllowTransferDc } from 'api/utility/allow-transfer-dc'

function AllowTransferDc() {
    const [showUpdateAllowTransferDcModal, setUpdateAllowTransferDcModal] = useState(false)
    const [showAddAllowTransferDcModal, setAddAllowTransferDcModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateAllowTransferDcModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddAllowTransferDcModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                // rowKey="allow-transfer-dcId"
                columns={allowTransferDcColumns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                getListApi={getAllowTransferDcList}
            />
            <AddEditModal  callApi={createNewAllowTransferDc} close={() => setAddAllowTransferDcModal(false)} visible={showAddAllowTransferDcModal} />
            <AddEditModal
                close={() => setUpdateAllowTransferDcModal(false)}
                visible={showUpdateAllowTransferDcModal}
                payload={modalFormPayload}
                callApi={updateAllowTransferDc} 
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default AllowTransferDc
