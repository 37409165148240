import React from 'react'
import CardDatePicker from 'components/Common/CardDatePicker'
import { Row, Col } from 'antd'
import ChartProductivityInboundGroup from './charts/chart-productivity-inbound-group'
import ChartCheckerInboundGroup from './charts/chart-checker-inbound-group'

function SectionThree() {
    return (
        <CardDatePicker className="my-12">
            <Row gutter={24}>
                <Col span={24}>
                    <ChartCheckerInboundGroup />
                </Col>
                <Col span={24}>
                    <ChartProductivityInboundGroup />
                </Col>
            </Row>
        </CardDatePicker>
    )
}

export default SectionThree
