import React, { useReducer, useEffect } from 'react'
import { Table, Input, Button, Modal } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import SimpleModal from 'components/Common/Modals/SimpleModal'

import { generatePallet } from 'api/transaction/plannerDoExecution'

const columns = [
    {
        title: 'Pallet ID',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Quantity',
        dataIndex: 'qty',
        key: 'qty',
        align: 'center',
    },
    {
        title: 'Available Quantity',
        dataIndex: 'availableQty',
        key: 'availableQty',
        align: 'center',
    },
    {
        title: 'Over Qty',
        dataIndex: 'overQty',
        key: 'overQty',
        align: 'center',
        render: (text, record) => {
            const isOverQuantity = +text > 0
            if (isOverQuantity) return <p className="text-red-600">{text}</p>
            return <p>{text}</p>
        },
    },
]

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PARAMS':
            return { ...state, params: action.params }
        case 'SET_DATA':
            return { ...state, pallets: [...action.pallets], loading: false }
        case 'SET_OPEN_QTY':
            return { ...state, openQty: action.openQty }
        case 'SET_ERROR':
            return { ...state, error: action.error, loading: false }
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'RESET_DATA':
            return { ...state, pallets: [], error: '' }
        default:
            return state
    }
}

const initialState = {
    pallets: [],
    loading: false,
    error: '',
    openQty: 0,
    params: {
        reqQty: 0,
        plant: '',
        material: '',
    },
}

const AssignPallet = ({
    isOnEditMode,
    close,
    payload,
    assignPalletToItem = () => {},
    formDataPlant,
    formDataSloc,
    ...props
}) => {
    const [state, dispatch] = useReducer(stateReducer, initialState)

    const handleGeneratePallet = async () => {
        dispatch({ type: 'SET_LOADING', loading: true })
        const { data } = await generatePallet({
            reqQty: +state.params.reqQty,
            plant: formDataPlant || state.params.plant,
            material: state.params.material,
            sloc: formDataSloc || state.params.slocId,
        })
        if (!data) return dispatch({ type: 'SET_ERROR', error: 'Pallets Not Available' })

        dispatch({ type: 'SET_DATA', pallets: data })
        const currentOpenQty = data.reduce((acc, cur) => acc - cur.qty, +state.params.reqQty)
        dispatch({ type: 'SET_OPEN_QTY', openQty: currentOpenQty })
    }

    const handleAssignPallet = async () => {
        if (state.pallets.length === 0) return Modal.error({ title: 'No Pallet Available' })

        const itemWithPallets = {
            ...payload,
            pallets: state.pallets.map((p) => ({
                palletId: p.palletId,
                palletCode: p.palletCode,
                qty: p.qty,
                overQty: p.overQty,
                batch: p.batch,
            })),
        }

        assignPalletToItem(itemWithPallets)

        Modal.success({ title: 'Success Assign Pallet' })

        close()
        dispatch({ type: 'RESET_DATA' })
        // doRefresh()
        return
    }

    useEffect(() => {
        if (!props.visible) return
        dispatch({
            type: 'SET_PARAMS',
            params: {
                reqQty: +payload?.qty,
                material: payload?.material,
                plant: payload?.plant,
                slocId: payload?.slocId,
            },
        })
        dispatch({ type: 'SET_OPEN_QTY', openQty: Number(payload?.qty) || 0 })
    }, [payload?.material, payload?.plant, payload?.qty, payload?.slocId, props.visible])

    return (
        <SimpleModal
            title="Assign Pallet"
            width={636}
            destroyOnClose={true}
            footer={null}
            {...props}
        >
            {state.error && (
                <div className="flex items-center text-lg text-white bg-nabati-red w-full text-center rounded-10 p-5 my-3">
                    <InfoCircleOutlined className="text-3xl justify-self-start mr-4" />
                    {state.error}
                </div>
            )}
            <p className="mb-2 text-base text-nabati-grey">Request Quantity</p>
            <Input
                value={+state.params.reqQty || 0}
                className="rounded-10 h-50 w-1/2"
                placeholder="Request Quantity"
                suffix={<p className="text-xs">DUS</p>}
                size="large"
            />
            <p className="mt-2 mb-2 text-base text-nabati-grey">Open Quantity</p>
            <div className="flex mb-4">
                <Input
                    value={state.openQty}
                    className="rounded-10 h-50 w-1/2"
                    placeholder="Request Quantity"
                    suffix={<p className="text-xs">DUS</p>}
                    size="large"
                />
                <Button
                    className="flatBlue ml-3 h-50 w-160 rounded-10"
                    onClick={handleGeneratePallet}
                    loading={state.loading}
                    // disabled={isOnEditMode}
                >
                    Generate
                </Button>
            </div>
            {state?.pallets?.length > 0 && (
                <Table
                    dataSource={state.pallets}
                    columns={columns}
                    rowClassName={(_, index) =>
                        `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                    }
                    pagination={false}
                />
            )}

            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={() => {
                        close()
                        dispatch({ type: 'RESET_DATA' })
                    }}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleAssignPallet}
                    disabled={state.pallets.length <= 0}
                    type="primary"
                    loading={state.loading}
                >
                    Copy
                </Button>
            </div>
        </SimpleModal>
    )
}

export default AssignPallet
