import React, { useState, createContext } from 'react'
import Card from '../Card'
import moment from 'moment'
import { DatePicker, Select } from 'antd'

const { RangePicker } = DatePicker
const { Option } = Select

const TODAY = 'Today'
const THIS_WEEK = 'This Week'
const THIS_MONTH = 'This Month'
const THIS_YEAR = 'This Year'
const DATE_FORMAT = 'YYYY-MM-DD'

const rangePresetDate = {
    [TODAY]: [moment(), moment()],
    [THIS_WEEK]: [moment().startOf('week'), moment().endOf('week')],
    [THIS_MONTH]: [moment().startOf('month'), moment().endOf('month')],
    [THIS_YEAR]: [moment().startOf('year'), moment().endOf('year')],
}

export const CardDatePickerContext = createContext()

function CardDatePicker({ children, ...props }) {
    const [inputPreset, setInputPreset] = useState(THIS_YEAR)
    const [dateRange, setDateRange] = useState(rangePresetDate[THIS_YEAR])

    return (
        <CardDatePickerContext.Provider
            value={{
                dateRange: {
                    startDate: dateRange[0].format(DATE_FORMAT),
                    endDate: dateRange[1].format(DATE_FORMAT),
                },
                dateRangeMoment: {
                    startDate: dateRange[0],
                    endDate: dateRange[1],
                },
            }}
        >
            <Card {...props}>
                <div className="flex justify-end">
                    <div id="areaCardDatePicker">
                        <Select
                            getPopupContainer={() => document.getElementById('areaCardDatePicker')}
                            onChange={(preset) => {
                                setInputPreset(preset)
                                setDateRange(rangePresetDate[preset])
                            }}
                            showArrow={true}
                            showSearch={false}
                            className={`customizedLeftRounded ml-auto w-40 align-baseline`}
                            size="large"
                            placeholder="Select time"
                            value={inputPreset}
                        >
                            {Object.keys(rangePresetDate).map((presetDate) => (
                                <Option value={presetDate} key={presetDate}>
                                    {presetDate}
                                </Option>
                            ))}
                        </Select>
                        <RangePicker
                            onChange={(dateSet, datasetArr) => {
                                setInputPreset(null)
                                setDateRange(dateSet)
                            }}
                            size="large"
                            className="customized rounded-r-lg rounded-l-none"
                            format={DATE_FORMAT}
                            value={dateRange}
                        />
                    </div>
                </div>
                <div className="pt-3">{children}</div>
            </Card>
        </CardDatePickerContext.Provider>
    )
}

export default CardDatePicker
