import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const gateColumns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'DC Location',
        dataIndex: 'dcLocationId',
        key: 'dcLocationId',
    },
    {
        title: 'Gate ID',
        dataIndex: 'gateId',
        key: 'gateId',
    },
    {
        title: 'Building',
        dataIndex: 'building',
        key: 'building',
    },
    {
        title: 'From',
        dataIndex: 'from',
        key: 'from',
    },
    {
        title: 'To',
        dataIndex: 'to',
        key: 'to',
    },
    {
        title: 'From Plant',
        dataIndex: 'fromPlant',
        key: 'fromPlant',
    },
    {
        title: 'To Plant',
        dataIndex: 'toPlant',
        key: 'toPlant',
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
    },
]
