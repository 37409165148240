// import { Row, Col } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = () => [
    {
        title: 'Name',
        dataIndex: 'name1',
        key: 'name1',
    },
    // {
    //     title: 'Account ID',
    //     dataIndex: 'accountId',
    //     key: 'accountId',
    // },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Company Org',
        dataIndex: 'companyOrg',
        key: 'companyOrg',
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
]
