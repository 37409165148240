import React from 'react'
import SelectDebounceFetch from './index'
import { getIpSystemList } from 'api/utility/ip-system'

function SelectIpSystem({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="IP System"
            fetchOptions={async (searchTerm) => {
                const res = await getIpSystemList({ filter: searchTerm, total: 10 })
                const list = res?.result?.data.map((ip) => ({
                    label: `${ip.systemId} - ${ip.description}`,
                    value: ip.systemId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectIpSystem
