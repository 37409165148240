import React, { useState, useEffect } from 'react'
import { Select, Spin } from 'antd'
import InputSkeleton from 'components/Common/SkeletonLoading/inputSkeleton'

import { getTeamUserIds } from 'api/master/spvForemanTeam'
import { removeDuplicateObjectInArray } from 'utils/array'

const SelectTeamUserIds = ({
    loading,
    onChange = () => {},
    placeholder = 'Select Team',
    ...props
}) => {
    const [loadingOpt, setLoadingOpt] = useState(false)
    const [optList, setOptList] = useState([])

    useEffect(() => {
        const fetchOptions = async () => {
            setLoadingOpt(true)
            const res = await getTeamUserIds()
            const opt = res.result.map((item) => ({
                label: `${item.username}`,
                value: String(item.username),
            }))
            setOptList(removeDuplicateObjectInArray(opt, 'value'))
            setLoadingOpt(false)
        }

        fetchOptions()
    }, [])
    if (loading) return <InputSkeleton />
    return (
        <Select
            showSearch
            filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
            loading={loadingOpt}
            onChange={onChange}
            placeholder={placeholder}
            className="rounded-10 h-50"
            style={{ width: '100%', borderRadius: '5 !important' }}
            size="large"
            dropdownRender={(menu) => (
                <>
                    {!loadingOpt && menu}
                    {loadingOpt && (
                        <div className="text-center p-10">
                            <Spin />
                        </div>
                    )}
                </>
            )}
            options={optList}
            {...props}
        />
    )
}

export default SelectTeamUserIds
