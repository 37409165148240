import React, { useState } from 'react'
import { Form, Modal, Divider, Button, Input } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

import useQueryRefresh from 'customHooks/useQueryRefresh'

function AddEditFormModal({
    close,
    visible,
    payload: initialValues = {},
    successMsg = 'success',
    title = '',
    callApi,
}) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        const payload = {
            ...initialValues,
            plantId: values.plantId.value,
            slocId: values.slocId,
            description: values.description,
        }

        const modifiedPayload = {
            ...payload,
        }

        try {
            const res = await callApi(modifiedPayload)
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="plantId"
                    label="Plant"
                    initialValue={{
                        label: initialValues?.plantId || null,
                        value: initialValues?.plantId || null,
                    }}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <SelectDebounceFetch.Plant disabled={initialValues?.plantId} />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="slocId"
                    label="Sloc ID"
                    initialValue={initialValues?.slocId || null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 4,
                            message: 'The max character length is 4',
                        },
                    ]}
                >
                    <Input
                        placeholder="Sloc ID"
                        className="rounded-5"
                        size="large"
                        disabled={initialValues?.slocId}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="description"
                    label="Description"
                    initialValue={initialValues?.description || null}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 20,
                            message: 'The max character length is 20',
                        },
                    ]}
                >
                    <Input placeholder="Description" className="rounded-5" size="large" />
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={close}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddEditFormModal
