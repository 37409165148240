import React, { useState } from 'react'
import { Spin } from 'antd'
import { Close } from 'assets'

function SingleImage({ img, onClickDeleteImage = async () => {} }) {
    const [loading, setLoading] = useState(false)

    const handleDelete = async () => {
        setLoading(true)
        await onClickDeleteImage(img)
        setLoading(false)
    }

    return (
        <div
            className="shadow-md"
            style={{
                background: `${img.data ? `url(${img.data}) 50% 50% no-repeat` : '#eee'}`,
                width: 50,
                height: 50,
                borderRadius: 10,
                position: 'relative',
            }}
        >
            {!img.data && <p className="ml-2">error image</p>}
            {loading && (
                <div className="h-12 flex justify-center items-center">
                    <Spin className="mt-1" />
                </div>
            )}
            <Close className="absolute -top-1 -right-1 cursor-pointer" onClick={handleDelete} />
        </div>
    )
}

export default SingleImage
