import React from 'react'
import CardDatePicker from 'components/Common/CardDatePicker'

// CHART COMPONENT
import ChartCheckinVsFinish from './charts/checkin-vs-finish'
import ChartLoadingProcessDurationByDate from './charts/loading-process-duration-hours'

function SectionTwo() {
    return (
        <CardDatePicker className="mt-5">
            <ChartCheckinVsFinish />
            <ChartLoadingProcessDurationByDate />
        </CardDatePicker>
    )
}

export default SectionTwo
