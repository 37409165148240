import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewDcLocation } from 'api/master/dcLocation'
import { auth } from 'auth'

function AddDcLocationModal({ close, visible }) {
    const user = auth.getUserInfo()
    const modifiedPayload = {
        createBy: user?.userName || 'BCO_MJK2', // delete me! ["BCO_MJK2"]
    }
    return (
        <ModalForm
            title="Add Dc Location"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={createNewDcLocation}
            successMsg={(values) => `New Dc Location ${values.dcLocationId} was created`}
            close={close}
            visible={visible}
        />
    )
}

export default AddDcLocationModal
