import React, { useState } from 'react'
import { Form, Modal, Divider, Button, DatePicker, Checkbox } from 'antd'
// import useQueryRefresh from 'customHooks/useQueryRefresh'

const DATE_FORMAT = 'YYYYMMDD'
function AddItemModal({ close, visible, onSubmit }) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { submit } = form
    // const { doRefresh } = useQueryRefresh()

    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()

        try {
            onSubmit({
                ...values,
                docDate: values.docDate.format(DATE_FORMAT),
                planDate: values.planDate.format(DATE_FORMAT),
                erdat: values.erdat.format(DATE_FORMAT),
            })
            close()
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }
    return (
        <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>Set Date</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="docDate"
                    label="Document Date"
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <DatePicker
                        size="large"
                        className="rounded-10 h-50 "
                        style={{ width: '100%' }}
                        onChange={() => {}}
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="planDate"
                    label="Plant Count Date"
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <DatePicker
                        size="large"
                        className="rounded-10 h-50 "
                        style={{ width: '100%' }}
                        onChange={(e) => form.setFieldsValue({ erdat: e })}
                    />
                </Form.Item>

                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="erdat"
                    label="Erdat"
                    shouldUpdate={true}
                    rules={[{ required: true }]}
                >
                    <DatePicker
                        size="large"
                        className="rounded-10 h-50 "
                        style={{ width: '100%' }}
                        onChange={() => {}}
                    />
                </Form.Item>

                <Form.Item
                    valuePropName="checked"
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="freezebookinv"
                    shouldUpdate={true}
                >
                    <Checkbox className="-ml-2" style={{ flexDirection: 'row-reverse' }}>
                        Freeze Book Inventory
                    </Checkbox>
                </Form.Item>
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={close}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
    )
}

export default AddItemModal
