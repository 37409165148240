import React, { useContext, useState, useEffect, useMemo } from 'react'
import BarChart from 'components/Common/Chart/BarChart'
import { PRIMARY_BLUE,
PRIMARY_PINK,
DARK_BLUE_1,
DARK_BLUE_2,
DARK_PINK_1,
DARK_RED_1,
DARK_RED_2,
PINK_1,
BLUE_1,
YELLOW_1,
GREEN_1,
GREEN_2} from 'config/colors'
import { getPalletBinUsage } from 'api/dashboard/wh-inventory'

// Context
import { CardDatePickerContext } from 'components/Common/CardDatePicker'
import { DashboardWhInventoryContext } from '../../index'

import { options } from './options'

function ChartBinUsage() {
    const [loading, setLoading] = useState(false)
    const [dataLabel, setDataLabel] = useState([])
    const [dataValue, setDataValue] = useState([])

    const { dateRange } = useContext(CardDatePickerContext)
    const { dcLocation } = useContext(DashboardWhInventoryContext)

    // Data and Labels
    const labels = useMemo(() => Object.values(dataLabel || {}), [dataLabel])
    // const datasets = useMemo(() => Object.values(dataValue || {}), [dataValue])
    const datasets = [
        {
            label: 'Bin Usage',
            data: dataValue,
        },
    ]
    useEffect(() => {
        if (!dcLocation) return
        const { startDate, endDate } = dateRange
        const reqBody = {
            startDate,
            endDate,
            dcLocationId: [dcLocation?.value],
        }
        const fetchData = async () => {
            setLoading(true)
            try {
                const res = await getPalletBinUsage(reqBody)
                setDataLabel(res?.data?.binUsage.label || {})
                setDataValue(res?.data?.binUsage.singleDataValue || {})
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }

        fetchData()
    }, [dateRange, dcLocation])

    return (
        <BarChart loading={loading} options={options()} datasets={datasets} labels={[...labels]} />
    )
}

export default ChartBinUsage
