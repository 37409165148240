import React, { useContext } from 'react'
import LineChart from 'components/Common/Chart/LineChart'
import { getProductionDus } from 'api/dashboard/inbound'

// Context
import { CardDatePickerContext } from 'components/Common/CardDatePicker'
import { DashboardInboundContext } from '../../index'

import useLineChart from '../useLineChart'
import { options } from './options'

function ChartInboundProductionDus() {
    const { dateRange } = useContext(CardDatePickerContext)
    const { dcLocation } = useContext(DashboardInboundContext)

    const { loading, datasets } = useLineChart({
        dateRange,
        dcLocation,
        callApi: getProductionDus,
    })

    return (
        <LineChart
            loading={loading}
            options={options()}
            datasets={datasets}
            borderedContainer={false}
        />
    )
}

export default ChartInboundProductionDus
