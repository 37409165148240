import React, { useState } from 'react'
import { Button, Row } from 'antd'

import RichTable from 'components/Common/RichTable'

import { assignPlantColumn } from './columns'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddAssignPlantModal from './modals/add-assign-plant'
import UpdateAssignPlantModal from './modals/update-assign-plant'

import { getAssignPlantList } from 'api/master/assignPlantToCompany'

function AssignPlant() {
    const [showUpdateCompanyModal, setUpdateCompanyModal] = useState(false)
    const [showAddCompanyModal, setAddCompanyModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateCompanyModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddCompanyModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                // rowKey="companyId"
                columns={assignPlantColumn(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                getListApi={getAssignPlantList}
                type="rich"
            />
            <AddAssignPlantModal
                close={() => setAddCompanyModal(false)}
                visible={showAddCompanyModal}
            />
            <UpdateAssignPlantModal
                close={() => setUpdateCompanyModal(false)}
                visible={showUpdateCompanyModal}
                payload={modalFormPayload}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default AssignPlant
