import { groupByKey } from 'utils/array'

const STOCK_TYPE = {
    UU: '1',
    QI: '2',
    BL: '4',
}

export const addTransactionId = (payload_) => (row) => ({
    ...row,
    transactionId: payload_.transactionId,
})

export const addCompanyId = (payload_) => (row) => {
    return {
        ...row,
        companyId: payload_.companyId,
    }
}

export const addDocnum = (row) => ({
    ...row,
    docnum: `${row.transactionId}-${row.plantId}${row.materialId}${row.slocId}`,
})

export const addRowKey = (row, index) => {
    return { ...row, rowKey: index }
}

const addPidValues = (pidValues) => (item) => ({ ...item, ...pidValues })

const reduceToObjectWithCountItemAndDetail = (groupedDataArr) => {
    const initialObject = {
        companyId: groupedDataArr[0].companyId,
        plant: groupedDataArr[0].plantId,
        stgeLoc: groupedDataArr[0].slocId,
        docDate: groupedDataArr[0].docDate,
        planDate: groupedDataArr[0].planDate,
        freezebookinv: groupedDataArr[0].freezebookinv ? 'X' : '',
        docnum: groupedDataArr[0].docnum,
        erdat: groupedDataArr[0].erdat,
        items: [],
        counts: [],
        detail: [],
    }

    return groupedDataArr.reduce((acc, curr, index) => {
        const newItem = {
            material: curr.materialId,
            description: curr.description,
            batch: curr.batch,
            stockType: STOCK_TYPE[curr.stockStatus],
            altUnit: 'X',
        }
        const newCount = {
            item: index + 1,
            material: curr.materialId,
            batch: curr.batch,
            entryQnt: curr.qtyWms || '0', // Tanya pak roel
            entryUom: curr.uom,
        }

        return {
            ...acc,
            items: [...acc.items, newItem],
            counts: [...acc.counts, newCount],
            detail: [...acc.detail, ...curr.detail],
        }
    }, initialObject)
}

export const getSubmitToSapReqBody = (data, pidValues, payload) => {
    const newData = data
        .map(addPidValues(pidValues))
        .map(addCompanyId(payload))
        .map(addTransactionId(payload))
        .map(addDocnum)
    console.log('newData', newData)

    const groupedDataByDocnum = Object.values(groupByKey(newData, 'docnum'))
    console.log('groupedDataByDocnum', groupedDataByDocnum)

    const dataWithItemsAndCounts = groupedDataByDocnum.map(reduceToObjectWithCountItemAndDetail)
    console.log('dataWithItemsAndCounts', dataWithItemsAndCounts)

    return dataWithItemsAndCounts
}

export const getReCountReqBody = (data, picAndDate) => {
    const reqBody = data
        .map((item) => item.detail)
        .reduce((acc, curr) => [...acc, ...curr], [])
        .map((item) => ({
            companyId: item.companyId,
            plantId: item.plantId,
            palletId: item.palletId,
            palletCode: item.palletCode,
            materialId: item.materialId,
            description: item.description,
            batch: item.batch,
            qty: item.qty ? +item.qty : 0,
            uom: item.uom,
            binId: item.binId,
            slocId: item.slocId,
            sectorId: item.sectorId,
            gateId: item.gateId,
            dockingId: item.dockingId,
            stagingIn: item.stagingIn,
            status: '00',
            type: '01', // Default dari BE
            userId: picAndDate?.foremanId || '',
            from: picAndDate?.fromTo[0].format('YYYY-MM-DD hh:mm:ss') || '',
            to: picAndDate?.fromTo[1].format('YYYY-MM-DD hh:mm:ss') || '',
        }))

    return reqBody
}
