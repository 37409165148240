import React from 'react'
import RichTable from 'components/Common/RichTable'
import { getPurchaseOrgList } from 'api/master/purchasingOrg'

import { columns } from './columns'

const PurchasingOrganization = () => {
    return (
        <RichTable
            columns={columns()}
            showButtonsArea={false}
            showSearch={true}
            withRowSelection={false}
            withSearchAndFilterArea={false}
            getListApi={getPurchaseOrgList}
        />
    )
}

export default PurchasingOrganization
