export const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
}

export const TOGGLE_PLANNER_CHANGE = 'TOGGLE_PLANNER_CHANGE'
export const TOGGLE_CREATE_OUTBOND_ID = 'TOGGLE_CREATE_OUTBOND_ID'
export const TOGGLE_CHANGE_OUTBOND_ID = 'TOGGLE_CHANGE_OUTBOND_ID'
export const TOGGLE_ADD_OUTBOND_ID = 'TOGGLE_ADD_OUTBOND_ID'
export const TOGGLE_DISPLAY_BOOKING = 'TOGGLE_DISPLAY_BOOKING'
export const TOGGLE_UNLOCK_DO_NUMBER = 'TOGGLE_UNLOCK_DO_NUMBER'
export const TOGGLE_LOCK_DO_NUMBER = 'TOGGLE_LOCK_DO_NUMBER'
export const TOGGLE_LOCK_UNLOCK_DO_NUMBER = 'TOGGLE_LOCK_UNLOCK_DO_NUMBER'
export const TOGGLE_GET_SINGLE_DO_NUMBER = 'TOGGLE_GET_SINGLE_DO_NUMBER'
export const TOGGLE_CANCEL_LOADING = 'TOGGLE_CANCEL_LOADING'
export const TOGGLE_ADD_INFORMATION = 'TOGGLE_ADD_INFORMATION'
export const TOGGLE_PLANNER_DISPLAY = 'TOGGLE_PLANNER_DISPLAY'
export const TOGGLE_ASSIGN_PALLET = 'TOGGLE_ASSIGN_PALLET'
export const TOGGLE_ASSIGN_SPV = 'TOGGLE_ASSIGN_SPV'
export const TOGGLE_REMOVE_SPV = 'TOGGLE_REMOVE_SPV'
export const TOGGLE_PLANNER_INFO = 'TOGGLE_PLANNER_INFO'
export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS'
