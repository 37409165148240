import React, { useState } from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { Form, Modal, Divider, Button, Input, Select } from 'antd'
import { formItems } from './formItems'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
import useQueryRefresh from 'customHooks/useQueryRefresh'

function AddPalletIdModal({
    close,
    visible,
    payload: initialValues = {},
    successMsg = 'success',
    title = '',
    callApi,
    additionalPayload = {},
}) {
  
    const [loading, setLoading] = useState(false)
    const [palletId, setPalletId] = useState('')
    const [plantId, setPlantId] = useState('')
    const [code, setCode] = useState(initialValues.code ?? '')
    console.log(initialValues.code)
    // const [isAvailable, setIsAvailable] = useState(initialValues?.isAvailable || "N")
    // const [isMulti, setIsMulti] = useState(initialValues?.isMulti || "N")
    // const [isHold, setIsHold] = useState(initialValues?.isHold || "N")
    // const [isFreeze, setIsFreeze] = useState(initialValues?.isFreeze || "N")
    const [form] = Form.useForm()
    const { submit } = form
    const { doRefresh } = useQueryRefresh()

    const changeCode = async (e) => {
        console.log("e.value")
        console.log(e.target.value)
        setCode(e.target.value ?? "")
        const prefixPlant = plantId ? plantId.substring(1) : ""
        initialValues.id = prefixPlant+e.target.value
       
    }
    const changePlant =  (e) => {
        const valueCode = ""
        setPlantId(e.value)
        if(initialValues?.code){
            setCode(initialValues?.code)
            initialValues.id = e.value.substring(1)+initialValues?.code
        }else{
            initialValues.id = e.value.substring(1)
        }
    }
    const handleSubmit = async (e) => {
        const values = await form.validateFields()
        setLoading(true)
        submit()
    
        const payload = {
            id : values.id || initialValues?.id,
            companyId : values.companyId.value || initialValues?.companyId,
            plantId : values.plantId.value || initialValues?.plantId,
            code : values.code || initialValues?.code,
            color : values.color || initialValues?.color,
            isAvailable : values.isAvailable || initialValues?.isAvailable,
            isActive : values.isActive || initialValues?.isActive,
            isMulti : values.isMulti || initialValues?.isMulti,
            isHold : values.isHold || initialValues?.isHold,
            isFreeze : values.isFreeze || initialValues?.isFreeze,
            status : values.status || initialValues?.status
        }

        const modifiedPayload = {
            ...payload,
            ...additionalPayload,
        }

        try {
            const res = await callApi(modifiedPayload)
            if (res.success || res.status) {
                Modal.success({
                    title: typeof successMsg == 'string' ? successMsg : successMsg(values),
                })
                close()
                doRefresh()
            }
            return setLoading(false)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }
    return (
            <Modal visible={visible} destroyOnClose width={576} footer={null} closable={false}>
            <p className="text-lg">
                <b>{title}</b>
            </p>
            <Divider className="border-black my-4" />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                preserve={false}
                style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, ${100 / 2}%)`,
                    gap: 10,
                }}
            >
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="id"
                    label="Pallet ID"
                    initialValue={palletId}
                    shouldUpdate={true}
                    rules={[
                        {
                            max: 10,
                            message: 'The max character length is 10',
                        },
                    ]}
                >
                     <Input
                     disabled={initialValues?.id}
                        placeholder={initialValues?.id?? "Pallet ID"}
                        className="rounded-5"
                        size="large"
                        readOnly="true"
                    />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="companyId"
                    label="Company"
                    initialValue={{
                        label: initialValues?.companyId || null,
                        value: initialValues?.companyId || null,
                    }}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                  <SelectDebounceFetch.Company />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="plantId"
                    label="Plant ID"
                    initialValue={{
                        label: initialValues?.plantId || null,
                        value: initialValues?.plantId || null,
                    }}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                   <SelectDebounceFetch.Plant  onChange={changePlant}/>
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="code"
                    label="Code"
                    initialValue={initialValues?.code}
                    // shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 6,
                            message: 'The max character length is 6',
                        },
                    ]}
                >
                    <Input placeholder={initialValues?.code??"Code"} disabled={initialValues?.code}  className="rounded-5" size="large"  onChange={changeCode}/>
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="color"
                    label="Color"
                    initialValue={initialValues?.color}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            max: 15,
                            message: 'The max character length is 15',
                        },
                    ]}
                >
                    <Input placeholder="Color" className="rounded-5" size="large" />
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="isAvailable"
                    label="Avaialble"
                    initialValue={initialValues?.isAvailable || 'Y'}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        placeholder="Is Avaialble"
                        className="rounded-5"
                        size="large"
                    >
                        {/* Y = No    N = Yes   => the origin data from backend is noActive. We need to reverse 'yes' and 'no' to make it clear from user's perspective  */}
                        <Select.Option value="N">No</Select.Option>
                        <Select.Option value="Y">Yes</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="isActive"
                    label="Active"
                    initialValue={initialValues?.isActive || 'Y'}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        placeholder="Is Avaialble"
                        className="rounded-5"
                        size="large"

                    >
                        {/* Y = No    N = Yes   => the origin data from backend is noActive. We need to reverse 'yes' and 'no' to make it clear from user's perspective  */}
                        <Select.Option value="N">No</Select.Option>
                        <Select.Option value="Y">Yes</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="isMulti"
                    label="Multi"
                    initialValue={initialValues?.isMulti || 'N'}
                    shouldUpdate={true}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        placeholder="Is Avaialble"
                        className="rounded-5"
                        size="large"
    
                    >
                        {/* Y = No    N = Yes   => the origin data from backend is noActive. We need to reverse 'yes' and 'no' to make it clear from user's perspective  */}
                        <Select.Option value="N">No</Select.Option>
                        <Select.Option value="Y">Yes</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="isHold"
                    label="Hold"
                    initialValue={initialValues?.isHold|| 'N'}
                    shouldUpdate={true}
                    readOnly="true"
                    // rules={[
                    //     {
                    //         required: true,
                    //     },
                    //     {
                    //         max: 15,
                    //         message: 'The max character length is 15',
                    //     },
                    // ]}
                >
                    <Input disabled={initialValues?.isHold || 'N'}
                        placeholder={initialValues?.isHold || 'N'} readOnly="true" className="rounded-5" size="large" />
                </Form.Item>
                {/* <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="isHold"
                    label="Hold"
                    initialValue={initialValues?.isHold || 'N'}
                    shouldUpdate={true}
                    readOnly="true"
                    rules={[
                        {
                            required: true,
                            
                        },
                    ]}
                > */}
                    {/* <Select
                        placeholder="Is Avaialble"
                        className="rounded-5"
                        size="large"
                        readOnly={true}
                    > */}
                        {/* Y = No    N = Yes   => the origin data from backend is noActive. We need to reverse 'yes' and 'no' to make it clear from user's perspective  */}
                        {/* <Select.Option value="N">No</Select.Option>
                        <Select.Option value="Y">Yes</Select.Option>
                    </Select> */}
                {/* </Form.Item> */}
                <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="isFreeze"
                    label="Freeze"
                    initialValue={initialValues?.isFreeze || 'N'}
                    shouldUpdate={true}
                    readOnly="true"
                    // rules={[
                    //     {
                    //         required: true,
                    //     },
                    //     {
                    //         max: 15,
                    //         message: 'The max character length is 15',
                    //     },
                    // ]}
                >
                    <Input  disabled={initialValues?.isFreeze || 'N'}
                        placeholder={initialValues?.isFreeze || 'N'} readOnly="true" className="rounded-5" size="large" />
                </Form.Item>
                {/* <Form.Item
                    className="my-1"
                    style={{ width: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}
                    name="isFreeze"
                    label="Freeze"
                    initialValue={initialValues?.isFreeze || 'N'}
                    shouldUpdate={true}
                  
                    rules={[
                        {
                            required: true,
                            readonly: true
                        },
                    ]}
                >
                    <Select
                        placeholder="Is Avaialble"
                        className="rounded-5"
                        size="large"
                        readOnly="true"
                    >
                  
                        <Select.Option value="N">No</Select.Option>
                        <Select.Option value="Y">Yes</Select.Option>
                    </Select>
                </Form.Item> */}
            </Form>
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={() => {
                        setLoading(false)
                        close()
                    }}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleSubmit}
                    type="primary"
                    loading={loading}
                >
                    Submit
                </Button>
            </div>
        </Modal>
        // <ModalForm
        //     title="Add Pallet Id"
        //     formItems={formItems}
        //     callApi={createNewPalletId}
        //     successMsg={(values) => `New Pallet was created`}
        //     close={close}
        //     visible={visible}
        // />
    )
}

export default AddPalletIdModal
