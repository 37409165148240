import React from 'react'
import Tooltip from 'components/Common/Tooltip'
import { InfoGreyIcon } from 'assets'

function TitleWithTooltip({ children, tooltip }) {
    return (
        <p className="font-semibold text-base flex justify-center items-center">
            <span className="mr-1">{children}</span>
            <Tooltip title={tooltip}>
                <InfoGreyIcon />
            </Tooltip>
        </p>
    )
}

export default TitleWithTooltip
