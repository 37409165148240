import React from 'react'
import RichTable from 'components/Common/RichTable'
import { getPoTypeList } from 'api/master/poType'
import { columns } from './columns'

const PoType = () => {
    return (
        <>
            <RichTable
                columns={columns()}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                getListApi={getPoTypeList}
            />
        </>
    )
}

export default PoType
