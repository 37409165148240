import React from 'react'
import RichTable from 'components/Common/RichTable'
import { columns } from './columns'

import { getGlList } from 'api/master/gl'

function Gl() {
    return <RichTable columns={columns()} showSearch={true} getListApi={getGlList} />
}

export default Gl
