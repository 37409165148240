import { Tag } from 'antd'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = (transactionId) => [
    {
        title: 'Outbond ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
        render: () => <>{transactionId}</>,
    },
    {
        title: 'DO',
        dataIndex: 'doNumber',
        key: 'doNumber',
    },
    {
        title: 'DO Item',
        dataIndex: 'doItem',
        key: 'doItem',
        width: 100,
        elipsis: true,
    },
    {
        title: 'Material',
        dataIndex: 'material',
        key: 'material',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Qty',
        dataIndex: 'totalQty',
        key: 'totalQty',
    },
]
