import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddCharacteristicSortModal from './modals/add-characteristic-sort'
import UpdateCharacteristicSortModal from './modals/update-characteristic-sort'

import { getCharacteristicSortList, deleteCharacteristicSort } from 'api/master/characteristicSort'
import { columns } from './columns'

function CharacteristicSort() {
    const [showUpdateCharacteristicSortModal, setUpdateCharacteristicSortModal] = useState(false)
    const [showAddCharacteristicSortModal, setAddCharacteristicSortModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [selectedRows, setSelectedRows] = useState([])

    const { doRefresh } = useQueryRefresh()

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateCharacteristicSortModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = selectedRows.map((row) => row.id)

                const res = await deleteCharacteristicSort(reqBody)

                if (!res.success) return
                doRefresh()
            },
        })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete All" onClick={handleBulkDelete} />
    )

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddCharacteristicSortModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                // rowKey="id"
                columns={columns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getCharacteristicSortList}
                type="rich"
            />
            <AddCharacteristicSortModal
                close={() => setAddCharacteristicSortModal(false)}
                visible={showAddCharacteristicSortModal}
            />
            <UpdateCharacteristicSortModal
                close={() => setUpdateCharacteristicSortModal(false)}
                visible={showUpdateCharacteristicSortModal}
                payload={modalFormPayload}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default CharacteristicSort
