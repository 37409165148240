import React from 'react'
import SelectDebounceFetch from './index'
import { getBinList } from 'api/master/bin'
const data = [
    {
        value: 'Y',
        label: "Y"
    },
    {
        value: 'N',
        label: "N"
    },
   
]
function SelectPickerPalletStatus({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Picker Pallet Status"
            fetchOptions={async (searchTerm) => {
                const list = data
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectPickerPalletStatus
