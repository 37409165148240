import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddMoveReasonModal from './modals/add-move-reason'
import UpdateMoveReasonModal from './modals/update-move-reason'

import { getMoveReasonList, deleteMoveReason } from 'api/master/moveReason'
import { moveReasonColumns } from './columns'

function MoveReason() {
    const [showUpdateMoveReasonModal, setUpdateMoveReasonModal] = useState(false)
    const [showAddMoveReasonModal, setAddMoveReasonModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [selectedRows, setSelectedRows] = useState([])

    const { doRefresh } = useQueryRefresh()

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateMoveReasonModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = { id: selectedRows.map((row) => row.id) }
                const res = await deleteMoveReason(reqBody)
                if (!res.success) return
                doRefresh()
            },
        })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete" onClick={handleBulkDelete} />
    )

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddMoveReasonModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                columns={moveReasonColumns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getMoveReasonList}
                type="rich"
            />
            <AddMoveReasonModal
                close={() => setAddMoveReasonModal(false)}
                visible={showAddMoveReasonModal}
            />
            <UpdateMoveReasonModal
                close={() => setUpdateMoveReasonModal(false)}
                visible={showUpdateMoveReasonModal}
                payload={modalFormPayload}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default MoveReason
