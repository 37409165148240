import React, { useState, useEffect } from 'react'
import { Modal, Divider, Button } from 'antd'
import { generateQrPallet } from 'api/master/palletId'
import SkeletonLoading from 'components/Common/SkeletonLoading/divSkeletonLoading'
import PrintConfirmationModal from 'components/Common/Modals/PrintConfirmationModal'

import QrCodePrint from './qr-code-print'

function QrCodeList({ visible, close, payload = [] }) {
    const [loading, setLoading] = useState(false)
    const [printModal, setPrintModal] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        if (!visible) return
        const fetchData = async () => {
            setLoading(true)
            const reqBody = payload.map((i) => ({ id: i.id }))
            const res = await generateQrPallet(reqBody)
            setLoading(false)
            setData([...res])
        }

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.length, visible])

    return (
        <Modal visible={visible} destroyOnClose width={900} footer={null} closable={false}>
            <p className="text-lg">Show QR Code (Pallet)</p>
            <Divider className="border-black my-4" />

            {loading && <SkeletonLoading />}
            <div className="max-h-96 overflow-y-scroll">
                {!loading && (
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: `repeat(auto-fit, 200px)`,
                        }}
                    >
                        {data?.length > 0 &&
                            data.map((dt) => (
                                <div
                                    key={dt.id}
                                    className="flex flex-col justify-center items-center w-40 mb-5"
                                >
                                    <div className="w-full flex justify-between items-center">
                                        <p>Pallet</p>
                                        <p>{dt.code}</p>
                                    </div>
                                    <img
                                        className="text-center bg-primary"
                                        alt={dt.id}
                                        src={`data:image/svg+xml;base64,${dt.qr}`}
                                    />
                                    <p className="text-center mt-2 text-lg">
                                        {dt.id} - {dt.color}
                                    </p>
                                </div>
                            ))}
                    </div>
                )}
            </div>
            {/* <Divider className="border-black my-4" />
            <QrCodePrint data={data} />
            <Divider className="border-black my-4" /> */}
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={() => {
                        setData([])
                        close()
                    }}
                    type="ghost"
                >
                    Close
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={() => setPrintModal(true)}
                    type="primary"
                    loading={loading}
                >
                    {loading ? 'Wait...' : 'Print'}
                </Button>
            </div>
            <PrintConfirmationModal
                visible={printModal}
                close={() => setPrintModal(false)}
                componentToPrint={<QrCodePrint data={data} />}
            />
        </Modal>
    )
}

export default QrCodeList
