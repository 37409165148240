import React from 'react'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
export const FILTER_BY = {
    BY_MATERIAL: 'By Material',
    BY_COMPANY: 'By Company',
    BY_PLANT: 'By Plant',
    BY_BUILDING: 'By Building',
    BY_GATE: 'By Gate',
    BY_DC_LOCATION: 'By DC Location',
    BY_TRANSACTION_STATUS: 'By Transaction Status',
    BY_PALLET_STATUS: 'By Pallet Status',
    BY_CHECK_PALLET_STATUS: 'By Check Pallet Status',
    BY_PICKER_PALLET_STATUS: 'By Picker Pallet Status',
    BY_CHANGE_BIN_STATUS: 'By Change Bin Status',
    BY_DRAFT_STATUS: 'Draft Status',
    BY_SCAN_PALLET_STATUS: 'By Scan Pallet Status',
    BY_TRANSACTION_TYPE: 'By Transaction Type',
    BY_CHECKIN_STATUS: 'By Checkin Status',
    BY_OUTBOUND_STATUS: 'By Outbound Status',
}

export const FILTER_COMPONENT = {
    [FILTER_BY.BY_MATERIAL]: (props) => <SelectDebounceFetch.Material mode="multiple" {...props} />,
    [FILTER_BY.BY_COMPANY]: (props) => <SelectDebounceFetch.Company mode="multiple" {...props} />,
    [FILTER_BY.BY_PLANT]: (props) => <SelectDebounceFetch.Plant mode="multiple" {...props} />,
    [FILTER_BY.BY_BUILDING]: (props) => (
        <SelectDebounceFetch.SlocByPlant mode="multiple" {...props} />
    ),
    [FILTER_BY.BY_DC_LOCATION]: (props) => (
        <SelectDebounceFetch.DcLocation mode="multiple" {...props} />
    ),
    [FILTER_BY.BY_GATE]: (props) => <SelectDebounceFetch.Gate mode="multiple" {...props} />,
    [FILTER_BY.BY_TRANSACTION_STATUS]: (props) => (
        <SelectDebounceFetch.TransactionStatus mode="multiple" {...props} />
    ),
    [FILTER_BY.BY_PALLET_STATUS]: (props) => (
        <SelectDebounceFetch.PalletStatus mode="multiple" {...props} />
    ),
    [FILTER_BY.BY_CHECK_PALLET_STATUS]: (props) => (
        <SelectDebounceFetch.CheckPalletStatus mode="multiple" {...props} />
    ),
    [FILTER_BY.BY_PICKER_PALLET_STATUS]: (props) => (
        <SelectDebounceFetch.PickerPalletStatus mode="multiple" {...props} />
    ),
    [FILTER_BY.BY_CHANGE_BIN_STATUS]: (props) => (
        <SelectDebounceFetch.ChangeBinStatus mode="multiple" {...props} />
    ),
    [FILTER_BY.BY_DRAFT_STATUS]: (props) => (
        <SelectDebounceFetch.DraftStatus mode="multiple" {...props} />
    ),
    [FILTER_BY.BY_SCAN_PALLET_STATUS]: (props) => (
        <SelectDebounceFetch.StatusScanPallet mode="multiple" {...props} />
    ),
    [FILTER_BY.BY_TRANSACTION_TYPE]: (props) => (
        <SelectDebounceFetch.TransactionType mode="multiple" {...props} />
    ),
    [FILTER_BY.BY_CHECKIN_STATUS]: (props) => (
        <SelectDebounceFetch.CheckinTransactionStatus mode="multiple" {...props} />
    ),
    [FILTER_BY.BY_OUTBOUND_STATUS]: (props) => (
        <SelectDebounceFetch.OutboundStatus mode="multiple" {...props} />
    ),
}
