import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getSpvList = async (params) => {
    const response = await call(METHODS.GET, '/master/spv', params)
    return response.data
}

export const createNewSpv = async (data) => {
    const url = `/master/spv/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updateSpv = async (data) => {
    const url = `/master/spv/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deleteSpv = async (data) => {
    const url = `/master/spv/delete`
    const response = await call(METHODS.DELETE, url, data)
    return response.data
}

export const getUserByRole = async (data) => {
    const url = `/master/spv/userbyrole`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const getSpvUserIds = async (data) => {
    const res = await getUserByRole({ roleId: [8] })
    return res
}

export const getForemanUserIds = async (data) => {
    const res = await getUserByRole({ roleId: [10] })
    return res
}

export const getTeamUserIds = async (data) => {
    const res = await getUserByRole({ roleId: [11, 12] })
    return res
}

export const bulkDeleteSpv = async () => {
    // const fakeRes = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve("");
    //   }, 500);
    // });
    // const response = await fakeRes;
    // return response;
}

export const getTransactionType = async (params) => {
    const response = await call(METHODS.GET, '/master/spv/transactiontype', params)
    return response.data
}
