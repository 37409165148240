export const DO_WO_CHECKIN_STATUS = {
    UN_CREATE: 'Uncreate',
    SING_PALLET: 'Sign Pallet',
    READY_TO_LOAD: 'Ready To Load',
    SUBMITTED: 'Submitted',
    DRAFT: 'Draft',
}

export const DO_WO_CHECKIN_COLOR = {
    [DO_WO_CHECKIN_STATUS.UN_CREATE]: 'red',
    [DO_WO_CHECKIN_STATUS.SING_PALLET]: 'yellow',
    [DO_WO_CHECKIN_STATUS.READY_TO_LOAD]: 'green',
    [DO_WO_CHECKIN_STATUS.SUBMITTED]: 'green',
    [DO_WO_CHECKIN_STATUS.DRAFT]: 'red',
}
