import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Form, Card, Input } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined, KeyOutlined } from '@ant-design/icons'
import axios from 'axios'
import { auth } from 'auth'
import { signInApi } from 'api/user'

import { logoImg } from 'assets/images'

function Login() {
    const [loading, setLoading] = useState(false)
    const [ipAddres, setIP] = useState("")
    const [form] = Form.useForm()
    const { submit, validateFields } = form

    const location = useLocation()
    const navigate = useNavigate()

    const from = location?.state?.from || '/'
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        console.log(res.data);
        setIP(res.data.ip);
 };
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const signInData = await validateFields()
            signInData.device = ipAddres
            console.log(signInData)
            const signInResponse = await signInApi(signInData)
            auth.authenticate(signInData, signInResponse)
            setLoading(false)
            navigate(from, { state: { from: location.pathname } })
        } catch (err) {
            console.error(err)
            setLoading(false)
        }
        submit()
    }

    useEffect(() => {
        getData()
        const isAuthenticated = auth.isAuthenticated()
        if (isAuthenticated) navigate('/')
    }, [navigate])

    return (
        <div className="bg-nabati-blue min-h-screen flex justify-center items-center flex-col">
            <div className="flex justify-center items-center mb-10">
                <img src={logoImg} className="mb-5 mt-2" alt="logo nabati" />
                <div className="text-white font-bold">
                    <p className="text-2xl">Warehouse Management System</p>
                    <p className="text-sm">Kaldu Sari Nabati Indonesia</p>
                </div>
            </div>
            <div className="drop-shadow-md w-full flex justify-center items-center">
                <Card style={{ maxWidth: 572, width: '90%' }} className="py-2 rounded-10">
                    <p className="text-center font-bold text-2xl py-4">Login</p>
                    <Form
                        requiredMark={false}
                        layout="vertical"
                        form={form}
                        // onFinish={onFinish}
                    >
                        <Form.Item
                            name="username"
                            label="Username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input username',
                                },
                            ]}
                        >
                            <Input
                                allowClear
                                size="large"
                                className="rounded-10"
                                placeholder="username"
                                prefix={<UserOutlined />}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input password',
                                },
                            ]}
                        >
                            <Input.Password
                                allowClear
                                size="large"
                                className="rounded-10"
                                placeholder="password"
                                prefix={<KeyOutlined />}
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                            />
                        </Form.Item>
                    </Form>
                    <Button
                        type="primary"
                        className="bg-nabati-pink w-full h-10 mt-6 rounded-10"
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        Login
                    </Button>
                </Card>
            </div>
        </div>
    )
}

export default Login
