import React from 'react'
// import { Button } from 'antd'

// import SimpleContainer from 'components/layouts/simple'
// import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import SearchAndFilterArea from 'components/Common/SearchAndFilterArea'
import SimpleTabs from 'components/Common/SimpleTabs'

// Hooks
import useSearchParams from 'customHooks/useSearchParams'

// tabs
import CycleCount from './tabs/cycle-count'
import StockOpname from './tabs/stock-opname'

function ResultCycleCountOpname() {
    const { resetQueryParamsToToday } = useSearchParams()
    return (
        <>
            <SearchAndFilterArea withDatePicker />
            <SimpleTabs
                onChange={(e) => {
                    resetQueryParamsToToday()
                }}
            >
                {SimpleTabs.TabPane({
                    children: <CycleCount />,
                    tabTitle: 'Cycle Count',
                    key: 1,
                })}
                {SimpleTabs.TabPane({
                    children: <StockOpname />,
                    tabTitle: 'Stock Opname',
                    key: 2,
                })}
            </SimpleTabs>
        </>
    )
}

export default ResultCycleCountOpname
