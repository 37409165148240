import React from 'react'
import { ArrowRightIcon, ArrowBottomIcon } from 'assets'

const StyledParentMenuList = ({
    floatingSubmenu,
    isActiveMenu,
    showSideMenu,
    icon,
    title,
    setOpenedParentMenu,
    submenu,
    isParentMenuOpen,
    path,
}) => {
    return (
        <>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 10fr 1fr',
                    justifyItems: 'start',
                    alignItems: 'center',
                }}
                className={`my-1 p-2 w-full text-white flex items-center rounded-5 cursor-pointer transition-all ease-in-out duration-600 hover:bg-nabati-pink ${
                    isActiveMenu ? ' bg-nabati-pink' : ''
                } ${!showSideMenu ? 'p-5' : ''}`}
                onMouseEnter={() => (!showSideMenu ? setOpenedParentMenu(path) : null)}
            >
                {icon}
                {showSideMenu && <span className="ml-2">{title}</span>}
                {submenu?.length > 0 && showSideMenu && (
                    <>
                        {isParentMenuOpen ? (
                            <ArrowBottomIcon className="block text-xs justify-self-end ml-auto" />
                        ) : (
                            <ArrowRightIcon className="block text-xs justify-self-end ml-auto" />
                        )}
                    </>
                )}
            </div>
            {!showSideMenu && isParentMenuOpen && (
                <div
                    onMouseEnter={() => (!showSideMenu ? setOpenedParentMenu(path) : null)}
                    onMouseLeave={() => (!showSideMenu ? setOpenedParentMenu('') : null)}
                    className={
                        showSideMenu ? '' : 'border-l-4 h-screen border-gray-200 overflow-y-scroll'
                    }
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 80,
                        zIndex: 100,
                    }}
                >
                    <div className="overflow-y-scroll h-screen">{floatingSubmenu}</div>
                </div>
            )}
        </>
    )
}

export default StyledParentMenuList
