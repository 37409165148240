import { PUBLIC_URL } from 'config/env'
import { setLocalStorage, getLocalStorage, clearLocalStorage } from 'utils/localStorage'
import { LOCAL_STORAGE_KEYS } from 'config/localStorage'
import { signOutApi } from 'api/user'

export const auth = {
    async authenticate(signInData, signInResponse) {
        const userInfo = signInResponse.user
        const userRoles = signInResponse.user.roles

        // set localStorage
        setLocalStorage(LOCAL_STORAGE_KEYS.USER, JSON.stringify(userInfo))
        setLocalStorage(LOCAL_STORAGE_KEYS.ROLES, JSON.stringify(userRoles))
        setLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, signInResponse.token)
    },

    signOut: async ({ withRedirect = false }) => {
        const userInfo = getLocalStorage(LOCAL_STORAGE_KEYS.USER)
        const user = JSON.parse(userInfo)

        const param = {username : user.userName , device : user.device}
 
        signOutApi(param)
        clearLocalStorage()
        if (!withRedirect) window.location.href = `${PUBLIC_URL}/login?isLogout=true`
    },

    isAuthenticated: () => !!auth.getToken(),
    getToken: () => {
        const token = getLocalStorage(LOCAL_STORAGE_KEYS.TOKEN)
        return token
    },
    getUserInfo: () => {
        const userInfo = getLocalStorage(LOCAL_STORAGE_KEYS.USER)
        return userInfo ? JSON.parse(userInfo) : {}
    },
    getUserRoles: () => {
        const roles = getLocalStorage(LOCAL_STORAGE_KEYS.ROLES)
        return roles ? JSON.parse(roles) : []
    },
}
