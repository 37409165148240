import React, { createContext } from 'react'

import SummarySection from './SummarySection'
import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'
import SectionFilter from '../_sharedComponents/SectionFilter'
import useDashboardState from '../useDashboardState'

export const DashboardWhInventoryContext = createContext()

function OutboundDashboard() {
    const { dcLocation, onChangeDcLocation } = useDashboardState()

    return (
        <DashboardWhInventoryContext.Provider value={{ dcLocation, onChangeDcLocation }}>
            <SectionFilter
                title="WH Inventory"
                dcLocation={dcLocation}
                onChangeDcLocation={onChangeDcLocation}
            />
            <SummarySection dcLocation={dcLocation} />
            <SectionOne />
            <SectionTwo />
        </DashboardWhInventoryContext.Provider>
    )
}

export default OutboundDashboard
