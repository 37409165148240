import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewAssignMaterialToBin } from 'api/master/assignMaterialToBIN'

function AddAssignMaterialToBinModal({ close, visible }) {
    return (
        <ModalForm
            title="Add Assign Material To Bin"
            formItems={formItems}
            callApi={createNewAssignMaterialToBin}
            successMsg={(values) =>
                `New Assign Material To Bin ${values.materialId.value} was created`
            }
            close={close}
            visible={visible}
        />
    )
}

export default AddAssignMaterialToBinModal
