import React, { useEffect, useReducer } from 'react'
import { Table, Button } from 'antd'

import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import AddRouteModal from '../add-route'
import ChangePlantModal from '../change-plant'

import { filterArrayByArray } from 'utils/array'
import { columns } from './column'

const initialState = {
    data: [],
    selectedRowKeys: [],
    selectedRows: [],
    addRouteModal: false,
    changePlantModal: false,
    confirmDeleteModal: false,
    rowPayload: null,
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_INITIAL_ITEMS':
            return {
                ...state,
                data: [...action.initialItems],
            }
        case 'RESET_DATA':
            return {
                ...state,
                data: [],
            }
        case 'SET_EDIT_ITEM':
            return {
                ...state,
                data: state.data.map((el, index) => {
                    const itemIndex = state.data.findIndex((p) => p.key === action.item.key)
                    if (index === itemIndex) return action.item
                    return el
                }),
            }
        case 'CHANGE_ALL_PLANT':
            return {
                ...state,
                data: state.data.map((el, index) => ({
                    ...el,
                    plant: action.plant,
                })),
            }
        // case 'DELETE_ITEM':
        //     return {
        //         ...state,
        //         data: state.data.filter((el) => el.key !== action.item.key),
        //     }
        case 'BULK_DELETE_ITEMS':
            console.log('action', action)
            return {
                ...state,
                data: filterArrayByArray(state.data, action.items, 'key'),
            }
        case 'TOGGLE_ADD_ROUTE_MODAL':
            return {
                ...state,
                addRouteModal: !state.addRouteModal,
            }
        case 'TOGGLE_CHANGE_PLANT_MODAL':
            return {
                ...state,
                changePlantModal: !state.changePlantModal,
            }
        case 'TOGGLE_CONFIRM_DELETE':
            return { ...state, confirmDeleteModal: !state.confirmDeleteModal }
        case 'SET_SELECTED_ROW_KEYS':
            return {
                ...state,
                selectedRowKeys: [...action.selectedRowKeys],
                selectedRows: [...action.selectedRows],
            }
        default:
            return state
    }
}

const DataTable = ({
    // isOnEditMode,
    setDataTable,
    initialDataTable = [],
    isOnDisplayMode,
    // isScrapType,
    loading,
}) => {
    const [state, dispatch] = useReducer(stateReducer, initialState)

    const rowSelection = () => {
        if (isOnDisplayMode) return null
        return {
            onChange: (selectedRowKeys, selectedRows) => {
                dispatch({ type: 'SET_SELECTED_ROW_KEYS', selectedRowKeys, selectedRows })
            },
            selectedRowKeys: [...state.selectedRowKeys],
        }
    }

    const clearRowSelection = () => {
        dispatch({ type: 'SET_SELECTED_ROW_KEYS', selectedRowKeys: [], selectedRows: [] })
    }

    const doEditItem = (editedItem) => {
        dispatch({ type: 'SET_EDIT_ITEM', item: editedItem })
        clearRowSelection()
        return { success: true }
    }

    const bulkDeleteItem = (itemsArr) => {
        dispatch({ type: 'BULK_DELETE_ITEMS', items: itemsArr })
        clearRowSelection()
        return { success: true }
    }

    // const changePlantForAllItem = (newPlant) => {
    //     dispatch({ type: 'CHANGE_ALL_PLANT', plant: newPlant })
    //     clearRowSelection()
    //     return { success: true }
    // }

    useEffect(() => {
        setDataTable(state.data)
    }, [setDataTable, state.data])

    useEffect(() => {
        if (initialDataTable.length === 0) return dispatch({ type: 'RESET_DATA' })
        dispatch({ type: 'ADD_INITIAL_ITEMS', initialItems: [...initialDataTable] })
    }, [initialDataTable])

    return (
        <>
            <ButtonsGroupGrid align="left" className="mb-3">
                <Button
                    onClick={() => dispatch({ type: 'TOGGLE_ADD_ROUTE_MODAL' })}
                    type="primary"
                    className="w-44 h-45 rounded-10 mr-4"
                    disabled={state?.selectedRows?.length !== 1}
                >
                    Add Route
                </Button>
                <Button
                    onClick={() => dispatch({ type: 'TOGGLE_CHANGE_PLANT_MODAL' })}
                    className="bg-nabati-blue text-white w-157 h-45 rounded-10 mx-2"
                    disabled={state?.selectedRows?.length === 0}
                >
                    Change Plant
                </Button>
                <Button
                    onClick={() => bulkDeleteItem([...state?.selectedRows])}
                    className="outLinedPink w-157 h-45 rounded-10"
                    disabled={state?.selectedRows?.length === 0}
                >
                    Delete Item
                </Button>
            </ButtonsGroupGrid>
            <Table
                loading={loading}
                dataSource={state.data}
                columns={columns()}
                rowClassName={(_, index) =>
                    `customizedPaddingTd customizedSmallFont ${
                        index % 2 === 0 ? 'bg-nabati-lightGray' : ''
                    }`
                }
                pagination={false}
                rowSelection={rowSelection()}
            />
            <AddRouteModal
                payload={state.selectedRows[0]}
                doEditItem={doEditItem}
                visible={state.addRouteModal}
                close={() => dispatch({ type: 'TOGGLE_ADD_ROUTE_MODAL' })}
            />
            <ChangePlantModal
                payload={state.selectedRows[0]}
                doEditItem={doEditItem}
                // changePlantForAllItem={changePlantForAllItem}
                visible={state.changePlantModal}
                close={() => dispatch({ type: 'TOGGLE_CHANGE_PLANT_MODAL' })}
            />
        </>
    )
}

export default DataTable
