import React, { useEffect, useState, useCallback, createContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Col, Divider, Row } from 'antd'

import SearchAndFilterArea from 'components/Common/SearchAndFilterArea'
import SearchQueryInput from 'components/Common/SearchQueryInput'
import SimpleContainer from 'components/layouts/simple'
import FloatingRowSelectionsInfo from './FloatingRowSelectionsInfo'
import SimpleTable from './SimpleTable'
import { addSorterToColumn } from './helper'
import { DEFAULT_PAGE_SIZE } from 'api/axios' // 50
import { removeDuplicateObjectInArray } from 'utils/array'
import { FILTER_BY } from 'config/constant/groupFilter'

const { BY_MATERIAL, BY_COMPANY, BY_PLANT, BY_BUILDING } = FILTER_BY
export const TABLE_TYPE = { RICH: 'rich', BASIC: 'basic' }

export const RichTableContext = createContext()

const RichTable = ({
    type = TABLE_TYPE.RICH, // type : "rich" || "basic"
    showButtonsArea = false,
    showSearch = false,
    withRowSelection = false,
    withFloatingRowSelectionInfo = false, // Floating row selection info at the bottom of the table
    withSearchAndFilterArea = false,
    withDatePicker = false,
    withHeaderTotal = false,
    byId = false,
    filterField = [BY_MATERIAL, BY_COMPANY, BY_PLANT, BY_BUILDING],
    ButtonsAreaComponent,
    RowSelectionComponent,
    getListApi,
    rowKey,
    onChangeSelectedRows = (rowKeys, rows) => {},
    children,
    columns,
    scroll = { x: 1000 },
    ...props
}) => {
    const [data, setData] = useState([])
    const [headerData, setHeaderData] = useState({})
    const [loading, setLoading] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams()

    // Row Selections
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectionStore, setSelectionStore] = useState([])

    const [pageState, setPageState] = useState(() => ({
        defaultCurrent: 1,
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        total: 0,
    }))

    const onPageChange = ({ current }) => {
        // Store selections
        const allSelection = removeDuplicateObjectInArray(
            [...selectionStore, ...selectedRows],
            rowKey
        )
        setSelectionStore(allSelection)
        setSelectedRowKeys(allSelection.map((el) => el[rowKey]))

        setPageState({ ...pageState, current })
        setSearchParams({
            ...Object.fromEntries([...searchParams]),
            page: current,
        })
    }

    const addRowKeyToData = (data) => {
        if (!data) return []
        return data?.map((row, index) => ({ ...row, rowKey: index }))
    }

    const rowSelection = () => {
        if (!withRowSelection) return false
        return {
            onChange: (rowKeys, rows, ...args) => {
                setSelectedRowKeys(rowKeys)
                setSelectedRows(rows)

                onChangeSelectedRows(
                    rowKeys,
                    rows,
                    removeDuplicateObjectInArray([...selectionStore, ...rows], rowKey)
                )
            },
            selectedRowKeys,
        }
    }

    const RowSelectionInfo = () => (
        <div className="flex items-center h-8">
            {selectedRowKeys.length > 0 && (
                <>
                    <p className="mr-5">Selected: {selectedRowKeys.length}</p>
                    {RowSelectionComponent && <RowSelectionComponent />}
                </>
            )}
        </div>
    )

    const resetRowSelections = () => {
        setSelectedRowKeys([])
        setSelectedRows([])
        onChangeSelectedRows([], [])
        setSelectionStore([])
    }

    const isNeedDateQueryWithoutFromTo = useCallback(() => {
        if (!withDatePicker) return false
        const hasFrom = !!searchParams.get('from')
        const hasTo = !!searchParams.get('to')
        const hasFromTo = hasFrom && hasTo

        if (!hasFromTo) return true
        return false
    }, [searchParams, withDatePicker])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                if(!byId){
                    var res = await getListApi(Object.fromEntries([...searchParams]))
                }else{
                    var res = getListApi
                }
                setData(
                    addRowKeyToData(
                        (Array.isArray(res?.data?.data) ? res?.data?.data : false) ||
                            (Array.isArray(res?.result?.data) ? res?.result?.data : false) ||
                            (Array.isArray(res?.data) ? res?.data : false) ||
                            (Array.isArray(res?.result) ? res?.result : false) ||
                            []
                    )
                )
                if(withHeaderTotal){
                    const headerSummary = []
                    headerSummary.push(
                        {'inventory' : res?.sumInventory},
                        {'assign' : res?.sumAssign},
                        {'temp' : res?.sumTemp},
                        {'nopallet' : res?.sumNoPallet},
                        {'totalpallet' : res?.totalPallet}
                    )
                    setHeaderData(headerSummary)
                }
                console.log("headerData")
                console.log(res.result)
                setPageState((prev) => ({
                    ...prev,
                    current: +searchParams.get('page') || 1,
                    pageSize: res?.result?.perPage || DEFAULT_PAGE_SIZE,
                    total: res?.result?.total || res?.count,
                }))
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.error(error)
            }
        }

        if (isNeedDateQueryWithoutFromTo()) return
        fetchData()
    }, [
        getListApi,
        withSearchAndFilterArea,
        withDatePicker,   
        searchParams,
        isNeedDateQueryWithoutFromTo,
    ])

    return (
        <RichTableContext.Provider
            value={{
                resetRowSelections,
                selectedRows: [...selectionStore, ...selectedRows],
                selectedRowKeys,
                data,
                rowKey,
            }}
        >
            <div className="" style={{ position: 'relative' }}>
                {showButtonsArea && (
                    <SimpleContainer>
                        <ButtonsAreaComponent />
                    </SimpleContainer>
                )}

                {withSearchAndFilterArea && <SearchAndFilterArea withDatePicker={withDatePicker} filterField={filterField} />}
                {withHeaderTotal &&
                <Row>
                    <Col span="3" style={{marginRight:10}} ><label>Total pallet</label>: <b>{headerData[4]?.totalpallet}</b></Col>
                    <Col span="3" style={{marginRight:10}} ><label>Pallet Inventory</label>: <b>{headerData[0]?.inventory}</b></Col>
                    <Col span="3" style={{marginRight:10}} ><label>Pallet Assign</label>: <b>{headerData[1]?.assign}</b></Col>
                    <Col span="3" style={{marginRight:10}} ><label>Pallet Temp</label>: <b>{headerData[2]?.temp}</b></Col>
                    <Col span="3" style={{marginRight:10}} ><label>No Pallet</label>: <b>{headerData[3]?.nopallet}</b></Col>
                </Row>
                }

                {type === TABLE_TYPE.RICH && (
                    <SimpleContainer>
                        {!showButtonsArea && !!ButtonsAreaComponent && (
                            <>
                                <ButtonsAreaComponent />
                                <Divider className="my-3" />
                            </>
                        )}

                        <Row
                            justify={`${withRowSelection ? 'space-between' : 'end'}`}
                            align="middle"
                        >
                            {withRowSelection && (
                                <div className="flex items-center h-8">
                                    {selectedRowKeys.length > 0 && (
                                        <>
                                            <p className="mr-5">
                                                Selected: {selectedRowKeys.length}
                                            </p>
                                            {RowSelectionComponent && <RowSelectionComponent />}
                                        </>
                                    )}
                                </div>
                            )}

                            {showSearch && !withSearchAndFilterArea && (
                                <SearchQueryInput className="w-80 mb-3" />
                            )}
                        </Row>
                        <SimpleTable
                            data={data}
                            loading={loading}
                            pagination={{ ...pageState, showSizeChanger: false }}
                            onChange={onPageChange}
                            columns={addSorterToColumn(columns)}
                            rowKey={rowKey ? rowKey : 'rowKey'}
                            scroll={scroll}
                            {...props}
                            rowSelection={rowSelection()}
                        />
                    </SimpleContainer>
                )}

                {type === TABLE_TYPE.BASIC && (
                    <>
                        <Row
                            justify={`${withRowSelection ? 'space-between' : 'end'}`}
                            align="middle"
                        >
                            {withRowSelection && <RowSelectionInfo />}
                        </Row>
                        <SimpleTable
                            data={data}
                            loading={loading}
                            pagination={{ ...pageState, showSizeChanger: false }}
                            onChangePage={onPageChange}
                            columns={addSorterToColumn(columns)}
                            rowKey={rowKey ? rowKey : 'rowKey'}
                            scroll={scroll}
                            {...props}
                            rowSelection={rowSelection()}
                        />
                    </>
                )}

                {withFloatingRowSelectionInfo && (
                    <FloatingRowSelectionsInfo
                        selectedRows={removeDuplicateObjectInArray(
                            [...selectionStore, ...selectedRows],
                            rowKey
                        )}
                        resetRowSelections={resetRowSelections}
                        rowKey={rowKey}
                    />
                )}
                <>{children}</>
            </div>
        </RichTableContext.Provider>
    )
}

export default RichTable
