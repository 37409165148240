import React from 'react'

export const columns = [
    {
        title: 'Pallet ID',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Quantity',
        dataIndex: 'qty',
        key: 'qty',
        align: 'center',
    },
    // {
    //     title: 'Available Quantity',
    //     dataIndex: '-',
    //     key: '-',
    //     align: 'center',
    // },
    {
        title: 'Status Description',
        dataIndex: 'statusDescription',
        key: 'statusDescription',
    },
    {
        title: 'Over Qty',
        dataIndex: 'overQty',
        key: 'overQty',
        align: 'center',
        render: (text, record) => {
            const isOverQuantity = +text > 0
            if (isOverQuantity) return <p className="text-red-600">{text}</p>
            return <p>{text}</p>
        },
    },
]
