import { Row, Col } from 'antd'
import { DeleteIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const dcLocationPlantColumns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
                {/* <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col> */}
            </Row>
        ),
    },
    {
        title: 'Dc Location',
        dataIndex: 'dcLocationId',
        key: 'dcLocationId',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Factory Plant',
        dataIndex: 'factoryPlant',
        key: 'factoryPlant',
    },
]
