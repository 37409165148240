import React from 'react'
import SelectDebounceFetch from './index'
import { getDockingList } from 'api/master/docking'

function SelectDocking({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Docking"
            fetchOptions={async (searchTerm) => {
                const res = await getDockingList({ filter: searchTerm, total: 10 })
                const list = res?.result?.data.map((docking) => ({
                    label: docking.dockingId,
                    value: docking.dockingId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectDocking
