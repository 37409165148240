import { Tag } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";
import { toTitleCase } from 'utils/formatter'
import { indonesianNumber } from 'utils/formatter'
import { SET_PRIORITY_COLOR } from 'config/constant'

export const columns = () => [
    {
        title: '',
        dataIndex: 'statusRespons',
        key: 'statusRespons',
        render: (text) => (
            <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: SET_PRIORITY_COLOR[text] }}
            />
        ),
    },
    {
        title: 'Material No',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Material Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'From',
        dataIndex: 'from',
        key: 'from',
        render: (text) => <>{toTitleCase(text)}</>,
    },
    {
        title: 'BIN Priority',
        dataIndex: 'priorityId',
        key: 'priorityId',
    },
    {
        title: 'From Plant',
        dataIndex: 'fromPlant',
        key: 'fromPlant',
    },
    {
        title: 'Qty Priority',
        dataIndex: 'qtyPriority',
        key: 'qtyPriority',
        render: (text) => <>{indonesianNumber(text)}</>,
    },
    {
        title: 'Qty Picked',
        dataIndex: 'qtyPicked',
        key: 'qtyPicked',
        render: (text) => <>{indonesianNumber(text)}</>,
    },
    {
        title: '+-',
        dataIndex: 'qtyAdjust',
        key: 'qtyAdjust',
        render: (text, record) => <>{indonesianNumber(record.qtyPriority - record.qtyPicked)}</>,
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
            const color = {
                draft: '#E5E5E5',
                ok: 'green',
                'not fulfill': 'yellow',
            }

            const textColor = {
                draft: '#666666',
                ok: '#01874E',
                'not fulfill': '#d4b106',
            }

            return (
                <Tag
                    color={color[text.toLowerCase() || 'red']}
                    style={{ color: textColor[text.toLowerCase() || '#ED1C24'] }}
                    className="py-1 px-3 rounded-lg"
                >
                    {text}
                </Tag>
            )
        },
    },
    {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
    },
]
