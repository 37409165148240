import React from 'react'

const COLOR_BY_LEVEL = {
    1: '#000000',
    2: '#EE028C',
    3: '#00C572',
    4: '#FFB400',
    5: '#2BBECB',
}
function SingleQrBox({ singleQrObj }) {
    const { qr, beam, subBeam, level, binNo } = singleQrObj
    const COLOR = COLOR_BY_LEVEL[level]
    return (
        <div
            style={{
                width: '37.5mm',
                height: '50mm',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '1rem',
            }}
        >
            <img
                style={{
                    width: '30mm',
                    textAlign: 'center',
                    marginBottom: '0.75rem',
                }}
                alt="Bin"
                src={`data:image/svg+xml;base64,${qr}`}
            />
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '4fr 5fr 6fr 7fr',
                    borderBottomWidth: 2,
                    padding: 0,
                    width: '100%',
                    borderColor: COLOR,
                }}
            >
                <div style={{ width: '100%' }}>
                    <p
                        style={{
                            textAlign: 'center',
                            fontWeight: 800,
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                        }}
                    >
                        {binNo}
                    </p>
                    <p style={{ fontSize: 10, textAlign: 'center' }}>BIN</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p
                        style={{
                            textAlign: 'center',
                            fontWeight: 800,
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                        }}
                    >
                        {beam}
                    </p>
                    <p style={{ fontSize: 10, textAlign: 'center' }}>BARIS</p>
                </div>
                <div
                    style={{
                        backgroundColor: COLOR,
                        width: '100%',
                    }}
                >
                    <p
                        style={{
                            textAlign: 'center',
                            fontWeight: 800,
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                            color: 'white',
                        }}
                    >
                        {level}
                    </p>
                    <p style={{ fontSize: 10, textAlign: 'center', color: 'white' }}>LEVEL</p>
                </div>
                <div
                    style={{
                        backgroundColor: COLOR,
                        width: '100%',
                    }}
                >
                    <p
                        style={{
                            textAlign: 'center',
                            fontWeight: 800,
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                            color: 'white',
                        }}
                    >
                        {subBeam}
                    </p>
                    <p style={{ fontSize: 10, textAlign: 'center', color: 'white' }}>SUB BIN</p>
                </div>
            </div>
        </div>
    )
}

export function SingleQrBoxToPrint({ singleQrObj }) {
    const { qr, beam, subBeam, level, binNo } = singleQrObj
    const COLOR = COLOR_BY_LEVEL[level]
    return (
        <div
            style={{
                width: '35mm',
                height: '50mm',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingTop: '1rem',
                // border: `2px solid ${COLOR}`,
                borderBottom: `2px solid ${COLOR}`,
            }}
        >
            <img
                style={{
                    width: '30mm',
                    textAlign: 'center',
                    marginBottom: '0.5rem',
                }}
                alt="Bin"
                src={`data:image/svg+xml;base64,${qr}`}
            />
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '4fr 5fr 6fr 7fr',
                    padding: 0,
                    width: '100%',
                }}
            >
                <div style={{ width: '100%' }}>
                    <p
                        style={{
                            textAlign: 'center',
                            fontWeight: 800,
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                            paddingTop: 4,
                        }}
                    >
                        {binNo}
                    </p>
                    <p
                        style={{
                            fontSize: 10,
                            textAlign: 'center',
                            paddingBottom: 4,
                        }}
                    >
                        BIN
                    </p>
                </div>
                <div style={{ width: '100%' }}>
                    <p
                        style={{
                            textAlign: 'center',
                            fontWeight: 800,
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                            paddingTop: 4,
                        }}
                    >
                        {beam}
                    </p>
                    <p
                        style={{
                            fontSize: 10,
                            textAlign: 'center',
                            paddingBottom: 4,
                        }}
                    >
                        BARIS
                    </p>
                </div>
                <div
                    style={{
                        backgroundColor: COLOR,
                        width: '100%',
                    }}
                >
                    <p
                        style={{
                            textAlign: 'center',
                            fontWeight: 800,
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                            color: 'white',
                            paddingTop: 4,
                        }}
                    >
                        {level}
                    </p>
                    <p
                        style={{
                            fontSize: 10,
                            textAlign: 'center',
                            color: 'white',
                            paddingBottom: 4,
                        }}
                    >
                        LEVEL
                    </p>
                </div>
                <div
                    style={{
                        backgroundColor: COLOR,
                        width: '100%',
                    }}
                >
                    <p
                        style={{
                            textAlign: 'center',
                            fontWeight: 800,
                            fontSize: '1.25rem',
                            lineHeight: '1.75rem',
                            color: 'white',
                            paddingTop: 4,
                        }}
                    >
                        {subBeam}
                    </p>
                    <p
                        style={{
                            fontSize: 10,
                            textAlign: 'center',
                            color: 'white',
                            paddingBottom: 4,
                        }}
                    >
                        SUB BIN
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SingleQrBox
