import { Row, Col } from 'antd'
import { DeleteIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const spvColumns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
                {/* <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col> */}
            </Row>
        ),
    },
    {
        title: 'DC Location',
        dataIndex: 'dcLocation',
        key: 'dcLocation',
    },
    {
        title: 'Spv User ID',
        dataIndex: 'spvId',
        key: 'spvId',
    },
    {
        title: 'Foreman User ID',
        dataIndex: 'foremanId',
        key: 'foremanId',
    },
    {
        title: 'Team User ID',
        dataIndex: 'teamId',
        key: 'teamId',
    },
]
