import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'
import XlsxUploader from 'components/Common/Uploader/XlsxUploader'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddEditModal from './modals/add-edit-form'

import {
    getDcCapacityList,
    deleteDcCapacity,
    createNewDcCapacity,
    updateDcCapacity,
    downloadDcCapacityTemplate,
    uploadDcCapacityTemplate,
} from 'api/master/dashboardDcCapacity'
import { DownloadIc } from 'assets'

import { columns } from './columns'

function DashboardProductionShift() {
    const [showUpdateModal, setUpdateModal] = useState(false)
    const [showAddModal, setAddModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [selectedRows, setSelectedRows] = useState([])
    const { doRefresh } = useQueryRefresh()

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = selectedRows.map((row) => ({
                    dcLocation: row.dcLocation,
                    plantId: row.plantId,
                    slocId: row.slocId,
                    capacity: row.capacity,
                }))
                try {
                    await Promise.all(reqBody.map(async (row) => await deleteDcCapacity(row)))
                    doRefresh()
                } catch (error) {
                    console.error(error)
                }
            },
        })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete All" onClick={handleBulkDelete} />
    )

    const handleDownload = async () => {
        await downloadDcCapacityTemplate()
    }

    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle "
            >
                Add
            </Button>
            <Button
                onClick={handleDownload}
                className="outLinedBlue flex justify-center items-center w-48 h-45 rounded-10 mx-1"
                size="middle "
            >
                <DownloadIc />
                <span className="ml-2">Download Template</span>
            </Button>
            <XlsxUploader callApi={uploadDcCapacityTemplate} />
        </Row>
    )

    return (
        <>
            <RichTable
                columns={columns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                withSearchAndFilterArea={false}
                ButtonsAreaComponent={buttons}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getDcCapacityList}
                type="rich"
            />
            <AddEditModal
                title="Add Dc Capacity"
                close={() => setAddModal(false)}
                visible={showAddModal}
                payload={null}
                successMsg="Success add Dc Capacity"
                callApi={createNewDcCapacity}
            />
            <AddEditModal
                title="Edit Dc Capacity"
                close={() => setUpdateModal(false)}
                visible={showUpdateModal}
                payload={modalFormPayload}
                successMsg="Success edit Dc Capacity"
                callApi={updateDcCapacity}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default DashboardProductionShift
const a = [
    {
        documentNumber: '',
        success: false,
        message: 'DO Transaction has been Posted',
        sapSuccess: null,
        sapMessage: null,
        documentYear: 0,
    },
    {
        documentNumber: '3000000111',
        success: true,
        message: 'Delivery has been saved',
        sapSuccess: null,
        sapMessage: null,
        logs: [],
        documentYear: 2022,
    },
]
