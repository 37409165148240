import React from 'react'
import CardDatePicker from 'components/Common/CardDatePicker'

// CHART COMPONENT
import ChartProductivityOutbound from './charts/chart-productivity-outbound'
import ChartDurationSpv from './charts/chart-duration-spv'

function SectionThree() {
    return (
        <CardDatePicker className="mt-5">
            <ChartProductivityOutbound />
            <ChartDurationSpv />
        </CardDatePicker>
    )
}

export default SectionThree
