import React, { useContext, useEffect, useCallback, useState } from 'react'
import { Button } from 'antd'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import DatePickerQueryInput from 'components/Common/DatePickerQueryInput'

import RichTable, { TABLE_TYPE } from 'components/Common/RichTable'
import { columns } from './columns'
import { SimpleTabsContext } from 'components/Common/SimpleTabs'

import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'
import EditPoStoDoModal from '../list-outstanding-material/modals/create-po-sto-do'

import useQueryRefresh from 'customHooks/useQueryRefresh'

import {
    getPoStoList,
    deletePoStoSap,
    createPoStoSapFromDraft,
} from 'api/transaction/plannerFisikWms'

function ListPoStoDo() {
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showSubmitModal, setShowSubmitModal] = useState(false)
    const [showEditPoStoModal, setShowEditModal] = useState(false)
    const [rowPayload, setRowPayload] = useState(null)
    const [selectedRows, setSelectedRows] = useState([])
    const { doRefresh } = useQueryRefresh()

    const { setButtonsHeader } = useContext(SimpleTabsContext)

    const onCLickDeleteIcon = (row) => {
        setRowPayload(row)
        setShowDeleteModal(true)
    }

    const onCLickEditIcon = (row) => {
        setRowPayload(row)
        setShowEditModal(true)
    }

    const onChangeSelectedRows = (rowKeys, rows) => {
        setSelectedRows(rows)
    }

    const handleDelete = async () => {
        const reqBody = { transactionId: rowPayload.transactionId }
        try {
            const res = await deletePoStoSap(reqBody)
            if (!res.success) return res
            doRefresh()
            setRowPayload(null)
            return res
        } catch (error) {
            console.error(error)
            setRowPayload(null)
            return { success: false }
        }
    }

    const handleSubmit = async () => {
        const reqBody = { transactionId: selectedRows[0].transactionId }
        try {
            const res = await createPoStoSapFromDraft(reqBody)
            if (!res.success) return res
            doRefresh()
            setRowPayload(null)
            return res
        } catch (error) {
            console.error(error)
            return { success: false }
        }
    }

    const HeaderButtons = useCallback(
        () => (
            <ButtonsGroupGrid>
                <Button
                    onClick={() => setShowSubmitModal(true)}
                    disabled={selectedRows.length !== 1}
                    type="primary"
                    className="w-157 h-45 rounded-10 mx-2"
                >
                    Submit
                </Button>
                {/* <Button onClick={() => {}} className="outLinedPink w-157 h-45 rounded-10 mx-2">
                    Edit
                </Button>
                <Button onClick={() => {}} className="outLinedPink w-157 h-45 rounded-10 mx-2">
                    Delete
                </Button> */}
            </ButtonsGroupGrid>
        ),
        [selectedRows.length]
    )

    useEffect(() => {
        setButtonsHeader(HeaderButtons)
    }, [HeaderButtons, setButtonsHeader])
    return (
        <>
            <div className="flex mt-3">
                <DatePickerQueryInput containerClassName="ml-auto" />
            </div>
            <RichTable
                columns={columns(onCLickEditIcon, onCLickDeleteIcon)}
                withRowSelection={true}
                withDatePicker={false}
                onChangeSelectedRows={onChangeSelectedRows}
                withSearchAndFilterArea={false}
                getListApi={getPoStoList}
                type={TABLE_TYPE.BASIC}
                scroll={{ x: 1300 }}
            >
                <EditPoStoDoModal
                    visible={showEditPoStoModal}
                    close={() => setShowEditModal(false)}
                    payload={rowPayload}
                    isEditMode
                />
                <ConfirmationModal
                    title="Are you sure you want to delete?"
                    content={`This will permanently delete. Action can not be undone.`}
                    successTitleMsg="Delete success!"
                    onOk={handleDelete}
                    centered={false}
                    okText="Delete"
                    visible={showDeleteModal}
                    close={() => setShowDeleteModal(false)}
                />
                <ConfirmationModal
                    visible={showSubmitModal}
                    title="Are you sure you want to Submit?"
                    content={`Transaction ID: ${selectedRows[0]?.transactionId}`}
                    successTitleMsg="Submit success!"
                    successContentMsg={(res) => (
                        <>
                            <p>Transaction ID: {res.data.transactionId}</p>
                            <p>PO Number: {res.poNumber}</p>
                            <p>DO Number: {res.doNumber}</p>
                        </>
                    )}
                    onOk={handleSubmit}
                    centered={false}
                    okText="Submit"
                    close={() => setShowSubmitModal(false)}
                />
            </RichTable>
        </>
    )
}

export default ListPoStoDo
