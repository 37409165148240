import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewMoveReason } from 'api/master/moveReason'
import { auth } from 'auth'

function AddMoveReasonModal({ close, visible }) {
    const user = auth.getUserInfo()
    const modifiedPayload = {
        createdBy: user?.userName || '',
    }

    return (
        <ModalForm
            title="Add Move Reason"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={createNewMoveReason}
            successMsg={(values) => `New Move Reason was created`}
            close={close}
            visible={visible}
        />
    )
}

export default AddMoveReasonModal
