import React, { useContext, useState, useEffect, useMemo } from 'react'
import BarChart from 'components/Common/Chart/BarChart'

import { getCheckerDurationProductivity } from 'api/dashboard/inbound'

// Context
import { CardDatePickerContext } from 'components/Common/CardDatePicker'
import { DashboardInboundContext } from '../../index'

import { options } from './options'

function ChartCheckerInbound() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const { dateRange } = useContext(CardDatePickerContext)
    const { dcLocation } = useContext(DashboardInboundContext)

    // Data and Labels
    const labels = useMemo(() => Object.keys(data || {}), [data])
    const datasets = [{ data: [...Object.values(data || {})] }]

    useEffect(() => {
        if (!dcLocation) return
        const { startDate, endDate } = dateRange
        const reqBody = {
            startDate,
            endDate,
            dcLocationId: [dcLocation?.value],
        }
        const fetchData = async () => {
            setLoading(true)
            try {
                const res = await getCheckerDurationProductivity(reqBody)
                setData(res?.data?.checkerInboundDuration || {})
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }

        fetchData()
    }, [dateRange, dcLocation])

    return (
        <BarChart loading={loading} options={options()} datasets={datasets} labels={[...labels]} />
    )
}

export default ChartCheckerInbound
