import React from 'react'
import SelectDebounceFetch from './index'
import { getBinList } from 'api/master/bin'
import { List, Select } from 'antd'
const data = [
    {
        value: '1',
        label: "Y"
    },
    {
        value: '0',
        label: "N"
    },
   
]
function SelectIsDraftStatus({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Draft Status"
            fetchOptions={async (searchTerm) => {
                const list = data
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectIsDraftStatus
