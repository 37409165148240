export const columns = () => [
    { title: 'Pallet ID', dataIndex: 'palletId', key: 'palletId' },
    {
        title: 'Pallet Code',
        dataIndex: 'palletCode',
        key: 'palletCode',
    },
    {
        title: 'batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'Uom',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
            return <>{record?.status || record.stockStatus || '-'}</>
        },
    },
]
