import React from 'react'
import SelectDebounceFetch from './index'
import { getBinList } from 'api/master/bin'

function SelectBin({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="BIN"
            fetchOptions={async (searchTerm) => {
                const res = await getBinList({ filter: searchTerm, total: 10 })
                const list = res?.result?.data.map((bin) => ({
                    label: bin.binId,
                    value: bin.binId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectBin
