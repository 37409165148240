import React, { useEffect, useReducer } from 'react'
import { Table, Button } from 'antd'

import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import AddGlCcModal from '../add-gl-cost-center'

import { filterArrayByArray } from 'utils/array'
import { columns } from './column'

const initialState = {
    data: [],
    selectedRowKeys: [],
    selectedRows: [],
    addGlCcModal: false,
    confirmDeleteModal: false,
    rowPayload: null,
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_INITIAL_ITEMS':
            return {
                ...state,
                data: [...action.initialItems],
            }
        case 'RESET_DATA':
            return {
                ...state,
                data: [],
            }
        case 'SET_EDIT_ITEM':
            return {
                ...state,
                data: state.data.map((el, index) => {
                    const itemIndex = state.data.findIndex((p) => p.key === action.item.key)
                    if (index === itemIndex) return action.item
                    return el
                }),
            }
        case 'DELETE_ITEM':
            return {
                ...state,
                data: state.data.filter((el) => el.key !== action.item.key),
            }
        case 'BULK_DELETE_ITEMS':
            return {
                ...state,
                data: filterArrayByArray(state.data, action.items, 'key'),
            }
        case 'TOGGLE_ADD_GL_CC_MODAL':
            return {
                ...state,
                addGlCcModal: !state.addGlCcModal,
            }
        case 'TOGGLE_CONFIRM_DELETE':
            return { ...state, confirmDeleteModal: !state.confirmDeleteModal }
        case 'SET_SELECTED_ROW_KEYS':
            return {
                ...state,
                selectedRowKeys: [...action.selectedRowKeys],
                selectedRows: [...action.selectedRows],
            }
        default:
            return state
    }
}

const DataTable = ({
    isOnEditMode,
    setDataTable,
    initialDataTable = [],
    isOnDisplayMode,
    loading,
}) => {
    const [state, dispatch] = useReducer(stateReducer, initialState)

    const rowSelection = () => {
        if (isOnDisplayMode) return null
        return {
            onChange: (selectedRowKeys, selectedRows) => {
                dispatch({ type: 'SET_SELECTED_ROW_KEYS', selectedRowKeys, selectedRows })
            },
            selectedRowKeys: [...state.selectedRowKeys],
        }
    }

    const clearRowSelection = () => {
        dispatch({ type: 'SET_SELECTED_ROW_KEYS', selectedRowKeys: [], selectedRows: [] })
    }

    const doEditItem = (editedItem) => {
        dispatch({ type: 'SET_EDIT_ITEM', item: editedItem })
        clearRowSelection()
        return { success: true }
    }

    const ButtonGroupBottom = (
        <ButtonsGroupGrid align="left" className="mb-3">
            <Button
                onClick={() => dispatch({ type: 'TOGGLE_ADD_GL_CC_MODAL' })}
                type="primary"
                className="w-44 h-45 rounded-10 mx-2"
                disabled={state?.selectedRows?.length !== 1}
            >
                Add GL & Cost Center
            </Button>
            {/* <Button
                onClick={() => {}}
                className="outLinedPink w-157 h-45 rounded-10 mx-2 ml-4"
                disabled={state?.selectedRows?.length === 0}
            >
                Delete Item
            </Button> */}
        </ButtonsGroupGrid>
    )

    useEffect(() => {
        setDataTable(state.data)
    }, [setDataTable, state.data])

    useEffect(() => {
        if (initialDataTable.length === 0) return dispatch({ type: 'RESET_DATA' })
        dispatch({ type: 'ADD_INITIAL_ITEMS', initialItems: [...initialDataTable] })
    }, [initialDataTable])

    return (
        <>
            {ButtonGroupBottom}
            <Table
                loading={loading}
                dataSource={state.data}
                columns={columns()}
                rowClassName={(_, index) =>
                    `customizedPaddingTd customizedSmallFont ${
                        index % 2 === 0 ? 'bg-nabati-lightGray' : ''
                    }`
                }
                pagination={false}
                rowSelection={rowSelection()}
            />
            <AddGlCcModal
                plant={state?.selectedRows[0]?.plant}
                payload={state.selectedRows[0]}
                doEditItem={doEditItem}
                visible={state.addGlCcModal}
                close={() => dispatch({ type: 'TOGGLE_ADD_GL_CC_MODAL' })}
            />
        </>
    )
}

export default DataTable
