import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'
import { options } from '../options'

export const getSetPriorityList = async (params) => {
    const response = await call(METHODS.GET, '/planner-priority', params, options)
    return response.data
}

export const getDeficitStock = async (params) => {
    const response = await call(METHODS.POST, '/planner-priority-deficit-stock', params, options)
    return response.data
}

export const getDeficitStockBooking = async (params) => {
    const response = await call(
        METHODS.POST,
        '/planner-priority-deficit-stock-booking',
        params,
        options
    )
    return response.data
}

export const setPriority = async (params) => {
    const response = await call(METHODS.POST, '/planner-priority', params, options)
    return response.data
}

export const updateStatusPlannerPriority = async (params) => {
    const response = await call(METHODS.POST, '/planner-priority-update-status', params, options)
    return response.data
}
