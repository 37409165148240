import React, { useEffect, useReducer } from 'react'
import { Table, Row, Col, Modal, Button, Input } from 'antd'
import moment from 'moment'

import { SearchOutlined } from '@ant-design/icons'

import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

// import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import { columns } from './columns'
import { addRowKeyToData } from 'utils/misc'

import {
    getListBooking,
    createDoExecutionWo,
    findDoWo,
    changeDoExecutionWo,
    // deleteDoExecutionWo,
    // doPlannerCheckIn,
} from 'api/transport/do-execution-wo-checkin'

const initialState = {
    submitDoWoModal: false,
    editDoWoModal: false,
    selectedRows: [],
    selectedRowKeys: [],
    loading: false,
    data: [],
    dataToShow: [],
    searchTerm: '',
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'SET_SELECTED_ROWS':
            return { ...state, selectedRows: [...action.selectedRows] }
        case 'SET_SELECTED_ROW_KEYS':
            return { ...state, selectedRowKeys: [...action.selectedRowKeys] }
        case 'TOGGLE_CONFIRMATION_SUBMIT':
            return { ...state, submitDoWoModal: action.visible }
        case 'TOGGLE_CONFIRMATION_EDIT':
            return { ...state, editDoWoModal: action.visible }
        case 'SET_SEARCH_TERM':
            return { ...state, searchTerm: action.searchTerm }
        case 'DELETE_ROW':
            let newData = state.data
            newData[action.rowIndex] = { ...newData[action.rowIndex], flag: 'D' }
            return { ...state, data: newData }
        case 'UNDO_DELETE_ROW':
            let newData2 = state.data
            newData2[action.rowIndex] = { ...newData[action.rowIndex], flag: '-' }
            return { ...state, data: newData2 }
        case 'SET_DATA':
            return { ...state, data: action.data }
        case 'SET_DATA_TO_SHOW':
            return {
                ...state,
                dataToShow: state?.data?.filter(
                    (dt) =>
                        dt.bookingId.toLowerCase().includes(state.searchTerm) ||
                        dt.forwarderId.toLowerCase().includes(state.searchTerm) ||
                        dt.forwarderName.toLowerCase().includes(state.searchTerm) ||
                        dt.customerName.toLowerCase().includes(state.searchTerm) ||
                        dt.customerId.toLowerCase().includes(state.searchTerm)
                ),
            }
        case 'RESET':
            return initialState
        default:
            return state
    }
}

const CreateDoExecutionWoCheckin = ({
    visible,
    isDisplayMode,
    isEditMode,
    payload,
    close,
    ...props
}) => {
    const [state, dispatch] = useReducer(stateReducer, initialState)
    const { doRefresh } = useQueryRefresh()

    const rowSelection = () => {
        if (isEditMode) return false
        if (isDisplayMode) return false
        return {
            onChange: (selectedRowKeys, selectedRows) => {
                dispatch({ type: 'SET_SELECTED_ROW_KEYS', selectedRowKeys })
                dispatch({ type: 'SET_SELECTED_ROWS', selectedRows })
            },
            selectedRowKeys: state.selectedRowKeys,
        }
    }

    const onClickDeleteIcon = (row) => {
        const selectedIndex = state.data.findIndex(
            (dt) =>
                dt.doNumber === row.doNumber &&
                dt.bookingId === row.bookingId &&
                dt.shipmentNo === row.shipmentNo
        )
        const deleteFlag = !state?.data[selectedIndex]?.flag ? 'D' : ''
        dispatch({
            type: 'SET_DATA',
            data: state.data.map((dt, index) => ({
                ...dt,
                flag: index === selectedIndex ? deleteFlag : dt.flag || '',
            })),
        })
        dispatch({ type: 'SET_DATA_TO_SHOW' })
    }

    const handleSearch = (e) => {
        dispatch({
            type: 'SET_SEARCH_TERM',
            searchTerm: e.target.value.toLowerCase(),
        })
        dispatch({ type: 'SET_DATA_TO_SHOW' })
    }

    const onClickSave = () => {
        if (state.selectedRows.length === 0) {
            return Modal.error({
                title: 'Oops',
                content: 'Please select at least one row',
            })
        }

        dispatch({ type: 'TOGGLE_CONFIRMATION_SUBMIT', visible: true })
    }

    const onClickEdit = () => {
        dispatch({ type: 'TOGGLE_CONFIRMATION_EDIT', visible: true })
    }

    const handleSubmit = async () => {
        const reqBody = state.selectedRows.map((dt) => ({
            bookingId: dt.bookingId || '',
            deliveryNumber: dt.doNumber || '', // deliveryNumber dan shipmentNo sama???
            forwarderId: dt.forwarderId || '',
            forwarderName: dt.forwarderName || '',
            plannedDlvDate: moment().format('YYYYMMDD'), // datanya mau diisi apa?
            customerId: dt.customerId || '',
            customerName: dt.customerName || '',
            shipmentNo: dt.shipmentNo || '', // Kalau dari BE belum dikirim shipment no gimana???
        }))

        try {
            const res = await createDoExecutionWo(reqBody)
            if (!res.success) return res
            dispatch({ type: 'RESET' })
            close()
            doRefresh()
            return res
        } catch (error) {
            console.error(error)
            return { success: false }
        }
    }

    const handleEdit = async () => {
        const reqBody = state.data
            .filter((dt) => dt.flag === 'D')
            .map((dt) => ({
                bookingId: dt.bookingId || '',
                deliveryNumber: dt.doNumber || '', // deliveryNumber dan shipmentNo sama???
                forwarderId: dt.forwarderId || '',
                forwarderName: dt.forwarderName || '',
                plannedDlvDate: moment().format('YYYYMMDD'), // datanya mau diisi apa?
                customerId: dt.customerId || '',
                customerName: dt.customerName || '',
                flag: dt.flag || '',
                shipmentNo: dt.shipmentNo || '', // Kalau dari BE belum dikirim shipment no gimana???
            }))

        if (!payload?.transactionId) {
            Modal.error({
                title: 'Oops',
                content: 'This transaction dont have transaction ID',
            })
            dispatch({ type: 'RESET' })
            return { success: false }
        }

        try {
            const res = await changeDoExecutionWo(reqBody, payload?.transactionId)
            if (!res.success) return res
            dispatch({ type: 'RESET' })
            close()
            doRefresh()
            return res
        } catch (error) {
            console.error(error)
            return { success: false }
        }
    }

    useEffect(() => {
        if (!visible) return

        const findWoDoByTransactionId = async () => {
            const res = await findDoWo({ id: payload?.transactionId })
            return res
        }

        const callApi = payload?.transactionId ? findWoDoByTransactionId : getListBooking
        dispatch({ type: 'SET_LOADING', loading: true })
        const fetchData = async () => {
            const { data } = await callApi()
            dispatch({ type: 'SET_DATA', data })
            dispatch({ type: 'SET_DATA_TO_SHOW' })
            dispatch({ type: 'SET_LOADING', loading: false })
        }

        fetchData()
    }, [payload?.transactionId, visible])

    return (
        <>
            <SimpleModal
                title={`${isEditMode ? 'Edit' : 'Create'} DO Execution w/o Check-IN`}
                footer={false}
                onOk={() => {}}
                width={1000}
                visible={visible}
                {...props}
            >
                <Row gutter={20}>
                    <Col span={12}>
                        <Input
                            className="rounded-10 h-50 mt-2 mb-3"
                            size="large"
                            placeholder="Search"
                            suffix={<SearchOutlined className="text-nabati-blue" />}
                            onChange={handleSearch}
                            allowClear
                        />
                    </Col>
                </Row>
                <div className="max-h-96 overflow-y-scroll">
                    <Table
                        loading={state.loading}
                        dataSource={addRowKeyToData(state?.dataToShow || [])}
                        columns={columns(isDisplayMode, isEditMode, onClickDeleteIcon)}
                        rowClassName={(_, index) =>
                            `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                        }
                        pagination={false}
                        rowSelection={rowSelection()}
                        rowKey="rowKey"
                    />
                </div>
                <div className="flex justify-end items-center">
                    <Button
                        className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                        style={{ width: 197, height: 45 }}
                        onClick={() => {
                            close()
                            dispatch({ type: 'RESET' })
                        }}
                        type="ghost"
                    >
                        {isDisplayMode ? 'Close' : 'Cancel'}
                    </Button>
                    {!isDisplayMode && !isEditMode && (
                        <Button
                            className="rounded-10 mx-1 mt-5"
                            style={{ width: 197, height: 45 }}
                            onClick={onClickSave}
                            type="primary"
                            loading={state.loading}
                        >
                            Save
                        </Button>
                    )}
                    {isEditMode && (
                        <Button
                            className="rounded-10 mx-1 mt-5"
                            style={{ width: 197, height: 45 }}
                            onClick={onClickEdit}
                            type="primary"
                            loading={state.loading}
                        >
                            Edit
                        </Button>
                    )}
                </div>
            </SimpleModal>
            <ConfirmationModal
                visible={state.submitDoWoModal}
                title="Create DO Execution w/o Check-IN?"
                content={state.selectedRows
                    .map((row) => row.bookingId)
                    .reduce(
                        (curr, node) => (
                            <>
                                {curr} <br />
                                {`• ${node}`}
                            </>
                        ),
                        <>Transaction/Booking IDs:</>
                    )}
                successTitleMsg="Submit Success!"
                close={() => dispatch({ type: 'TOGGLE_CONFIRMATION_SUBMIT', visible: false })}
                onOk={handleSubmit}
                centered={false}
                okText="Submit"
            />
            <ConfirmationModal
                visible={state.editDoWoModal}
                title="Edit DO Execution w/o Check-IN?"
                successTitleMsg="Data Edited!"
                close={() => dispatch({ type: 'TOGGLE_CONFIRMATION_EDIT', visible: false })}
                onOk={handleEdit}
                centered={false}
                okText="Edit"
            />
        </>
    )
}

export default CreateDoExecutionWoCheckin
