import React from 'react'

const StyledSubMenuList = ({ onFloatingMenu, ...props }) => {
    return (
        <div
            {...props}
            className={`${
                onFloatingMenu ? 'w-72' : 'ml-4 w-auto'
            } my-1 p-2 text-white flex items-center rounded-5 cursor-pointer transition-all ease-in-out duration-600 hover:bg-nabati-pink ${
                props.className
            }`}
        />
    )
}

function SingleSubMenu({ onFloatingMenu, parentMenu, onClickSubMenu, subPath }) {
    return (
        <div
            style={{ top: 0 }}
            className={` ${
                onFloatingMenu ? 'p-2 pt-10 h-screen overflow-y-scroll' : ''
            } bg-nabati-blue`}
        >
            <div className="p-2 fixed top-0 w-72 bg-nabati-blue uppercase text-white font-bold text-center">
                {parentMenu.title}
            </div>
            {parentMenu?.submenu?.map((submenu) => {
                const isActiveSubMenu = submenu.path === subPath
                return (
                    <StyledSubMenuList
                        onFloatingMenu={onFloatingMenu}
                        onClick={() => onClickSubMenu(submenu, parentMenu)}
                        type="text"
                        className={`${isActiveSubMenu ? ' bg-nabati-pink' : ''} `}
                        key={submenu.path}
                    >
                        <span className="ml-3">{submenu.icon}</span>
                        <span className="ml-2">{submenu.title}</span>
                    </StyledSubMenuList>
                )
            })}
        </div>
    )
}

export default SingleSubMenu
