import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getBackgroundMonitoring = async (params) => {
    const response = await call(METHODS.GET, '/utility/backgroundmonitoring', params)
    return response.data
}

export const getBackgroundMonitoringById = async (params) => {
    const response = await call(METHODS.POST, '/utility/backgroundmonitoring/show', params)
    return response.data
}

export const updateBackgroundMonitoring = async (data) => {
    const url = `/utility/backgroundmonitoring/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const reloadManualSap = async (data) => {
    const url = `/utility/backgroundmonitoring/manualsap`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deleteBackgroundMonitoring = async (data) => {
    const url = `/utility/backgroundmonitoring/delete`
    const response = await call(METHODS.DELETE, url, data)
    return response.data
}
