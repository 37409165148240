import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewDcLocationPlant } from 'api/master/dcLocationPlant'
import { auth } from 'auth'

function AddDcLocationPlantModal({ close, visible }) {
    const user = auth.getUserInfo()
    const modifiedPayload = {
        createBy: user?.userName || 'BCO_MJK2', // delete me! ["BCO_MJK2"]
    }

    return (
        <ModalForm
            title="Add Dc Location Id"
            initialValues={modifiedPayload}
            formItems={formItems}
            callApi={createNewDcLocationPlant}
            successMsg={(values) => `New Dc Location Id ${values.dcLocationId.value} was created`}
            close={close}
            visible={visible}
        />
    )
}

export default AddDcLocationPlantModal
