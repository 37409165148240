export const PRIMARY_BLUE = '#2BBECB'
export const PRIMARY_PINK = '#EB008B'
export const DARK_BLUE_1 = '#1A727A'
export const DARK_BLUE_2 = '#1E858E'
export const DARK_PINK_1 = '#BC006F'
export const DARK_RED_1 = '#B40E0E'
export const DARK_RED_2 = '#ED1C24'
export const PINK_1 = '#F799D1'
export const BLUE_1 = '#AAE5EA'
export const YELLOW_1 = '#FFB400'
export const GREEN_1 = '#01A862'
export const GREEN_2 = '#00C572'
