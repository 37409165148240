import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from '../formItems'
import { createNewCompany } from 'api/master/company'

function ScrapAddItemModal({ close, visible, ...props }) {
    return (
        <ModalForm
            title="Add Item"
            formItems={formItems}
            callApi={createNewCompany}
            successMsg={(values) => `New Item ${values.id} was created`}
            close={close}
            visible={visible}
            gridColumn={2}
            {...props}
        />
    )
}

export default ScrapAddItemModal
