import React, { useReducer, useEffect } from 'react'
import { Table, Input, Button, Modal } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import SimpleModal from 'components/Common/Modals/SimpleModal'
import { generatePallet } from 'api/transaction/plannerDoExecution'

const columns = [
    {
        title: 'Pallet ID',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Quantity',
        dataIndex: 'qty',
        key: 'qty',
        align: 'center',
    },
    {
        title: 'Available Quantity',
        dataIndex: 'availableQty',
        key: 'availableQty',
        align: 'center',
    },
    {
        title: 'Over Qty',
        dataIndex: 'overQty',
        key: 'overQty',
        align: 'center',
        render: (text, record) => {
            const isOverQuantity = +text > 0
            if (isOverQuantity) return <p className="text-red-600">{text}</p>
            return <p>{text}</p>
        },
    },
]

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PARAMS':
            return { ...state, params: action.params }
        case 'SET_DATA':
            return { ...state, pallets: [...action.pallets], loading: false }
        case 'SET_OPEN_QTY':
            return { ...state, requestQuantity: action.qty }
        case 'SET_ERROR':
            return { ...state, error: action.error, loading: false }
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'RESET_DATA':
            return { ...state, pallets: [], error: '' }
        default:
            return state
    }
}

const initialState = {
    pallets: [],
    loading: false,
    error: '',
    openQuantity: 0,
    params: {
        reqQty: 0,
        plant: '',
        material: '',
    },
}

const EditPallet = ({ close, payload, assignPalletToItem = () => {}, formDataSloc, ...props }) => {
    const [state, dispatch] = useReducer(stateReducer, initialState)

    const handleGeneratePallet = async () => {
        dispatch({ type: 'SET_LOADING', loading: true })
        const { data } = await generatePallet({
            reqQty: +state.params.reqQty,
            plant: state.params.plant,
            material: state.params.material,
            sloc: formDataSloc || state.params.slocId,
        })
        if (data) dispatch({ type: 'SET_DATA', pallets: data })
        if (!data) dispatch({ type: 'SET_ERROR', error: 'Pallets Not Available' })
    }

    const handleEditPallet = async () => {
        // if (state.pallets.length === 0) return Modal.error({ title: 'No Pallet Available' })

        const itemWithPallets = {
            ...payload,
            pallets: state.pallets.map((p) => ({
                palletId: p.palletId,
                palletCode: p.palletCode,
                qty: p.qty,
                overQty: p.overQty,
                batch: p.batch,
            })),
        }

        assignPalletToItem(itemWithPallets)

        Modal.success({
            title: `Success ${itemWithPallets.pallets.length === 0 ? 'Delete' : 'Assign'} Pallet `,
        })

        close()
        dispatch({ type: 'RESET_DATA' })
        // doRefresh()
        return
    }

    useEffect(() => {
        if (!props.visible) return
        dispatch({
            type: 'SET_PARAMS',
            params: { reqQty: +payload?.qty, material: payload?.material, plant: payload?.plant },
        })
    }, [payload?.material, payload?.plant, payload?.qty, props.visible])

    return (
        <SimpleModal title="Edit Pallet" width={636} destroyOnClose={true} footer={null} {...props}>
            {state.error && (
                <div className="flex items-center text-lg text-white bg-nabati-red w-full text-center rounded-10 p-5 my-3">
                    <InfoCircleOutlined className="text-3xl justify-self-start mr-4" />
                    {state.error}
                </div>
            )}
            {state?.pallets?.length === 0 && payload?.pallets?.length === 0 && (
                <p>No Pallets Available To Edit</p>
            )}
            {(state?.pallets?.length > 0 || payload?.pallets?.length > 0) && (
                <>
                    <p className="mb-2 text-base text-nabati-grey">Request Quantity</p>
                    <Input
                        value={+state.params.reqQty || 0}
                        className="rounded-10 h-50 w-1/2"
                        placeholder="Request Quantity"
                        suffix={<p className="text-xs">DUS</p>}
                        size="large"
                    />
                    <p className="mt-2 mb-2 text-base text-nabati-grey">Open Quantity</p>
                    <div className="flex mb-4">
                        <Input
                            value={state.openQuantity}
                            className="rounded-10 h-50 w-1/2"
                            placeholder="Request Quantity"
                            suffix={<p className="text-xs">DUS</p>}
                            size="large"
                        />
                        <Button
                            className="flatBlue ml-3 h-50 w-160 rounded-10"
                            onClick={handleGeneratePallet}
                            loading={state.loading}
                        >
                            Generate
                        </Button>
                    </div>
                </>
            )}

            {(state?.pallets?.length > 0 || payload?.pallets?.length > 0) && (
                <Table
                    dataSource={state.pallets.length > 0 ? state.pallets : payload.pallets}
                    columns={columns}
                    rowClassName={(_, index) =>
                        `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                    }
                    pagination={false}
                />
            )}

            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={() => {
                        close()
                        dispatch({ type: 'RESET_DATA' })
                    }}
                    type="ghost"
                >
                    Cancel
                </Button>
                <Button
                    className="rounded-10 mx-1 mt-5 "
                    style={{ width: 197, height: 45 }}
                    onClick={handleEditPallet}
                    // disabled={state.pallets.length == 0}
                    type="primary"
                    loading={state.loading}
                >
                    {state?.pallets?.length === 0 ? 'Delete Pallet' : 'Edit'}
                </Button>
            </div>
        </SimpleModal>
    )
}

export default EditPallet
