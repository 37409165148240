// import { Row, Col, Tag } from 'antd'
// import { DeleteIcon, EditIcon, EditIconDisabled, EyeIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = () => [
    // {
    //     title: 'Actions',
    //     width: 100,
    //     render: (_, record) => {
    //         const isCanEdit = () => {
    //             if (record.reservationNumber) return false
    //             if (record.scrapId) return false
    //             return true
    //         }
    //         return (
    //             <div
    //                 style={{
    //                     wordWrap: 'break-word',
    //                     wordBreak: 'break-word',
    //                     minWidth: 70,
    //                 }}
    //             >
    //                 <Row gutter={8}>
    //                     {isCanEdit() ? (
    //                         <Col className={'cursor-pointer'} onClick={() => {}}>
    //                             <EditIcon />
    //                         </Col>
    //                     ) : (
    //                         <Col className={'cursor-not-allowed'}>
    //                             <EditIconDisabled />
    //                         </Col>
    //                     )}
    //                     <Col className={'cursor-pointer'} onClick={() => {}}>
    //                         <EyeIcon />
    //                     </Col>
    //                 </Row>
    //             </div>
    //         )
    //     },
    // },
    {
        title: 'No Material',
        dataIndex: 'materialId',
        key: 'materialId',
        render: (text) => <p className="w-20">{text}</p>,
    },
    {
        title: 'Material Description',
        dataIndex: 'description',
        key: 'description',
        render: (text) => <p className="min-w-max">{text}</p>,
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Plant',
        dataIndex: 'plant',
        key: 'plant',
    },
    {
        title: 'Route',
        dataIndex: 'route',
        key: 'route',
    },
]
