import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { DATE_FORMAT } from 'config/constant/timeAndDate'

const useSearchParamsCustomized = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const currentQuery = Object.fromEntries([...searchParams])

    const resetQueryParams = () => setSearchParams({})

    const resetQueryParamsToToday = () =>
        setSearchParams({ from: moment().format(DATE_FORMAT), to: moment().format(DATE_FORMAT) })

    return { currentQuery, setSearchParams, resetQueryParams, resetQueryParamsToToday }
}

export default useSearchParamsCustomized
