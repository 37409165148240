import React from 'react'
import SelectDebounceFetch from './index'
import { getDcLocationPlantList } from 'api/master/dcLocationPlant'

function SelectPlantByDc({ withoutLabel = false, ...props }) {
    console.log("this.props =============================")
    console.log(props)
    return (
        <SelectDebounceFetch
            placeholder="Plant"
            fetchOptions={async (searchTerm) => {
                const res = await getDcLocationPlantList({ filter: searchTerm, where: props.plant, total: 10 })
                const list = res?.result?.data.map((plant) => ({
                    label: withoutLabel ? plant.plantId : `${plant.plantId}`,
                    value: plant.plantId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectPlantByDc
