import React from 'react'

function SimpleContainer({ children, className = '', style, ...props }) {
    return (
        <div
            style={style}
            className={`site-layout-background p-4 rounded-5 mb-2 shadow-simpleCard bg-white ${className}`}
        >
            <div className="">{children}</div>
        </div>
    )
}

export default SimpleContainer
