import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { createNewCharacteristicSort } from 'api/master/characteristicSort'
import { auth } from 'auth'

function AddCharacteristicSortModal({ close, visible }) {
    const user = auth.getUserInfo()
    const modifiedPayload = {
        createdBy: user?.userName || '',
    }
    return (
        <ModalForm
            title="Add Characteristic Sort"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={createNewCharacteristicSort}
            successMsg={(values) => `New Characteristic Sort was created`}
            close={close}
            visible={visible}
        />
    )
}

export default AddCharacteristicSortModal
