import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import ConfirmationDeleteModal from './modals/confirmation-delete'
import AddEditModal from './modals/add-edit-company-system'

import { columns } from './columns'
import { auth } from 'auth'
import { createNewAllowBackDate, deleteAllowBackDate, getAllowBackDateList, updateAllowBackDate } from 'api/utility/allow-back-date'

function AllowBackDate() {
    const [showUpdateUserModal, setUpdateUserModal] = useState(false)
    const [showAddUserModal, setAddUserModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [selectedRows, setSelectedRows] = useState([])
    const { doRefresh } = useQueryRefresh()
    const user = auth.getUserInfo()

    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateUserModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = selectedRows.map((row) => ({
                    dcLocation: row.dcLocation,
                }))

                const res = await deleteAllowBackDate(reqBody)

                if (!res.success) return
                doRefresh()
            },
        })
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete All" onClick={handleBulkDelete} />
    )

    // const buttons = () => (
    //     <Row justify="end">
    //         <Button
    //             onClick={() => {
    //                 setModalFormPayload({})
    //                 setAddUserModal(true)
    //             }}
    //             type="primary"
    //             className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
    //             size="middle"
    //             // disabled={true}
    //         >
    //             Add
    //         </Button>
    //     </Row>
    // )

    return (
        <>
            <RichTable
                columns={columns(onCLickEditIcon, onCLickDeleteIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                // ButtonsAreaComponent={buttons}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getAllowBackDateList}
                type="rich"
            />
            <AddEditModal
                title={`Add Allow Back Date`}
                close={() => setAddUserModal(false)}
                visible={showAddUserModal}
                payload={null}
                successMsg={`Success add Allow Back Date`}
                callApi={createNewAllowBackDate}
           
            />
            <AddEditModal
                title={`Edit Allow Back Date`}
                close={() => setUpdateUserModal(false)}
                visible={showUpdateUserModal}
                payload={modalFormPayload}
                successMsg={`Success edit Allow Back Date`}
                callApi={updateAllowBackDate}
          
            />
            {/* <AddCompanyModal close={() => setAddUserModal(false)} visible={showAddUserModal} />
            <UpdateCompanyModal
                close={() => setUpdateUserModal(false)}
                visible={showUpdateUserModal}
                payload={modalFormPayload}
            /> */}
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
        </>
    )
}

export default AllowBackDate
