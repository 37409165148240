import React from 'react'

import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'
import SimpleModal from 'components/Common/Modals/SimpleModal'

function SetFilter({ close, visible, onSubmit, onChange, values, resetValues, ...props }) {
    const handleClose = () => {
        close()
    }

    const handleSave = () => {
        onSubmit()
        close()
    }

    return (
        <SimpleModal
            title="Filter"
            visible={visible}
            close={handleClose}
            onOk={handleSave}
            header={false}
            className="p-5"
            width={360}
            {...props}
        >
            <div className="flex justify-between items-center -mt-3">
                <p className="font-bold text-lg">Filter</p>
                <p
                    className="font-bold text-nabati-blue text-sm cursor-pointer"
                    onClick={resetValues}
                >
                    Reset
                </p>
            </div>
            <div id="filter-material">
                <p className="font-bold mt-2 mb-1">By Material</p>
                <SelectDebounceFetch.Material
                    getPopupContainer={() => document.getElementById('filter-material')}
                    // withStar
                    mode="multiple"
                    onChange={(values) => onChange({ material: values })}
                    value={values.material}
                />
            </div>
            <div id="filter-company">
                <p className="font-bold mt-2 mb-1">By Company</p>
                <SelectDebounceFetch.Company
                    getPopupContainer={() => document.getElementById('filter-company')}
                    // withStar
                    mode="multiple"
                    onChange={(values) => onChange({ company: values })}
                    value={values.company}
                />
            </div>
            <div id="filter-plant">
                <p className="font-bold mt-2 mb-1">By Plant</p>
                <SelectDebounceFetch.Plant
                    getPopupContainer={() => document.getElementById('filter-plant')}
                    // withStar
                    mode="multiple"
                    onChange={(values) => onChange({ plant: values })}
                    value={values.plant}
                />
            </div>
            <div id="filter-sloc">
                <p className="font-bold mt-2 mb-1">By Building</p>
                <SelectDebounceFetch.Sloc
                    getPopupContainer={() => document.getElementById('filter-sloc')}
                    // withStar
                    mode="multiple"
                    onChange={(values) => onChange({ building: values })}
                    value={values.building}
                />
            </div>
        </SimpleModal>
    )
}

export default SetFilter
