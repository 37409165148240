import React, { useState, useEffect } from 'react'
import { Select, Spin } from 'antd'

import { getPrioritybinList } from 'api/master/priorityBin'
import { removeDuplicateObjectInArray } from 'utils/array'
import InputSkeleton from 'components/Common/SkeletonLoading/inputSkeleton'

function SelectPriorityBinByPlant({
    loading: parentLoading,
    plant,
    onChange,
    placeholder = 'Select Priority Bin',
    ...props
}) {
    const [loading, setLoading] = useState(false)
    const [optList, setOptList] = useState([])

    useEffect(() => {
        if (!plant) return

        const fetchOrderOptions = async () => {
            setLoading(true)
            const res = await getPrioritybinList({ filter: plant })
            const opt = res.result.data.map((item) => ({
                label: item.priorityId,
                value: item.priorityId,
            }))
            setOptList(removeDuplicateObjectInArray(opt, 'value'))
            setLoading(false)
        }

        fetchOrderOptions()
    }, [plant])

    if (parentLoading)
        return (
            <InputSkeleton
                className={props.className ? props.className : 'rounded-10 h-50'}
                style={{ width: '100%', borderRadius: '5 !important', ...props.style }}
                size="large"
            />
        )

    return (
        <Select
            allowClear
            showSearch
            filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
            loading={loading}
            onChange={onChange}
            placeholder={placeholder}
            className="rounded-10 h-50"
            style={{ width: '100%', borderRadius: '5 !important' }}
            size="large"
            dropdownRender={(menu) => (
                <>
                    {!loading && menu}
                    {loading && (
                        <div className="text-center p-10">
                            <Spin />
                        </div>
                    )}
                </>
            )}
            options={optList}
            {...props}
        />
    )
}

export default SelectPriorityBinByPlant
