import { Button } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const materialColumns = (onClickViewSloc, onClickViewConvertion) => [
    {
        title: 'Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Product Hierarchy',
        dataIndex: 'productHierarchy',
        key: 'productHierarchy',
    },
    {
        title: 'View',
        dataIndex: '-',
        key: '-',
        width: 365,
        render: (text, record) => (
            <div>
                <Button
                    onClick={() => onClickViewSloc(record)}
                    className="outLinedPink w-157 rounded-10 mx-1"
                >
                    View Sloc
                </Button>
                <Button
                    onClick={() => onClickViewConvertion(record)}
                    className="outLinedPink w-157 rounded-10 mx-1"
                >
                    View Conversion
                </Button>
            </div>
        ),
    },
]
