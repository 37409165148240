import React from 'react'
import SelectDebounceFetch from './index'
import { getBinList } from 'api/master/bin'
const data = [
    {
        label: 1,
        value: 1,
    },
    {
        label: 2,
        value: 2,
    },
    {
        label: 3,
        value: 3,
    }
]
function SelectTransactionStatus({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Transaction Status"
            fetchOptions={async (searchTerm) => {
                const list = data
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectTransactionStatus
