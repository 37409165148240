import React from 'react'
import RichTable from 'components/Common/RichTable'
import { getPurchaseGroupList } from 'api/master/purchasingGroup'
import { columns } from './columns'

const PurchasingOrganization = () => {
    return (
        <RichTable
            columns={columns()}
            showButtonsArea={false}
            showSearch={true}
            withRowSelection={false}
            withSearchAndFilterArea={false}
            getListApi={getPurchaseGroupList}
        />
    )
}

export default PurchasingOrganization
