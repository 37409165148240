// import { Row, Col } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = () => [
    {
        title: 'Country',
        dataIndex: 'countryName',
        key: 'countryName',
    },
    {
        title: 'Country Key',
        dataIndex: 'countryKey',
        key: 'countryKey',
    },
]
