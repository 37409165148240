import React from 'react'
import SelectDebounceFetch from './index'
import { getPlantList } from 'api/master/plant'

function SelectPlant({ withoutLabel = false, ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Plant"
            fetchOptions={async (searchTerm) => {
                const res = await getPlantList({ filter: searchTerm, total: 10 })
                const list = res?.result?.data.map((plant) => ({
                    label: withoutLabel ? plant.plantId : `${plant.plantId} - ${plant.description}`,
                    value: plant.plantId,
                }))
                return list || []
            }}
            {...props}
        />
    )
}

export default SelectPlant
