// import { Row, Col } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = () => [
    {
        title: 'Company ID',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'GL ID',
        dataIndex: 'accountId',
        key: 'accountId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
