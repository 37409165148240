import { Navigate, Outlet } from 'react-router-dom'
import ReportPhotoOutbound from 'components/pages/report/report-photo-outbound'
import ReportTransferDc from 'components/pages/report/report-transfer-dc'
import ReportPalletId from 'components/pages/report/report-pallet-id'
import ReportCheckPallet from 'components/pages/report/report-check-pallet'
import ReportPickerDc from 'components/pages/report/report-picker-dc'
import ReportChangeBin from 'components/pages/report/report-change-bin'
import ReportCheckinout from 'components/pages/report/report-check-in-out'
import ReportReachTruck from 'components/pages/report/report-reach-truck'

export const reportRoutes = {
    path: '/report',
    element: <Outlet />,
    children: [
        { index: false, element: <Navigate to="report-photo-outbound" /> },
        { path: 'report-photo-outbound', element: <ReportPhotoOutbound /> },
        { path: 'report-inbound-transfer-dc', element: <ReportTransferDc /> },
        { path: 'report-inbound-pallet-id', element: <ReportPalletId /> },
        { path: 'report-inbound-check-pallet', element: <ReportCheckPallet /> },
        { path: 'report-inbound-picker-dc', element: <ReportPickerDc /> },
        { path: 'report-outbound-change-bin', element: <ReportChangeBin /> },
        { path: 'report-outbound-reach-truck', element: <ReportReachTruck /> },
        { path: 'report-outbound-check-in-out', element: <ReportCheckinout /> },
        { path: '*', element: <Navigate to="report-photo-outbound" /> }
    ],
}
