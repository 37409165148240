import React, { useState, useEffect } from 'react'
import { Select, Spin } from 'antd'
import InputSkeleton from 'components/Common/SkeletonLoading/inputSkeleton'

import { getSlocByPlantWithoutTotal } from 'api/master/plantSloc'
import { removeDuplicateObjectInArray } from 'utils/array'

function SelectSlocByPlant({
    loading: parentLoading,
    plant,
    onChange,
    placeholder = 'Select Sloc',
    labelInValue = true,
    ...props
}) {
    const [loading, setLoading] = useState(false)
    const [optList, setOptList] = useState([])

    useEffect(() => {
        if (!plant) return

        const fetchOrderOptions = async () => {
            setLoading(true)
            const res = await getSlocByPlantWithoutTotal({ filter: plant })
            const opt = res.result.map((item) => ({
                label: `${item.slocId} - ${item.description}`,
                value: item.slocId,
            }))
            setOptList(removeDuplicateObjectInArray(opt, 'value'))
            setLoading(false)
        }

        fetchOrderOptions()
    }, [plant])

    if (parentLoading)
        return (
            <InputSkeleton
                className={props.className ? props.className : 'rounded-10 h-50'}
                style={{ width: '100%', borderRadius: '5 !important', ...props.style }}
                size="large"
            />
        )
    return (
        <Select
            labelInValue={labelInValue}
            allowClear
            showSearch
            // filterOption={(input, option) => {
            //     return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }}
            loading={loading}
            onChange={onChange}
            placeholder={placeholder}
            className="rounded-10 h-50"
            style={{ width: '100%', borderRadius: '5 !important' }}
            size="large"
            dropdownRender={(menu) => (
                <>
                    {!loading && menu}
                    {loading && (
                        <div className="text-center p-10">
                            <Spin />
                        </div>
                    )}
                </>
            )}
            options={optList}
            {...props}
        />
    )
}

export default SelectSlocByPlant
