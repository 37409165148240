
export const columns = () => [
    {
        title: 'Transaction Id',
        dataIndex: 'transactionId',
        key: 'transactionId',
        // render: (text) => <p className="w-20">{text}</p>,
    },
    {
        title: 'Do Number',
        dataIndex: 'doNumber',
        key: 'doNumber',
        // render: (text) => <p className="w-20">{text}</p>,
    },
    {
        title: 'Do Item',
        dataIndex: 'doItem',
        key: 'doItem',
        // render: (text) => <p className="w-20">{text}</p>,
    },
    {
        title: 'Company Id',
        dataIndex: 'companyId',
        key: 'companyId',
        // render: (text) => <p className="min-w-max">{text}</p>,
    },
    {
        title: 'Plant Id',
        dataIndex: 'plantId',
        key: 'plantId',
        // render: (text) => <p className="min-w-max">{text}</p>,
    },
    {
        title: 'Sloc Id',
        dataIndex: 'slocId',
        key: 'slocId',
        // render: (text) => <p className="min-w-max">{text}</p>,
    },
    {
        title: 'Gate Id',
        dataIndex: 'gateId',
        key: 'gateId',
        // render: (text) => <p className="min-w-max">{text}</p>,
    },
    {
        title: 'Bin Id',
        dataIndex: 'binId',
        key: 'binId',
        // render: (text) => <p className="min-w-max">{text}</p>,
    },
    {
        title: 'Docking Id',
        dataIndex: 'dockingId',
        key: 'dockingId',
        // render: (text) => <p className="min-w-max">{text}</p>,
    },
    {
        title: 'Material Id',
        dataIndex: 'materialId',
        key: 'materialId',
        // render: (text) => <p className="min-w-max">{text}</p>,
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
   
]
