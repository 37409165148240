import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon, DeleteIconDisabled, EditIconDisabled } from 'assets'

export const columns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        render: (_, record) => {
            const isHasPoNumber = !!record.purchaseNo
            return (
                <div
                    style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        minWidth: 60,
                    }}
                >
                    <Row gutter={16} justify="start" align="middle">
                        {!isHasPoNumber && (
                            <>
                                <Col
                                    span={12}
                                    className={'cursor-pointer'}
                                    onClick={() => onCLickEditIcon(record)}
                                >
                                    <EditIcon />
                                </Col>
                                <Col
                                    span={12}
                                    className={'cursor-pointer'}
                                    onClick={() => onCLickDeleteIcon(record)}
                                >
                                    <DeleteIcon />
                                </Col>
                            </>
                        )}
                        {isHasPoNumber && (
                            <>
                                <Col span={12} className={'cursor-not-allowed'}>
                                    <EditIconDisabled />
                                </Col>
                                <Col span={12} className={'cursor-not-allowed'}>
                                    <DeleteIconDisabled />
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            )
        },
    },

    {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Purchase No',
        dataIndex: 'purchaseNo',
        key: 'purchaseNo',
    },
    {
        title: 'Item',
        dataIndex: 'purchaseItem',
        key: 'purchaseItem',
    },
    {
        title: 'Deliver No',
        dataIndex: 'deliveryNo',
        key: 'deliveryNo',
    },
    {
        title: 'Item',
        dataIndex: 'deliveryItem',
        key: 'deliveryItem',
    },
    {
        title: 'PO Type',
        dataIndex: 'poType',
        key: 'poType',
    },
    {
        title: 'Purch. Org.',
        dataIndex: 'purchaseOrganization',
        key: 'purchaseOrganization',
    },
    {
        title: 'Purch. Group',
        dataIndex: 'purchaseGroup',
        key: 'purchaseGroup',
    },
    {
        title: 'No Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'Uom',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Route',
        dataIndex: 'route',
        key: 'route',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
]
