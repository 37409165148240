import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

export const formItems = (payload) => [
    {
        name: 'companyId',
        label: 'Company',
        placeholder: 'Company',
        initialValue: { value: payload?.companyId || null },
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.Company />,
    },
    {
        name: 'plantId',
        label: 'Plant',
        placeholder: 'Plant',
        initialValue: { value: payload?.plantId || null },
        rules: [
            {
                required: true,
            },
        ],
        children: <SelectDebounceFetch.Plant />,
    },
]
