import React, { useState } from 'react'
import { Button, Row, Modal } from 'antd'

import RichTable from 'components/Common/RichTable'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import useQueryRefresh from 'customHooks/useQueryRefresh'
//
import ConfirmationDeleteModal from './modals/confirmation-delete'
import ConfirmationResetModal from './modals/confirmation-reset'
import AddEditModal from './modals/add-edit-user-id'
import NewPassword from './modals/new-password'

import { getUserList, deleteUser, resetUser, createNewUser, updateUser, updatePassword } from 'api/maintain-user/user'

import { columns } from './columns'
import { auth } from 'auth'

function User() {
    const [showUpdateUserModal, setUpdateUserModal] = useState(false)
    const [showAddUserModal, setAddUserModal] = useState(false)
    const [showNewPasswordModal, setNewPasswordModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showResetModal, setShowResetModal] = useState(false)
    const [modalFormPayload, setModalFormPayload] = useState({})
    const [selectedRows, setSelectedRows] = useState([])
    const { doRefresh } = useQueryRefresh()
    const user = auth.getUserInfo()
    const onCLickEditIcon = (row) => {
        setModalFormPayload(row)
        setUpdateUserModal(true)
    }

    const onClickChangePasswordIcon = (row) => {
        console.log("row ----------")
        console.log(row)
        setModalFormPayload(row)
        setNewPasswordModal(true)
    }

    const onCLickDeleteIcon = (row) => {
        setModalFormPayload(row)
        setShowDeleteModal(true)
    }

    const handleBulkDelete = () => {
        Modal.confirm({
            title: 'Bulk Delete Confirmation',
            content: 'Are you sure want to delete all of selected data?',
            okText: 'Delete',
            onOk: async (close) => {
                const reqBody = selectedRows.map((row) => row.id)

                const res = await deleteUser(reqBody)

                if (!res.success) return
                doRefresh()
            },
        })
    }

    const onClickResetIcon = (row) => {
        setModalFormPayload(row)
        setShowResetModal(true)
    }

    const onChangeSelectedRows = (keys, values) => {
        setSelectedRows(values)
    }

    const rowSelectionButtons = () => (
        <ButtonRowSelection label="Delete All" onClick={handleBulkDelete} />
    )
    const buttons = () => (
        <Row justify="end">
            <Button
                onClick={() => {
                    setModalFormPayload({})
                    setAddUserModal(true)
                }}
                type="primary"
                className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
                size="middle"
                // disabled={true}
            >
                Add
            </Button>
        </Row>
    )

    return (
        <>
            <RichTable
                columns={columns(onCLickEditIcon, onCLickDeleteIcon, onClickChangePasswordIcon, onClickResetIcon)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={true}
                ButtonsAreaComponent={buttons}
                RowSelectionComponent={rowSelectionButtons}
                onChangeSelectedRows={onChangeSelectedRows}
                getListApi={getUserList}
                type="rich"
            />
            <AddEditModal
                title="Add User ID"
                close={() => setAddUserModal(false)}
                visible={showAddUserModal}
                payload={null}
                successMsg="Success add User ID"
                callApi={createNewUser}
                additionalPayload={{ createBy: user.name }}
            />
            <NewPassword
                title="Change Password"
                close={() => setNewPasswordModal(false)}
                visible={showNewPasswordModal}
                payload={modalFormPayload}
                successMsg="Success set new password"
                callApi={updatePassword}
                additionalPayload={{ userID: user.id }}
            />
            <AddEditModal
                title="Edit User ID"
                close={() => setUpdateUserModal(false)}
                visible={showUpdateUserModal}
                payload={modalFormPayload}
                successMsg="Success edit User ID"
                callApi={updateUser}
                additionalPayload={{ changeBy: user.name }}
            />
            <ConfirmationDeleteModal
                payload={modalFormPayload}
                visible={showDeleteModal}
                close={() => setShowDeleteModal(false)}
            />
            <ConfirmationResetModal
                payload={modalFormPayload}
                visible={showResetModal}
                callApi={resetUser}
                close={() => setShowResetModal(false)}
            />
        </>
    )
}

export default User
