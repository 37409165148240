import React, { useState } from 'react'
import RichTable from 'components/Common/RichTable'
import ViewSlocModal from './modals/view-sloc'
import ViewConversionModal from './modals/view-conversion'

import { getMaterialList } from 'api/master/material'
import { materialColumns } from './columns'

const dummyPayload = { materialId: 300330, materialName: 'Material B' }

function Material() {
    const [showViewSlocModal, setShowViewSlocModal] = useState(false)
    const [showViewConversionModal, setShowViewConversionModal] = useState(false)
    const [modalPayload, setModalPayload] = useState(dummyPayload)

    const onClickViewSloc = (row) => {
        setModalPayload(row)
        setShowViewSlocModal(true)
    }

    const onClickViewConversion = (row) => {
        setModalPayload(row)
        setShowViewConversionModal(true)
    }

    return (
        <>
            <RichTable
                columns={materialColumns(onClickViewSloc, onClickViewConversion)}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                getListApi={getMaterialList}
                type="rich"
            />
            <ViewSlocModal
                visible={showViewSlocModal}
                title={(payload) => `View Sloc ${payload?.materialId} - ${payload?.description}`}
                payload={modalPayload}
                close={() => setShowViewSlocModal(false)}
            />
            <ViewConversionModal
                visible={showViewConversionModal}
                title={(payload) => `View Sloc ${payload?.materialId} - ${payload?.description}`}
                payload={modalPayload}
                close={() => setShowViewConversionModal(false)}
            />
        </>
    )
}

export default Material
