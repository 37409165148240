import React, { useEffect, useState } from 'react'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import SimpleTabs from 'components/Common/SimpleTabs'
import {
    getDetailReport
} from 'api/report/photoOutbound'
import {
	ImageMapingTab,  
	ImageCheckingArmadaTab,  
	ImageMapingTabCheckout,  
	ImageMapingTabLoadingProcess,  
	ImageMapingTabParkingCrew,
    DetailTabPallet
} from './tabs'

function LabeledItem({ title, content, className = 'my-4' }) {
    return (
        <div className={className}>
            <p className="font-bold text-sm text-nabati-grey">{title}</p>
            <p>{content}</p>
        </div>
    )
}

const DetailPhoto = ({ close, payload, ...props }) => {
    const [dataitem, setData] = useState({})
  
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!payload?.transactionId) return
        if (!props.visible) return

        const callApi = async () => {
            setLoading(true)
            const data = await getDetailReport({ transactionId: payload?.transactionId })
			console.log(data)
            setData(data)
        }
        callApi()
        setLoading(false)
    }, [payload, props.visible])

    return (
        <SimpleModal
            title="Display Report Foto Outbound"
            footer={false}
            closable
            width={950}
			close={() => {
                close()
            }}
            loading={loading}
            {...props}
        >
            {!loading && (
                <>
                    <div>
                        <LabeledItem title="Outbound ID" content={dataitem.result?.transactionId || '-'} />
                        <LabeledItem
                            title="Customer "
                            content={dataitem.result?.customerId+'-'+dataitem.result?.customerName || '-'}
                        />
                    </div>
                    <div
                        className="mt-12"
                        style={{
                            display: 'grid',
                            // gridTemplateColumns: '1fr 1fr',
                            // gap: 16,
                        }}
                    >
					    <SimpleTabs >
							{SimpleTabs.TabPane({
								children: <ImageMapingTab imageData={dataitem.result?.checkin} outboundId={dataitem.result?.transactionId} />,
								tabTitle: 'Checkin',
								key: 1,
							})}
							{SimpleTabs.TabPane({
								children: <ImageCheckingArmadaTab imageData={dataitem.result?.checkArmada} outboundId={dataitem.result?.transactionId}/>,
								tabTitle: 'Checking Armada',
								key: 2,
							})}
							{SimpleTabs.TabPane({
								children: <ImageMapingTabLoadingProcess imageData={dataitem.result?.loadingProcess} outboundId={dataitem.result?.transactionId} />,
								tabTitle: 'Loading Process',
								key: 3,
							})}
							{SimpleTabs.TabPane({
								children: <ImageMapingTabParkingCrew imageData={dataitem.result?.parkingCrew} outboundId={dataitem.result?.transactionId} />,
								tabTitle: 'Parking Crew',
								key: 4,
							})}
							{SimpleTabs.TabPane({
								children: <ImageMapingTabCheckout imageData={dataitem.result?.checkout} outboundId={dataitem.result?.transactionId} />,
								tabTitle: 'Checkout',
								key: 5,
							})}{SimpleTabs.TabPane({
								children: <DetailTabPallet imageData={dataitem.result?.checkout} outboundId={dataitem.result?.transactionId} />,
								tabTitle: 'Pallet Assign',
								key: 6,
							})}
						</SimpleTabs>
                    </div>{' '}
                </>
            )}
        </SimpleModal>
    )
}

export default DetailPhoto
