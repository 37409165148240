import { Row, Col } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { DeleteIcon, DeleteIconDisabled, EditIcon, EditIconDisabled } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = (onCLickDeleteIcon, onCLickEditIcon) => [
    {
        title: 'Actions',
        render: (_, record) => {
            const isHasMatDoc = !!record.materialDocument
            return (
                <div
                    style={{
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                        minWidth: 60,
                    }}
                >
                    <Row gutter={16} justify="start" align="middle">
                        {!isHasMatDoc && (
                            <>
                                <Col
                                    span={12}
                                    className={'cursor-pointer'}
                                    onClick={() => onCLickEditIcon(record)}
                                >
                                    <EditIcon />
                                </Col>
                                <Col
                                    span={12}
                                    className={'cursor-pointer'}
                                    onClick={() => onCLickDeleteIcon(record)}
                                >
                                    <DeleteIcon />
                                </Col>
                            </>
                        )}
                        {isHasMatDoc && (
                            <>
                                <Col span={12} className={'cursor-not-allowed'}>
                                    <EditIconDisabled />
                                </Col>
                                <Col span={12} className={'cursor-not-allowed'}>
                                    <DeleteIconDisabled />
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            )
        },
    },
    {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Mat Doc',
        dataIndex: 'materialDocument',
        key: 'materialDocument',
    },
    {
        title: 'Item',
        dataIndex: 'materialItem',
        key: 'materialItem',
    },
    {
        title: 'Year',
        dataIndex: 'materialYear',
        key: 'materialYear',
    },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'No Material',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'Uom',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (text) => {
            const isOk = text === 'Posted'
            if (isOk) return <CheckCircleFilled className="text-green-500 text-lg" />
            return <CheckCircleFilled className="text-gray-200 text-lg" />
        },
    },
]
