import React from 'react'
import { Input } from 'antd'

function InputSkeleton({
    size = 'large',
    className = 'rounded-10 h-50',
    style = { width: '100%' },
    ...props
}) {
    return (
        <Input
            size={size}
            className={className}
            style={style}
            {...props}
            placeholder="loading..."
        />
    )
}

export default InputSkeleton
