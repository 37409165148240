import React from 'react'
import ModalForm from 'components/Common/Modals/ModalForm'
import { formItems } from './formItems'
import { updateGate } from 'api/master/gate'
import { auth } from 'auth'

function UpdateGateModal({ payload, close, visible }) {
    const user = auth.getUserInfo()

    const modifiedPayload = {
        gateId: payload.gateId,
        dcLocationId: payload.dcLocationId,
        building: payload.building,
        description: payload.description,
        from: payload.from,
        to: payload.to,
        fromPlant: payload.fromPlant,
        toPlant: payload.toPlant,
        active: payload.active,
        changeBy: user?.userName || 'BCO_MJK2', // delete me! ["BCO_MJK2"]
    }

    return (
        <ModalForm
            title="Edit Gate"
            formItems={formItems}
            initialValues={modifiedPayload}
            callApi={updateGate}
            successMsg={(values) => `Gate ${values.gateId} was updated`}
            close={close}
            visible={visible}
            gridColumn={2}
        />
    )
}

export default UpdateGateModal
