import React from 'react'
import CardDatePicker from 'components/Common/CardDatePicker'

import ChartInboundProductionDus from './charts/chart-inbound-production-dus'
import ChartInboundProductionPallet from './charts/chart-inbound-production-pallet'
import ChartInboundProductionTransCount from './charts/chart-inbound-production-trans-count'
import ChartInboundNonProductionTransCount from './charts/chart-inbound-non-production-trans-count'

function SectionTwo() {
    return (
        <CardDatePicker className="my-5">
            <ChartInboundProductionDus />
            <ChartInboundProductionPallet />
            <ChartInboundProductionTransCount />
            <ChartInboundNonProductionTransCount />
        </CardDatePicker>
    )
}

export default SectionTwo
