import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import moment from 'moment'
import SelectDcLocation from 'components/Common/SelectFetchByApi/selectDcLocation'
import Card from 'components/Common/Card'
import { IcFullScreen } from 'assets'
import { BgWaveOcean } from 'assets'
import './dashboard.css'

function SectionFilter({ title = '', dcLocation = {}, onChangeDcLocation }) {
    const dayTime = moment().format('MMMM Do YYYY')
    const svgString = encodeURIComponent(renderToStaticMarkup(<BgWaveOcean />))

    return (
        <Card
            className="my-5"
            style={{
                backgroundImage: `url("data:image/svg+xml,${svgString}")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
                borderRadius: 16,
            }}
        >
            <div className="flex w-full justify-between items-center">
                <div className="w-full">
                    <p className="font-bold text-2xl">{title}</p>
                    <p className="mt-1">{dayTime}</p>
                </div>
                <div
                    className="w-full max-w-xs flex justify-center items-center"
                    id="dashboardSelect"
                >
                    <SelectDcLocation
                        getPopupContainer={() => document.getElementById('dashboardSelect')}
                        labelInValue
                        value={dcLocation.value ? dcLocation : null}
                        placeholder="Select DC Locations"
                        onChange={onChangeDcLocation}
                        bordered={false}
                        size="middle"
                        className="rounded-lg h-9 border-gradient border-gradient-purple modified"
                    />
                    <div className="mt-2 -ml-1 cursor-pointer">
                        <IcFullScreen />
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default SectionFilter
