import React, { useReducer } from 'react'
import { Modal, Tabs } from 'antd'

import SimpleModal from 'components/Common/Modals/SimpleModal'
import SetPriorityForm from './setPriorityForm'

import { setPriority } from 'api/transaction/plannerSetPriority'

import useQueryRefresh from 'customHooks/useQueryRefresh'

const initialState = {
    loading: false,
    formInput: [],
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'SET_FORM':
            const formInput = [...state.formInput]
            formInput[action.index] = action.formValue
            return { ...state, formInput }
        default:
            return state
    }
}

function AddItemModal({
    close,
    closeParent,
    visible,
    payload = [],
    tabKey = 'materialId',
    ...props
}) {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { doRefresh } = useQueryRefresh()

    const handleSave = ({ index, formValue }) => {
        dispatch({ type: 'SET_FORM', index, formValue })
    }

    const handleSubmit = async () => {
        if (payload.length === 0) return
        const reqBody = [...state.formInput]
        dispatch({ type: 'SET_LOADING', loading: true })

        try {
            const res = await setPriority(reqBody)
            if (res.success) {
                Modal.success({ title: 'Success', content: res.message })
                close()
                closeParent()
                doRefresh()
            }
            return dispatch({ type: 'SET_LOADING', loading: false })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <SimpleModal
            title="Set Priority"
            close={close}
            visible={visible}
            onOk={handleSubmit}
            loading={state.loading}
            okText="Submit"
            cancelText="Cancel"
            closable={false}
            footer={true}
            header={true}
            {...props}
        >
            {payload.length === 0 && <>Oops... Please Select At Least One Row.</>}
            {/* <Row gutter={8} className="mt-2 mb-7">
                <Col span={12}>
                    <p>From Date & Time</p>
                    <DatePicker
                        showTime
                        onChange={() => {}}
                        onOk={() => {}}
                        size="large"
                        className="rounded-5"
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col span={12}>
                    <p>To Date & Time</p>
                    <DatePicker
                        showTime
                        onChange={() => {}}
                        onOk={() => {}}
                        size="large"
                        className="rounded-5"
                        style={{ width: '100%' }}
                    />
                </Col>
            </Row> */}
            {payload.length > 0 && (
                <Tabs tabPosition="left">
                    {payload.map((row, index) => {
                        return (
                            <Tabs.TabPane tab={row[tabKey]} key={index}>
                                <div className="pr-3 pl-0">
                                    <SetPriorityForm
                                        tabKey={index}
                                        handleSave={handleSave}
                                        plantForBin={row.plantId}
                                        noBooking={row.bookingNo}
                                        materialId={row.materialId}
                                        description={row.description}
                                        vehicleNo={row.vehicleNo || ''}
                                    />
                                </div>
                            </Tabs.TabPane>
                        )
                    })}
                </Tabs>
            )}
        </SimpleModal>
    )
}

export default AddItemModal
