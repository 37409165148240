import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'
import { options } from '../options'

export const getListReservation = async (params) => {
    const response = await call(METHODS.GET, '/planner-reservation', params, options)
    return response.data
}

export const getReservationDraft = async (params) => {
    const response = await call(METHODS.GET, '/planner-find-reservation', params, options)
    return response.data
}

export const downloadImageReservation = async (params) => {
    const response = await call(METHODS.GET, '/planner-reservation-download', params, {
        ...options,
        responseType: 'blob',
    })
    const dataResponse = await response.blob()
    let blob = new Blob([dataResponse], { type: 'text/plain' })
    var reader = new FileReader()
    reader.readAsDataURL(blob)

    let b64Response = ''
    reader.onloadend = function () {
        var base64String = reader.result
        // console.log(base64String.substr(base64String.indexOf(', ') + 1))
        //Setting response to hook
        b64Response = base64String
    }

    return b64Response
}

export const getScrapDraft = async (params) => {
    const response = await call(METHODS.GET, '/planner-scrap', params, options)
    return response.data
}

export const deleteImageFromDb = async (params) => {
    const response = await call(
        METHODS.DELETE,
        '/planner-reservation-delete-image',
        params,
        options
    )
    return response.data
}

export const createPlannerReservation = async (params) => {
    const response = await call(METHODS.POST, '/planner-reservation', params, options)
    return response.data
}

export const createPlannerReservationDraft = async (params) => {
    const response = await call(METHODS.POST, '/planner-create-reservation', params, options)
    return response.data
}

export const createScrapSap = async (params) => {
    const response = await call(METHODS.POST, '/planner-scrap-sap', params, options)
    return response.data
}

export const createScrapDraft = async (params) => {
    const response = await call(METHODS.POST, '/planner-scrap', params, options)
    return response.data
}

export const startLoadingScrap = async (params) => {
    const response = await call(METHODS.POST, '/planner-scrap-assign-spv', params, options)
    return response.data
}

export const startLoadingReservation = async (params) => {
    const response = await call(METHODS.POST, '/planner-reservation-assign-spv', params, options)
    return response.data
}

export const postScrapToSap = async (params) => {
    const response = await call(METHODS.POST, '/planner-scrap-from-draft', params, options)
    return response.data
}

export const postReservationToSap = async (params) => {
    const response = await call(METHODS.POST, '/planner-reservation-from-draft', params, options)
    return response.data
}

export const deleteReservation = async (params) => {
    const response = await call(METHODS.DELETE, '/planner-delete-reservation', params, options)
    return response.data
}

export const deleteScrap = async (params) => {
    const response = await call(METHODS.DELETE, '/planner-delete-scrap', params, options)
    return response.data
}
