export const columns = () => [
    {
        title: 'System ID',
        dataIndex: 'systemId',
        key: 'systemId',
        width: 200,
    },
    {
        title: 'Shipping Type',
        dataIndex: 'shippingType',
        key: 'shippingType',
        width: 200,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
]
