import React, { useState, useRef } from 'react'
import { Divider, Input, Select, Space, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { toTitleCase } from 'utils/formatter'
import { removeDuplicateObjectInArray } from 'utils/array'

const printOptions = [
    { label: 'Print 1 columns', value: 1 },
    { label: 'Print 2 columns', value: 2 },
    { label: 'Print 3 columns', value: 3 },
    { label: 'Print 4 columns', value: 4 },
    { label: 'Print 5 columns', value: 5 },
]

const SelectPrintColumnButton = ({ onChange, ...props }) => {
    const [options, setOptions] = useState([...printOptions])
    const [newOptions, setNewOptions] = useState('')
    const inputRef = useRef(null)

    const onInputChange = (event) => setNewOptions(event.target.value)

    const addItem = (e) => {
        e.preventDefault()
        if (!newOptions) return

        const copyState = [...options, { label: `Print ${newOptions} Column`, value: newOptions }]
        setOptions(removeDuplicateObjectInArray(copyState, 'value'))
        setNewOptions('')
        setTimeout(() => {
            inputRef.current?.focus()
        }, 0)
    }

    return (
        <div id="area" className="flex items-end">
            <Select
                labelInValue
                defaultValue={{ value: '', label: 'Print' }}
                getPopupContainer={() => document.getElementById('area')}
                dropdownClassName="mt-10"
                placement="bottomRight"
                showArrow={true}
                bordered={false}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                                type="number"
                                placeholder="Input Column"
                                ref={inputRef}
                                value={newOptions}
                                onChange={onInputChange}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem} />
                        </Space>
                    </>
                )}
                size="large"
                className="customDropDownButton text-white bg-nabati-pink w-48 h-45 rounded-10 mx-1"
                onChange={onChange}
                {...props}
            >
                {options.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                        {toTitleCase(item.label)}
                    </Select.Option>
                ))}
            </Select>
        </div>
    )
}

export default SelectPrintColumnButton
