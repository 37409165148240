import React from 'react'
import { getPlantByCompanies } from 'api/master/plantSectorSloc'

import AsyncSelectWithDependency from 'components/Common/AsyncSelectWithDependency'

const SelectPlantByCompanies = ({
    loading,
    companies = [],
    onChange,
    placeholder = 'Select Plant',
    ...props
}) => {
    const loadOptions = (res) => {
        return res.result.map((item) => ({
            label: `${item.plantId} - ${item.companyId}`,
            value: item.plantId,
        }))
    }

    return (
        <AsyncSelectWithDependency
            labelInValue={true}
            placeholder={placeholder}
            callApi={(val) => getPlantByCompanies({ companyId: val })}
            dependencyState={companies}
            loading={loading}
            onChange={onChange}
            loadOptions={loadOptions}
            {...props}
        />
    )
}

export default SelectPlantByCompanies
