import React, { useState, useEffect } from 'react'
import { Input, Modal } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'

import { addInformation } from 'api/transaction/plannerDoExecution'
import useQueryRefresh from 'customHooks/useQueryRefresh'

const AddInformation = ({ close, payload, ...props }) => {
    const [information, setInformation] = useState('')
    const { doRefresh } = useQueryRefresh()

    const handleCreate = async () => {
        const { success, message } = await addInformation({
            transactionId: payload.transactionId,
            information,
        })

        if (success) {
            Modal.success({ title: 'Information added', content: message })
            close()
            setInformation('')
        }
        doRefresh()
        close()
    }

    useEffect(() => {
        setInformation(payload?.information || '')
    }, [payload])

    return (
        <SimpleModal
            title="Add Information"
            okText="Create"
            width={636}
            onOk={handleCreate}
            close={() => {
                close()
                setInformation('')
            }}
            {...props}
        >
            <p className="mb-2 text-base text-nabati-grey">Type Information</p>
            <Input.TextArea
                value={information}
                onChange={(e) => setInformation(e.target.value)}
                className="rounded-10 w-full"
                placeholder="Type Information"
                size="large"
                autoSize={{ minRows: 4, maxRows: 8 }}
                allowClear
            />
        </SimpleModal>
    )
}

export default AddInformation
