import { useLocation, useParams } from 'react-router-dom'

// https://stackoverflow.com/questions/66265608/react-router-v6-get-path-pattern-for-current-route
const getRoutePath = (location, params) => {
    const { pathname } = location

    if (!Object.keys(params).length) {
        return pathname // we don't need to replace anything
    }

    let path = pathname
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
            path = path.replace(paramValue, `:${paramName}`)
        }
    })
    return path
}

function useMatchRoute() {
    const location = useLocation()
    const params = useParams()
    const fullPath = getRoutePath(location, params)

    // console.log('path xc', fullPath)

    const parentPath = '/' + fullPath.split('/')[1]
    const subPath = '/' + fullPath.split('/')[2]

    return { parentPath, subPath, fullPath }
}

export default useMatchRoute
