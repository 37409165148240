import { PUBLIC_URL } from 'config/env'
import { range } from 'utils/array'
import { compareData } from 'utils/array'

export const findCurrentMenu = (path, MENU) => {
    const pathArray = path.split('/')
    const path1 = pathArray[1] ? `/${path.split('/')[1]}` : null
    const path2 = pathArray[2] ? `/${path.split('/')[2]}` : null
    const parentMenu = MENU.find((menu) => menu.path === path1) || null
    const subMenu = parentMenu?.submenu?.find((child) => child.path === path2) || null

    if (parentMenu && subMenu) return subMenu
    if (parentMenu && !subMenu) return parentMenu
    if (!parentMenu && !subMenu) return MENU[0]
    return MENU[0]
}

export const findPageTitle = (path, MENU) => {
    const pathArray = path.split('/')
    const subMenu1 = pathArray[1] ? `/${path.split('/')[1]}` : null
    const subMenu2 = pathArray[2] ? `/${path.split('/')[2]}` : null
    const currentMenu = MENU.find((menu) => menu.path === subMenu1)
    const childMenu = currentMenu?.children?.find((child) => child.path === subMenu2) || null

    const subTitle1 = currentMenu?.title || ''
    const subTitle2 = childMenu?.title || ''
    const defaultSubTitle = 'Home'

    if (!subMenu1 && !subMenu2) return defaultSubTitle
    if (!subMenu2) return subTitle1
    if (subMenu2) return subTitle2
    return defaultSubTitle
}

export const route = {
    push: (redirectUrl) =>
        window.location.replace(
            `${window.location.protocol}//${window.location.host}${PUBLIC_URL}${redirectUrl}`
        ),
}

export const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const addRowKeyToData = (data) => {
    if (!Array.isArray) return []
    return data.map((item, idx) => ({ ...item, rowKey: idx }))
}

// Generate data per page for QR List paper print
export const getGenerateDataPerPage = (dataArr = [], dataPerPage = 20) => {
    const totalPage = Math.ceil(dataArr.length / dataPerPage)
    return new Array(totalPage)
        .fill('')
        .map((it, ind) => {
            const from = (ind + 1) * dataPerPage - 1
            let to = from + 1 - dataPerPage + 1
            return { from: to, to: from }
        })
        .map(({ from, to }) => range(from - 1, to + 1))
        .map((item) => {
            return item.map((i) => dataArr[i]).filter((i) => !!i)
        })
}

// ADD SORTER TO COLUMN ANTD TABLE

export const addSorterToColumn = (columns) => {
    // input: {
    //           title: 'Plant',
    //           dataIndex: 'plantId',
    //           key: 'plantId',
    //        }

    // output: {
    //           title: 'Plant',
    //           dataIndex: 'plantId',
    //           key: 'plantId',
    //           sorter: (a, b) => compareData(a.plantId, b.plantId),
    //        }

    return columns.map((column) => {
        if (column.title === 'Actions') return column
        if (!column.dataIndex) return column

        return {
            ...column,
            sorter: (a, b) => compareData(a[column.dataIndex], b[column.dataIndex]),
        }
    })
}
