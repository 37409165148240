import { CaretDownOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { useReducer } from 'react'
import { FILTER_BY } from 'config/constant/groupFilter'
import ButtonsGroupGrid from 'components/Common/ButtonsGroupGrid'
import RichTable from 'components/Common/RichTable'
import SimpleContainer from 'components/layouts/simple'
import { photoOutboundColumns } from './columns'
import {
    getListReportOutbound,
    getDetailReport,
} from 'api/report/photoOutbound'
import {
    SET_SELECTED_ROWS,
    TOGGLE_PLANNER_DISPLAY
} from 'config/constant/common'
import DetailPhoto from './modals/detail'
const initialState = {
    selectedRows: [],
    ddetailDataModal: false,
    detailDataPayload:null
}
const { BY_MATERIAL, BY_COMPANY, BY_PLANT, BY_BUILDING } = FILTER_BY
const stateReducer = (state, action) => {
    switch (action.type) {
        case SET_SELECTED_ROWS:
            return { ...state, selectedRows: [...action.selectedRows] }
        case TOGGLE_PLANNER_DISPLAY:
            return {
                ...state,
                detailDataModal: !state.detailDataModal,
                detailDataPayload: action?.payload || null,
            }
        default:
            return state
    }
}
const ReportPhotoOutbound = () => {
    const [state, dispatch] = useReducer(stateReducer, initialState)


    const onCLickDisplayIcon = (row) => {
        console.log(row)
        dispatch({ type: TOGGLE_PLANNER_DISPLAY, payload: row })
    }

	return (
        <RichTable
            rowKey="transactionId"
            columns={photoOutboundColumns(
                onCLickDisplayIcon
            )}
            showButtonsArea={false}
            withRowSelection={false}
            withSearchAndFilterArea={true}
            filterField={[BY_COMPANY, BY_PLANT]}
            getListApi={getListReportOutbound}
            withDatePicker={true}
            scroll={{ x: 1300 }}
        >
            <DetailPhoto
                visible={state.detailDataModal}
                close={() => dispatch({ type: TOGGLE_PLANNER_DISPLAY, visible: false })}
                payload={state.detailDataPayload}
            />

        </RichTable>
	)
}

export default ReportPhotoOutbound