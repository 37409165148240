import React, { useState } from 'react'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import RichTable from 'components/Common/RichTable'
import { summaryColumns, detailColumns } from './columns'
// import { downloadAsXlsx } from 'utils/downloader'

// Hooks
import useSearchParams from 'customHooks/useSearchParams'

// API
import { getListStocker, downloadListStocker } from 'api/transaction/result-cycle-count-opname'

const TAB_ACTIVE = {
    SUMMARY: 'summary',
    DETAIL: 'detail',
}

function ListScrap() {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(TAB_ACTIVE.SUMMARY)
    const { currentQuery } = useSearchParams()
    const isSummaryTabActive = activeTab === TAB_ACTIVE.SUMMARY

    const handleDownload = async () => {
        try {
            setLoading(true)
            await downloadListStocker(currentQuery)
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }
    return (
        <div>
            <Button
                icon={<DownloadOutlined />}
                loading={loading}
                onClick={handleDownload}
                className="outLinedBlue w-48 h-45 rounded-10 flex justify-center items-center"
            >
                Download
            </Button>
            <div
                className="mt-4 mb-7"
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                }}
            >
                <Button
                    value={TAB_ACTIVE.SUMMARY}
                    onClick={(e) => setActiveTab(e.target.value)}
                    className={` h-45 rounded-l-10 rounded-r-none ${
                        activeTab === TAB_ACTIVE.SUMMARY ? 'flatBlue' : 'outLinedBlue'
                    }`}
                >
                    Summary
                </Button>
                <Button
                    value={TAB_ACTIVE.DETAIL}
                    onClick={(e) => setActiveTab(e.target.value)}
                    className={` h-45 rounded-r-10 rounded-l-none ${
                        activeTab === TAB_ACTIVE.DETAIL ? 'flatBlue' : 'outLinedBlue'
                    }`}
                >
                    Detail
                </Button>
            </div>
            <RichTable
                columns={isSummaryTabActive ? summaryColumns() : detailColumns()}
                showButtonsArea={false}
                withSearchAndFilterArea={false}
                getListApi={getListStocker}
                withDatePicker
            />
        </div>
    )
}

export default ListScrap
