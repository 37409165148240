// import { Row, Col } from 'antd'
// import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const columns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Date',
        dataIndex: 'createDate',
        key: 'createDate',
    },
    {
        title: 'Stock Opname ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Company',
        dataIndex: 'companyId',
        key: 'companyId',
    },
    {
        title: 'Plant',
        dataIndex: 'plantId',
        key: 'plantId',
    },
    {
        title: 'Pallet ID',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Pallet Code',
        dataIndex: 'palletCode',
        key: 'palletCode',
    },
    {
        title: 'Material ',
        dataIndex: 'materialId',
        key: 'materialId',
    },
    {
        title: 'Material  Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Storage loc',
        dataIndex: 'slocId',
        key: 'slocId',
    },
    {
        title: 'Batch',
        dataIndex: 'batch',
        key: 'batch',
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },
    {
        title: 'Qty Check',
        dataIndex: 'qtyInput',
        key: 'qtyInput',
    },
    {
        title: 'UOM',
        dataIndex: 'uom',
        key: 'uom',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Foreman ID',
        dataIndex: 'userId',
        key: 'userId',
    },
    {
        title: 'Opname Status',
        dataIndex: 'statusDescription',
        key: 'statusDescription',
    },
]
