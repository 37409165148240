import { Input } from 'antd'
import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

export const formItems = (initialValues) => {
    return [
        {
            name: 'dcLocationId',
            label: 'DC Location ID',
            initialValue: { value: initialValues?.dcLocationId || null },
            rules: [
                {
                    required: true,
                },
            ],
            children: <SelectDebounceFetch.DcLocation disabled={initialValues?.dcLocationId} />,
        },
        {
            name: 'sortId',
            label: 'Sort',
            initialValue: initialValues?.sortId || null,
            rules: [
                {
                    required: true,
                },
                {
                    max: 2,
                    message: 'The max character length is 2',
                },
            ],
            children: (
                <Input
                    placeholder="Sort"
                    className="rounded-5"
                    size="large"
                    disabled={initialValues?.sortId}
                />
            ),
        },
        {
            name: 'name',
            label: 'Name',
            initialValue: initialValues?.name || null,
            rules: [
                {
                    required: true,
                },
                {
                    max: 15,
                    message: 'The max character length is 15',
                },
            ],
            children: <Input placeholder="Name" className="rounded-5" size="large" />,
        },
        {
            name: 'characteristic',
            label: 'Characteristic',
            initialValue: {
                value: initialValues.characteristic || null,
            },
            rules: [
                {
                    required: true,
                },
            ],
            children: <SelectDebounceFetch.CharacteristicSort />,
        },
    ]
}
