import ConfirmWithPasswordModal from 'components/Common/Modals/ConfirmWithPasswordModal'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import SimpleTable from 'components/Common/SimpleTable'
import { useEffect, useState } from 'react'
import moment from 'moment'
// import { Divider } from 'antd'
import { Col, DatePicker, Row, Tag } from 'antd'
import { getDetailDo, giSapWoTruck } from 'api/transaction/plannerDoExecution'
import { IcExclamationGold } from 'assets'
import useQueryRefresh from 'customHooks/useQueryRefresh'

import { columns } from './columns'

const StatusMessageComponent = ({ res }) => {
    return (
        <div>
            {res?.status?.map((i, ind) => (
                <div
                    key={i.documentNumber + ind}
                    className="border-b my-2 pb-4 pt-2 overflow-y-scroll"
                    style={{ maxHeight: '20vh' }}
                >
                    <p>
                        Document Number: <Tag>{i.documentNumber}</Tag>
                    </p>
                    <p>
                        Transaction ID: <Tag>{i.transactionId}</Tag>
                    </p>
                    <p className="font-mono mt-2">
                        Success:{' '}
                        <Tag color={i.success ? 'green' : 'red'}>{(!!i.success).toString()}</Tag>
                    </p>
                    <p className="font-mono">
                        Sap Success:{' '}
                        <Tag color={i.sapSuccess ? 'green' : 'red'}>
                            {(!!i.sapSuccess).toString()}
                        </Tag>
                    </p>
                    <p className="font-mono">Message: {i.message}</p>
                </div>
            ))}
        </div>
    )
}

const GiWoTruckModal = ({ close, payload, bulkPayload, visible, ...props }) => {
    const [loading, setLoading] = useState(false)
    const [bulkData, setBulkData] = useState([])
    const [postingDate, setPostingDate] = useState()
    const [confirmationModal, setConfirmationModal] = useState(false)

    const { doRefresh } = useQueryRefresh()

    const openConfirmationModal = () => {
        setConfirmationModal(true)
    }

    const handleSGiWoTruck = async () => {
        setLoading(true)
        const allStatus = []
        for (const row of bulkPayload) {
            const res = await giSapWoTruck({
                transactionId: row?.transactionId || '',
                doNumber: row?.documentNumber || '',
                postingDate: postingDate ||  moment().format('YYYY-MM-DD'),
            })

            const status = {
                documentNumber: row?.documentNumber,
                transactionId: row?.transactionId,
                success: res?.success,
                message: res.message,
                sapSuccess: res.sapSuccess,
                sapMessage: res.sapMessage,
                documentYear: res.documentYear,
            }

            allStatus.push(status)
        }
        setLoading(false)
        close()
        doRefresh()
        setConfirmationModal(false)
        return { success: true, status: allStatus }
    }

    useEffect(() => {
        if (!visible) return
        const fetchApi = async () => {
            try {
                setLoading(true)
                const allRes = await Promise.all(
                    bulkPayload.map(
                        async (row) =>
                            await getDetailDo({
                                transactionId: row?.transactionId || '',
                                doNumber: row?.documentNumber || '',
                                company: row?.company || '',
                            })
                    )
                )

                setBulkData(allRes.map((i) => i.data))

                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }
        fetchApi()
    }, [bulkPayload, visible])

    console.log('bulkPayload', bulkPayload)

    return (
        <>
            <SimpleModal
                title="GI w/o Truck"
                okText="Confirm"
                width={800}
                close={() => {
                    close()
                }}
                onOk={openConfirmationModal}
                visible={visible}
                {...props}
            >
                <div className="overflow-y-scroll" style={{ maxHeight: '50vh' }}>
                    <p className="m-auto text-center text-4xl text-nabati-red font-bold mt-8 mb-3">
                        PERHATIAN !!!
                    </p>
                    <p className="m-auto text-center text-nabati-grey4 font-semibold mb-8">
                        {`(Cek kembali transaksi anda. Transaksi tidak dapat dibatalkan)`}
                    </p>
                    <Row gutter={[16, 16]} className="mb-5">
                            <Col span={12}>
                                <p className="text-nabati-grey text-base mb-1">Posting Date</p>
                                <DatePicker
                                    size="large"
                                    className="rounded-10 h-50"
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD"
                                    onChange={(e) => {
                                        setPostingDate(e.format('YYYY-MM-DD'))
                                    }}
                                    defaultValue={postingDate}
                                />
                            </Col>
                        </Row>
                    {bulkData &&
                        bulkData.map((i, ind) => {
                            return (
                                <div
                                    key={i.transactionId + ind}
                                    className="mb-6 border-b-4 pb-4 mt-8"
                                >
                                    <div className="flex justify-between items-center">
                                        <p className="font-semibold text-sm">
                                            Customer: {bulkPayload[ind]?.customerName}
                                        </p>
                                        <p>
                                            Document Number:{' '}
                                            <Tag>{bulkPayload[ind]?.documentNumber}</Tag>
                                        </p>
                                    </div>
                                    <SimpleTable
                                        loading={loading}
                                        columns={columns(i.transactionId)}
                                        dataSource={i.items || []}
                                        footer={null}
                                    />
                                </div>
                            )
                        })}
                </div>
                <div
                    className="flex items-center font-semibold text-sm mt-8 p-2 rounded-lg"
                    style={{ backgroundColor: '#FFFBDF' }}
                >
                    <IcExclamationGold />
                    <p className="ml-3" style={{ color: '#B78101' }}>
                        Setelah di confirm, DO di SAP akan ter-GI sesuai pallet assign.
                    </p>
                </div>
            </SimpleModal>
            <ConfirmWithPasswordModal
                visible={confirmationModal}
                close={() => {
                    setConfirmationModal(false)
                }}
                title="Confirmation"
                content="Are you sure to confirm GI w/o Truck?"
                successTitleMsg={'GI Success'}
                successContentMsg={(res) => <StatusMessageComponent res={res} />}
                onOk={handleSGiWoTruck}
            />
        </>
    )
}

export default GiWoTruckModal
