import { Row, Col, Tag } from 'antd'
import { EyeIcon, EditIcon, InfoYellowIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const reportTransDcColumns = (
) => [
    {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        key: 'transactionId',
    },
    {
        title: 'Po Number',
        dataIndex: 'poNumber',
        key: 'poNumber',
    },
    {
        title: 'Po Item',
        dataIndex: 'poItem',
        key: 'poItem',
    },
    {
        title: 'Pallet id',
        dataIndex: 'palletId',
        key: 'palletId',
    },
    {
        title: 'Pallet Code',
        dataIndex: 'palletCode',
        key: 'palletCode',
    },
    {
        title: 'Supplying Plant',
        dataIndex: 'supplyingPlant',
        key: 'supplyingPlant',
    },
    {
        title: 'Material',
        dataIndex: 'material',
        key: 'material',
    },
    {
        title: 'Description',
        dataIndex: 'shortText',
        key: 'shortText',
    },
    {
        title: 'Order Quantity',
        dataIndex: 'orderQuantity',
        key: 'orderQuantity',
    },
    {
        title: 'Order Unit',
        dataIndex: 'orderUnit',
        key: 'orderUnit',
    },
    {
        title: 'Outstanding Quantity',
        dataIndex: 'outstandingQuantity',
        key: 'outstandingQuantity',
    },
    {
        title: 'Transaction Status',
        dataIndex: 'transactionStatus',
        key: 'transactionStatus',
    },
    {
        title: 'Gate',
        dataIndex: 'gate',
        key: 'gate',
    },
    {
        title: 'From Plant',
        dataIndex: 'fromPlant',
        key: 'fromPlant',
    },
    {
        title: 'To Plant',
        dataIndex: 'toPlant',
        key: 'toPlant',
    },
    {
        title: 'Pallet Status',
        dataIndex: 'palletStatus',
        key: 'palletStatus',
    },
    {
        title: 'Check Pallet Status',
        dataIndex: 'checkPalletStatus',
        key: 'checkPalletStatus',
        render: (text) => {
            if (text === 'Y') return 'N'
            if (text === 'N') return 'Y'
            return '-'
        },
    },
    {
        title: 'Picker Pallet Status',
        dataIndex: 'pickerPalletStatus',
        key: 'pickerPalletStatus',
        render: (text) => {
            if (text === 'Y') return 'N'
            if (text === 'N') return 'Y'
            return '-'
        },
    },
    {
        title: 'User Id',
        dataIndex: 'userId',
        key: 'userId',
    },
     {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
    }
]
