import React from 'react'
import { Row, Col } from 'antd'
import CardDatePicker from 'components/Common/CardDatePicker'

// CHART COMPONENT
import ChartMaterialPosition from './charts/chart-material-position'
import ChartStockPosition from './charts/chart-stock-position'

function SectionOne() {
    return (
        <CardDatePicker className="mt-5">
            <Row gutter={16}>
                <Col span={12}>
                    <ChartStockPosition />
                </Col>
                <Col span={12}>
                    <ChartMaterialPosition />
                </Col>
            </Row>
        </CardDatePicker>
    )
}

export default SectionOne
