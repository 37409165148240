import React from 'react'
import { Col, Layout, Row } from 'antd'
import SidebarMenu from './sidebar-menu'
import { logoImg } from 'assets'

const { Sider } = Layout

export default function Sidebar({ showSideMenu }) {
    const onCollapse = (e) => {}
    return (
        <Sider
            className="bg-nabati-blue h-screen p-1 pt-0 overflow-y-scroll"
            trigger={null}
            collapsible
            collapsed={!showSideMenu}
            onCollapse={onCollapse}
            theme="light"
            width="250"
        >
            <Row
                className="sticky top-0 z-10 bg-nabati-blue"
                type="flex"
                align="middle"
                justify="center"
            >
                <Col>
                    <img src={logoImg} className="mb-5 mt-2" alt="logo nabati" />
                </Col>
            </Row>
            <SidebarMenu showSideMenu={showSideMenu} />
        </Sider>
    )
}
