import React, { useReducer, useEffect, useCallback } from 'react'
import { Button, Radio, Row, Col, Checkbox, Input, DatePicker, Modal } from 'antd'
import moment from 'moment'

import SimpleModal from 'components/Common/Modals/SimpleModal'
import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'
import ConfirmationDeletePoSto from '../confirmation-delete'

import SelectDebounceFetch from 'components/Common/SelectDebounceFetch'

import DataTable from './data-table'

import useQueryRefresh from 'customHooks/useQueryRefresh'

import { createPoStoDraft, getPoStoDraft } from 'api/transaction/plannerStockTransfer'

const getBodyRequest = (state) => {
    const bodyReq = {
        transactionId: state.formData.transactionId,
        plant: state.formData.plant,
        docnum: state.formData.transactionId || '', // leave it empty
        docType: state.formData.docType, // PO Type
        transactionType: state.formData.transactionType, // 0='In Plant' 1='Out Plant'   ????
        isNeedForwarder: state.formData.isNeedForwarder, // true false  ==> true/false
        building: state.formData.building, // dari PlantSectorSloc
        purchOrg: state.formData.purchOrg,
        purGroup: state.formData.purGroup,
        docDate: state.formData.docDate,
        supplPlnt: state.formData.plant, // dapat dari mana ==> sama dengan 'plant'
        collectNo: '', // kosongkan saja
        poRelInd: '', // kirim string kosong
        items: state.dataTable.map((el, idx) => ({
            poItem: +idx + 1, // index + 1
            material: el.material,
            description: el.description,
            plant: el.plant,
            slocId: el.slocId,
            quantity: +el.qty,
            poUnit: el.poUnit,
            pallets: el.pallets,
        })),
        shippings: state.dataTable.map((el, idx) => ({
            poItem: +idx + 1, // index + 1
            shipPoint: state.formData.plant,
            route: el.route,
        })),
        schedules: state.dataTable.map((el, idx) => ({
            poItem: +idx + 1, // index + 1
            schedLine: 1, // always 1. Default == 1
            deliveryDate: moment().format('YYYY-MM-DD'), // Default now()
            quantity: +el.qty, // sama dengan quantity diatas
        })),
    }

    return bodyReq
}

const TRANSACTION_TYPE = {
    IN_PLANT: '0',
    OUT_PLANT: '1',
}

const initState = {
    loading: false,
    formData: {
        poNumber: null,
        doNumber: null,
        transactionId: null,
        transactionType: '0', // plantType
        docType: null, // docType == PO TYPE
        purchOrg: null,
        purGroup: null,
        docDate: moment().format('YYYY-MM-DD'),
        plant: null,
        building: null,
        isNeedForwarder: false,
    },
    dataTable: [],
    initialDataTable: [],
    confirmationSaveModal: false,
    deletePoStoModal: false,
}

const stateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.loading }
        case 'SET_FORM_DATA':
            return { ...state, formData: { ...state.formData, ...action.payload } }
        case 'SET_DATA_TABLE':
            return { ...state, dataTable: action.dataTable }
        case 'SET_INITIAL_DATA_TABLE':
            return { ...state, initialDataTable: action.initialDataTable }
        case 'TOGGLE_CONFIRM_SAVE':
            return { ...state, confirmationSaveModal: action.toggle }
        case 'TOGGLE_DELETE_PO_STO':
            return { ...state, deletePoStoModal: !state.deletePoStoModal }
        case 'RESET':
            return initState
        default:
            return state
    }
}

const CreatePostToDoSto = ({ close, payload, ...props }) => {
    const [state, dispatch] = useReducer(stateReducer, initState)
    const { doRefresh } = useQueryRefresh()

    const isOnDisplayMode = payload?.displayMode || false
    const isOnCreateMode = payload?.createMode || false

    const setDataTable = useCallback((dataTable) => {
        dispatch({ type: 'SET_DATA_TABLE', dataTable })
    }, [])

    const validateFormDataInput = () => {
        const error = []
        if (!state.formData.transactionType) error.push('Transaction Type is required.')
        if (!state.formData.docType) error.push('PO type is required.')
        if (!state.formData.purchOrg) error.push('Purchasing Organization is required.')
        if (!state.formData.purGroup) error.push('Purchasing Group is required.')
        if (!state.formData.docDate) error.push('Posting Date is required.')
        if (!state.formData.plant) error.push('Plant is required.')
        if (!state.formData.building) error.push('Gedung is required')
        if (error.length > 0) return error

        if (state.dataTable.length === 0) error.push('Add at least one item to save.')
        if (error.length > 0) return error

        const isContainNullPallet =
            state.dataTable.map((data) => data.pallets).filter((a) => a.length === 0).length > 0
        if (isContainNullPallet) error.push('Please assign pallet for every items')
        if (error.length > 0) return error

        const isInPlantTransactionType = state.formData.transactionType === '0' // to limit plant option when add item
        const itemPlantsArr = [state.formData.plant, ...state.dataTable.map((data) => data.plant)]
        const isAllPlantsEqual = itemPlantsArr.every((v) => v === itemPlantsArr[0])
        if (isInPlantTransactionType && !isAllPlantsEqual)
            error.push('In Plant Transaction Type only valid with the same plant')
        if (error.length > 0) return error

        return error
    }

    const validateBeforeDelete = () => {
        const error = []
        state.dataTable.forEach((dt) => {
            if (dt.pallets.length > 0)
                error.push(`${dt.material}: Please remove assigned pallets before delete`)
        })
        if (error.length > 0) return error
        return error
    }

    const onClickSave = () => {
        const error = validateFormDataInput()
        if (error.length > 0)
            return Modal.error({
                title: 'Oops',
                width: 500,
                content: error.reduce((acc, cur) => (
                    <>
                        <p>{acc}</p>
                        <p>{cur}</p>
                    </>
                )),
            })

        dispatch({ type: 'TOGGLE_CONFIRM_SAVE', toggle: true })
    }

    const onClickDelete = () => {
        const error = validateBeforeDelete()
        if (error.length > 0)
            return Modal.error({
                title: 'Oops',
                width: 500,
                content: error.reduce((acc, cur) => (
                    <>
                        <p>{acc}</p>
                        <p>{cur}</p>
                    </>
                )),
            })
        dispatch({ type: 'TOGGLE_DELETE_PO_STO' })
    }

    const handleSave = async () => {
        const bodyReq = getBodyRequest(state)
        try {
            const res = await createPoStoDraft(bodyReq)
            if (!res.success) return
            doRefresh({ resetToToday: !isOnCreateMode ? true : false })
            dispatch({ type: 'RESET' })
            close()
            return res
        } catch (error) {}
    }

    const onChangeTransactionType = ({ target }) => {
        const isTableAlreadyFilled = state.dataTable.length > 0

        if (isTableAlreadyFilled) {
            return Modal.error({
                title: 'Oops',
                content: 'Please clear table to change transaction type',
            })
        }
        dispatch({
            type: 'SET_FORM_DATA',
            payload: { transactionType: target.value },
        })
    }

    useEffect(() => {
        dispatch({ type: 'RESET' })
        if (!payload?.transactionId) return
        dispatch({ type: 'SET_LOADING', loading: true })
        const fetchData = async () => {
            const res = await getPoStoDraft({ id: payload.transactionId })
            if (!res.success) {
                return Modal.error({
                    title: 'Oops',
                    content: res?.message || '',
                })
            }

            dispatch({
                type: 'SET_FORM_DATA',
                payload: {
                    doNumber: res?.data?.doNumber,
                    poNumber: res?.data?.poNumber,
                    transactionId: res?.data?.transactionId,
                    transactionType: res?.data?.transactionType,
                    docType: res?.data?.docType,
                    purchOrg: res?.data?.purchOrg,
                    purGroup: res?.data?.purGroup,
                    docDate: res?.data?.docDate,
                    plant: res?.data?.plant,
                    building: res?.data?.building,
                    isNeedForwarder: res?.data?.isNeedForwarder,
                },
            })
            dispatch({
                type: 'SET_INITIAL_DATA_TABLE',
                initialDataTable: res?.data?.items?.map((el, idx) => ({
                    key: parseInt(Math.random() * 1000000000),
                    material: el.material || '-',
                    materialDescription: el.description || '-',
                    qty: el.quantity || '-',
                    poUnit: el.poUnit || '-',
                    plant: el.plant || '-',
                    plantDescription: el.plantDescription || '-',
                    slocId: el.slocId || '-',
                    slocDescription: el.slocDescription || '-',
                    route: res?.data?.shippings[idx].route || '-',
                    routeDescription: res?.data?.shippings[idx].routeDescription || '-',
                    pallets: el.pallets || [],
                })),
            })
            dispatch({ type: 'SET_LOADING', loading: false })
        }

        fetchData()
    }, [payload?.transactionId])

    return (
        <>
            <SimpleModal
                title={`Create POSTO>DO STO ${
                    payload?.transactionId ? '- ' + payload?.transactionId : ''
                }`}
                okText="Create"
                width={1190}
                footer={null}
                {...props}
            >
                <Radio.Group
                    className="my-3 text-base font-bold"
                    onChange={onChangeTransactionType}
                    value={state.formData.transactionType}
                    disabled={isOnDisplayMode}
                >
                    <Radio value={TRANSACTION_TYPE.IN_PLANT}>In Plant</Radio>
                    <Radio value={TRANSACTION_TYPE.OUT_PLANT}>Out Plant</Radio>
                </Radio.Group>

                <Row gutter={15} className="my-3">
                    <Col span={7}>
                        <p className="text-nabati-grey text-base mb-1">PO Type</p>
                        {!state.loading && (
                            <SelectDebounceFetch.PoType
                                onChange={({ value }) => {
                                    dispatch({ type: 'SET_FORM_DATA', payload: { docType: value } })
                                }}
                                defaultValue={{ value: state?.formData?.docType }}
                                className="rounded-10 h-50"
                                style={{ width: '100%', borderRadius: '5 !important' }}
                                size="large"
                                disabled={isOnDisplayMode}
                            />
                        )}
                    </Col>
                    <Col span={7}>
                        <p className="text-nabati-grey text-base mb-1">Purchasing Organization</p>
                        {!state.loading && (
                            <SelectDebounceFetch.PurchasingOrg
                                onChange={({ value }) => {
                                    dispatch({
                                        type: 'SET_FORM_DATA',
                                        payload: { purchOrg: value },
                                    })
                                }}
                                defaultValue={{ value: state?.formData?.purchOrg }}
                                className="rounded-10 h-50"
                                style={{ width: '100%', borderRadius: '10 !important' }}
                                size="large"
                                disabled={isOnDisplayMode}
                            />
                        )}
                    </Col>
                    <Col span={7}>
                        <p className="text-nabati-grey text-base mb-1">Purchasing Group</p>
                        {!state.loading && (
                            <SelectDebounceFetch.PurchasingGroup
                                onChange={({ value }) => {
                                    dispatch({
                                        type: 'SET_FORM_DATA',
                                        payload: { purGroup: value },
                                    })
                                }}
                                defaultValue={{ value: state?.formData?.purGroup }}
                                className="rounded-10 h-50"
                                style={{ width: '100%', borderRadius: '5 !important' }}
                                size="large"
                                disabled={isOnDisplayMode}
                            />
                        )}
                    </Col>
                </Row>
                <Row gutter={15} className="my-3">
                    <Col span={7}>
                        <p className="text-nabati-grey text-base mb-1">Posting Date</p>
                        {!state.loading && (
                            <DatePicker
                                size="large"
                                className="rounded-10 h-50"
                                style={{ width: '100%' }}
                                format="YYYY-MM-DD"
                                onChange={(e) => {
                                    dispatch({
                                        type: 'SET_FORM_DATA',
                                        payload: { docDate: e.format('YYYY-MM-DD') },
                                    })
                                }}
                                defaultValue={moment(state?.formData?.docDate)}
                                disabled={isOnDisplayMode}
                            />
                        )}
                    </Col>
                    <Col span={7}>
                        <p className="text-nabati-grey text-base mb-1">Plant</p>
                        {!state.loading && (
                            <SelectDebounceFetch.Plant
                                onChange={({ value }) => {
                                    dispatch({ type: 'SET_FORM_DATA', payload: { plant: value } })
                                }}
                                defaultValue={{ value: state?.formData?.plant }}
                                className="rounded-10 h-50"
                                style={{ width: '100%' }}
                                size="large"
                                disabled={isOnDisplayMode}
                            />
                        )}
                    </Col>
                    <Col span={7}>
                        <p className="text-nabati-grey text-base mb-1">Sloc</p>
                        {!state.loading && (
                            <SelectDebounceFetch.SlocByPlant
                                onChange={({ value }) => {
                                    dispatch({
                                        type: 'SET_FORM_DATA',
                                        payload: { building: value },
                                    })
                                }}
                                defaultValue={{ value: state?.formData?.building }}
                                size="large"
                                className="rounded-10 h-50"
                                style={{ width: '100%', borderRadius: '5 !important' }}
                                disabled={isOnDisplayMode}
                            />
                        )}
                    </Col>
                    <Col span={3}>
                        <p className="text-nabati-grey text-sm">Need Forwarder</p>
                        <div className="text-black mt-5">
                            <Checkbox
                                // checked={state.formData.isNeedForwarder}
                                checked={false}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'SET_FORM_DATA',
                                        payload: { isNeedForwarder: e.target.checked },
                                    })
                                }}
                                // disabled={isOnDisplayMode}
                                disabled={true}
                            />
                        </div>
                    </Col>
                </Row>

                {!isOnCreateMode && (
                    <Row gutter={15} className="mb-10">
                        <Col span={12}>
                            <p className="text-nabati-grey text-base mb-1">PO STO</p>
                            <Input
                                size="large"
                                className="rounded-10 h-50"
                                value={state.formData.poNumber}
                            />
                        </Col>
                        <Col span={12}>
                            <p className="text-nabati-grey text-base mb-1">DO STO</p>
                            <Input
                                size="large"
                                className="rounded-10 h-50"
                                value={state.formData.doNumber}
                            />
                        </Col>
                    </Row>
                )}

                <DataTable
                    setDataTable={setDataTable}
                    initialDataTable={state.initialDataTable}
                    isOnDisplayMode={isOnDisplayMode}
                    isInPlantTransactionType={state.formData.transactionType === '0'}
                    formDataPlant={state.formData.plant}
                    formDataSloc={state.formData.building}
                    loading={state.loading}
                />

                <div align="left" className="mt-5 flex">
                    <Button
                        type="primary"
                        onClick={onClickDelete}
                        className="block w-157 h-45 rounded-10 mr-auto"
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={() => {
                            close()
                            dispatch({ type: 'RESET' })
                        }}
                        className="block outLinedGrey w-157 h-45 rounded-10 mx-2"
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={onClickSave}
                        className="block w-157 h-45 rounded-10 mx-2"
                        loading={state.loading}
                    >
                        Save
                    </Button>
                </div>
            </SimpleModal>
            <ConfirmationModal
                visible={state.confirmationSaveModal}
                close={() => dispatch({ type: 'TOGGLE_CONFIRM_SAVE', toggle: false })}
                onOk={handleSave}
                title="Confirm Save"
                content="Are you sure to save as DRAFT POSTO>DOSTO?"
                successTitleMsg="Saved"
                successContentMsg={(res) => `Transaction ID: ${res.data.transactionId}`}
            />
            <ConfirmationDeletePoSto
                payload={payload}
                visible={state.deletePoStoModal}
                close={() => {
                    dispatch({ type: 'TOGGLE_DELETE_PO_STO' })
                    close()
                }}
            />
        </>
    )
}

export default CreatePostToDoSto
