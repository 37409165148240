import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'

import { getBase64FromFile, checkImageValidity } from 'utils/base64'
import { AddUploadIcon } from 'assets'

import DropFileModal from '../DropFileModal'
import SingleImage from './SingleImageBox'

function ImageUploader({
    onChange = () => {},
    onDeleteImage = async () => {},
    multiple,
    title = 'Upload',
    initialValues = [],
}) {
    const [visible, setVisible] = useState(false)
    const [base64Images, setBase64Images] = useState([])

    const onClickDeleteImage = async (imgToDelete) => {
        // callBack
        const idxToDelete = base64Images.findIndex(
            (cur) => cur.name === imgToDelete.name && cur.data === imgToDelete.data
        )
        const imageToDelete = base64Images[idxToDelete]
        await onDeleteImage(imageToDelete)

        const newList = base64Images.filter((image, idx) => idx !== idxToDelete)
        setBase64Images([...newList])
    }

    const handleFiles = async (files) => {
        let incomingImages64 = []
        let err = false
        for (let i = 0; i < files.length; i++) {
            const [isValidImage] = checkImageValidity(files[i])

            if (!isValidImage) err = true

            const newImage = {
                name: files[i].name,
                data: await getBase64FromFile(files[i]),
            }

            incomingImages64.push(newImage)
        }

        if (err) return

        setBase64Images([...base64Images, ...incomingImages64])
        setVisible(false)
    }

    useEffect(() => {
        onChange(base64Images)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [base64Images])

    useEffect(() => {
        if (initialValues?.length === 0) return
        // return
        setBase64Images((prev) => {
            if (prev?.length === initialValues?.length) return prev
            return initialValues || []
        })
    }, [initialValues])

    return (
        <>
            <Row justify="start" gutter={[8, 8]}>
                {base64Images.map((img, idx) => (
                    <Col key={idx + img}>
                        <SingleImage
                            onClickDeleteImage={onClickDeleteImage}
                            img={img}
                            gridNumber={idx}
                        />
                    </Col>
                ))}
                <Col>
                    <AddUploadIcon className="cursor-pointer" onClick={() => setVisible(true)} />
                </Col>
            </Row>

            <DropFileModal
                visible={visible}
                close={() => setVisible(false)}
                handleFiles={handleFiles}
                title={title}
                multiple={multiple}
            />
        </>
    )
}

export default ImageUploader
