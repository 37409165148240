import React, { useState, useEffect } from 'react'
import { checkTxtValidity } from 'utils/base64'
import { AttachmentIcon } from 'assets'
import DropFileModal from '../DropFileModal'

function PalletCodesUploader({ onChange = () => {}, title = 'Upload Pallet Codes' }) {
    const [visible, setVisible] = useState(false)
    const [palletCodes, setPalletCodes] = useState([])

    const handleFiles = async (files) => {
        const [isValidTxtFile] = checkTxtValidity(files[0])
        if (!isValidTxtFile) return
        // if (files.length > 1)
        //     return Modal.error({
        //         title: 'Oops',
        //         content: 'Please select only one TXT file',
        //     })
        const fr = new FileReader()
        fr.readAsText(files[0])

        fr.onload = function (e) {
            const incomingPalletCodes = fr.result

            // Here the content has been read successfully
            setPalletCodes([...incomingPalletCodes.split('\r\n')])
            setVisible(false)
        }
    }

    useEffect(() => {
        onChange(palletCodes)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [palletCodes])

    return (
        <>
            <div
                onClick={() => setVisible(true)}
                className="border-nabati-blue text-nabati-blue mt-2 p-3 h-50 border rounded-10 flex items-center justify-between cursor-pointer"
            >
                <p>Upload Pallet Code/ID</p>
                <AttachmentIcon className="text-nabati-blue" />
            </div>

            <DropFileModal
                visible={visible}
                close={() => setVisible(false)}
                handleFiles={handleFiles}
                title={title}
            />
        </>
    )
}

export default PalletCodesUploader
