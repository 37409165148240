import { ReactComponent as NabatiLogo } from '../svg/logo-nabati.svg'
import { ReactComponent as UserIcon } from './user-icon.svg'
import { ReactComponent as DeleteIcon } from './Delete.svg'
import { ReactComponent as DeleteIconDisabled } from './DeleteDisabled.svg'
import { ReactComponent as EditIcon } from './Edit.svg'
import { ReactComponent as EditIconDisabled } from './EditGray.svg'
import { ReactComponent as EyeIcon } from './Eye.svg'
import { ReactComponent as CheckCircleIcon } from './check-circle.svg'
import { ReactComponent as AddUploadIcon } from './button add photo.svg'
import { ReactComponent as AttachmentIcon } from './attachment.svg'
import { ReactComponent as InfoIcon } from './Vector Info.svg'
import { ReactComponent as InfoGreyIcon } from './Vector Info grey.svg'
import { ReactComponent as ArrowRightIcon } from './arrow-right.svg'
import { ReactComponent as ArrowBottomIcon } from './arrow-bottom.svg'
import { ReactComponent as InfoYellowIcon } from './info.svg'
import { ReactComponent as SmHome } from './sm-home.svg'
import { ReactComponent as SmMasterData } from './sm-master.svg'
import { ReactComponent as SmUser } from './sm-maintain-user.svg'
import { ReactComponent as SmTransaction } from './sm-transaction.svg'
import { ReactComponent as SmTransport } from './sm-transport.svg'
import { ReactComponent as SmReport } from './sm-report.svg'
import { ReactComponent as SmUtility } from './sm-utility.svg'
import { ReactComponent as UploadIc } from './upload-icon.svg'
import { ReactComponent as DownloadIc } from './download-icon.svg'
import { ReactComponent as QrIc } from './qr-icon.svg'
import { ReactComponent as PalletIc } from './pallet.svg'
import { ReactComponent as Close } from './Close.svg'
import { ReactComponent as UploadCloudIc } from './upload-cloud.svg'
import { ReactComponent as CrossIc } from './x-ic.svg'
import { ReactComponent as ReloadIc } from './reload.svg'
import { ReactComponent as SmDashboard } from './smDashboard.svg'
import { ReactComponent as IcFullScreen } from './ic fullscreen.svg'
import { ReactComponent as BgWaveOcean } from './wave-ocean.svg'
import { ReactComponent as IcExclamationGold } from './exclamation-gold.svg'
import { ReactComponent as PasswordIcon } from './password.svg'

export {
    NabatiLogo,
    UserIcon,
    DeleteIcon,
    DeleteIconDisabled,
    EditIcon,
    EditIconDisabled,
    CheckCircleIcon,
    EyeIcon,
    AddUploadIcon,
    AttachmentIcon,
    InfoIcon,
    InfoGreyIcon,
    ArrowRightIcon,
    ArrowBottomIcon,
    InfoYellowIcon,
    SmHome,
    SmMasterData,
    SmUser,
    SmTransaction,
    SmTransport,
    SmReport,
    SmUtility,
    UploadIc,
    DownloadIc,
    QrIc,
    PalletIc,
    Close,
    UploadCloudIc,
    CrossIc,
    ReloadIc,
    SmDashboard,
    IcFullScreen,
    BgWaveOcean,
    IcExclamationGold,
    PasswordIcon,
}
