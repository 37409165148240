import { API_URL_PLANNER , API_URL} from 'config/env'

export const options = {
    baseURL: API_URL_PLANNER,
    // headers: {
    // "Access-Control-Allow-Credentials": "true",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // },
}

export const optionsib = {
    baseURL: API_URL,
    // headers: {
    // "Access-Control-Allow-Credentials": "true",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // },
}