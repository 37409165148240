import React from 'react'
import { Tooltip } from 'antd'

function TooltipStyled(props) {
    return (
        <Tooltip
            {...props}
            color={'#F4FBFC'}
            overlayInnerStyle={{
                borderRadius: 4,
                color: '#444444',
                fontSize: 12,
                fontWeight: '600',
            }}
        />
    )
}

export default TooltipStyled
