import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getGateList = async (params) => {
    const response = await call(METHODS.GET, '/master/gate', params)
    return response.data
}

export const createNewGate = async (data) => {
    const url = `/master/gate/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updateGate = async (data) => {
    const url = `/master/gate/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deleteGate = async (data) => {
    const url = `/master/gate/delete`
    const response = await call(METHODS.DELETE, url, data)
    return response.data
}

export const generateQrGate = async (data) => {
    const response = await call(METHODS.POST, `/master/gate/qr`, data)
    return response.data
}

export const getGateByDcLocations = async (params) => {
    const response = await call(METHODS.POST, '/master/gate/bydc', params)
    return response.data
}
