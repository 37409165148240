import { notification } from 'antd'

const error = (desc, msg = 'ERROR', duration = 10) => {
    notification.error({
        message: msg,
        description: desc,
        duration,
    })
}

const success = (desc, msg = 'SUCCESS', duration) => {
    notification.success({
        message: msg,
        description: desc,
        duration,
    })
}

const notify = {
    error,
    success,
}

export default notify
