import React, { useState, useEffect } from 'react'
import { Table, Row, Col, Tooltip, Modal, Button, Input } from 'antd'
import SimpleModal from 'components/Common/Modals/SimpleModal'
import ButtonRowSelection from 'components/Common/RichTable/ButtonRowSelection'
import PalletCodesUploader from 'components/Common/Uploader/PalletCodesUploader'

// import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { SearchOutlined } from '@ant-design/icons'
import { InfoIcon } from 'assets'
import { columns } from './column.js'
import { filterArrayByArray } from 'utils/array'
import { findPallet } from 'api/transaction/reportInventory'
import { generatePalletFromPayload } from './helper'
import { addRowKeyToData } from 'utils/misc'

function AddPallet({
    close,
    doEditItem,
    isDisplayModePallet,
    isEditMode,
    setItemsByPallets,
    payload,
    visible,
    ...props
}) {
    const [loading, setLoading] = useState(false)
    const [palletCodes, setPalletCodes] = useState([])
    const [palletsDetailData, setPalletsDetailData] = useState([])
    const [dataToShow, setDataToShow] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRow, setSelectedRow] = useState([])
    const [searchTerm, setSearchTerm] = useState()

    const rowSelection = () => ({
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRow(selectedRows)
        },
        selectedRowKeys: selectedRowKeys,
    })

    const resetState = () => {
        setSelectedRow([])
        setSelectedRowKeys([])
        setPalletsDetailData([])
        setPalletCodes([])
        setLoading(false)
        setSearchTerm('')
    }

    const handleAssignPallet = async () => {
        if (isEditMode) return handleEditPallet()
        handleAddPallet()
    }

    const handleAddPallet = () => {
        if (palletsDetailData.length === 0) {
            return Modal.error({ title: 'No Pallet Available' })
        }

        setItemsByPallets([...palletsDetailData])
        Modal.success({ title: 'Success Add Pallet' })
        close()
        resetState()
        // doRefresh()
        return
    }

    const handleEditPallet = async () => {
        const itemWithPallets = {
            ...payload,
            pallets: palletsDetailData.map((pallet) => ({
                palletId: pallet.palletId,
                palletCode: pallet.palletCode,
                binId: pallet.binId,
                qty: pallet.qty,
                uom: pallet.uom,
                sled: pallet.sled,
                manufactureDate: pallet.manufactureDate,
                stockStatus: pallet.stockType,
                slocId: pallet.slocId,
                sectorId: pallet.sectorId,
                gateId: pallet.gateId,
                dockingId: pallet.dockingId,
                staggingIn: pallet.staggingIn,
            })),
        }

        doEditItem(itemWithPallets)

        Modal.success({
            title: `Success ${itemWithPallets.pallets.length === 0 ? 'Delete' : 'Edit'} Pallet`,
        })

        close()
        resetState()
        // doRefresh()
        return
    }

    const handleDeletePallet = () => {
        const newPalletsDetailData = filterArrayByArray(palletsDetailData, selectedRow, 'palletId')
        setPalletsDetailData(newPalletsDetailData)
        setDataToShow(
            [...newPalletsDetailData].filter(
                (pallet) =>
                    pallet.palletId.toLowerCase().includes(searchTerm) ||
                    pallet.palletCode.toLowerCase().includes(searchTerm)
                // pallet.description.toLowerCase().includes(searchTerm)
            )
        )
        setSelectedRow([])
        setSelectedRowKeys([])
    }

    const getTitleText = () => {
        if (isDisplayModePallet) return 'Pallet List'
        if (isEditMode) return 'Edit Pallet'
        return 'Add Pallet'
    }

    useEffect(() => {
        if (palletCodes.length === 0) return
        const fetchData = async () => {
            setLoading(true)
            const res = await findPallet({ palletId: palletCodes.join(',') })
            if (res?.data) setPalletsDetailData([...res?.data])
            setLoading(false)
        }
        fetchData()
    }, [palletCodes])

    useEffect(() => {
        setSearchTerm('')
        setPalletsDetailData([])
        if (!isEditMode) return
        const palletFromPayload = generatePalletFromPayload(payload)
        setPalletsDetailData([...palletFromPayload])
    }, [payload, isEditMode, isDisplayModePallet, visible])

    useEffect(() => {
        if (searchTerm) return
        setDataToShow([...palletsDetailData])
    }, [palletsDetailData, searchTerm])

    return (
        <SimpleModal
            title={getTitleText()}
            footer={false}
            onOk={handleAssignPallet}
            width={636}
            visible={visible}
            {...props}
        >
            <Row gutter={20}>
                <Col span={11}>
                    {/* <SearchQueryInput
                        className="h-50 mt-2 mb-3"
                        suffix={<SearchOutlined className="text-nabati-blue" />}
                    /> */}
                    <Input
                        className="rounded-10 h-50 mt-2 mb-3"
                        size="large"
                        placeholder="Search"
                        suffix={<SearchOutlined className="text-nabati-blue" />}
                        onChange={(e) => {
                            const inputSearchTerm = e.target.value.toLowerCase()
                            setSearchTerm(inputSearchTerm)
                            setSelectedRow([])
                            setSelectedRowKeys([])
                            setDataToShow(
                                [...palletsDetailData].filter(
                                    (pallet) =>
                                        pallet.palletId.toLowerCase().includes(inputSearchTerm) ||
                                        pallet.palletCode.toLowerCase().includes(inputSearchTerm) ||
                                        pallet.batch.toLowerCase().includes(inputSearchTerm) ||
                                        pallet.description.toLowerCase().includes(inputSearchTerm)
                                )
                            )
                        }}
                        allowClear
                    />
                </Col>
                {!isEditMode && (
                    <>
                        <Col span={11}>
                            <PalletCodesUploader
                                onChange={(palletCodes) => setPalletCodes([...palletCodes])}
                            />
                        </Col>
                        <Col span={2}>
                            <Tooltip title="In Transfer Plant To Plant (by DO)">
                                <div className="flex item-center mt-6">
                                    <InfoIcon />
                                </div>
                            </Tooltip>
                        </Col>
                    </>
                )}
            </Row>
            <Row justify={'space-between'} align="middle">
                <div className="flex items-center h-8">
                    <p className="mr-5">Selected: {selectedRowKeys.length}</p>
                    {selectedRowKeys.length > 0 && (
                        <ButtonRowSelection label="Delete" onClick={handleDeletePallet} />
                    )}
                </div>
            </Row>
            <Table
                loading={loading}
                dataSource={addRowKeyToData([...dataToShow])}
                columns={columns()}
                rowClassName={(_, index) =>
                    `customizedPaddingTd ${index % 2 === 0 ? 'bg-nabati-lightGray' : ''}`
                }
                pagination={false}
                rowSelection={isEditMode ? (isDisplayModePallet ? false : rowSelection()) : false}
                rowKey="rowKey"
            />
            <div className="flex justify-end items-center">
                <Button
                    className="rounded-10 mx-1 mt-5 text-nabati-pink border-nabati-pink"
                    style={{ width: 197, height: 45 }}
                    onClick={() => {
                        close()
                        resetState()
                    }}
                    type="ghost"
                >
                    {isDisplayModePallet ? 'Close' : 'Cancel'}
                </Button>
                {!isDisplayModePallet && (
                    <Button
                        className="rounded-10 mx-1 mt-5 "
                        style={{ width: 197, height: 45 }}
                        onClick={handleAssignPallet}
                        type="primary"
                        loading={loading}
                    >
                        {isEditMode
                            ? palletsDetailData.length > 0
                                ? 'Edit Pallet'
                                : 'Delete Pallet'
                            : 'Add Pallet'}
                    </Button>
                )}
            </div>
        </SimpleModal>
    )
}

export default AddPallet
