import { Typography } from 'antd'

const { Paragraph } = Typography

const defaultEllipsisProps = {
    rows: 3,
    expandable: true,
    // suffix: string,
    symbol: 'More',
    tooltip: true,
    // onExpand: (e) => {},
    // onEllipsis: (ellipsis) => {},
}

function EllipsisText({ children, ellipsis = defaultEllipsisProps }) {
    return <Paragraph ellipsis={ellipsis}>{children}</Paragraph>
}

export default EllipsisText
