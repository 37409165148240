import { Input } from 'antd'

export const formItems = (initialValues) => {
    return [
        {
            name: 'companyId',
            label: 'Company ID',
            initialValue: initialValues?.companyId || null,
            rules: [
                {
                    required: true,
                },
                {
                    max: 4,
                    message: 'The max character length is 4',
                },
            ],
            children: (
                <Input
                    disabled={initialValues?.companyId}
                    placeholder="Company ID"
                    className="rounded-5"
                    size="large"
                />
            ),
        },
        {
            name: 'description',
            label: 'Description',
            placeholder: 'Description',
            initialValue: initialValues?.description || null,
            rules: [
                {
                    required: true,
                },
                {
                    max: 20,
                    message: 'The max character length is 20',
                },
            ],
            children: <Input placeholder="Description" className="rounded-5" size="large" />,
        },
    ]
}
