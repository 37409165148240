import React from 'react'
import { useRoutes, Outlet, Navigate } from 'react-router-dom'

// pages
import Login from 'components/pages/login'
import Maintenance from 'components/pages/maintenance'
import Page404V1 from 'components/pages/404/404-v1'
import Dashboard from 'components/layouts/dashboard'

// routes
import { dashboardRoutes } from './dashboard'
import { masterDataRoutes } from './master-data'
import { maintainUserRoutes } from './maintain-user'
import { transactionRoutes } from './transaction'
import { transportRoutes } from './transport'
import { utilityRoutes } from './utility'
import { reportRoutes } from './report'

import { IS_MAINTENANCE } from 'config/env'

const routes = [
    {
        path: '/',
        element: IS_MAINTENANCE ? <Navigate to="/maintenance" /> : <Dashboard />,
        children: [
            {
                index: true,
                element: <Outlet />,
            },
            dashboardRoutes,
            masterDataRoutes,
            maintainUserRoutes,
            transactionRoutes,
            transportRoutes,
            utilityRoutes,
            reportRoutes,
        ],
    },
    { path: '/login', element: <Login /> },
    {
        path: '/maintenance',
        element: !IS_MAINTENANCE ? <Navigate to="/" /> : <Maintenance />,
    },
    { path: '/404', element: <Page404V1 /> },
    { path: '*', element: <Page404V1 /> },
]

function AppRoutes() {
    let element = useRoutes(routes)
    return element
}

export default AppRoutes
