import moment from 'moment'
import { utils, writeFile, write } from 'xlsx'

import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'
import { options } from '../options'

export const getListStocker = async (params) => {
    params = {
        ...params,
        from: moment(params.from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        to: moment(params.to, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        limit: params.limit,
        page: params.page,
        total: params.total,
        filter: params.filter,
    }
    const response = await call(METHODS.GET, '/wh-stock-cycle/detail-list', {
        ...params,
        transactionType: '00',
    })
    return response.data
}

export const getDetailStocker = async (params) => {
    const response = await call(METHODS.GET, '/wh-stock-cycle/stock-opname-detail', params)
    return response.data
}

export const downloadListStocker = async (params) => {
    params = {
        ...params,
        from: moment(params.from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        to: moment(params.to, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        limit: params.limit,
        page: params.page,
        total: params.total,
        filter: params.filter,
    }
    const response = await call(METHODS.GET, '/wh-stock-cycle/detail-list', {
        ...params,
        transactionType: '00',
    })
    const dataResponse = response.data.result
    let ws = utils.json_to_sheet(dataResponse.data)
    let wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'sheet')
    write(wb, { bookType: 'xlsx', type: 'buffer' }) // generate a nodejs buffer
    write(wb, { bookType: 'xlsx', type: 'binary' }) // generate a binary string in web browser
    return writeFile(wb, 'detail-list.xlsx')
}

export const getListStockOpname = async (params) => {
    params = {
        ...params,
        from: moment(params.from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        to: moment(params.to, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        limit: params.limit,
        page: params.page,
        total: params.total,
        filter: params.filter,
    }
    const response = await call(METHODS.GET, '/wh-stock-cycle/stock-opname-list', params)
    return response.data
}

export const searchPallet = async (params) => {
    // params = { ...params, fromDate: params.from, toDate: params.to }
    const response = await call(METHODS.GET, '/wh-pallet-search', params, options)
    return response.data
}

export const getForemanList = async (params) => {
    // params = { ...params, fromDate: params.from, toDate: params.to }
    const response = await call(METHODS.POST, 'master/spv/finddatabydcspv', params)
    return response.data
}

export const submitStockOpname = async (params) => {
    // SUBMIT status = 00
    // DRAFT status = 06
    const response = await call(METHODS.POST, '/wh-foreman-stocker', params, options)
    return response.data
}

export const submitStockOpnameFromDraft = async (params) => {
    const response = await call(METHODS.GET, '/wh-stock-cycle/stock-opname-finish', params)
    return response.data
}

export const deleteWhStocker = async (params) => {
    const response = await call(METHODS.DELETE, '/wh-foreman-stocker', params, options)
    return response.data
}

export const deletePallet = async (params) => {
    const response = await call(
        METHODS.DELETE,
        '/wh-foreman-stocker-delete-pallet',
        params,
        options
    )
    return response.data
}

export const stockOpnameCompareSapList = async (params) => {
    const response = await call(
        METHODS.GET,
        '/wh-stock-cycle/stock-opname-compare-sap',
        params
        // options
    )
    return response.data
}

export const reCountCompareStock = async (params) => {
    const response = await call(METHODS.POST, '/wh-foreman-stocker-comparison', params, options)
    return response.data
}

export const finishCompareStock = async (params) => {
    const response = await call(METHODS.POST, '/wh-foreman-stocker-finish', params, options)
    return response.data
}

export const submitToSapCompareStock = async (params) => {
    const response = await call(
        METHODS.POST,
        'wh-stock-cycle/stock-opname-recount',
        params
        // options,
    )
    return response.data
}

// export const downloadCompareStock = async (params) => {
//     const response = await call(
//         METHODS.GET,
//         'wh-stock-cycle/stock-opname-compare-sap-export?transaction_id=500136',
//         params
//         // options
//     )
//     return response.data
// }

export const downloadCompareStock = async (params) => {
    const response = await call(METHODS.GET, '/wh-stock-cycle/stock-opname-compare-sap', params)
    const dataResponse = response.data.result
    let ws = utils.json_to_sheet(dataResponse.data)
    let wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'sheet')
    write(wb, { bookType: 'xlsx', type: 'buffer' }) // generate a nodejs buffer
    write(wb, { bookType: 'xlsx', type: 'binary' }) // generate a binary string in web browser
    return writeFile(wb, `compare-list(${params.transactionId}).xlsx`)
}
