import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getDcLocationPlantList = async (params) => {
    const response = await call(METHODS.GET, '/master/dclocationplant', params)
    return response.data
}

export const createNewDcLocationPlant = async (data) => {
    const url = `/master/dclocationplant/store`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const updateDcLocationPlant = async (data) => {
    const url = `/master/dclocationplant/update`
    const response = await call(METHODS.POST, url, data)
    return response.data
}

export const deleteDcLocationPlant = async (data) => {
    const url = `/master/dclocationplant/delete`
    const response = await call(METHODS.DELETE, url, data)
    return response.data
}

export const bulkDeleteDcLocationPlant = async () => {
    // const fakeRes = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve("");
    //   }, 500);
    // });
    // const response = await fakeRes;
    // return response;
}
