import { call } from 'api/axios'
import { METHODS } from 'config/constant/common'

export const getWhDashboardSummary = async (params) => {
    const response = await call(METHODS.POST, '/wh-dashboard/header', params)
    return response.data
}

export const getMaterialStockData = async (params) => {
    const response = await call(METHODS.POST, '/wh-dashboard/material-stock', params)
    return response.data
}

export const getPalletBinUsage = async (params) => {
    const response = await call(METHODS.POST, '/wh-dashboard/get-usage', params)
    return response.data
}
