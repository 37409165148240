import React from 'react'
import SelectDebounceFetch from './index'
import { getPurchaseOrgList } from 'api/master/purchasingOrg'

function SelectPurchOrg({ ...props }) {
    return (
        <SelectDebounceFetch
            placeholder="Purchasing Organization"
            fetchOptions={async (searchTerm) => {
                const res = await getPurchaseOrgList({
                    filter: searchTerm,
                    limit: 10,
                })
                return res.result.data.map((org) => ({
                    label: `${org.purchaseOrganization} - ${org.description}`,
                    value: org.purchaseOrganization,
                }))
            }}
            {...props}
        />
    )
}

export default SelectPurchOrg
