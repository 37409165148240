import React from 'react'
// import { Button, Row } from 'antd'

import RichTable from 'components/Common/RichTable'
// import ModalForm from 'components/Common/Modals/ModalForm'
// import ConfirmationModal from 'components/Common/Modals/ConfirmationModal'

import { columns } from './columns'
// import useQueryRefresh from 'customHooks/useQueryRefresh'

import { getRouteList } from 'api/master/route'

function Route() {
    // const [showDeleteModal, setShowDeleteModal] = useState(false)
    // const { doRefresh } = useQueryRefresh()

    // const buttons = () => (
    //     <Row justify="end">
    //         <Button
    //             onClick={() => {}}
    //             type="primary"
    //             className="bg-nabati-pink w-157 h-45 rounded-10 mx-1"
    //             size="middle"
    //         >
    //             Get From SAP
    //         </Button>
    //     </Row>
    // )

    return (
        <>
            <RichTable
                // rowKey="companyId"
                columns={columns()}
                showButtonsArea={false}
                showSearch={true}
                withRowSelection={false}
                withSearchAndFilterArea={false}
                // ButtonsAreaComponent={buttons}
                getListApi={getRouteList}
                withDatePicker={false}
                scroll={{ x: 1300 }}
            />
        </>
    )
}

export default Route
