import { Row, Col } from 'antd'
import { DeleteIcon, EditIcon } from 'assets'
// import { ReactComponent as DeleteIcon } from "assets/svg/";

export const dcLocationColumns = (onCLickEditIcon, onCLickDeleteIcon) => [
    {
        title: 'Actions',
        width: 100,
        render: (_, record) => (
            <Row gutter={8}>
                <Col className={'cursor-pointer'} onClick={() => onCLickDeleteIcon(record)}>
                    <DeleteIcon />
                </Col>
                <Col className={'cursor-pointer'} onClick={() => onCLickEditIcon(record)}>
                    <EditIcon />
                </Col>
            </Row>
        ),
    },
    {
        title: 'DC Location ID',
        dataIndex: 'dcLocationId',
        key: 'dcLocationId',
    },
    {
        title: 'Description',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Active',
        dataIndex: 'active',
        key: 'active',
    },
]
