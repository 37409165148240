import { METHODS } from 'config/constant/common'
import { call } from 'api/axios'

export const getListTransferDc = async (params) => {
    const parameter = {
        filter: params.filter,
        gate: params.filterbyGate,
        material: params.filterbyMaterial,
        plantId: params.filterbyPlant,
        transactionStatus: params.filterbyTransactionStatus,
        from: params.from,
        to: params.to,
        total: params.total ?? 50,
        page: params.page ?? 1,
        palletStatus: params.filterbyPalletStatus ?? null,
        checkPalletStatus: params.filterbyCheckPalletStatus ?? null,
        pickerPalletStatus: params.filterbyPickerPalletStatus ?? null,
    }
    const response = await call(METHODS.GET, '/report-inbound/transferdc', parameter)
    return response.data
}

export const getListPalletId = async (params) => {
    const parameter = {
        filter: params.filter,
        gate: params.filterbyGate,
        material: params.filterbyMaterial,
        companyId: params.filterbyCompany,
        plantId: params.filterbyPlant,
        from: params.from,
        to: params.to,
        total: params.total ?? 50,
        page: params.page ?? 1,
        palletStatus: params.filterbyPalletStatus ?? null,
    }
    console.log(parameter)
    const response = await call(METHODS.GET, '/report-inbound/pallet', parameter)
    return response.data
}

export const getListCheckPallet = async (params) => {
    const parameter = {
        filter: params.filter,
        gate: params.filterbyGate,
        material: params.filterbyMaterial,
        plantId: params.filterbyPlant,
        from: params.from,
        to: params.to,
        total: params.total ?? 50,
        page: params.page ?? 1,
        palletStatus: params.filterbyPalletStatus ?? null,
        transactionStatus: params.filterbyTransactionStatus,
    }
    console.log(parameter)
    const response = await call(METHODS.GET, '/report-inbound/checkpallet', parameter)
    return response.data
}

export const getListPickerDc = async (params) => {
    const parameter = {
        filter: params.filter,
        gate: params.filterbyGate,
        material: params.filterbyMaterial,
        plantId: params.filterbyPlant,
        companyId: params.filterbyCompany,
        from: params.from,
        to: params.to,
        total: params.total ?? 50,
        page: params.page ?? 1,
    }
    console.log(parameter)
    const response = await call(METHODS.GET, '/report-inbound/pickertodc', parameter)
    return response.data
}
